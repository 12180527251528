import { FormInstance } from 'antd/lib/form'
import { t } from 'i18next'
import { useMemo } from 'react'
import {
  ModalContentContainer,
  ModalTextDescription,
  ModalTextLabel,
} from '../../../../../../components'
import { DelConfigurationFormValues } from '../DelConfigurationModal'
import { delConfigurationModalTypes } from '../../../../../../constants/constants'

interface DelConfigurationModalReviewStepProps {
  form: FormInstance<DelConfigurationFormValues>
}

const DelConfigurationModalReviewStep = ({
  form,
}: DelConfigurationModalReviewStepProps) => {
  const reviewType = useMemo(() => form.getFieldValue('reviewType'), [form])

  const [reviewFileName, clientFileName] = useMemo(() => {
    if (reviewType === delConfigurationModalTypes.DIN) {
      return [
        form.getFieldValue('dinFileName'),
        form.getFieldValue('dinClientFileName'),
      ]
    } else if (reviewType === delConfigurationModalTypes.TABLE_A) {
      return [
        form.getFieldValue('tableAHcFileName'),
        form.getFieldValue('tableAComprehensiveFileName'),
      ]
    } else {
      return [null, null]
    }
  }, [form, reviewType])

  return (
    <ModalContentContainer>
      <ModalTextLabel>
        {t('delConfigurationModal.steps.review.type')}
      </ModalTextLabel>
      <ModalTextDescription>
        {reviewType === delConfigurationModalTypes.DIN
          ? t('delConfigurationModal.steps.typeStep.din')
          : t('delConfigurationModal.steps.typeStep.tableA')}
      </ModalTextDescription>

      <ModalTextLabel>
        {t('delConfigurationModal.steps.review.inputHeader')}
      </ModalTextLabel>
      <ModalTextDescription>{reviewFileName}</ModalTextDescription>
      <ModalTextLabel>
        {t('delConfigurationModal.steps.review.scopeHeader')}
      </ModalTextLabel>
      <ModalTextDescription>{clientFileName}</ModalTextDescription>
    </ModalContentContainer>
  )
}

export default DelConfigurationModalReviewStep
