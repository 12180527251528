import { ArrowLeftOutlined } from '@ant-design/icons'
import { Button, Tooltip } from 'antd'
import { t } from 'i18next'
import { useNavigate } from 'react-router-dom'

interface BackButtonProps {
  url: string
  tooltipMessage?: string
}

const BackButton = ({ url, tooltipMessage }: BackButtonProps) => {
  const navigate = useNavigate()
  return (
    <Tooltip
      title={tooltipMessage || t('backButton.tooltipMessage')}
      placement="right"
    >
      <Button
        icon={<ArrowLeftOutlined />}
        type="primary"
        onClick={() => navigate(url)}
      />
    </Tooltip>
  )
}

export default BackButton
