import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  SyncOutlined,
} from '@ant-design/icons'
import { Tag } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  ofrDocumentStatus,
  privacyTypes,
} from '../../constants/constants'
import { useOfrDocumentTemplate } from '../../hooks'
import { routePaths } from '../RootPage'
import CreateOfrFormModal from './modals/CreateOfrDocumentTemplateModal.tsx'

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0;
`

const InitialMessageContainer = styled.div`
  font-weight: 600;
  margin: auto;
`

const OfrDocumentTemplateListPage = () => {
  const navigate = useNavigate()

  const {
    ofrDocumentTemplateList,
    loading,
    deleteOfrDocumentTemplate,
    exportOfrDocumentTemplateList,
  } = useOfrDocumentTemplate({})

  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const formattedData = useMemo(
    () =>
      ofrDocumentTemplateList?.map((ofrForm) => ({
        ...ofrForm,
        key: ofrForm.id,
      })) || [],
    [ofrDocumentTemplateList]
  )

  const handleCancelDelete = (event: MouseEvent) => {
    event.stopPropagation()
    setDeleteId(undefined)
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = (event: MouseEvent) => {
    event.stopPropagation()
    if (deleteId) {
      deleteOfrDocumentTemplate({
        documentId: deleteId,
        navigate: null,
      })
    }
    setDeleteId(undefined)
    setConfirmDeleteModalOpen(false)
  }

  const handleClick = (id: string) => {
    navigate(`${routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS}/${id}`)
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setDeleteId(id)
    setConfirmDeleteModalOpen(true)
  }, [])

  const statusIcons = {
    [ofrDocumentStatus.PROCESSING]: <SyncOutlined spin />,
    [ofrDocumentStatus.READY]: <CheckCircleOutlined />,
    [ofrDocumentStatus.FAILED]: <CloseCircleOutlined />,
    [ofrDocumentStatus.INGESTION]: <SyncOutlined spin />,
  }

  const statusColorKeys = {
    [ofrDocumentStatus.PROCESSING]: 'processing',
    [ofrDocumentStatus.READY]: 'success',
    [ofrDocumentStatus.FAILED]: 'error',
    [ofrDocumentStatus.INGESTION]: 'processing',
  }

  const statusTextMap = {
    [ofrDocumentStatus.PROCESSING]: 'Processing',
    [ofrDocumentStatus.READY]: 'Ready',
    [ofrDocumentStatus.FAILED]: 'Failed',
    [ofrDocumentStatus.INGESTION]: 'Ingestion',
  }

  const renderStatusTag = (text: string) => {
    const tagText =
      text in statusTextMap ? statusTextMap[text] : NO_DATA_PLACEHOLDER
    return (
      <Tag icon={statusIcons[text]} color={statusColorKeys[text] || 'default'}>
        {tagText || NO_DATA_PLACEHOLDER}
      </Tag>
    )
  }

  const columns: ColumnsType<any> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Status'),
      dataIndex: 'status',
      key: 'status',
      render: renderStatusTag,
    },

    {
      title: t('Last Update'),
      key: 'lastModifiedDate',
      dataIndex: 'lastModifiedDate',
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },

    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id, status, visibility }) => (
        <>
          {visibility !== privacyTypes.PUBLIC && (
            <DeleteIconButton
              disabled={status === ofrDocumentStatus.PROCESSING}
              tooltipTitle={t(
                'ofrDocumentTemplateListPage.deleteAnnotationTemplateTooltip'
              )}
              onClick={(event: MouseEvent) => handleDeleteClick(event, id)}
            />
          )}
        </>
      ),
    },
  ]

  const handleExportOfrList = useCallback(() => {
    exportOfrDocumentTemplateList()
  }, [exportOfrDocumentTemplateList])

  return (
    <>
      <ListPageLayout>
        <ListPageHeader
          title={t('ofrDocumentTemplateListPage.title', {
            count: formattedData.length,
          })}
          actions={
            <>
              <CreateOfrFormModal />
              <PageContentDropdown
                onExport={handleExportOfrList}
                disabled={formattedData.length === 0}
              />
            </>
          }
        />
        {formattedData.length === 0 ? (
          <FullPageContainer>
            <InitialMessageContainer>
              {t('ofrDocumentTemplateListPage.initialMessage')}
            </InitialMessageContainer>
          </FullPageContainer>
        ) : (
          <Table
            loading={loading}
            rowClassName={(record) => {
              if (record.status === ofrDocumentStatus.PROCESSING) {
                return 'page-list-table-row-disabled'
              }
              return 'page-list-table-row clickable'
            }}
            columns={columns}
            dataSource={formattedData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 170px)' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  // only allow click on row if status is Ready
                  if (record.status === ofrDocumentStatus.READY)
                    handleClick(record.id)
                }, // click row
              }
            }}
          />
        )}
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          confirmButtonLabel={
            t('ofrDocumentTemplateListPage.confirmDeleteModal.yesButton') || ''
          }
        >
          {t('ofrDocumentTemplateListPage.confirmDeleteModal.content')}
        </ConfirmDeleteModal>
      </ListPageLayout>
    </>
  )
}

export default OfrDocumentTemplateListPage
