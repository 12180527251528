import { biomarkFormRanges } from '../../constants/constants'
import { AlgorithmRun } from '../../store/reducers/workspaceReducer'

const workspaceAlgorithmRunBiomark: AlgorithmRun = {
  id: '56241',
  workspace: undefined,
  dataSource: undefined,
  runStatus: 'COMPLETE',
  actionBy: {
    id: '63d9602a68abf834dee77a07',
    email: 'roy.chowdhury@genaiz.com',
    displayName: 'Roy Chowdhury',
    role: '63f79441930987a1621ef6fd',
    isActive: true,
    language: 'en',
    createdBy: '63f79441930987a1621ef6e0',
    lastModifiedBy: '63d9602a68abf834dee77a07',
  },
  config: {
    maxScore: biomarkFormRanges.maxScore.default,
    trials: biomarkFormRanges.trials.default,
    testPercent: biomarkFormRanges.testPercent.default,
    generations: biomarkFormRanges.generations.default,
    population: biomarkFormRanges.population.default,
    mutationRate: biomarkFormRanges.mutationRate.default,
    numMutants: biomarkFormRanges.numMutants.default,
    numXovers: biomarkFormRanges.numXovers.default,
    numRefines: biomarkFormRanges.numRefines.default,
    modelType: biomarkFormRanges.modelType.default,
    language: 'en',
  },
  resultPath: '/mnt/shared-data/algorithm_result_files/56108-56241.json',
  completedDate: '2023-11-07T20:09:41.098Z',
  actionDate: '2023-11-07T20:09:32.474Z',
  runResult: {
    bestMask: [1, 1, 0, 1, 1, 1, 1, 0, 1, 0, 1, 1, 1, 0],
    featureRanking: [
      {
        index: 5,
        columnName: 'elevation',
        importance: 0.08156086504459381,
        importancePercentage: 0.52082759141922,
      },
      {
        index: 6,
        columnName: 'horizontal_distance_to_hydrology',
        importance: 0.007772507611662149,
        importancePercentage: 0.049633320420980453,
      },
      {
        index: 8,
        columnName: 'vertical_distance_to_hydrology',
        importance: 0.0076436847448349,
        importancePercentage: 0.0488106906414032,
      },
      {
        index: 10,
        columnName: 'horizontal_distance_to_roadways',
        importance: 0.009797056205570698,
        importancePercentage: 0.06256158649921417,
      },
    ],
    f1Score: 0.7130108893773179,
  },
}

export default workspaceAlgorithmRunBiomark
