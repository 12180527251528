import axios from './axiosFactory'
import { urlList } from '../constants/constants'
import moment from 'moment'
import apiService from './apiService'
import { NavigateFunction } from 'react-router-dom'
import { BackEndSortOrder } from '../types/generalTypes'

interface SearchProps {
  searchQuery: string
  searchMethod: string
  source: string
  startDate: string
  endDate: string
  page?: number
  sortField?: string
  sortOrder?: BackEndSortOrder
  navigate?: NavigateFunction
}

const search = async ({
  searchQuery,
  searchMethod,
  source,
  startDate,
  endDate,
  page = 1,
  sortField,
  sortOrder,
  navigate,
}: SearchProps) => {
  const params = new URLSearchParams()
  let sortParams = ''
  if (sortField) {
    sortParams += `&sortBy=${sortField}`
    if (sortOrder) {
      sortParams += `&sortBy=${sortOrder}`
    }
  }
  params.append('searchQuery', searchQuery)
  params.append('searchMethod', searchMethod)
  params.append('source', source)

  if (moment(startDate).isValid()) {
    params.append('lastModifiedDateStart', startDate)
  }
  if (moment(endDate).isValid()) {
    params.append('lastModifiedDateEnd', endDate)
  }

  return axios()
    .post(
      urlList.SEARCH + `?language=en&pageSize=100&page=${page}${sortParams}`,
      params
    )
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      apiService.notificationError(error)
      console.error('axios search error', error)
      apiService.handleError401(error, navigate)
      throw error
    })
}

const searchService = {
  search,
}

export default searchService
