import { urlList } from '../constants/constants'
import apiService from './apiService'

const baseUrl = urlList.EGNYTE_FOLDERS

interface validateEgnyteConfigurationProps {
  code: string
  domainName: string
  folderName: string
}

const validateEgnyteConfiguration = async ({
  code,
  domainName,
  folderName,
}: validateEgnyteConfigurationProps) => {
  return apiService
    .fetchItems(
      `${baseUrl}?domainName=${domainName}&folderName=${folderName}&code=${code}`
    )
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const egnyteService = {
  validateEgnyteConfiguration,
}

export default egnyteService
