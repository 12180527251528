import { AxiosError, isAxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode } from 'react'
import { NavigateFunction } from 'react-router-dom'
import styled from 'styled-components'
import { httpErrorStatus } from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import { ErrorResponse } from '../types/generalTypes'
import axiosFactory from './axiosFactory'
import mockService from './mockService'
import notificationService from './notificationService'

const MAX_RETRIES = 3
const RETRY_DELAY = 1000 // in milliseconds
const handleError401 = (
  error: AxiosError | Error,
  navigate?: NavigateFunction
) => {
  if (
    isAxiosError(error) &&
    error.response?.status === httpErrorStatus.ERROR_401_UNAUTHORIZED &&
    navigate
  ) {
    navigate(routePaths.LOGIN_UNAUTHORIZED)
  }
}

const fetchItems = (url: string) => {
  if (mockService.isApiMocked(url)) {
    return mockService
      .getMock(url)
      .then((response: any) => {
        return response
      })
      .catch((error: AxiosError | Error) => {
        notificationError(error)
        console.error('axios fetchItems error', error)
        throw error
      })
  } else {
    return axiosFactory()
      .get(url)
      .then((response: any) => {
        return response.data
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetchItems error', error)
        throw error
      })
  }
}

const fetchWithRetry = async (
  url: string,
  retries = MAX_RETRIES,
  delay = RETRY_DELAY
) => {
  for (let attempt = 1; attempt <= retries; attempt++) {
    try {
      const response = await apiService.fetchItems(url)
      return response
    } catch (error) {
      if (attempt === retries) {
        throw error
      }
      await new Promise((resolve) => setTimeout(resolve, delay))
    }
  }
}

const saveItem = (url: string, navigate?: NavigateFunction, params?: any) => {
  return axiosFactory()
    .post(url, params)
    .then((response: any) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      notificationError(error)
      console.error('axios saveItem error', error)
      handleError401(error, navigate)
      throw error
    })
}

const updateItem = (url: string, navigate?: NavigateFunction, params?: any) => {
  return axiosFactory()
    .put(url, params)
    .then((response: any) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      notificationError(error)
      console.error('axios updateItem error', error)
      handleError401(error, navigate)
      throw error
    })
}

const patchItem = (url: string, navigate: NavigateFunction, params?: any) => {
  return axiosFactory()
    .patch(url, params)
    .then((response: any) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      notificationError(error)
      console.error('axios updateItem error', error)
      handleError401(error, navigate)
      throw error
    })
}

const deleteItem = (url: string, navigate: NavigateFunction, params?: any) => {
  return axiosFactory()
    .delete(url, params)
    .then((response: any) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      notificationError(error)
      console.error('axios deleteItem error', error)
      handleError401(error, navigate)
      throw error
    })
}

const ErrorListContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const getListErrorMessage = (error: AxiosError) => {
  const responseData = error.response?.data
  const errorData: ErrorResponse =
    typeof responseData === 'string'
      ? { errors: [{ message: responseData }] }
      : typeof (responseData as any)?.message === 'string'
      ? { errors: [{ message: (responseData as any)?.message }] }
      : (responseData as ErrorResponse)
  return (
    <ErrorListContainer>
      {errorData.errors.map((error) => (
        <div>{error.message}</div>
      ))}
    </ErrorListContainer>
  )
}

const notificationError = (error: AxiosError | Error) => {
  if (isAxiosError(error)) {
    switch (error.response?.status) {
      case httpErrorStatus.ERROR_400_BAD_REQUEST:
      case httpErrorStatus.ERROR_403_FORBIDDEN:
      case httpErrorStatus.ERROR_404_NOT_FOUND:
        const errorList: ReactNode = getListErrorMessage(error)
        notificationService.notificationError(errorList)
        break
      case httpErrorStatus.ERROR_500_INTERNAL_SERVER_ERROR:
        notificationService.notificationError(t('errorMessage.error500'))
        break
      case httpErrorStatus.ERROR_502_BAD_GATEWAY:
        notificationService.notificationError(t('errorMessage.error502'))
        break
      case httpErrorStatus.ERROR_503_SERVICE_UNAVAILABLE:
        notificationService.notificationError(t('errorMessage.error503'))
        break
    }
  }
}

const apiService = {
  fetchItems,
  fetchWithRetry,
  saveItem,
  updateItem,
  patchItem,
  deleteItem,
  notificationError,
  handleError401,
}

export default apiService
