import {
  CalendarOutlined,
  FieldTimeOutlined,
  TagsOutlined,
  UnlockOutlined,
  UserOutlined,
} from '@ant-design/icons'
import { Form } from 'antd'
import Paragraph from 'antd/lib/typography/Paragraph'
import { t } from 'i18next'
import { useMemo, useState } from 'react'
import Moment from 'react-moment'
import {
  IconTextContainer,
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
} from '../../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  workspaceTypes,
} from '../../../constants/constants'
import { useUsers } from '../../../hooks'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import BioMarkerSmartFunctionStatisticsPanel from '../components/statistics/BioMarkerSmartFunctionStatisticsPanel'
import RefAiSmartFunctionStatisticsPanel from '../components/statistics/RefAiSmartFunctionStatisticsPanel'
import RegulatorySubmissionSmartFunctionStatisticsPanel from '../components/statistics/RegulatorySubmissionSmartFunctionStatisticsPanel'
import QualityReviewSmartFunctionStatisticsPanel from '../components/statistics/QualityReviewSmartFunctionStatisticsPanel'

const WorkspaceOverviewInfoPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const { userList } = useUsers({})
  const lastModifiedUser = useMemo(
    () =>
      userList?.find((user) => user.id === selectedWorkspace?.lastModifiedBy),
    [userList, selectedWorkspace]
  )
  const createdByUser = useMemo(
    () => userList?.find((user) => user.id === selectedWorkspace?.createdBy),
    [userList, selectedWorkspace]
  )
  const [statisticsContent, setStatisticsContent] =
    useState<JSX.Element | null>(null)

  useMemo(() => {
    let statistic = null
    switch (selectedWorkspace?.typeCode) {
      case workspaceTypes.REFAI:
        statistic = <RefAiSmartFunctionStatisticsPanel />
        break
      case workspaceTypes.BIOMARK:
        statistic = <BioMarkerSmartFunctionStatisticsPanel />
        break
      case workspaceTypes.REGSUB:
        statistic = <RegulatorySubmissionSmartFunctionStatisticsPanel />
        break
      case workspaceTypes.OFR_QR:
        statistic = <QualityReviewSmartFunctionStatisticsPanel />
        break
      default:
        statistic = null
    }
    setStatisticsContent(statistic)
  }, [selectedWorkspace])

  const canShowStatistics = useMemo(() => {
    return selectedWorkspace?.typeCode !== workspaceTypes.DEL
  }, [selectedWorkspace])

  return (
    <MultiColumnFormContentContainer>
      <MultiColumnFormColumnContainer>
        <Form.Item label={t('Title')}>
          {selectedWorkspace?.title || NO_DATA_PLACEHOLDER}
        </Form.Item>
        <Form.Item label={t('Description')}>
          {selectedWorkspace?.description || NO_DATA_PLACEHOLDER}
        </Form.Item>
        <Form.Item label={t('Created')}>
          <IconTextContainer>
            <CalendarOutlined />
            {selectedWorkspace?.createdDate ? (
              <Moment
                date={selectedWorkspace?.createdDate}
                format={dateFormat.PRIMARY}
              />
            ) : (
              NO_DATA_PLACEHOLDER
            )}
          </IconTextContainer>
        </Form.Item>
        <Form.Item label={t('Last Update')}>
          <IconTextContainer>
            <FieldTimeOutlined />
            {selectedWorkspace?.lastModifiedDate ? (
              <Moment
                date={selectedWorkspace?.lastModifiedDate}
                format={dateFormat.PRIMARY}
              />
            ) : (
              NO_DATA_PLACEHOLDER
            )}
          </IconTextContainer>
        </Form.Item>
        <Form.Item label={t('Last activity')}>
          <IconTextContainer>
            <UserOutlined />
            {lastModifiedUser?.displayName || NO_DATA_PLACEHOLDER}
          </IconTextContainer>
        </Form.Item>
        <Form.Item label={t('Specifications')}>
          <IconTextContainer>
            <TagsOutlined />
            {selectedWorkspace?.typeName || NO_DATA_PLACEHOLDER}
          </IconTextContainer>
        </Form.Item>
        <Form.Item label={t('Privacy Settings')}>
          <IconTextContainer>
            <UnlockOutlined />
            {selectedWorkspace?.visibility
              ? t(selectedWorkspace?.visibility)
              : NO_DATA_PLACEHOLDER}
          </IconTextContainer>
        </Form.Item>
        <Form.Item label={t('Owner')}>
          <IconTextContainer>
            <UserOutlined />
            {createdByUser?.displayName || NO_DATA_PLACEHOLDER}
          </IconTextContainer>
        </Form.Item>
      </MultiColumnFormColumnContainer>
      {canShowStatistics && (
        <MultiColumnFormColumnContainer>
          <Form.Item
            label={t(
              'workspaceDetailsPage.workspaceOverviewInfoPanel.secondColumnTitle'
            )}
          >
            {selectedWorkspace?.algorithmRuns === undefined ||
            selectedWorkspace?.algorithmRuns?.length === 0 ? (
              <Paragraph
                style={{
                  paddingLeft: '8px',
                  fontWeight: 400,
                  color: '#434343',
                }}
              >
                {t(
                  'workspaceDetailsPage.workspaceOverviewInfoPanel.secondColumnDescription'
                )}
              </Paragraph>
            ) : (
              statisticsContent
            )}
          </Form.Item>
        </MultiColumnFormColumnContainer>
      )}
    </MultiColumnFormContentContainer>
  )
}

export default WorkspaceOverviewInfoPanel
