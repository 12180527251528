import { Form, InputNumber, Radio, Space } from 'antd'
import { FormInstance } from 'antd/lib'
import { t } from 'i18next'
import { useState } from 'react'
import styled from 'styled-components'
import { RangeSlider } from '../../../../../../components/RangeSlider'
import { biomarkFormRanges } from '../../../../../../constants/constants'
import { BiomarkConfigValues } from '../../../../../../store/reducers/workspaceReducer'

interface BiomarkConfigurationModalParametersStepProps {
  form: FormInstance<BiomarkConfigValues>
}

const RangeSliderContainer = styled.div`
  margin-left: 6px;
`

const StyledInputNumber = styled(InputNumber)`
  width: 100%;
`

const BiomarkConfigurationModalParametersStep = ({
  form,
}: BiomarkConfigurationModalParametersStepProps) => {
  
  const [parameters, setParameters] = useState<BiomarkConfigValues>({
    maxScore:
      form.getFieldValue('maxScore') || biomarkFormRanges.maxScore.default,
    trials: form.getFieldValue('trials') || biomarkFormRanges.trials.default,
    testPercent:
      form.getFieldValue('testPercent') ||
      biomarkFormRanges.testPercent.default,
    generations:
      form.getFieldValue('generations') ||
      biomarkFormRanges.generations.default,
    population:
      form.getFieldValue('population') || biomarkFormRanges.population.min,
    mutationRate:
      form.getFieldValue('mutationRate') ||
      biomarkFormRanges.mutationRate.default,
    numMutants:
      form.getFieldValue('numMutants') || biomarkFormRanges.numMutants.default,
    numXovers:
      form.getFieldValue('numXovers') || biomarkFormRanges.numXovers.min,
    numRefines:
      form.getFieldValue('numRefines') || biomarkFormRanges.numRefines.min,
    modelType:
      form.getFieldValue('modelType') || biomarkFormRanges.modelType.default,
    language: 'en',
  })

  const handleChange = (value: number | null, name: string) => {
    if (value) form.setFieldsValue({ [name]: value })
    setParameters({ ...parameters, [name]: value })
  }

  return (
    <>
      <Form.Item
        name="maxScore"
        label={t('biomarkConfigurationModal.parameterStep.maxScore.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.maxScore.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <RangeSliderContainer>
          <RangeSlider
            min={biomarkFormRanges.maxScore.min}
            max={biomarkFormRanges.maxScore.max}
            onChange={(value: number | null) => handleChange(value, 'maxScore')}
            value={parameters.maxScore || 0}
            isDecimal={true}
          />
        </RangeSliderContainer>
      </Form.Item>
      <Form.Item
        name="trials"
        label={t('biomarkConfigurationModal.parameterStep.trials.title')}
        extra={t('biomarkConfigurationModal.parameterStep.trials.description')}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <RangeSliderContainer>
          <RangeSlider
            min={biomarkFormRanges.trials.min}
            max={biomarkFormRanges.trials.max}
            onChange={(value: number | null) => handleChange(value, 'trials')}
            value={parameters.trials || 0}
          />
        </RangeSliderContainer>
      </Form.Item>
      <Form.Item
        name="testPercent"
        label={t('biomarkConfigurationModal.parameterStep.testPercent.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.testPercent.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <RangeSliderContainer>
          <RangeSlider
            min={biomarkFormRanges.testPercent.min}
            max={biomarkFormRanges.testPercent.max}
            onChange={(value: number | null) => 
              handleChange(value, 'testPercent')
            }
            value={parameters.testPercent || 0}
          />
        </RangeSliderContainer>
      </Form.Item>
      <Form.Item
        name="generations"
        label={t('biomarkConfigurationModal.parameterStep.generations.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.generations.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <StyledInputNumber
          min={biomarkFormRanges.generations.min}
          max={biomarkFormRanges.generations.max}
          step={1}
          value={parameters.generations}
          onChange={(value: number | null) =>
            handleChange(value, 'generations')
          }
        />
      </Form.Item>
      <Form.Item
        name="population"
        label={t('biomarkConfigurationModal.parameterStep.population.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.population.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <StyledInputNumber
          min={biomarkFormRanges.population.min}
          max={biomarkFormRanges.population.max}
          step={1}
          value={parameters.population}
          onChange={(value: number | null) => handleChange(value, 'population')}
        />
      </Form.Item>

      <Form.Item
        name="mutationRate"
        label={t('biomarkConfigurationModal.parameterStep.mutationRate.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.mutationRate.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <RangeSliderContainer>
          <RangeSlider
            min={biomarkFormRanges.mutationRate.min}
            max={biomarkFormRanges.mutationRate.max}
            onChange={(value: number | null) =>
              handleChange(value, 'mutationRate')
            }
            value={parameters.mutationRate || 0}
            isDecimal={true}
          />
        </RangeSliderContainer>
      </Form.Item>
      <Form.Item
        name="numMutants"
        label={t('biomarkConfigurationModal.parameterStep.numMutants.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.numMutants.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <StyledInputNumber
          min={biomarkFormRanges.numMutants.min}
          max={biomarkFormRanges.numMutants.max}
          step={1}
          value={parameters.numMutants}
          onChange={(value: number | null) => handleChange(value, 'numMutants')}
        />
      </Form.Item>
      <Form.Item
        name="numXovers"
        label={t('biomarkConfigurationModal.parameterStep.numXovers.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.numXovers.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <StyledInputNumber
          min={biomarkFormRanges.numXovers.min}
          max={biomarkFormRanges.numXovers.max}
          step={1}
          value={parameters.numXovers}
          onChange={(value: number | null) => handleChange(value, 'numXovers')}
        />
      </Form.Item>
      <Form.Item
        name="numRefines"
        label={t('biomarkConfigurationModal.parameterStep.numRefines.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.numRefines.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <StyledInputNumber
          min={biomarkFormRanges.numRefines.min}
          max={biomarkFormRanges.numRefines.max}
          step={1}
          value={parameters.numRefines}
          onChange={(value: number | null) => handleChange(value, 'numRefines')}
        />
      </Form.Item>

      <Form.Item
        name="modelType"
        label={t('biomarkConfigurationModal.parameterStep.modelType.title')}
        extra={t(
          'biomarkConfigurationModal.parameterStep.modelType.description'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <Radio.Group
          onChange={(e: any) => handleChange(e.target.value, 'modelType')}
          value={parameters.modelType}
        >
          <Space direction="vertical">
            {biomarkFormRanges.modelType.types.map((type: string) => (
              <Radio key={type} value={type}>
                {t(`biomarkConfigurationModal.parameterStep.modelType.${type}`)}
              </Radio>
            ))}
          </Space>
        </Radio.Group>
      </Form.Item>
    </>
  )
}

export default BiomarkConfigurationModalParametersStep
