import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CloudDownloadOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import styled from 'styled-components'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px;
`

interface CreateDataStoreFooterProps {
  onCancel: () => void
  onNext?: () => void
  nextDisabled?: boolean
  onPrevious?: () => void
  onSubmit?: () => void
}

const CreateDataStoreFooter = memo(
  ({
    onCancel,
    onNext,
    nextDisabled = false,
    onPrevious,
    onSubmit,
  }: CreateDataStoreFooterProps) => {
    return (
      <Footer>
        <Button onClick={onCancel} type="text">
          {t('Cancel')}
        </Button>
        {onPrevious && (
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={onPrevious}>
            {t('Previous')}
          </Button>
        )}
        {onNext && (
          <Button
            icon={<ArrowRightOutlined />}
            disabled={nextDisabled}
            type="primary"
            onClick={onNext}
          >
            {t('Next')}
          </Button>
        )}
        {onSubmit && (
          <Button icon={<CloudDownloadOutlined />} type="primary" onClick={onSubmit}>
            {t('dataStoresListPage.addDataStoreButton')}
          </Button>
        )}
      </Footer>
    )
  }
)

export default CreateDataStoreFooter
