import React from 'react'
import { InboxOutlined } from '@ant-design/icons'
import { styled } from 'styled-components'
import { BackButton, PageContentDropdown, Spacer } from '../../../components'
import { routePaths } from '../../RootPage'
import { t } from 'i18next'

const SectionHeaderContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  gap: 16px;
`

interface SearchDocumentSummariesPageHeaderProps {
  total: number
}

const SearchDocumentSummariesPageHeader = ({
  total,
}: SearchDocumentSummariesPageHeaderProps) => {
  return (
    <SectionHeaderContainer>
      <BackButton url={routePaths.SEARCH} />
      <div>
        <InboxOutlined />{' '}
        {t('searchDocumentSummariesPage.found', { count: total })}
      </div>
      <Spacer />
      <PageContentDropdown disabled />
    </SectionHeaderContainer>
  )
}

export default SearchDocumentSummariesPageHeader
