import { Form, FormInstance } from 'antd'
import TextArea from 'antd/lib/input/TextArea'
import { t } from 'i18next'
import styled from 'styled-components'
import { SelectLanguage, SelectRole } from '../../../../components'
import { EMAIL_FORMAT_REGEX } from '../../../../constants/constants'
import { Role } from '../../../../store/reducers/roleReducer'
import { Language } from '../../../../types/generalTypes'
import { CreateUserFormValues } from '../CreateUserModal'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const HeaderTextContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-top: 8px;
`

interface CreateUserModalDefinitionStepProps {
  form: FormInstance
  setSelectedRole: (role: Role | undefined) => void
  setSelectedLanguage: (language: Language) => void
  setEmails: (emails: string) => void
  handleRoleChange: (value: string) => void
}

const CreateUserModalDefinitionStep = ({
  form,
  setEmails,
  handleRoleChange,
  setSelectedLanguage,
}: CreateUserModalDefinitionStepProps) => {
  const defaultValues: CreateUserFormValues = {
    emails: '',
    userRole: '',
    language: 'en',
  }

  const isValidEmailList = (emails: string) => {
    const emailArray = emails.split(',')
    const invalidEmails = emailArray.filter(
      (email: string) => !EMAIL_FORMAT_REGEX.test(email.trim())
    )

    return !(invalidEmails.length >= 1)
  }
  return (
    <ContentContainer>
      <HeaderTextContainer>
        {t('userListPage.createUserModal.emailDescription')}
      </HeaderTextContainer>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultValues}
        requiredMark={false}
      >
        <Form.Item
          label={t('userListPage.createUserModal.emailLabel')}
          required
          name="emails"
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
            {
              validator: (_, value) => {
                if (isValidEmailList(value)) {
                  return Promise.resolve()
                } else {
                  return Promise.reject(t('errorMessage.invalidEmail') || '')
                }
              },
            },
          ]}
          extra={t('userListPage.createUserModal.emailHelper')}
        >
          <TextArea
            placeholder={
              t('userListPage.createUserModal.emailPlaceholder') || ''
            }
            style={{ height: 100 }}
            onChange={(event) => setEmails(event.target.value)}
          />
        </Form.Item>
        <Form.Item
          label={t('userListPage.createUserModal.userRole')}
          name="userRole"
        >
          <SelectRole onChange={handleRoleChange} preventFetch />
        </Form.Item>
        <Form.Item label={t('Language')} name="language">
          <SelectLanguage
            onChange={(value) => setSelectedLanguage(value || 'en')}
          />
        </Form.Item>
      </Form>
    </ContentContainer>
  )
}

export default CreateUserModalDefinitionStep
