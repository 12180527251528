import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAlgorithmRunDetails } from '../../../../hooks'
import useWorkspaceDetails from '../../../../hooks/useWorkspaceDetails'
import { RegulatorySubmissionRunResult } from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import SmartFunctionHeader from '../../components/SmartFunctionHeader'

interface RegulatorySubmissionSmartFunctionDetailsPageHeaderProps {
  setSearch: (search: string) => void
  search: string
}

const RegulatorySubmissionSmartFunctionDetailsPageHeader = ({
  setSearch,
  search,
}: RegulatorySubmissionSmartFunctionDetailsPageHeaderProps) => {
  const { workspaceId, algorithmRunId } = useParams()

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })
  const smartFunctionResult = useMemo(
    () => selectedAlgorithmRun?.runResult as RegulatorySubmissionRunResult,
    [selectedAlgorithmRun?.runResult]
  )
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const searchedRegulatoryItems = useMemo(
    () =>
      smartFunctionResult?.absent.filter((regulatoryItem) =>
        !!search ? regulatoryItem.includes(search || '') : true
      ) || [],
    [search, smartFunctionResult]
  )

  const backButtonUrl = useMemo(
    () =>
      `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`,
    [selectedWorkspace?.id]
  )

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  return (
    <SmartFunctionHeader
      backButtonUrl={backButtonUrl}
      totalFound={searchedRegulatoryItems?.length || 0}
      onSearchAction={handleSearch}
      onSearchChange={(event) => handleSearch(event.target.value)}
    ></SmartFunctionHeader>
  )
}

export default RegulatorySubmissionSmartFunctionDetailsPageHeader
