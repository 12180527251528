import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { setTheme, setThemeConfig } from '../store/reducers/themeReducer'
import { darkTheme, lightTheme, themeColorKeys, ThemeType } from '../theme'

const useTheme = () => {
  const dispatch = useDispatch()

  const selectedTheme = useSelector(
    (state: RootState) => state.theme.selectedTheme
  )

  const selectedThemeConfig = useSelector(
    (state: RootState) => state.theme.selectedThemeConfig
  )

  const toggleTheme = () => {
    const newTheme =
      selectedTheme === themeColorKeys.light
        ? themeColorKeys.dark
        : themeColorKeys.light
    dispatch(setTheme(newTheme))
    document.documentElement.setAttribute('data-theme', newTheme)
    localStorage.setItem('theme', newTheme)
  }

  useEffect(() => {
    const storedTheme = localStorage.getItem('theme') as ThemeType
    if (
      storedTheme &&
      [themeColorKeys.light, themeColorKeys.dark].includes(storedTheme)
    ) {
      dispatch(setTheme(storedTheme))
      document.documentElement.setAttribute('data-theme', storedTheme)
    }
  }, [dispatch])

  useEffect(() => {
    const themeConfig =
      selectedTheme === themeColorKeys.light ? lightTheme : darkTheme
    dispatch(setThemeConfig(themeConfig))
  }, [dispatch, selectedTheme])

  return { selectedTheme, toggleTheme, themeConfiguration: selectedThemeConfig }
}

export default useTheme
