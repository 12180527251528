import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { Switch } from 'antd'
import { MouseEvent, memo, useEffect, useState } from 'react'

interface SimpleSwitchProps {
  checked?: boolean
  onClick?: (value: boolean) => void
  loading?: boolean
  disabled?: boolean
}

const SimpleSwitch = memo(
  ({ checked, onClick, loading, ...props }: SimpleSwitchProps) => {
    const [val, setVal] = useState(!!checked)
    const handleClick = (value: boolean, e: MouseEvent) => {
      e.stopPropagation()
      setVal(value)
      if (onClick) {
        onClick(value)
      }
    }

    useEffect(() => {
      setVal(!!checked)
    }, [checked])

    return (
      <Switch
        checked={val}
        onClick={handleClick}
        loading={loading}
        checkedChildren={<CheckOutlined />}
        unCheckedChildren={<CloseOutlined />}
        {...props}
      />
    )
  }
)

export default SimpleSwitch
