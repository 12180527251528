import { Select } from 'antd'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useUsers } from '../hooks'
import { User } from '../store/reducers/userReducer'

interface SelectOption {
  key: string
  value: string
  label: string
  disabled?: boolean
}

interface SelectParticpantProps {
  onChange?: (value: string[] | undefined) => void
  disabled?: boolean
  initialParticipants?: string[]
  allParticipants?: User[]
  disableRemove?: boolean
}

const SelectParticipant = memo(
  ({
    onChange,
    disabled,
    initialParticipants,
    allParticipants,
    disableRemove,
  }: SelectParticpantProps) => {
    const { userList: allUsers, loading } = useUsers({})
    const [participants, setParticipants] = useState<string[]>(
      initialParticipants || []
    )

    const options: SelectOption[] = useMemo(() => {
      const users =
        allParticipants !== undefined && allParticipants.length !== 0
          ? allParticipants
          : allUsers
      return (users ?? []).map((user: User) => ({
        key: user.id,
        value: user.id,
        label: user.displayName,
        ...(disableRemove && {
          disabled: initialParticipants?.includes(user.id),
        }),
      }))
    }, [allUsers, allParticipants, disableRemove, initialParticipants])

    const handleChange = useCallback(
      (value: string[]) => {
        setParticipants(value)
        if (onChange) {
          onChange(value)
        }
      },
      [onChange]
    )

    useEffect(() => {
      setParticipants(initialParticipants || [])
    }, [initialParticipants])

    return (
      <Select
        mode="multiple"
        size="large"
        style={{ width: '100%' }}
        options={options}
        disabled={disabled}
        value={!!options ? participants : []} // We need to make sur options are ready to send values.
        onChange={handleChange}
        optionFilterProp="label"
        loading={loading}
      />
    )
  }
)

export default SelectParticipant
