import { Select } from 'antd'
import { useEffect, useState, useMemo, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urlList } from '../constants/constants'
import { apiService } from '../services'
import { RootState } from '../store'
import {
  LiteratureReview,
  setLiteratureReviewList,
} from '../store/reducers/literatureReviewReducer'
import { AxiosError } from 'axios'

interface SelectOption {
  label: string
  value: string
}

interface SelectLiteratureReviewProps {
  onChange: (value: LiteratureReview | undefined) => void
  style?: Object
}

const SelectLiteratureReview = memo(
  ({ onChange, style }: SelectLiteratureReviewProps) => {
    const dispatch = useDispatch()
    const literatureReviews = useSelector(
      (state: RootState) => state.literatureReview?.literatureReviewList
    )
    const options: SelectOption[] | undefined = useMemo(
      () =>
        literatureReviews
          ?.filter(
            (literatureReview: LiteratureReview) => !!literatureReview.id
          )
          .map((literatureReview: LiteratureReview) => ({
            label: literatureReview.title || '',
            value: literatureReview.id || '',
          })),
      [literatureReviews]
    )
    const [loading, setLoading] = useState(true)
    const [selectedValue, setSelectedValue] = useState('')

    const handleChange = (value: string) => {
      setSelectedValue(value)
      if (literatureReviews) {
        onChange(
          literatureReviews.find(
            (literatureReview: LiteratureReview) =>
              literatureReview.id === value
          )
        )
      }
    }

    useEffect(() => {
      apiService
        .fetchItems(urlList.LITERATURE_REVIEWS + '?ownedOnly=true')
        .then((response: LiteratureReview[]) => {
          setLoading(false)
          dispatch(setLiteratureReviewList(response))
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          setLoading(false)
        })
    }, [dispatch])

    return (
      <Select
        style={style}
        onChange={handleChange}
        value={selectedValue}
        options={options}
        loading={loading}
      />
    )
  }
)

export default SelectLiteratureReview
