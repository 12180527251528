import { CloseCircleOutlined } from '@ant-design/icons'

export const AllowClearIcon = () => {
  return (
    <CloseCircleOutlined
      style={{
        color: '#FF4D4F',
      }}
    />
  )
}

export default AllowClearIcon
