import { Breadcrumb as AntdBreadcrumb } from 'antd'
import { useBreadcrumbItems } from '../hooks'
import { memo } from 'react'

const Breadcrumbs = memo(() => {
  const breadcrumbItems = useBreadcrumbItems()

  return <AntdBreadcrumb items={breadcrumbItems} />
})

export default Breadcrumbs
