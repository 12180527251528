import { Button } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import styled from 'styled-components'
import {
  ModalBase,
  ModalContentContainer,
  ModalTextDescription,
  ModalTextLabel,
} from '../../../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../../../constants/constants'
import { DelConfigValues } from '../../../../../store/reducers/workspaceReducer'

const DropDownItem = styled.div`
  width: 200px;
`
interface DelLastConfigurationModalProps {
  config?: DelConfigValues
}

const DelLastConfigurationModal = ({
  config,
}: DelLastConfigurationModalProps) => {
  const [open, setOpen] = useState(false)

  const handleClose = () => {
    setOpen(false)
  }

  const documentName = config?.documentName || NO_DATA_PLACEHOLDER

  return (
    <>
      <DropDownItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {t(
          'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.viewRunSetup'
        )}
      </DropDownItem>
      <ModalBase
        destroyOnClose
        key="delLastConfigModal"
        title={t('delConfigurationModal.modalTitle')}
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        forceRender
        footer={<Button onClick={handleClose}>{t('Close')}</Button>}
      >
        <ModalContentContainer>
          <ModalTextLabel>
            {t('delConfigurationModal.steps.review.inputHeader')}
          </ModalTextLabel>
          <ModalTextDescription>{documentName}</ModalTextDescription>
        </ModalContentContainer>
      </ModalBase>
    </>
  )
}

export default DelLastConfigurationModal
