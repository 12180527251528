import { Form } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ModalBase, ModalContentContainer } from '../../../../../components'
import {
  NO_DATA_PLACEHOLDER,
  biomarkFormRanges,
} from '../../../../../constants/constants'
import { workspaceAlgorithmRunBiomarkMock } from '../../../../../mocks/dev'
import { BiomarkConfigValues } from '../../../../../store/reducers/workspaceReducer'
import ModalFormReviewSection from '../../../../DataStoreListPage/modals/component/ModalFormReviewSection'
import BiomarkLastConfigurationModalFooter from './BiomarkLastConfigurationModalFooter'

const DropDownItem = styled.div`
  width: 200px;
`

interface BiomarkLastConfigurationModalProps {
  config?: BiomarkConfigValues
}

const BiomarkLastConfigurationModal = ({
  config,
}: BiomarkLastConfigurationModalProps) => {
  const [form] = Form.useForm()

  const [open, setOpen] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [formValues, setFormValues] = useState<BiomarkConfigValues | null>(null)

  useEffect(() => {
    setConfirmLoading(true)
    if (config) {
      setFormValues(config)
    } else {
      setFormValues(
        workspaceAlgorithmRunBiomarkMock.config as BiomarkConfigValues
      )
    }
  }, [open, form, config])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <DropDownItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {t(
          'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.viewRunSetup'
        )}
      </DropDownItem>
      <ModalBase
        key="main"
        title={t('biomarkConfigurationModal.modalTitle')}
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        forceRender
        confirmLoading={confirmLoading}
        footer={
          <BiomarkLastConfigurationModalFooter closeModal={handleClose} />
        }
      >
        <ModalContentContainer>
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.maxScore')}
            value={formValues?.maxScore ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.trials')}
            value={formValues?.trials ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.testPercent')}
            value={
              config?.testPercent
                ? config.testPercent * 100
                : biomarkFormRanges.testPercent.default
            }
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.generations')}
            value={formValues?.generations ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.population')}
            value={formValues?.population ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.mutationRate')}
            value={formValues?.mutationRate ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.numMutants')}
            value={formValues?.numMutants ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.numXovers')}
            value={formValues?.numXovers ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.numRefines')}
            value={formValues?.numRefines ?? NO_DATA_PLACEHOLDER}
          />
          <ModalFormReviewSection
            label={t('biomarkConfigurationModal.reviewStep.modelType')}
            value={
              formValues?.modelType
                ? t(
                    `biomarkConfigurationModal.parameterStep.modelType.${formValues?.modelType}`
                  )
                : NO_DATA_PLACEHOLDER
            }
          />
        </ModalContentContainer>
      </ModalBase>
    </>
  )
}

export default BiomarkLastConfigurationModal
