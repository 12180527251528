import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urlList } from '../constants/urlConstant'
import { apiService } from '../services'
import notificationService from '../services/notificationService'
import { RootState } from '../store'
import {
  WorkspaceFiles,
  WorkspaceFolder,
  WorkspaceFolderResponse,
  clearWorkspaceFolders,
  setWorkspaceFiles,
  setWorkspaceFilesLoading,
  setWorkspaceFolders,
} from '../store/reducers/workspaceReducer'

interface useWorkspaceFoldersProps {
  workspaceId?: string
  algorithmRunId?: string
  preventFetch?: boolean
}

const useWorkspaceFolders = ({
  workspaceId,
  preventFetch = false,
}: useWorkspaceFoldersProps) => {
  const dispatch = useDispatch()

  const workspaceFolders: WorkspaceFolder[] | undefined = useSelector(
    (state: RootState) => state.workspace.folders
  )

  const workspaceFiles: WorkspaceFiles[] | undefined = useSelector(
    (state: RootState) => state.workspace.files
  )

  const isWorkspaceFilesLoading = useSelector(
    (state: RootState) => state.workspace.workspaceFilesLoading
  )

  const [error, setError] = useState<AxiosError | Error>()
  const [loading, setLoading] = useState(false)

  const parsePath = (filePath: string): string => {
    const parts = filePath.split('/')
    const filename = parts.pop()!
    const parentFolder = parts.pop() // Get the parent folder prefix
    return parentFolder ? `${parentFolder}/${filename}` : filename
  }

  const fetchWorkspaceFolders = useCallback(
    async (refreshForceCall = false) => {
      if (!workspaceId) {
        return
      }
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        dispatch(setWorkspaceFilesLoading(true))
        apiService
          .fetchWithRetry(`${urlList.WORKSPACES}/${workspaceId}/folders`)
          .then((response: WorkspaceFolderResponse) => {
            dispatch(setWorkspaceFolders([response.folderStructure]))
            dispatch(setWorkspaceFiles(response.filesInStructure))
            setLoading(false)
            dispatch(setWorkspaceFilesLoading(false))
          })
          .catch((error) => {
            notificationService.notificationError(
              t('smartFunctionDetailsPage.errorFetchingWorkspaceFolders')
            )
            setError(error)
            dispatch(setWorkspaceFilesLoading(false))
            setLoading(false)
          })
      }
    },
    [workspaceId, loading, preventFetch, dispatch]
  )

  const clearFolders = useCallback(() => {
    dispatch(clearWorkspaceFolders())
  }, [dispatch])

  const refreshWorkspaceFolders = useCallback(async () => {
    fetchWorkspaceFolders(true)
  }, [fetchWorkspaceFolders])

  useEffect(() => {
    fetchWorkspaceFolders()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return {
    clearFolders,
    parsePath,
    fetchWorkspaceFolders,
    refreshWorkspaceFolders,
    workspaceFolders,
    workspaceFiles,
    isWorkspaceFilesLoading,
    error,
  }
}

export default useWorkspaceFolders
