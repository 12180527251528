import { Button, Form, Input } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { authService } from '../../../services'
import { ForgotPasswordStepsFooter, FormLayout } from '../ForgotPasswordPage'
import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons'

interface PasswordInviteResponse {
  inviteToken: string
}

interface FormValues {
  email: string
}

interface ForgotPasswordStep1Props {
  onNext: (email: string, inviteToken: string) => void
  onCancel: () => void
}

const ForgotPasswordStep1Email = ({
  onNext,
  onCancel,
}: ForgotPasswordStep1Props) => {
  const [loading, setLoading] = useState(false)

  const handleSubmit = async (values: FormValues) => {
    setLoading(true)
    await authService.forgotPasswordInvite(values.email).then(
      (response: PasswordInviteResponse) => {
        setLoading(false)
        onNext(values.email, response.inviteToken)
      },
      (error) => {
        console.error('Login error', error)
        setLoading(false)
      }
    )
  }

  return (
    <Form onFinish={handleSubmit} layout="vertical" requiredMark={false}>
      <FormLayout>
        <Form.Item
          label={t('forgotPasswordPage.step1Email.emailLabel')}
          name="email"
          rules={[
            { required: true, message: t('errorMessage.isRequired') || '' },
          ]}
          extra={t('forgotPasswordPage.step1Email.emailExtra')}
        >
          <Input placeholder={t('Email') || ''} disabled={loading} />
        </Form.Item>

        <ForgotPasswordStepsFooter>
          <Button onClick={onCancel} icon={<ArrowLeftOutlined />} type="text">
            {t('forgotPasswordPage.stepsCancelButton')}
          </Button>
          <Button type="primary" htmlType="submit" icon={<MailOutlined />}>
            {t('forgotPasswordPage.step1Email.okButton')}
          </Button>
        </ForgotPasswordStepsFooter>
      </FormLayout>
    </Form>
  )
}

export default ForgotPasswordStep1Email
