import { AxiosError } from 'axios'
import { useState, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ofrDocumentTemplateService } from '../services'
import {
  OfrDocumentTemplate,
  setSelectedOfrDocumentTemplate,
} from '../store/reducers/ofrDocumentTemplateReducer'
import { RootState } from '../store'

interface UseOfrDocumentTemplateDetailsProps {
  documentId?: string
  preventFetch?: boolean
}

const useOfrDocumentTemplateDetails = ({
  documentId,
  preventFetch = false,
}: UseOfrDocumentTemplateDetailsProps) => {
  const dispatch = useDispatch()
  const selectedOfrDocumentTemplate: OfrDocumentTemplate | undefined =
    useSelector(
      (state: RootState) => state.ofrDocumentTemplate.selectedDocumentTemplate
    )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const [isFetchDone, setIsFetchDone] = useState(false)

  const fetchOfrDocumentTemplateDetails = useCallback(
    async (refreshForceCall = false) => {
      if (!documentId) {
        return
      }
      if (!loading && ((!preventFetch && !isFetchDone) || refreshForceCall)) {
        setLoading(true)
        return ofrDocumentTemplateService
          .getOfrDocumentTemplate({ documentId })
          .then((response: OfrDocumentTemplate) => {
            dispatch(setSelectedOfrDocumentTemplate(response))
            setLoading(false)
            setIsFetchDone(true)
            setLoading(false)
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios get error', error)
            setLoading(false)
          })
      }
    },
    [dispatch, documentId, isFetchDone, loading, preventFetch]
  )

  const refreshOfrDocumentTemplateDetails = useCallback(async () => {
    fetchOfrDocumentTemplateDetails(true)
  }, [fetchOfrDocumentTemplateDetails])

  useEffect(() => {
    fetchOfrDocumentTemplateDetails(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    fetchOfrDocumentTemplateDetails,
    selectedOfrDocumentTemplate,
    refreshOfrDocumentTemplateDetails,
    loading,
    error,
  }
}

export default useOfrDocumentTemplateDetails
