import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { RootState } from '..'
import { HttpResponse, RequestMethod } from '../../types/generalTypes'

export interface AuditTrail {
  id: string
  requestComponent: string
  requestMethod: RequestMethod
  requestRoute: string
  responseCode: number
  actionBy: string | null
  actionByDisplayName: string | null
  actionDate: string
}

interface AuditTrailsResponse extends HttpResponse {
  data: AuditTrail[]
}

interface AuditTrailsState {
  auditTrailsComponents?: string[]
  auditTrails?: AuditTrailsResponse
  listCsv?: string
}

const initialState: AuditTrailsState = {
  auditTrails: undefined,
  listCsv: undefined,
}

const auditTrailsSlice = createSlice({
  name: 'auditTrails',
  initialState,
  reducers: {
    setAuditTrails: (state, action: PayloadAction<AuditTrailsResponse>) => {
      state.auditTrails = action.payload
    },
    setAuditTrailsComponents: (state, action: PayloadAction<string[]>) => {
      state.auditTrailsComponents = action.payload
    },
    setListCsv: (state, action: PayloadAction<string>) => {
      state.listCsv = action.payload
    },
    clearAuditTrailsState: () => initialState,
  },
})

export const {
  setAuditTrails,
  setAuditTrailsComponents,
  setListCsv,
  clearAuditTrailsState,
} = auditTrailsSlice.actions

export const getAuditTrails = (state: RootState) => state.auditTrail.auditTrails

const auditTrailReducer = auditTrailsSlice.reducer
export default auditTrailReducer
