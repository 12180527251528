import { UserResponse } from '../../hooks/useUsers'

const userMock: UserResponse = {
  data: [
    {
      id: '63f79442930987a1621ef75a',
      email: 'amine.belaid@genaiz.com',
      displayName: 'Amine Belaid',
      role: '63f79441930987a1621ef6fd',
      isActive: true,
      language: 'en',
      createdBy: '63f79441930987a1621ef6e0',
      lastModifiedBy: '63f79442930987a1621ef75a',
    },
    {
      id: '6447fab3a4cf88f35a308625ZZZ',
      email: 'guillaume.fournier@genaiz.com',
      displayName: 'Guillaume Fournier',
      role: '63f79441930987a1621ef6fd',
      isActive: true,
      language: 'fr',
      createdBy: '63f79442930987a1621ef74f',
      lastModifiedBy: '63d9602a68abf834dee77a07',
    },
    {
      id: '63f79441930987a1621ef718',
      email: 'mathieu.daudelin@genaiz.com',
      displayName: 'Mathieu Daudelin',
      role: '63f79441930987a1621ef6fd',
      isActive: true,
      language: 'en',

      createdBy: '63f79441930987a1621ef6e0',
      lastModifiedBy: '63f79443930987a1621ef77b',
    },
    {
      id: '63d9602a68abf834dee77a07',
      email: 'roy.chowdhury@genaiz.com',
      displayName: 'Roy Chowdhury',
      role: '63f79441930987a1621ef6fd',
      isActive: true,
      language: 'en',
      createdBy: '63f79441930987a1621ef6e0',
      lastModifiedBy: '63d9602a68abf834dee77a07',
    },
    {
      id: '63f79442930987a1621ef74f',
      email: 'victor.patrin@genaiz.com',
      displayName: 'Victor Patrin',
      role: '63f79441930987a1621ef6fd',
      isActive: true,
      language: 'en',
      createdBy: '63f79441930987a1621ef6e0',
      lastModifiedBy: '63f79441930987a1621ef6e0',
    },
  ],
  totalResults: 45,
  totalPages: 1,
  page: 1,
  pageSize: 1000,
}

export default userMock
