import { urlList } from '../constants/constants'
import apiService from './apiService'

const baseUrl = urlList.Audit_TRAILS

const exportAuditTrailCsv = async () => {
  return apiService
    .fetchItems(`${baseUrl}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const auditTrailService = {
  exportAuditTrailCsv,
}

export default auditTrailService
