import { useCallback, useMemo } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { routePaths } from '../pages/RootPage'
import SelectAuditTrailsComponent from './SelectAuditTrailsComponent'
import SelectTimePeriod, {
  SelectTimePeriodOnChangeParams,
  TimePeriodValue,
} from './SelectTimePeriod'

interface BuildUrlParams {
  component?: string | null
  timePeriod?: string | null
  startDate?: string | null
  endDate?: string | null
}

const MainHeaderAuditTrails = () => {
  const navigate = useNavigate()
  const { search } = useLocation()
  const query = useMemo(() => new URLSearchParams(search), [search])

  const buildUrl = useCallback((params: BuildUrlParams) => {
    const haveParameter = params.component || params.timePeriod
    let url = haveParameter ? '?' : ''
    if (params?.component) {
      url = url.concat(`component=${params.component || ''}`)
    }
    if (params.timePeriod) {
      url = url.concat(
        url.length > 1 ? `&` : ``,
        `timePeriod=${params.timePeriod || ''}`
      )
      if (params.startDate) {
        url = url.concat(
          url.length > 1 ? `&` : ``,
          `startDate=${params.startDate || ''}`
        )
      }
      if (params.endDate) {
        url = url.concat(
          url.length > 1 ? `&` : ``,
          `endDate=${params.endDate || ''}`
        )
      }
    }
    return url
  }, [])

  const handleComponentChange = useCallback(
    (value?: string) => {
      const urlParams = buildUrl({
        component: value,
        timePeriod: query.get('timePeriod'),
        startDate: query.get('startDate'),
        endDate: query.get('endDate'),
      })
      navigate(`${routePaths.AUDIT_TRAIL_LIST}${urlParams}`)
    },
    [navigate, buildUrl, query]
  )

  const handleTimePeriodChange = useCallback(
    (value?: SelectTimePeriodOnChangeParams) => {
      const urlParams = buildUrl({
        component: query.get('component'),
        timePeriod: value?.timePeriodValue,
        startDate: value?.startDate,
        endDate: value?.endDate,
      })
      navigate(`${routePaths.AUDIT_TRAIL_LIST}${urlParams}`)
    },
    [navigate, buildUrl, query]
  )

  return (
    <>
      <SelectAuditTrailsComponent
        onChange={handleComponentChange}
        value={query.get('component') || ''}
      />
      <SelectTimePeriod
        onChange={handleTimePeriodChange}
        defaultValue={query.get('timePeriod') as TimePeriodValue}
      />
    </>
  )
}

export default MainHeaderAuditTrails
