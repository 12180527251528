import { Table } from 'antd/lib'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useMemo, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ListPageLayout, TableBoldColumnContainer } from '../../components'
import { NO_DATA_PLACEHOLDER } from '../../constants/constants'
import i18n from '../../i18n/i18n'
import { RootState } from '../../store'
import { SearchResultItem } from '../../store/reducers/searchResultInfoReducer'
import SearchDocumentSummariesPageHeader from './SearchDocumentSummariesPageHeader/SearchDocumentSummariesPageHeader'
import { routePaths } from '../RootPage'
import { useNavigate } from 'react-router-dom'

export interface SearchDocumentSummariesTableRow {
  id: string
  key: string
  title?: string
  summary?: string
}

const SearchDocumentSummariesPage = () => {
  const navigate = useNavigate()
  const searchResultInfo: SearchResultItem[] | undefined = useSelector(
    (state: RootState) => state.searchResultInfo.searchResult?.data
  )

  const formattedData: SearchDocumentSummariesTableRow[] = useMemo(
    () =>
      (searchResultInfo || [])
        .map((document, index: number) => {
          const language = i18n.language
          const title =
            typeof document?.title === 'string'
              ? document.title
              : document?.title?.[language] ?? NO_DATA_PLACEHOLDER
          const summary =
            typeof document?.summary === 'string'
              ? document.summary
              : document?.summary?.[language] ?? ''

          return {
            key: index.toString(),
            id: document._id ?? `missing-id-${index}`,
            title,
            summary,
          }
        })
        .slice(0, 10),
    [searchResultInfo]
  )

  useEffect(() => {
    if (!searchResultInfo || !(searchResultInfo.length > 0)) {
      navigate(routePaths.SEARCH)
    }
  }, [searchResultInfo, navigate])

  const columns: ColumnsType<SearchDocumentSummariesTableRow> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '50%',
      render: (text: string) => (
        <TableBoldColumnContainer>
          {text || t('searchDocumentSummariesPage.noTitleAvailable')}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Summary'),
      dataIndex: 'summary',
      key: 'summary',
      width: '50%',
      render: (text: string) => (
        <>{text || t('searchDocumentSummariesPage.noSummaryAvailable')}</>
      ),
    },
  ]

  return (
    <ListPageLayout>
      <SearchDocumentSummariesPageHeader total={formattedData.length} />
      <Table
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
      />
    </ListPageLayout>
  )
}

export default SearchDocumentSummariesPage
