import { ReloadOutlined, SafetyCertificateOutlined } from '@ant-design/icons'
import { Button, Form, Input, Select } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useCallback, useEffect, useState } from 'react'
import { CustomLinkTranslation } from '../../../../../components'
import {
  SHAREPOINT_CLIENT_ID,
  TENANT_ID_INFORMATION_URL,
} from '../../../../../constants/constants'
import { sharepointService } from '../../../../../services'
import { SharePointSite } from '../../../../../services/sharepointService'
import { SelectOption } from '../../../../../types/generalTypes'

interface CreateDataSourceSharePointConfigProps {
  form: FormInstance
}

const CreateDataSourceSharePointConfig = memo(
  ({ form }: CreateDataSourceSharePointConfigProps) => {
    const [tenantUid, setTenantUid] = useState(
      form.getFieldValue('config')?.tenantUid || ''
    )

    const [sharePointSites, setSharePointSites] = useState([])

    const handleSiteChange = (option: SelectOption) => {
      form.setFieldsValue({
        config: {
          sourceFolder: option.label,
          sourceFolderId: option.value,
        },
      })
    }

    useEffect(() => {
      form.setFieldValue('config', {
        tenantUid: null,
        sourceFolder: null,
      })
    }, [form])

    const getSharePointSites = useCallback(() => {
      sharepointService
        .getSharePointSitesWithTenantUid({ tenantUid })
        .then((response) => {
          setSharePointSites(
            response.map((site: SharePointSite) => ({
              value: site.id,
              label: site.name,
            }))
          )
        })
        .catch((error) => {
          console.error(error)
          setSharePointSites([])
        })
    }, [tenantUid])

    return (
      <>
        <Form.Item
          label={t(
            'createDataSourceModal.sharePointConfig.tenantIdField'
          )}
          name={['config', 'tenantUid']}
          initialValue={tenantUid}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={
            <CustomLinkTranslation
              parentTranslationKey="createDataSourceModal.sharePointConfig.tenantIdDescription"
              childTranslationKey="createDataSourceModal.sharePointConfig.tenantIDLinkText"
              link={TENANT_ID_INFORMATION_URL}
            />
          }
          style={{
            marginBottom: 0,
          }}
        >
          <Input
            onChange={(e) => {
              setTenantUid(e.target.value)
            }}
            placeholder={
              t(
                'createDataSourceModal.sharePointConfig.tenantIdField'
              ) || ''
            }
          />
        </Form.Item>
        <Button
          icon={<SafetyCertificateOutlined />}
          type="primary"
          block
          target="_blank"
          href={`https://login.microsoftonline.com/${tenantUid}/adminconsent?client_id=${SHAREPOINT_CLIENT_ID}&redirect_uri=https://genaiz.com`}
          style={{
            marginBottom: 16,
          }}
        >
          {t(
            'createDataSourceModal.sharePointConfig.authorizeOnMicrosoftButtonText'
          )}
        </Button>

        <Form.Item
          label={t(
            'createDataSourceModal.sharePointConfig.sharePointSiteSelectorField'
          )}
          name={['config', 'sourceFolder']}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={t(
            'createDataSourceModal.sharePointConfig.helpText'
          )}
        >
          <Select
            showSearch
            placeholder={t(
              'createDataSourceModal.sharePointConfig.sharePointSiteSelectorField'
            )}
            onChange={(_value: string, options: any) =>
              handleSiteChange(options)
            }
            disabled={sharePointSites.length === 0 && tenantUid !== ''}
            options={sharePointSites}
            optionFilterProp="label"
          />
        </Form.Item>
        <Button onClick={getSharePointSites} icon={<ReloadOutlined />} block>
          {t(
            'createDataSourceModal.sharePointConfig.refreshButtonText'
          )}
        </Button>
      </>
    )
  }
)

export default CreateDataSourceSharePointConfig
