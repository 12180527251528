import styled from 'styled-components'

const MultiColumnFormContentContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 16px;
  border-radius: 4px;
`

export default MultiColumnFormContentContainer
