import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { useAlgorithmRunDetails } from '../../../../hooks'
import useWorkspaceDetails from '../../../../hooks/useWorkspaceDetails'
import {
  BiomarkersRunResult,
  WorkspaceFolder,
} from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import SmartFunctionHeader from '../../components/SmartFunctionHeader'

interface BiomarkSmartFunctionDetailsPageHeaderProps {
  setSearch: (search: string) => void
  search: string
  singleWorkspace?: boolean
}

const BiomarkSmartFunctionDetailsPageHeader = ({
  setSearch,
  search,
  singleWorkspace,
}: BiomarkSmartFunctionDetailsPageHeaderProps) => {
  const { workspaceId, algorithmRunId } = useParams()

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })
  const runResult = useMemo(
    () => selectedAlgorithmRun?.runResult as BiomarkersRunResult,
    [selectedAlgorithmRun?.runResult]
  )
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
  })

  const searchedResult = useMemo(
    () =>
      runResult?.featureRanking
        ? runResult?.featureRanking?.filter((featureRanking) =>
            !!search ? featureRanking.columnName.includes(search || '') : true
          ) || []
        : [],
    [search, runResult]
  )

  const numberOfFiles: (folders?: WorkspaceFolder[]) => number = useCallback(
    (folders?: WorkspaceFolder[]) => {
      if (!folders) {
        return 0
      }
      return folders
        .map((folder) => {
          const filteredFiles = folder.files.filter((document) =>
            !!search ? document.path.includes(search || '') : true
          )
          return filteredFiles.length + numberOfFiles(folder.folders)
        })
        .reduce((total, number) => total + number, 0)
    },
    [search]
  )

  const backButtonUrl = useMemo(
    () =>
      singleWorkspace
        ? `${routePaths.SINGLE_WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`
        : `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`,
    [singleWorkspace, selectedWorkspace?.id]
  )

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  return (
    <SmartFunctionHeader
      backButtonUrl={backButtonUrl}
      totalFound={searchedResult.length}
      onSearchAction={handleSearch}
      onSearchChange={(event) => handleSearch(event.target.value)}
    />
  )
}

export default BiomarkSmartFunctionDetailsPageHeader
