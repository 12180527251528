import React from 'react'
import styled from 'styled-components'
import { ModalTextLabel, ModalTextDescription } from '../../../../components'

const SectionContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`

interface ReviewSectionProps {
  label: string
  value: string | React.ReactNode | number
}

const ModalFormReviewSection = ({ label, value }: ReviewSectionProps) => {
  return (
    <SectionContainer>
      <ModalTextLabel>{label}</ModalTextLabel>
      <ModalTextDescription>{value}</ModalTextDescription>
    </SectionContainer>
  )
}

export default ModalFormReviewSection
