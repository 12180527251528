import { UserAddOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { useCallback, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ModalBase, SelectParticipant } from '../../../../../components'
import { privacyTypes } from '../../../../../constants/constants'
import useWorkspaceDetails from '../../../../../hooks/useWorkspaceDetails'
import notificationService from '../../../../../services/notificationService'
import workspaceService from '../../../../../services/workspaceService'
import WorkspaceSettingsFooter from '../footer/WorkspaceSettingsFooter'

const WorkspaceDetailsAddParticipantModal = () => {
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { workspaceId } = useParams()

  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const participantsArray = useMemo(
    () =>
      selectedWorkspace?.participants
        ? selectedWorkspace.participants.map((participant) => participant.id)
        : [],
    [selectedWorkspace]
  )

  const handleOk = () => {
    setConfirmLoading(true)
  }

  const handleCancel = useCallback(() => {
    setOpen(false)
  }, [])

  const handleConfirm = useCallback(() => {
    setOpen(false)
    if (selectedWorkspace) {
      workspaceService
        .updateWorkspaceDetails({
          workspaceId: selectedWorkspace.id,
          participants: form.getFieldValue('participants'),
        })
        .then(() => {
          refreshWorkspaceDetails()
          setConfirmLoading(false)
          notificationService.notificationSuccess(
            t(
              'workspaceDetailsPage.workspaceSettingsPeoplePanel.addParticipantModal.addParticipantSuccess'
            )
          )
        })
        .catch(() => {
          setConfirmLoading(false)
        })
    }
    return
  }, [
    selectedWorkspace,
    setConfirmLoading,
    setOpen,
    form,
    refreshWorkspaceDetails,
  ])

  return (
    <>
      <Button
        icon={<UserAddOutlined />}
        onClick={() => setOpen(true)}
        type="primary"
        disabled={selectedWorkspace?.visibility === 'ORGANIZATION'}
      >
        {t(
          'workspaceDetailsPage.workspaceSettingsPeoplePanel.addParticipantModal.addPersonButton'
        )}
      </Button>
      <ModalBase
        key="main"
        title={t(
          'workspaceDetailsPage.workspaceSettingsPeoplePanel.addParticipantModal.title'
        )}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        footer={
          <WorkspaceSettingsFooter
            handleCancel={handleCancel}
            handleSubmit={handleConfirm}
            icon={<UserAddOutlined />}
            confirmButtonText={t('Confirm')}
          />
        }
      >
        <Form form={form} layout="vertical">
          <Form.Item
            label={t(
              'createWorkspaceModal.privacyStep.participantSelectHeader'
            )}
            name="participants"
            extra={
              t('createWorkspaceModal.privacyStep.extraInputPlaceholder') || ''
            }
          >
            <SelectParticipant
              disabled={selectedWorkspace?.visibility !== privacyTypes.PRIVATE}
              initialParticipants={participantsArray}
              disableRemove
            />
          </Form.Item>
        </Form>
      </ModalBase>
    </>
  )
}

export default WorkspaceDetailsAddParticipantModal
