import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ofrDocumentTemplateService } from '../services'
import { RootState } from '../store'
import {
  OfrDocumentTemplate,
  OfrDocumentTemplateResponse,
  setOfrDocumentTemplateList,
  setOfrDocumentTemplateListCsv,
} from '../store/reducers/ofrDocumentTemplateReducer'
import useDownloadFile from './useDownloadFile'


interface useOfrDocumentTemplateProps {
  preventFetch?: boolean
}

const useOfrDocumentTemplate = ({
  preventFetch = false,
}: useOfrDocumentTemplateProps) => {
  const dispatch = useDispatch()
  const downloadCsv = useDownloadFile()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()

  const ofrDocumentTemplateList: OfrDocumentTemplate[] = useSelector(
    (state: RootState) => state.ofrDocumentTemplate.ofrDocumentTemplateList
  ) as OfrDocumentTemplate[]

  const fetchOfrDocumentTemplateList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        ofrDocumentTemplateService
          .getOfrDocumentTemplateList()
          .then((response: OfrDocumentTemplateResponse) => {
            setLoading(false)
            dispatch(setOfrDocumentTemplateList(response.data))
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios fetch error', error)
            setLoading(false)
          })
      }
    },
    [loading, preventFetch, dispatch]
  )

  interface CreateOfrDocumentTemplateProps {
    documentId: string
    dataSource: string
    title: string
    navigate: any
  }

  const createOfrDocumentTemplate = useCallback(
    ({
      documentId,
      dataSource,
      title,
      navigate,
    }: CreateOfrDocumentTemplateProps): Promise<void> => {
      setLoading(true)
      return ofrDocumentTemplateService
        .createOfrDocumentTemplate({
          documentId,
          dataSource,
          title,
          navigate,
        })
        .then(() => {
          fetchOfrDocumentTemplateList(true)
          setLoading(false)
        })
        .catch((error: AxiosError | Error) => {
          setError(error)
          console.error('axios create error', error)
          setLoading(false)
        })
    },
    [fetchOfrDocumentTemplateList]
  )

  const refreshOfrDocumentTemplateList = useCallback(async () => {
    fetchOfrDocumentTemplateList(true)
  }, [fetchOfrDocumentTemplateList])

  useEffect(() => {
    fetchOfrDocumentTemplateList(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  interface DeleteOfrDocumentTemplateProps {
    documentId: string
    navigate?: any
  }

  const deleteOfrDocumentTemplate = useCallback(
    async ({ documentId, navigate }: DeleteOfrDocumentTemplateProps) => {
      setLoading(true)
      return ofrDocumentTemplateService
        .deleteOfrDocumentTemplate({ documentId, navigate })
        .then(() => {
          fetchOfrDocumentTemplateList(true)
          setLoading(false)
        })
        .catch((error: AxiosError | Error) => {
          setError(error)
          console.error('axios delete error', error)
          setLoading(false)
        })
    },
    [fetchOfrDocumentTemplateList]
  )

  const exportOfrDocumentTemplateList = useCallback(() => {
    if (ofrDocumentTemplateList) {
      ofrDocumentTemplateService
        .exportOfrDocumentTemplateList()
        .then((response) => {
          const fileName = 'ofrDocumentTemplateList.csv'
          downloadCsv(response, fileName)
          dispatch(setOfrDocumentTemplateListCsv(response))
        })
        .catch((error: any) => {
          console.error('Error fetching data source list CSV data', error)
        })
    }
  }, [ofrDocumentTemplateList, dispatch, downloadCsv])

  return {
    exportOfrDocumentTemplateList,
    ofrDocumentTemplateList,
    loading,
    error,
    fetchOfrDocumentTemplateList,
    refreshOfrDocumentTemplateList,
    createOfrDocumentTemplate,
    deleteOfrDocumentTemplate,
  }
}

export default useOfrDocumentTemplate
