import { AimOutlined } from '@ant-design/icons'
import { t } from 'i18next'
import { useMemo } from 'react'
import { Location, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { routePaths } from '../pages/RootPage'
import { workspacePanelTypes } from '../pages/WorkspaceDetailsPage/WorkspaceDetailsPage'

const BreadcrumbItem = styled.div`
  color: ${(props) => props.theme.token.colorText};
`

const MainContainer = styled.div`
  display: flex;
  gap: 8px;
`

const AfterBreadcrumb = () => {
  const location: Location = useLocation()
  const basePath = `/${location.pathname.split('/')[1]}`

  const content = useMemo(() => {
    if (basePath === routePaths.WORKSPACE_DETAILS) {
      const panelType = location.pathname.split('/')[3]
      return panelType && panelType === workspacePanelTypes.SETTINGS
        ? t('Settings')
        : t('Overview')
    }
    if (basePath === routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS) {
      return t('Configuration')
    }
  }, [basePath, location])

  const icon = useMemo(() => {
    if (basePath === routePaths.OFR_DOCUMENT_TEMPLATE_DETAILS) {
      return <AimOutlined />
    }
  }, [basePath])

  return (
    <div>
      {!!content && (
        <MainContainer>
          <BreadcrumbItem> / </BreadcrumbItem>
          <BreadcrumbItem>{!!icon && icon}</BreadcrumbItem>
          <BreadcrumbItem>{content}</BreadcrumbItem>
        </MainContainer>
      )}
    </div>
  )
}

export default AfterBreadcrumb
