import styled from 'styled-components'

const SubHeader = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
  gap: 8px;
  color: ${(props) => props.theme.token.colorText};
`

export default SubHeader
