import { useCallback } from 'react'
import notificationService from '../services/notificationService'
import { t } from 'i18next'

const useDownloadFile = () => {
  const downloadFile = useCallback((response: string, filename: string) => {
    const url = window.URL.createObjectURL(new Blob([response]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', filename)
    document.body.appendChild(link)
    link.click()
    // Clean up
    URL.revokeObjectURL(url)
    document.body.removeChild(link)
    notificationService.notificationSuccess(t('contentExported'))
  }, [])

  return downloadFile
}

export default useDownloadFile
