import { Button } from 'antd'
import { t } from 'i18next'
import styled from 'styled-components'
import { ForgotPasswordStepsFooter, FormLayout } from '../ForgotPasswordPage'

const TextContainer = styled.div`
  font-weight: 600;
`

interface ForgotPasswordStep4Props {
  onButtonClick: () => void
}

const ForgotPasswordStep4Review = ({
  onButtonClick,
}: ForgotPasswordStep4Props) => {
  return (
    <FormLayout>
      <TextContainer>{t('forgotPasswordPage.step4Review.text')}</TextContainer>
      <ForgotPasswordStepsFooter>
        <Button
          style={{ marginRight: 8 }}
          onClick={onButtonClick}
          type="primary"
        >
          {t('Close')}
        </Button>
      </ForgotPasswordStepsFooter>
    </FormLayout>
  )
}

export default ForgotPasswordStep4Review
