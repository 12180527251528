import { t } from 'i18next'
import { Fragment } from 'react'
import styled from 'styled-components'

// in the translation file, use the following pattern:
// "translationKey": "This is a translation with a {{link}} in it."
// "linkWord": "some link text"

// parentTranslationKey: parent key in the translation file (e.g. "translationKey")
// childTranslationKey: child key in the translation file (e.g. "linkWord")
// link:  the link to be used in the translation (e.g. "https://example.com")

//TODO: make this handle multiple links in the same translation

const CustomLink = styled.a`
  color: #7a40ea;
`

interface CustomLinkTranslationProps {
  parentTranslationKey: string
  childTranslationKey: string
  link: string
}

const CustomLinkTranslation = ({
  parentTranslationKey,
  childTranslationKey,
  link,
}: CustomLinkTranslationProps) => {
  const transText = t(parentTranslationKey)
  const parts = transText.split(/({{link}})/g)

  return (
    <p>
      {parts.map((part, index) =>
        part === '{{link}}' ? (
          <Fragment key={index}>
            <CustomLink href={link}>{t(childTranslationKey)}</CustomLink>
          </Fragment>
        ) : (
          part
        )
      )}
    </p>
  )
}

export default CustomLinkTranslation
