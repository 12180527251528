import ReloadOutlined from '@ant-design/icons/ReloadOutlined'
import { Button, Form, Select, SelectProps, Spin } from 'antd'
import { FormInstance } from 'antd/lib'
import { t } from 'i18next'
import { useCallback } from 'react'
import { RegulatorySubmissionConfigValues } from '../../../../../../store/reducers/workspaceReducer'
import { RegulatorySubmissionConfigurationModalFormValues } from '../RegulatorySubmissionConfigurationModal'

interface RegulatorySubmissionConfigurationScopeStepProps {
  form: FormInstance<RegulatorySubmissionConfigurationModalFormValues>
  config?: RegulatorySubmissionConfigValues
  loading?: boolean
  options: SelectProps['options']
  refreshWorkspaceFolders: () => void
}

const RegulatorySubmissionConfigurationScopeStep = ({
  form,
  options, // This is the data that is being passed to the component
  loading,
  refreshWorkspaceFolders,
}: RegulatorySubmissionConfigurationScopeStepProps) => {
  const handleChange = (selectedOptions: SelectProps['options']) => {
    form.setFieldsValue({
      documents: selectedOptions,
      tableOfContentIds: selectedOptions?.map(
        (option) => option.value as string
      ),
    })
  }

  const refreshDocumentList = useCallback(() => {
    refreshWorkspaceFolders()
  }, [refreshWorkspaceFolders])

  // Custom filter function
  const customFilterOption = (input: any, option: any) => {
    return option.label.toLowerCase().includes(input.toLowerCase())
  }

  return (
    <>
      <Spin spinning={loading}>
        <Form.Item
          label={t('regulatorySubmissionConfigurationModal.header')}
          name="documents"
          rules={[
            {
              required: true,
              message: t(
                'regulatorySubmissionConfigurationModal.documentsRequired'
              ),
            },
          ]}
          extra={t('regulatorySubmissionConfigurationModal.extraInfo')}
        >
          <Select
            labelInValue
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder={t(
              'regulatorySubmissionConfigurationModal.addDocumentPlaceholder'
            )}
            showSearch
            onChange={handleChange}
            options={options}
            filterOption={customFilterOption}
          />
        </Form.Item>
      </Spin>
      <Button
        onClick={refreshDocumentList}
        icon={<ReloadOutlined />}
        block
        disabled={loading}
      >
        {t('regulatorySubmissionConfigurationModal.refreshDocuments')}
      </Button>
    </>
  )
}

export default RegulatorySubmissionConfigurationScopeStep
