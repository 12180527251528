import { Form, Input, Radio, RadioChangeEvent, Space } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import {
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  SelectOfrDocumentTemplate,
  SelectParticipant,
} from '../../../components'
import { OfrDocumentSelectOption } from '../../../components/SelectOfrDocumentTemplate'
import { privacyTypes, workspaceTypes } from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'

interface WorkspaceSettingsInfoPanelProps {
  form: FormInstance
}
const WorkspaceSettingsInfoPanel = ({
  form,
}: WorkspaceSettingsInfoPanelProps) => {
  const { selectedWorkspace } = useWorkspaceDetails({
    preventFetch: true,
  })
  const [isPrivate, setIsPrivate] = useState(
    selectedWorkspace?.visibility === privacyTypes.PRIVATE
  )
  const [isSelectDisabled, setIsSelectDisabled] = useState(
    selectedWorkspace?.visibility === privacyTypes.ORGANIZATION || false
  )

  const [initialParticipants, setInitialParticipants] = useState<string[]>(
    selectedWorkspace?.participants?.map((participant) => participant.id) || []
  )

  const isQualityReviewWorkspace = useMemo(() => {
    return selectedWorkspace?.typeCode === workspaceTypes.OFR_QR
  }, [selectedWorkspace])

  const handleRadioChange = useCallback((e: RadioChangeEvent) => {
    e.preventDefault()
    setIsPrivate(e.target.value === privacyTypes.PRIVATE)
    setIsSelectDisabled(e.target.value === privacyTypes.ORGANIZATION)
  }, [])

  useEffect(() => {
    const setParticipantsAndValues = () => {
      if (!selectedWorkspace) return

      const currentVisibility = form.getFieldValue('visibility')
      const isPrivate = currentVisibility === privacyTypes.PRIVATE

      setIsPrivate(isPrivate)
      setIsSelectDisabled(!isPrivate)

      const participants = isPrivate
        ? selectedWorkspace.participants?.map(
            (participant) => participant.id
          ) || []
        : []

      setInitialParticipants(participants)
      form.setFieldsValue({
        participants: participants,
        config: {
          ofrDocumentTemplatesId:
            selectedWorkspace?.config?.ofrDocumentTemplatesId || '',
          ofrDocumentTemplates:
            selectedWorkspace?.config?.ofrDocumentTemplates || '',
          ofrDocumentTemplatesName:
            selectedWorkspace?.config?.ofrDocumentTemplatesName || '',
        },
      })
    }

    setParticipantsAndValues()
  }, [selectedWorkspace, form])

  const handleChangeOfrDocumentTemplates = useCallback(
    (value: OfrDocumentSelectOption | undefined) => {
      if (value) {
        form.setFieldsValue({
          config: {
            ofrDocumentTemplatesId: value.value,
            ofrDocumentTemplates: value.documentId,
            ofrDocumentTemplatesName: value.label,
          },
        })
      } else {
        form.setFieldsValue({
          config: {
            ofrDocumentTemplatesId: '',
            ofrDocumentTemplates: '',
            ofrDocumentTemplatesName: '',
          },
        })
      }
    },
    [form]
  )

  return (
    <MultiColumnFormContentContainer>
      <MultiColumnFormColumnContainer>
        <Form.Item
          label={t('Title')}
          required
          name="title"
          rules={[
            { required: true, message: t('errorMessage.isRequired') || '' },
          ]}
        >
          <Input width={'100%'} placeholder={'Title'} />
        </Form.Item>
        <Form.Item label={t('Description')} name="description">
          <Input width={'100%'} placeholder={'Description'} />
        </Form.Item>
        {selectedWorkspace?.visibility && (
          <Form.Item
            label={t('Privacy Settings')}
            name="visibility"
            rules={[
              {
                required: true,
                message: t('errorMessage.isRequired') || '',
              },
            ]}
          >
            <Radio.Group
              buttonStyle="solid"
              defaultValue={selectedWorkspace?.visibility}
              onChange={handleRadioChange}
            >
              <Space direction="vertical">
                <Radio value={privacyTypes.PRIVATE}>
                  {t('createWorkspaceModal.privacyStep.privateOption')}
                </Radio>
                <Radio
                  value={privacyTypes.ORGANIZATION}
                  disabled={
                    selectedWorkspace?.dataSource.visibility ===
                    privacyTypes.PRIVATE
                  }
                >
                  {t('createWorkspaceModal.privacyStep.organizationalOption')}
                </Radio>
              </Space>
            </Radio.Group>
          </Form.Item>
        )}
        <Form.Item
          label={t('createWorkspaceModal.privacyStep.participantSelectHeader')}
          name="participants"
          rules={[
            {
              required: isPrivate,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={
            t('createWorkspaceModal.privacyStep.extraInputPlaceholder') || ''
          }
        >
          <SelectParticipant
            disabled={isSelectDisabled}
            initialParticipants={initialParticipants}
            allParticipants={selectedWorkspace?.dataSource.participants || []}
          />
        </Form.Item>
        {isQualityReviewWorkspace && (
          <>
            <Form.Item
              label={t(
                'workspaceDetailsPage.workspaceSettingsPanel.templateAnnotationsSection'
              )}
              name={['config', 'ofrDocumentTemplates']}
              rules={[
                {
                  required: isPrivate,
                  message: t('errorMessage.isRequired') || '',
                },
              ]}
              extra={
                t(
                  'workspaceDetailsPage.workspaceSettingsPanel.templateAnnotationsSectionDescription'
                ) || ''
              }
            >
              <SelectOfrDocumentTemplate
                disabled={!isQualityReviewWorkspace}
                dataSourceId={selectedWorkspace?.dataSource.id}
                initalDocument={{
                  key: selectedWorkspace?.config?.ofrDocumentTemplatesId || '',
                  value:
                    selectedWorkspace?.config?.ofrDocumentTemplatesId || '',
                  label:
                    selectedWorkspace?.config?.ofrDocumentTemplatesName || '',
                  documentId:
                    selectedWorkspace?.config?.ofrDocumentTemplates || '',
                }}
                onChange={handleChangeOfrDocumentTemplates}
              />
            </Form.Item>
            <Form.Item
              name={['config', 'ofrDocumentTemplatesName']} // Separate field for the file name
              noStyle
            >
              <Input type="hidden" />{' '}
              {/* Hidden field to hold the name value */}
            </Form.Item>
            <Form.Item
              name={['config', 'ofrDocumentTemplatesId']} // Separate field for the file id
              noStyle
            >
              <Input type="hidden" />{' '}
              {/* Hidden field to hold the name value */}
            </Form.Item>
          </>
        )}
      </MultiColumnFormColumnContainer>
      <MultiColumnFormColumnContainer></MultiColumnFormColumnContainer>
    </MultiColumnFormContentContainer>
  )
}

export default WorkspaceSettingsInfoPanel
