import { FormInstance } from 'antd'
import { t } from 'i18next'
import styled from 'styled-components'
import { Role } from '../../../../store/reducers/roleReducer'
import { Language } from '../../../../types/generalTypes'

const HeaderTextContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-top: 8px;
`

const ItemTextCotainer = styled.div`
  margin-bottom: 8px;
  padding-left: 8px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

interface CreateUserModalReviewStepProps {
  form: FormInstance
  selectedRole: Role | undefined
  selectedLanguage: Language
  emails: string
}

const CreateUserModalReviewStep = ({
  form,
  selectedRole,
  selectedLanguage,
  emails,
}: CreateUserModalReviewStepProps) => {
  return (
    <ContentContainer>
      <HeaderTextContainer
        style={{
          paddingTop: '8px',
        }}
      >
        {t('userListPage.createUserModal.addedEmailAddresses')}
      </HeaderTextContainer>
      <ItemTextCotainer>{emails}</ItemTextCotainer>
      <HeaderTextContainer>
        {t('userListPage.createUserModal.userRole')}
      </HeaderTextContainer>
      <ItemTextCotainer>{selectedRole?.title}</ItemTextCotainer>
      <HeaderTextContainer>{t('Language')}</HeaderTextContainer>
      <ItemTextCotainer>{t(selectedLanguage)}</ItemTextCotainer>
    </ContentContainer>
  )
}

export default CreateUserModalReviewStep
