import { ofrBlockTypes } from '../../constants/constants'
import {
  OfrBlockInfo,
  OfrDocumentTemplatePage,
  OfrDocumentTemplateResponse,
  ofrStatusEnum,
} from '../../store/reducers/ofrDocumentTemplateReducer'

const blocks: OfrBlockInfo[] = [
  {
    id: '1111',
    ofrBlockName: 'Mock list name',
    ofrBlockType: ofrBlockTypes.EXPECTED_DATA,
    cell: { x1: 100, y1: 100, x2: 200, y2: 200, isGhost: false },
    deviations: undefined,
    ocrTexts: [],
    htrTexts: [],
    isCheckbox: false,
    containsWriting: false,
    hasStrikeThrough: undefined,
  },
]

const mockOfrDocumentTemplatePages: OfrDocumentTemplatePage[] = [
  {
    id: 'mocked id',
    blocks: blocks,
    DPI: 10,
    writingHeightCM: 100,
    writingWidthCM: 100,
    imageWidth: 500,
    imageHeight: 600,
  },
]

const mockOfrDocumentTemplateList: OfrDocumentTemplateResponse = {
  data: [
    {
      id: '270636',
      title: 'Template Creation Test',
      description: null,
      dataSource: '270614',
      documentId: '270614-01IKYMC4QEC4LCTJ5BSJHZMGFDVYUGOHYT',
      status: ofrStatusEnum.PROCESSING,
      formPages: mockOfrDocumentTemplatePages,
      createdBy: '56362',
      lastModifiedBy: '56362',
      createdDate: '2024-08-19T15:44:08.789Z',
      lastModifiedDate: '2024-08-19T15:44:08.789Z',
      archived: false,
    },
    {
      id: '272448',
      title: 'Template Creation Test 2',
      description: null,
      dataSource: '270614',
      documentId: '270614-01IKYMC4QEC4LCTJ5BSJHZMGFDVYUGOHYT',
      status: ofrStatusEnum.PROCESSING,
      formPages: [],
      createdBy: '56362',
      lastModifiedBy: '56362',
      createdDate: '2024-08-27T19:28:11.466Z',
      lastModifiedDate: '2024-08-27T19:28:11.466Z',
      archived: false,
    },
    {
      id: '271110',
      title: 'test',
      description: null,
      dataSource: '95900',
      documentId: '95900-01IPA26SIQLUV6CQNHFJGLCQPVIUUJQMSP',
      status: ofrStatusEnum.PROCESSING,
      formPages: [],
      createdBy: '56370',
      lastModifiedBy: '56355',
      createdDate: '2024-08-22T17:36:35.711Z',
      lastModifiedDate: '2024-08-26T21:30:00.216Z',
      archived: false,
    },
    {
      id: '271134',
      title: 'test 2',
      description: null,
      dataSource: '235448',
      documentId: '235448-01IPA26SIQLUV6CQNHFJGLCQPVIUUJQMSP',
      status: ofrStatusEnum.PROCESSING,
      formPages: [],
      createdBy: '56370',
      lastModifiedBy: '56355',
      createdDate: '2024-08-22T17:39:22.966Z',
      lastModifiedDate: '2024-08-26T21:30:00.254Z',
      archived: false,
    },
    {
      id: '271917',
      title: 'Test Creation 2',
      description: null,
      dataSource: '95900',
      documentId: '95900-01IPA26SIQLUV6CQNHFJGLCQPVIUUJQMSP',
      status: ofrStatusEnum.PROCESSING,
      formPages: [],
      createdBy: '56370',
      lastModifiedBy: '56355',
      createdDate: '2024-08-26T18:33:09.645Z',
      lastModifiedDate: '2024-08-26T21:30:00.255Z',
      archived: false,
    },
  ],
  totalResults: 5,
  totalPages: 1,
  page: 1,
  pageSize: 100,
}

export default mockOfrDocumentTemplateList
