import { message } from 'antd'
import { ReactNode } from 'react'
import { NOTIFICATION_DEFAULT_DURATION } from '../constants/constants'

const notificationSuccess = (messageToDisplay?: ReactNode) => {
  message.success({
    content: messageToDisplay,
    duration: NOTIFICATION_DEFAULT_DURATION,
    className: 'success-message',
  })
}

const notificationError = (messageToDisplay?: ReactNode) => {
  message.error({
    content: messageToDisplay,
    duration: NOTIFICATION_DEFAULT_DURATION,
    className: 'error-message',
  })
}

const notificationWarning = (messageToDisplay?: ReactNode) => {
  message.warning({
    content: messageToDisplay,
    duration: NOTIFICATION_DEFAULT_DURATION,
    className: 'warning-message',
  })
}

const notificationService = {
  notificationError,
  notificationSuccess,
  notificationWarning
}

export default notificationService
