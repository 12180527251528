import { useState } from 'react'
import { DetailsPageLayout } from '../../../components'
import BiomarkSmartFunctionDetailsPageHeader from './components/BiomarkSmartFunctionDetailsPageHeader'
import BiomarkSmartFunctionPanel from './panels/BiomarkSmartFunctionPanel'

const BiomarkSmartFunctionDetailsPage = () => {
  const [search, setSearch] = useState('')

  return (
    <DetailsPageLayout>
      <BiomarkSmartFunctionDetailsPageHeader
        setSearch={setSearch}
        search={search}
      />
      <BiomarkSmartFunctionPanel search={search} />
    </DetailsPageLayout>
  )
}

export default BiomarkSmartFunctionDetailsPage
