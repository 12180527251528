import { DetailsPageLayout } from '../../../components'
import QualityReviewSmartFunctionDocumentDetailsPageHeader from './components/QualityReviewSmartFunctionDocumentDetailsPageHeader'
import QualityReviewSmartFunctionDocumentDetailsPagePanel from './panels/QualityReviewSmartFunctionDocumentDetailsPagePanel'

const QualityReviewSmartFunctionDocumentDetailsPage = () => {
  return (
    <DetailsPageLayout>
      <QualityReviewSmartFunctionDocumentDetailsPageHeader />
      <QualityReviewSmartFunctionDocumentDetailsPagePanel />
    </DetailsPageLayout>
  )
}

export default QualityReviewSmartFunctionDocumentDetailsPage
