import { useState, useEffect } from 'react'

interface UseUIFlagsProps {
  uiFlag: number | undefined
}
const useUIFlags = ({ uiFlag }: UseUIFlagsProps) => {
  const [flags, setFlags] = useState<number | undefined>(uiFlag)

  useEffect(() => {
    setFlags(uiFlag)
  }, [uiFlag])

  const hasPermission = (flagsToCheck: number | undefined) => {
    return flagsToCheck && flags ? (flags & flagsToCheck) !== 0 : false
  }

  return {
    flags,
    hasPermission,
  }
}

export default useUIFlags
