import { Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { Language, SelectOption } from '../types/generalTypes'

interface SelectLanguageProps {
  defaultValue?: Language
  value?: Language
  onChange?: (value: Language | undefined) => void
  style?: Object
  loading?: boolean
}

const SelectLanguage = ({
  defaultValue,
  value,
  onChange,
  style,
  loading,
}: SelectLanguageProps) => {

  const [selectedValue, setSelectedValue] = useState<Language | undefined>(
    defaultValue
  )

  const options: SelectOption[] = [
    {
      label: t('English') || '',
      value: 'en',
    },
    {
      label: t('French') || '',
      value: 'fr',
    },
  ]

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  const handleChange = (value: Language) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      style={style}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('selectLanguage.placeholder')}
      loading={loading}
    />
  )
}

export default SelectLanguage
