import Table, { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { MouseEvent, useCallback, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ConfirmDeleteModal,
  DeleteIconButton,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  dateFormat,
  privacyTypes,
} from '../../constants/constants'
import { useDataSources } from '../../hooks'
import dataSourceService from '../../services/dataSourcesService'
import notificationService from '../../services/notificationService'
import {
  DataSource,
  setSelectedDataSource,
} from '../../store/reducers/dataSourceReducer'
import { routePaths } from '../RootPage'
import CreateDataSourceModal from './modals/CreateDataSourceModal'
import moment from 'moment'

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0;
`

const InitialMessageContainer = styled.div`
  font-weight: 600;
  margin: auto;
`

interface DataSourceExtended extends DataSource {
  key: string
}

const DataSourcesListPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {
    dataSourceList,
    refreshDataSourceList,
    loading,
    exportDataSourceListCsv,
  } = useDataSources({})
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteId, setDeleteId] = useState<string | undefined>(undefined)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const formattedData: DataSourceExtended[] =
    useMemo(
      () =>
        dataSourceList?.map((dataSource: DataSource) => ({
          ...dataSource,
          key: dataSource.id,
        })),
      [dataSourceList]
    ) || []

  const handleCancelDelete = (event: MouseEvent) => {
    event.stopPropagation()
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = (event: MouseEvent) => {
    event.stopPropagation()
    if (deleteId) {
      setDeleteLoading(true)
      dataSourceService
        .deleteDataSource({ dataSourceId: deleteId, navigate })
        .then(() => {
          notificationService.notificationSuccess(
            t('dataSourcesListPage.deleteSuccessful')
          )
          refreshDataSourceList()
          setDeleteLoading(false)
        })
        .catch((error: AxiosError | Error) => {
          console.error('axios fetch error', error)
          setDeleteLoading(false)
        })
    }
    setConfirmDeleteModalOpen(false)
    setDeleteId(undefined)
  }

  const handleClick = (id: string) => {
    const dataSource = dataSourceList.find(
      (dataSource: DataSource) => dataSource.id === id
    )
    if (dataSource) {
      dispatch(setSelectedDataSource(dataSource))
    }
    navigate(`${routePaths.DATA_SOURCE_DETAILS}/${id}`)
  }

  const handleDeleteClick = useCallback((event: MouseEvent, id: string) => {
    event.stopPropagation()
    setDeleteId(id)
    setConfirmDeleteModalOpen(true)
  }, [])

  const columns: ColumnsType<DataSource> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      sorter: (a: DataSource, b: DataSource) =>
        a.title && b.title ? a.title.localeCompare(b.title) : -1,
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },

    {
      title: t('Creation Date'),
      key: 'createdDate',
      dataIndex: 'createdDate',
      sorter: (a: DataSource, b: DataSource) =>
        a.createdDate && b.createdDate
          ? moment(a.createdDate).valueOf() - moment(b.createdDate).valueOf()
          : -1,
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('Type'),
      dataIndex: 'typeName',
      key: 'typeName',
      sorter: (a: DataSource, b: DataSource) =>
        a.typeName && b.typeName ? a.typeName.localeCompare(b.typeName) : -1,
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, { id, visibility }) => (
        <>
          {visibility !== privacyTypes.PUBLIC && (
            <DeleteIconButton
              tooltipTitle={t('dataSourcesListPage.deleteDataSourceTooltip')}
              onClick={(event: MouseEvent) => handleDeleteClick(event, id)}
            />
          )}
        </>
      ),
    },
  ]

  const handleExportDataSourcesList = () => {
    exportDataSourceListCsv()
  }

  return loading ? (
    <SectionLoadingState />
  ) : (
    <>
      <ListPageLayout data-testid="data-sources-list">
        <ListPageHeader
          title={t('dataSourcesListPage.title', {
            count: dataSourceList.length,
          })}
          actions={
            <>
              <CreateDataSourceModal />
              <PageContentDropdown
                onExport={handleExportDataSourcesList}
                disabled={formattedData.length === 0}
              />
            </>
          }
        />
        {dataSourceList.length === 0 ? (
          <FullPageContainer>
            <InitialMessageContainer>
              {t('dataSourcesListPage.initialMessage')}
            </InitialMessageContainer>
          </FullPageContainer>
        ) : (
          <Table
            data-testid="data-sources-list-table"
            rowClassName="page-list-table-row clickable"
            columns={columns}
            dataSource={formattedData}
            pagination={false}
            scroll={{ y: 'calc(100vh - 170px)' }}
            onRow={(record, rowIndex) => {
              return {
                onClick: (event) => {
                  handleClick(record.id)
                }, // click row
              }
            }}
          />
        )}
        <ConfirmDeleteModal
          open={confirmDeleteModalOpen}
          onConfirm={handleConfirmDelete}
          onCancel={handleCancelDelete}
          loading={deleteLoading}
          confirmButtonLabel={
            t('dataSourcesListPage.confirmDeleteModal.yesButton') || ''
          }
        >
          {t('dataSourcesListPage.confirmDeleteModal.content')}
        </ConfirmDeleteModal>
      </ListPageLayout>
    </>
  )
}

export default DataSourcesListPage
