import {
  ControlOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Radio, Space } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackButton,
  ConfirmDeleteModal,
  DetailsPageLayout,
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  PageContentDropdown,
  SelectParticipant,
  Spacer,
  SubHeader,
} from '../../components'
import {
  EGNYTE_TYPECODE,
  NO_DATA_PLACEHOLDER,
  REGDOCS_TYPECODE,
  SHAREPOINT_TYPECODE,
  privacyTypes,
} from '../../constants/constants'
import { useDataSources } from '../../hooks'
import { dataSourceService, validationService } from '../../services'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import {
  DataSource,
  setDataSourceLoading,
  setSelectedDataSource,
} from '../../store/reducers/dataSourceReducer'
import { User } from '../../store/reducers/userReducer'
import { PrivacyType, TranslationDictionary } from '../../types/generalTypes'
import { routePaths } from '../RootPage'

interface DataSourceFormValues {
  id: string
  title: TranslationDictionary | string
  description: string
  visibility?: PrivacyType
  participants?: string[]
  contactName: string
  contactEmail: string
}

const DataSourceDetailsPage = () => {
  const dispatch = useDispatch()
  const { dataSourceId } = useParams()
  const navigate = useNavigate()

  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const [form] = Form.useForm()

  const { refreshDataSourceList, exportDataSourceDetailsCsv } = useDataSources({
    preventFetch: true,
  })

  const selectedDataSource = useSelector(
    (state: RootState) => state.dataSource.selected
  )
  const defaultValues: DataSourceFormValues = useMemo(
    () => ({
      id: selectedDataSource?.id || dataSourceId || '',
      title: selectedDataSource?.title || '',
      visibility: selectedDataSource?.visibility || privacyTypes.PRIVATE,
      participants: selectedDataSource?.participants.map(
        (participant: User) => participant.id
      ),
      description: selectedDataSource?.description || '',
      contactName: selectedDataSource?.contactName || '',
      contactEmail: selectedDataSource?.contactEmail || '',
    }),
    [selectedDataSource, dataSourceId]
  )

  const dataSourceLibrary = useMemo(() => {
    if (
      selectedDataSource &&
      selectedDataSource.typeCode === REGDOCS_TYPECODE
    ) {
      return selectedDataSource.config?.sourceLibrary
    }
    return undefined
  }, [selectedDataSource])

  const dataSourceTypeCode = useMemo(
    () => selectedDataSource?.typeCode,
    [selectedDataSource]
  )

  const dataSourceTypeName = useMemo(
    () => selectedDataSource?.typeName,
    [selectedDataSource]
  )

  const dataSourcePrivacy = useMemo(
    () => selectedDataSource?.visibility,
    [selectedDataSource]
  )

  const datasourceFolderPath = useMemo(() => {
    if (
      selectedDataSource?.typeCode === SHAREPOINT_TYPECODE ||
      selectedDataSource?.typeCode === EGNYTE_TYPECODE
    ) {
      return selectedDataSource?.config?.sourceFolder
    } else if (selectedDataSource?.typeCode === REGDOCS_TYPECODE) {
      return selectedDataSource?.config?.sourceUrl
    } else {
      return NO_DATA_PLACEHOLDER
    }
  }, [selectedDataSource])

  const configLabel = useMemo(() => {
    if (dataSourceTypeCode === SHAREPOINT_TYPECODE) {
      return t('dataSourceDetailsPage.sharepointSourceLabel')
    } else if (dataSourceTypeCode === EGNYTE_TYPECODE) {
      return t('dataSourceDetailsPage.egnyteSourceLabel')
    } else if (dataSourceTypeCode === REGDOCS_TYPECODE) {
      return t('dataSourceDetailsPage.regDocsSourceLabel')
    } else {
      return undefined
    }
  }, [dataSourceTypeCode])

  useEffect(() => {
    dispatch(setDataSourceLoading(true))
    dataSourceService
      .getDataSourceDetails({ dataSourceId: dataSourceId || '' })
      .then((response: DataSource) => {
        dispatch(setDataSourceLoading(false))
        dispatch(setSelectedDataSource(response))
      })
      .catch((error: AxiosError | Error) => {
        dispatch(setDataSourceLoading(false))
        console.error('axios fetch error', error)
      })
  }, [dispatch, dataSourceId])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  const handleSave = (values: DataSourceFormValues) => {
    if (
      values.visibility === privacyTypes.PRIVATE &&
      values.participants?.length === 0
    ) {
      return notificationService.notificationError(
        t('dataSourceDetailsPage.dataSourceParticipantsError') || ''
      )
    }
    setSaveLoading(true)
    dataSourceService
      .updateDataSourceDetails({
        title: values.title,
        description: values.description || '',
        visibility: values.visibility || privacyTypes.PRIVATE,
        participants: values.participants || [],
        dataSourceId: selectedDataSource?.id || '',
        contactEmail: values.contactEmail,
        contactName: values.contactName,
        navigate,
      })
      .then((response) => {
        refreshDataSourceList()
        setSaveLoading(false)
        notificationService.notificationSuccess(
          t('dataSourceDetailsPage.dataSourceSaveSuccess') || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setSaveLoading(false)
      })
  }

  const handleCancel = () => {
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = () => {
    dispatch(setDataSourceLoading(true))
    setConfirmDeleteModalOpen(false)
    setDeleteLoading(true)
    dataSourceService
      .deleteDataSource({
        dataSourceId: selectedDataSource?.id || '',
        navigate,
      })
      .then(() => {
        refreshDataSourceList()
        setDeleteLoading(false)
        notificationService.notificationSuccess(
          t('dataSourceDetailsPage.deleteSuccessful') || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setDeleteLoading(false)
      })
    dispatch(setDataSourceLoading(false))
    navigate(routePaths.DATA_SOURCE_LIST)
  }

  const handleExportDataSourceDetails = () => {
    exportDataSourceDetailsCsv(selectedDataSource?.id || '')
  }

  return (
    <DetailsPageLayout>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultValues}
        onFinish={handleSave}
        requiredMark={false}
      >
        <SubHeader>
          <BackButton url={routePaths.DATA_SOURCE_LIST} />
          <ControlOutlined />
          {t('dataSourceDetailsPage.dataSourceDetailsHeaderTitle')}
          <Spacer />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              setConfirmDeleteModalOpen(true)
            }}
            loading={deleteLoading}
          >
            {t('dataSourceDetailsPage.deleteDataSourceButton')}
          </Button>
          <ConfirmDeleteModal
            open={confirmDeleteModalOpen}
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancel}
            loading={deleteLoading}
            confirmButtonLabel={
              t('dataSourcesListPage.confirmDeleteModal.yesButton') || ''
            }
          >
            {t('dataSourcesListPage.confirmDeleteModal.content')}
          </ConfirmDeleteModal>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            loading={saveLoading}
          >
            {t('saveButton')}
          </Button>
          <PageContentDropdown onExport={handleExportDataSourceDetails} />
        </SubHeader>
        <MultiColumnFormContentContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item
              label={t('Title')}
              required
              name="title"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('Description')} name="description">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </MultiColumnFormColumnContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item label={t('Type')}>{dataSourceTypeName}</Form.Item>
            {configLabel && (
              <Form.Item label={configLabel}>{datasourceFolderPath}</Form.Item>
            )}
            {dataSourceLibrary && (
              <Form.Item label={t('Library')}>{dataSourceLibrary}</Form.Item>
            )}
            <Form.Item
              label={t('dataSourceDetailsPage.privacySettingsLabel')}
              name="visibility"
            >
              <Radio.Group disabled>
                <Space direction="vertical">
                  <Radio value={privacyTypes.PUBLIC} disabled>
                    {t('dataSourceDetailsPage.privacySettingsPublicLabel')}
                  </Radio>
                  <Radio value={privacyTypes.PRIVATE} disabled>
                    {t('dataSourceDetailsPage.privacySettingsPrivateLabel')}
                  </Radio>
                  <Radio value={privacyTypes.ORGANIZATION} disabled>
                    {t(
                      'dataSourceDetailsPage.privacySettingsOrganizationalLabel'
                    )}
                  </Radio>
                  <Radio value={privacyTypes.FEDERATION} disabled>
                    {t('dataSourceDetailsPage.privacySettingsFederationLabel')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            {dataSourcePrivacy !== privacyTypes.PUBLIC && (
              <>
                <Form.Item
                  label={t('dataSourceDetailsPage.dataSourceParticipantsLabel')}
                  name="participants"
                >
                  <SelectParticipant
                    disabled
                    initialParticipants={defaultValues?.participants}
                  />
                </Form.Item>
                <Form.Item
                  label={t('dataSourceDetailsPage.contactNameLabel')}
                  required
                  name="contactName"
                  rules={[
                    {
                      required: true,
                      message: t('errorMessage.isRequired') || '',
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
                <Form.Item
                  label={t('dataSourceDetailsPage.contactEmailLabel')}
                  required
                  name="contactEmail"
                  rules={[
                    {
                      required: true,
                      message: t('errorMessage.isRequired') || '',
                    },
                    {
                      validator: validationService.validateEmail,
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </>
            )}
          </MultiColumnFormColumnContainer>
        </MultiColumnFormContentContainer>
      </Form>
    </DetailsPageLayout>
  )
}

export default DataSourceDetailsPage
