import { FormOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { memo, useCallback, useMemo, useState } from 'react'
import { ModalBase, ModalContentContainer } from '../../../components'
import { optionalRequiredMark } from '../../../constants/constants'
import { useOfrDocumentTemplate } from '../../../hooks'
import CreateOfrFormModalFooter from './footer/CreateOfrFormModalFooter'
import CreateOfrDocumentTemplateModalStep from './steps/CreateOfrDocumentTemplateModalStep'


export interface OfrFormValues {
  documentId: string
  dataSource: string
  title: string
}

const CreateOfrDocumentTemplateModal = memo(() => {
  const [form] = Form.useForm<OfrFormValues>()

  const { createOfrDocumentTemplate } = useOfrDocumentTemplate({})

  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const resetValues = useCallback(() => {
    form.resetFields()
  }, [form])

  const handleOk = useCallback(() => {
    setConfirmLoading(true)
    form.submit()
  }, [form])

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
  }, [resetValues])

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields()
      createOfrDocumentTemplate({
        documentId: values.documentId,
        dataSource: values.dataSource,
        title: values.title,
        navigate: null,
      }).then(() => {
        resetValues()
        setOpen(false)
      })
    } catch (error) {
      console.error('Validation Failed:', error)
    } finally {
      setConfirmLoading(false)
    }
  }, [createOfrDocumentTemplate, form, resetValues])

  const defaultValues = useMemo(
    () => ({
      title: undefined,
      dataSource: undefined,
      documentId: undefined,
    }),
    []
  )

  return (
    <>
      <Button
        icon={<FormOutlined />}
        onClick={() => setOpen(true)}
        type="primary"
      >
        {t('ofrDocumentTemplateListPage.annotateTemplateButton')}
      </Button>
      <ModalBase
        key="main"
        title={t('createOfrDocumentTemplateModal.title')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        destroyOnClose
        width={720}
        footer={
          <CreateOfrFormModalFooter
            onCancel={handleCancel}
            onSubmit={handleSubmit}
          />
        }
        styles={{
          body: {
            maxHeight: 'calc(100vh - 400px)',
          },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          requiredMark={optionalRequiredMark}
          onFinish={handleSubmit}
        >
          <ModalContentContainer>
            <CreateOfrDocumentTemplateModalStep form={form} />
          </ModalContentContainer>
        </Form>
      </ModalBase>
    </>
  )
})

export default CreateOfrDocumentTemplateModal
