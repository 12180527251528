import { Form } from 'antd'
import { useMemo } from 'react'
import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { SearchQueryListTable } from '../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../constants/constants'
import { useLiteratureReviews } from '../../../hooks'
import { RootState } from '../../../store'

interface LiteratureReviewDetailsOverviewQueriesPanelProps {
  handleSearchDelete: () => void
}

const SearchQueryListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`

const LiteratureReviewDetailsOverviewQueriesPanel = ({
  handleSearchDelete,
}: LiteratureReviewDetailsOverviewQueriesPanelProps) => {
  const { selectedLiteratureReview } =
    useLiteratureReviews({
      preventFetch: true,
    })
  const { currentUser } = useSelector((state: RootState) => state.user)
  const isCurrentUserOwner = useMemo(
    () => currentUser?.id === selectedLiteratureReview?.createdBy?.id,
    [selectedLiteratureReview, currentUser]
  )

  return (
    <div>
      <Form.Item>
        <SearchQueryListContainer>
          {selectedLiteratureReview?.searchQueries &&
          selectedLiteratureReview?.searchQueries.length >= 1 ? (
            <SearchQueryListTable
              searchQueries={selectedLiteratureReview?.searchQueries}
              literatureReviewId={selectedLiteratureReview?.id}
              useDelete={isCurrentUserOwner}
              onDelete={handleSearchDelete}
            />
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </SearchQueryListContainer>
      </Form.Item>
    </div>
  )
}

export default LiteratureReviewDetailsOverviewQueriesPanel
