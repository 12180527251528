import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { DetailsPageLayout } from '../../../components'
import {
  NO_DATA_PLACEHOLDER,
  algorithmRunStatuses,
} from '../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../hooks'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import useWorkspaceFolders from '../../../hooks/useWorkspaceFolders'
import { routePaths } from '../../RootPage'
import SmartFunctionProgressPanel from '../components/SmartFunctionProgressPanel'
import RefAISmartFunctionDetailsPageHeader from './components/RefAiSmartFunctionDetailsPageHeader'
import RefAISmartFunctionFoldersPanel from './panels/RefAISmartFunctionFoldersPanel'
import RefAISmartFunctionMissingReferencesPanel from './panels/RefAISmartFunctionMissingReferencesPanel'

export type ReferenceType =
  | 'missingReference'
  | 'ignoredReference'
  | 'document'
  | 'runStatus'
export type ReferenceTypes = {
  MISSING_REFERENCE: ReferenceType
  IGNORED_REFERENCE: ReferenceType
  DOCUMENT: ReferenceType
  RUN_STATUS: ReferenceType
}
export const referenceTypes: ReferenceTypes = {
  MISSING_REFERENCE: 'missingReference',
  IGNORED_REFERENCE: 'ignoredReference',
  DOCUMENT: 'document',
  RUN_STATUS: 'runStatus',
}

const referenceTypeToPanelIndex = (
  referenceType: ReferenceType | undefined
) => {
  switch (referenceType) {
    case referenceTypes.MISSING_REFERENCE:
      return 0
    case referenceTypes.IGNORED_REFERENCE:
      return 1
    case referenceTypes.DOCUMENT:
      return 2
    case referenceTypes.RUN_STATUS:
      return 3
  }

  return 0
}

interface RefAISmartFunctionDetailsPageProps {
  singleWorkspace?: boolean
}

const RefAISmartFunctionDetailsPage = ({
  singleWorkspace,
}: RefAISmartFunctionDetailsPageProps) => {
  const navigate = useNavigate()
  const { workspaceId, algorithmRunId, referenceType } = useParams()
  const [panelIndex, setPanelIndex] = useState(
    referenceTypeToPanelIndex(referenceType as ReferenceType)
  )
  const [refreshWorkspaceDone, setRefreshWorkspaceDone] = useState(false)
  const [refreshWorkspaceFoldersDone, setRefreshWorkspaceFoldersDone] =
    useState(false)
  const [search, setSearch] = useState('')

  const { refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { refreshWorkspaceFolders } = useWorkspaceFolders({
    workspaceId,
    preventFetch: true,
  })

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  useEffect(() => {
    if (!refreshWorkspaceDone) {
      setRefreshWorkspaceDone(true)
      refreshWorkspaceDetails()
    }
  }, [refreshWorkspaceDetails, refreshWorkspaceDone])

  useEffect(() => {
    if (!refreshWorkspaceFoldersDone) {
      setRefreshWorkspaceFoldersDone(true)
      refreshWorkspaceFolders()
    }
  }, [refreshWorkspaceFolders, refreshWorkspaceFoldersDone])

  const isStatusOngoing = useMemo(
    () =>
      !!selectedAlgorithmRun?.runStatus &&
      (selectedAlgorithmRun.runStatus === algorithmRunStatuses.ONGOING_AI ||
        selectedAlgorithmRun.runStatus ===
          algorithmRunStatuses.ONGOING_INGESTION),
    [selectedAlgorithmRun?.runStatus]
  )

  useEffect(() => {
    if (
      selectedAlgorithmRun &&
      (isStatusOngoing ||
        selectedAlgorithmRun.runStatus === algorithmRunStatuses.CANCELLED)
    ) {
      setPanelIndex(referenceTypeToPanelIndex(referenceTypes.RUN_STATUS))
    } else {
      setPanelIndex(referenceTypeToPanelIndex(referenceTypes.MISSING_REFERENCE))
    }
  }, [isStatusOngoing, selectedAlgorithmRun])

  const progressText = useMemo(() => {
    if (isStatusOngoing) {
      return t('smartFunctionDetailsPage.refAi.refAiRunInProgress')
    } else if (
      selectedAlgorithmRun?.runStatus === algorithmRunStatuses.CANCELLED
    ) {
      return t('smartFunctionDetailsPage.refAi.refAiRunCancelled')
    } else {
      return NO_DATA_PLACEHOLDER
    }
  }, [isStatusOngoing, selectedAlgorithmRun])

  useEffect(() => {
    referenceTypeToPanelIndex(referenceType as ReferenceType)
  }, [referenceType])

  const panels = useMemo(
    () => [
      <RefAISmartFunctionMissingReferencesPanel search={search} />,
      <RefAISmartFunctionMissingReferencesPanel
        search={search}
        isIgnoredReference
      />,
      <RefAISmartFunctionFoldersPanel search={search} />,
      <SmartFunctionProgressPanel text={progressText} />,
    ],
    [search, progressText]
  )

  const handleReferenceChange = useCallback(
    (value: ReferenceType | undefined) => {
      setPanelIndex(referenceTypeToPanelIndex(value))
      const baseRoute = singleWorkspace
        ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
        : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
      navigate(
        `${baseRoute}/${workspaceId}/${algorithmRunId}/${
          value || referenceTypes.MISSING_REFERENCE
        }`
      )
    },
    [algorithmRunId, workspaceId, navigate, singleWorkspace]
  )

  return (
    <DetailsPageLayout>
      <RefAISmartFunctionDetailsPageHeader
        handleReferenceChange={handleReferenceChange}
        setSearch={setSearch}
        search={search}
        singleWorkspace={singleWorkspace}
      />
      {panels[panelIndex]}
    </DetailsPageLayout>
  )
}

export default RefAISmartFunctionDetailsPage
