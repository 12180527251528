import { Tag } from 'antd'
import { ReactNode } from 'react'

export interface BadgeProps {
  color?: string
  children?: ReactNode
}

const FilterBadge = ({ color, children }: BadgeProps) => {
  return (
    <Tag
      color={color}
      style={{
        margin: 0,
      }}
    >
      {children}
    </Tag>
  )
}

export default FilterBadge
