import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import { delConfigurationModalTypes } from '../../../../../../constants/constants'

interface DelConfigurationModalTypeStepProps {
  form: FormInstance
}

const DelConfigurationModalTypeStep = memo(
  ({ form }: DelConfigurationModalTypeStepProps) => {
    const delConfigurationTypeList = [
      {
        id: delConfigurationModalTypes.DIN,
        typeName: t('delConfigurationModal.steps.typeStep.din'),
        typeCode: delConfigurationModalTypes.DIN,
      },
      {
        id: delConfigurationModalTypes.TABLE_A,
        typeName: t('delConfigurationModal.steps.typeStep.tableA'),
        typeCode: delConfigurationModalTypes.TABLE_A,
      },
    ]

    const handleOnFieldChange = (delType: string) => {
      form.setFieldsValue({
        reviewType: delType,
      })
    }

    return (
      <>
        <Form.Item
          label={t('delConfigurationModal.steps.typeStep.label')}
          name="reviewType"
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
        >
          <Radio.Group
            buttonStyle="solid"
            onChange={(e) => {
              handleOnFieldChange(e.target.value)
            }}
            value={form.getFieldValue('reviewType')}
          >
            <Space direction="vertical">
              {delConfigurationTypeList.map((delType) => {
                return (
                  <Radio key={delType.id} value={delType.typeCode}>
                    {delType.typeName}
                  </Radio>
                )
              })}
            </Space>
          </Radio.Group>
        </Form.Item>
      </>
    )
  }
)

export default DelConfigurationModalTypeStep
