import { Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { SelectOption } from '../../../types/generalTypes'
import {
  WorkspacePanelType,
  workspacePanelTypes,
} from '../WorkspaceDetailsPage'

interface SelectWorkspacePanelTypeProps {
  onChange?: (value: WorkspacePanelType | undefined) => void
  disabled?: boolean
}

const SelectWorkspacePanelType = ({
  onChange,
  disabled,
}: SelectWorkspacePanelTypeProps) => {
  const { panelType, workspaceId } = useParams()
  const [selectedValue, setSelectedValue] = useState<WorkspacePanelType>(
    (panelType || workspacePanelTypes.OVERVIEW) as WorkspacePanelType
  )
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState<
    string | undefined
  >(workspaceId)

  useEffect(() => {
    if (workspaceId !== selectedWorkspaceId || panelType !== selectedValue) {
      setSelectedValue(workspacePanelTypes.OVERVIEW)
      setSelectedWorkspaceId(workspaceId)
    }
  }, [panelType, workspaceId, selectedWorkspaceId, selectedValue])

  const options: SelectOption[] = [
    {
      label: t('Overview') || '',
      value: workspacePanelTypes.OVERVIEW,
    },
    {
      label: t('Settings') || '',
      value: workspacePanelTypes.SETTINGS,
    },
  ]

  const handleChange = (value: WorkspacePanelType) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('Overview')}
      disabled={disabled}
    />
  )
}

export default SelectWorkspacePanelType
