import {
  PermissionAction,
  PermissionName,
  PermissionType,
} from '../types/generalTypes'

export interface RolePermissionName {
  ALL: PermissionName
  ADMIN: PermissionName
  USERS: PermissionName
  INITIATIVES: PermissionName
  AI_JOBS: PermissionName
  DESIGNER: PermissionName
  DATA_SOURCES: PermissionName
  DATA_STORES: PermissionName
  ROLES: PermissionName
  AUDIT_TRAILS: PermissionName
}
export const permissionNames: RolePermissionName = {
  ALL: '*',
  ADMIN: 'admin',
  USERS: 'users',
  INITIATIVES: 'initiatives',
  AI_JOBS: 'aiJobs',
  DESIGNER: 'designer',
  DATA_SOURCES: 'dataSources',
  DATA_STORES: 'dataStores',
  ROLES: 'roles',
  AUDIT_TRAILS: 'auditTrail',
}

export interface RolePermissionAction {
  ALL: PermissionAction
  CREATE: PermissionAction
  DELETE: PermissionAction
  READ: PermissionAction
  UPDATE: PermissionAction
  VIEW: PermissionAction
}
export const permissionActions: RolePermissionAction = {
  ALL: '*',
  CREATE: 'create',
  DELETE: 'delete',
  READ: 'read',
  UPDATE: 'update',
  VIEW: 'view',
}

export interface RolePermissionType {
  ALL: PermissionType
  OWN: PermissionType
}
export const permissionValues: RolePermissionType = {
  ALL: '*',
  OWN: 'own',
}
