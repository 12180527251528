import { InboxOutlined } from '@ant-design/icons'
import { ReactNode } from 'react'
import styled from 'styled-components'
import Spacer from './display/Spacer'
import SubHeader from './display/SubHeader'

const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
`

interface ListPageHeaderProps {
  title?: ReactNode
  actions?: ReactNode
}

const ListPageHeader = ({ title, actions }: ListPageHeaderProps) => {
  return (
    <SubHeader
      style={{
        height: 32,
      }}
    >
      <TitleContainer>
        <InboxOutlined />
        {title}
      </TitleContainer>
      <Spacer />
      {actions}
    </SubHeader>
  )
}

export default ListPageHeader
