import { Button, Tag, Typography } from 'antd'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import styled from 'styled-components'
import { ModalBase, ModalContentContainer } from '../../../../../components'
import { RegulatorySubmissionConfigValues } from '../../../../../store/reducers/workspaceReducer'

const { Text } = Typography

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-start;
`

const DropDownItem = styled.div`
  width: 200px;
`
interface RegulatorySubmissionLastConfigurationModalProps {
  config?: RegulatorySubmissionConfigValues
}

const RegulatorySubmissionLastConfigurationModal = ({
  config,
}: RegulatorySubmissionLastConfigurationModalProps) => {
  const [open, setOpen] = useState(false)

  const [confirmLoading, setConfirmLoading] = useState(false)

  const [documentNames, setDocumentNames] = useState<string[]>([])

  useEffect(() => {
    setConfirmLoading(true)
    if (config && config.documentNames) {
      setDocumentNames([...config.documentNames])
    }
    setConfirmLoading(false)
  }, [open, config])

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <DropDownItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {t(
          'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.viewRunSetup'
        )}
      </DropDownItem>
      <ModalBase
        destroyOnClose
        key="regsubLastConfigModal"
        title={t('regulatorySubmissionConfigurationModal.title')}
        open={open}
        onOk={handleClose}
        onCancel={handleClose}
        forceRender
        confirmLoading={confirmLoading}
        footer={<Button onClick={handleClose}>{t('Close')}</Button>}
      >
        <ModalContentContainer>
          <>
            <Text>
              {t('regulatorySubmissionConfigurationModal.reviewHeader')}
            </Text>
            <TagsContainer
              style={{
                display: 'flex',
                flexDirection: 'column',
                marginTop: '10px',
                alignItems: 'flex-start', // Align flex items to the start (top) of the column
              }}
            >
              {documentNames.map((doc, index) => (
                <Tag
                  key={index}
                  style={{
                    marginBottom: '5px',
                    maxWidth: '400px',
                  }}
                >
                  <Text ellipsis>{doc}</Text>
                </Tag>
              ))}
            </TagsContainer>
          </>
        </ModalContentContainer>
      </ModalBase>
    </>
  )
}

export default RegulatorySubmissionLastConfigurationModal
