import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ThemeConfig } from 'antd'
import { RootState } from '..'
import { lightTheme, themeColorKeys, ThemeType } from '../../theme'

export interface ThemeState {
  selectedTheme: ThemeType
  selectedThemeConfig: any
}

const initialState: ThemeState = {
  selectedTheme: themeColorKeys.light,
  selectedThemeConfig: lightTheme,
}

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    setTheme: (state, action: PayloadAction<ThemeType>) => {
      state.selectedTheme = action.payload
    },
    setThemeConfig: (state, action: PayloadAction<ThemeConfig>) => {
      state.selectedThemeConfig = action.payload
    },
    clearThemeState: () => initialState,
  },
})

export const { setTheme, setThemeConfig, clearThemeState } = themeSlice.actions

export const getTheme = (state: RootState) => state.theme.selectedTheme
export const getThemeConfig = (state: RootState) =>
  state.theme.selectedThemeConfig

const themeReducer = themeSlice.reducer
export default themeReducer
