import { useState } from 'react'
import {
  QualityReviewFilterType,
  qualityReviewFilterTypes,
} from '../QualityReviewFunctionDetailsPage'
import { Select } from 'antd'
import { useParams } from 'react-router-dom'
import { SelectOption } from '../../../../types/generalTypes'
import { t } from 'i18next'

interface SelectQualityReviewDeviationFilterChoiceProps {
  onChange?: (value: QualityReviewFilterType | undefined) => void
}

const SelectQualityReviewDeviationFilterChoice = ({
  onChange,
}: SelectQualityReviewDeviationFilterChoiceProps) => {
  const { referenceType } = useParams()
  const [selectedValue, setSelectedValue] = useState<QualityReviewFilterType>(
    (referenceType ||
      qualityReviewFilterTypes.ALL_DOCUMENTS) as QualityReviewFilterType
  )

  const options: SelectOption[] = [
    {
      label:
        t(
          'smartFunctionDetailsPage.qualityReviewSelectFilterType.allDocuments'
        ) || '',
      value: qualityReviewFilterTypes.ALL_DOCUMENTS,
    },
    {
      label:
        t(
          'smartFunctionDetailsPage.qualityReviewSelectFilterType.deviationsOnly'
        ) || '',
      value: qualityReviewFilterTypes.DEVIATIONS_ONLY,
    },
  ]

  const handleChange = (value: QualityReviewFilterType) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t(
        'smartFunctionDetailsPage.qualityReviewSelectFilterType.allDocuments'
      )}
    />
  )
}

export default SelectQualityReviewDeviationFilterChoice
