import { useCallback, useState } from 'react'
import { DetailsPageLayout } from '../../../components'
import QualityReviewSmartFunctionDetailsPageHeader from './components/QualityReviewFunctionDetailsPageHeader'
import QualityReviewSmartFunctionPanel from './panels/QualityReviewSmartFunctionPanel'

export type QualityReviewFilterType = 'allDocuments' | 'deviationsOnly'

export type QualityReviewFilterTypes = {
  ALL_DOCUMENTS: QualityReviewFilterType
  DEVIATIONS_ONLY: QualityReviewFilterType
}
export const qualityReviewFilterTypes: QualityReviewFilterTypes = {
  ALL_DOCUMENTS: 'allDocuments',
  DEVIATIONS_ONLY: 'deviationsOnly',
}

const QualityReviewFunctionDetailsPage = () => {
  const [search, setSearch] = useState('')
  const [filter, setFilter] = useState<QualityReviewFilterType>(
    qualityReviewFilterTypes.ALL_DOCUMENTS
  )

  const handleFilterChange = useCallback((value?: QualityReviewFilterType) => {
    if (value) {
      setFilter(value)
    }
  }, [])

  return (
    <DetailsPageLayout>
      <QualityReviewSmartFunctionDetailsPageHeader
        handleFilterChange={handleFilterChange}
        setSearch={setSearch}
        search={search}
      />
      <QualityReviewSmartFunctionPanel
        search={search}
        deviationsOnly={filter === qualityReviewFilterTypes.DEVIATIONS_ONLY}
      />
    </DetailsPageLayout>
  )
}

export default QualityReviewFunctionDetailsPage
