import { WorkspaceFolderResponse } from '../../store/reducers/workspaceReducer'

const workspaceFolders: WorkspaceFolderResponse = {
  filesInStructure: [],
  folderStructure: {
    path: 'AGP MA',
    folders: [
      {
        path: 'AGP MA/AGP-002026 Mocked',
        folders: [],
        files: [
          {
            id: '1',
            path: 'AGP MA/AGP-002026/AGP-002026 Allowed & Prohibited Conmeds.xlsx',
            webUrl: 'AGP MA/AGP-002026 Mocked/AGP-002026 Mocked.docx',
          },
          {
            id: '2',
            path: 'AGP MA/AGP-002026/AGP-002026_20201218c.pdf',
            webUrl: 'AGP MA/AGP-002026 Mocked/AGP-002026 Mocked.pdf',
          },
          {
            id: '3',
            path: 'AGP MA/AGP-002026/AGP-4654877_Table B9501002_LEGD.docx',
            webUrl:
              'AGP MA/AGP-002026 Mocked/AGP-4654877_Table B9501002_LEGD.docx',
          },
        ],
      },
      {
        path: 'AGP MA/AGP-002027',
        folders: [],
        files: [
          {
            id: '4',
            path: 'AGP MA/AGP-002027/AGP-002027_TableB.xlsx',
            webUrl: 'AGP MA/AGP-002027/AGP-002027_TableB.xlsx',
          },
        ],
      },
      {
        path: 'AGP MA/Amendment 1',
        folders: [],
        files: [
          {
            id: '5',
            path: 'AGP MA/Amendment 1/AGP-2019-034714.docx',
            webUrl: 'AGP MA/Amendment 1/AGP-2019-034714.docx',
          },
          {
            id: '6',
            path: 'AGP MA/Amendment 1/Appendix_2.docx',
            webUrl: 'AGP MA/Amendment 1/Appendix_2.docx',
          },
        ],
      },
      {
        path: 'AGP MA/Manuals',
        folders: [],
        files: [
          {
            id: '7',
            path: 'AGP MA/Manuals/ECHO_MANUAL.docx',
            webUrl: 'AGP MA/Manuals/ECHO_MANUAL.docx',
          },
        ],
      },
      {
        path: 'AGP MA/Protocol Deviations',
        folders: [],
        files: [],
      },
    ],
    files: [
      {
        id: '8',
        path: 'AGP MA/AGP-001014_Stud_Results  .pdf',
        webUrl: 'AGP MA/AGP-001014_Stud_Results  .pdf',
      },
      {
        id: '9',
        path: 'AGP MA/AGP-001014DeviationsStudy.pdf',
        webUrl: 'AGP MA/AGP-001014DeviationsStudy.pdf',
      },
      {
        id: '10',
        path: 'AGP MA/AGP-002026_SCHEDULE_OF_ACTIVITIES.docx',
        webUrl: 'AGP MA/AGP-002026_SCHEDULE_OF_ACTIVITIES.docx',
      },
      {
        id: '11',
        path: 'AGP MA/AGP-002027ITP.pdf',
        webUrl: 'AGP MA/AGP-002027ITP.pdf',
      },
      {
        id: '12',
        path: 'AGP MA/AGP-2019-034714.docx',
        webUrl: 'AGP MA/AGP-2019-034714.docx',
      },
      {
        id: '13',
        path: 'AGP MA/AGPD2-072_Report.pdf',
        webUrl: 'AGP MA/AGPD2-072_Report.pdf',
      },
      {
        id: '14',
        path: 'AGP MA/AKL-032Appendices  .docx',
        webUrl: 'AGP MA/AKL-032Appendices  .docx',
      },
      {
        id: '15',
        path: 'AGP MA/Appendix_1.docx',
        webUrl: 'AGP MA/Appendix_1.docx',
      },
      {
        id: '16',
        path: 'AGP MA/Assent_Form_12andmore.docx',
        webUrl: 'AGP MA/Assent_Form_12andmore.docx',
      },
      {
        id: '17',
        path: 'AGP MA/DevReportData_Draftv.1.pdf',
        webUrl: 'AGP MA/DevReportData_Draftv.1.pdf',
      },
      {
        id: '18',
        path: 'AGP MA/Eligibility_criteria_2023.docx',
        webUrl: 'AGP MA/Eligibility_criteria_2023.docx',
      },
      {
        id: '19',
        path: 'AGP MA/Qualit_Assuranc_approved_process.pdf',
        webUrl: 'AGP MA/Qualit_Assuranc_approved_process.pdf',
      },
      {
        id: '20',
        path: 'AGP MA/ReleaseForm_20221104.xlsx',
        webUrl: 'AGP MA/ReleaseForm_20221104.xlsx',
      },
    ],
  },
}

export default workspaceFolders
