import { CloudDownloadOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { FormInstance } from 'antd/lib'
import { t } from 'i18next'
import { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ModalBase,
  ModalContentContainer,
  ModalStepsTitle,
} from '../../../components'
import {
  ModalSteps,
  REGDOCS_TYPECODE,
  SHAREPOINT_TYPECODE,
  optionalRequiredMark,
  privacyTypes,
} from '../../../constants/constants'
import { useDataSourceTypes, useDataStores } from '../../../hooks'
import { dataStoreService } from '../../../services'
import notificationService from '../../../services/notificationService'
import { RootState } from '../../../store'
import { DataStoreConfig } from '../../../store/reducers/dataStoreReducer'
import CreateDataStoreFooter from './footers/CreateDataStoreFooter'
import CreateDataStoreContactStep from './steps/CreateDataStoreContactStep'
import CreateDataStoreDetailsStep from './steps/CreateDataStoreDetailsStep'
import CreateDataStorePrivacyStep from './steps/CreateDataStorePrivacyStep'
import CreateDataStoreReviewStep from './steps/CreateDataStoreReviewStep'
import CreateDataStoreTypeStep from './steps/CreateDataStoreTypeStep'
import { CreateDataStoreSharePointConfig } from './steps/setup'
import CreateDataStoreRegdocConfig from './steps/setup/CreateDataStoreRegdocsConfig'
import { DataSourceType } from '../../../store/reducers/dataSourceReducer'

const CreateDataStoreModal = memo(() => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const currentUser = useSelector((state: RootState) => state.user.currentUser)

  const { refreshDataStoreList } = useDataStores({
    preventFetch: true,
  })

  const { refreshDataSourceTypeList, dataSourceTypeList } = useDataSourceTypes({
    preventFetch: true,
  })

  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const stepItems: ModalSteps[] = useMemo(
    () => [
      {
        title: t('createDataStoreModal.stepItems.definition'),
      },
      { title: t('createDataStoreModal.stepItems.type') },
      { title: t('createDataStoreModal.stepItems.setup') },
      {
        title: t('createDataStoreModal.stepItems.privacy'),
      },
      {
        title: t('createDataStoreModal.stepItems.contact'),
      },
      {
        title: t('createDataStoreModal.stepItems.review'),
      },
    ],
    []
  )

  const resetValues = useCallback(() => {
    form.resetFields()
    setCurrentStep(0)
  }, [form])

  const handleOk = useCallback(() => {
    setConfirmLoading(true)
  }, [])

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
  }, [resetValues])

  const handleNextClick = useCallback(() => {
    form
      .validateFields()
      .then(() => setCurrentStep(currentStep + 1))
      .catch(() => {
        notificationService.notificationError(
          t('createDataStoreModal.invalidFields')
        )
      })
  }, [currentStep, form])

  const getDataSourceType = useCallback(
    async (selectedSource: string) => {
      if (!dataSourceTypeList) {
        await refreshDataSourceTypeList()
      }
      const sourceType = dataSourceTypeList?.find(
        (source: DataSourceType) => source.typeCode === selectedSource
      )
      if (sourceType) {
        form.setFieldsValue({ sourceType: sourceType.id })
      }
    },
    [form, refreshDataSourceTypeList, dataSourceTypeList]
  )

  const handlePreviousClick = useCallback(() => {
    setCurrentStep(currentStep - 1)
  }, [currentStep])

  const handleTypeStepNextClick = useCallback(() => {
    const selectedSource = form.getFieldValue('sourceName')
    getDataSourceType(selectedSource)
    handleNextClick()
  }, [form, getDataSourceType, handleNextClick])

  const handleSubmit = useCallback(async () => {
    try {
      const code = form.getFieldValue('sourceCode')
      const config: DataStoreConfig = form.getFieldValue('config')
      let newConfig: DataStoreConfig = {}
      if (code === SHAREPOINT_TYPECODE) {
        newConfig.sourceFolder = config.sourceFolder
        newConfig.sourceFolderId = config.sourceFolderId
        newConfig.tenantUid = config.tenantUid
      } else if (code === REGDOCS_TYPECODE) {
        if (config.sourceUrl && config.sourceLibrary !== '') {
          newConfig.sourceUrl = config.sourceUrl
        }
        newConfig.sourceLibrary = config.sourceLibrary
        newConfig.sourceLibraryId = config.sourceLibraryId
      }
      const dataStore = {
        title: form.getFieldValue('title'),
        description: form.getFieldValue('description'),
        visibility: form.getFieldValue('visibility'),
        config: newConfig,
        participants: form.getFieldValue('participants'),
        sourceType: form.getFieldValue('sourceType'),
        secrets: form.getFieldValue('secrets'),
        contactName: form.getFieldValue('contactName'),
        contactEmail: form.getFieldValue('contactEmail'),
        navigate,
      }
      // ADD CALL TO CREATE DATA STORE
      await dataStoreService.createDataStore(dataStore)
      notificationService.notificationSuccess(
        t('createDataStoreModal.successCreateDataStore')
      )
      refreshDataStoreList()
      resetValues()
      setOpen(false)
    } catch (error) {
      console.error('axios create data store error', error)
      notificationService.notificationError(
        t('createDataStoreModal.errorCreateDataStore')
      )
    }
    setConfirmLoading(false)
  }, [form, navigate, refreshDataStoreList, resetValues])

  const defaultValues = useMemo(
    () => ({
      title: '',
      sourceCode: SHAREPOINT_TYPECODE,
      visibility: privacyTypes.PRIVATE,
      participants: currentUser ? [currentUser.id] : [],
    }),
    [currentUser]
  )

  const CreateDataStoreConfigWrapper = memo(
    ({ form }: { form: FormInstance }) => {
      switch (form.getFieldValue('sourceCode')) {
        case SHAREPOINT_TYPECODE:
          return <CreateDataStoreSharePointConfig form={form} />
        case REGDOCS_TYPECODE:
          return <CreateDataStoreRegdocConfig form={form} />
        default:
          return <CreateDataStoreSharePointConfig form={form} />
      }
    }
  )

  const footers = useMemo(
    () => [
      <CreateDataStoreFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateDataStoreFooter
        onNext={handleTypeStepNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataStoreFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataStoreFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataStoreFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataStoreFooter
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
    ],
    [
      handleTypeStepNextClick,
      handleCancel,
      handleNextClick,
      handlePreviousClick,
      handleSubmit,
    ]
  )

  const steps = useMemo(
    () => [
      <CreateDataStoreDetailsStep />,
      <CreateDataStoreTypeStep form={form} />,
      <CreateDataStoreConfigWrapper form={form} />,
      <CreateDataStorePrivacyStep form={form} />,
      <CreateDataStoreContactStep />,
      <CreateDataStoreReviewStep form={form} />,
    ],
    [form, CreateDataStoreConfigWrapper]
  )

  return (
    <>
      <Button
        icon={<CloudDownloadOutlined />}
        onClick={() => setOpen(true)}
        type="primary"
      >
        {t('dataStoresListPage.addDataStoreButton')}
      </Button>
      <ModalBase
        key="main"
        title={
          <ModalStepsTitle
            title={t('createDataStoreModal.title')}
            currentStep={currentStep}
            stepItems={stepItems}
          />
        }
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        width={720}
        footer={footers[currentStep]}
        styles={{
          body: {
            maxHeight: 'calc(100vh - 400px)',
          },
        }}
      >
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          requiredMark={optionalRequiredMark}
        >
          <ModalContentContainer>{steps[currentStep]}</ModalContentContainer>
        </Form>
      </ModalBase>
    </>
  )
})

export default CreateDataStoreModal
