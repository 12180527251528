import { Button } from 'antd'
import { t } from 'i18next'
import { ReactNode } from 'react'
import ModalBase from './ModalBase'

export interface ConfirmSaveModalProps {
  key?: string
  open: boolean
  onConfirm: () => void
  onCancel?: () => void
  loading?: boolean
  children?: ReactNode
}

const ConfirmSaveModal = ({
  key = 'confirm',
  open,
  onConfirm,
  onCancel,
  loading,
  children,
}: ConfirmSaveModalProps) => {
  return (
    <ModalBase
      key={key}
      title={t('confirmSaveModal.title')}
      open={open}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button key="back" onClick={onCancel} type="text">
          {t('confirmSaveModal.noButton')}
        </Button>,
        <Button
          key="submit"
          type="primary"
          loading={loading}
          onClick={onConfirm}
        >
          {t('confirmSaveModal.yesButton')}
        </Button>,
      ]}
    >
      {children}
    </ModalBase>
  )
}

export default ConfirmSaveModal
