import { styled } from 'styled-components'

const OfrFormContainer = styled.div`
  position: relative;
  display: block;
  margin: auto 16px auto 16px;
  overflow: auto;
`

export default OfrFormContainer
