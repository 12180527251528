import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import { missingReferenceCategories } from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import useWorkspaceDetails from '../../../../hooks/useWorkspaceDetails'
import useWorkspaceFolders from '../../../../hooks/useWorkspaceFolders'
import {
  RefAIRunResult,
  WorkspaceFolder,
} from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import SmartFunctionHeader from '../../components/SmartFunctionHeader'
import { ReferenceType, referenceTypes } from '../RefAISmartFunctionDetailsPage'
import SelectDocumentReferenceType from './SelectDocumentReferenceType'
import workspaceService from '../../../../services/workspaceService'

interface RefAISmartFunctionDetailsPageHeaderProps {
  handleReferenceChange: (value: ReferenceType | undefined) => void
  setSearch: (search: string) => void
  search: string
  singleWorkspace?: boolean
}

const RefAISmartFunctionDetailsPageHeader = ({
  handleReferenceChange,
  setSearch,
  search,
  singleWorkspace,
}: RefAISmartFunctionDetailsPageHeaderProps) => {
  const { workspaceId, algorithmRunId, referenceType } = useParams()
  const [total, setTotal] = useState<number>(0)

  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
  })
  const refAIResult = useMemo(
    () => selectedAlgorithmRun?.runResult as RefAIRunResult,
    [selectedAlgorithmRun?.runResult]
  )
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { workspaceFolders } = useWorkspaceFolders({
    workspaceId,
    preventFetch: true,
  })

  const numberOfFiles: (folders?: WorkspaceFolder[]) => number = useCallback(
    (folders?: WorkspaceFolder[]) => {
      if (!folders) {
        return 0
      }
      return folders
        .map((folder) => {
          const filteredFiles = folder.files.filter((document) =>
            !!search ? document.path.includes(search || '') : true
          )
          return filteredFiles.length + numberOfFiles(folder.folders)
        })
        .reduce((total, number) => total + number, 0)
    },
    [search]
  )

  const filteredMissingReferences = useMemo(
    () =>
      refAIResult?.missingReferences.filter(
        (missingReference) =>
          missingReference.category ===
            missingReferenceCategories.EXTERNAL_REFERENCES ||
          missingReference.category ===
            missingReferenceCategories.INTERNAL_REFERENCES
      ) || [],
    [refAIResult?.missingReferences]
  )

  const searchedMissingReferences = useMemo(
    () =>
      filteredMissingReferences.filter((missingReference) =>
        !!search
          ? workspaceService
              .getRefAIMissingReferenceKey(missingReference)
              .includes(search || '')
          : true
      ) || [],
    [search, filteredMissingReferences]
  )

  const searchedIgnoredReference = useMemo(
    () =>
      searchedMissingReferences.filter((missingReference) =>
        selectedWorkspace?.config?.excludedMissingReference?.includes(
          workspaceService.getRefAIMissingReferenceKey(missingReference)
        )
      ),
    [searchedMissingReferences, selectedWorkspace]
  )

  useEffect(() => {
    switch (referenceType) {
      case referenceTypes.DOCUMENT:
        setTotal(numberOfFiles(workspaceFolders) || 0)
        break
      case referenceTypes.MISSING_REFERENCE:
        setTotal(searchedMissingReferences?.length || 0)
        break
      case referenceTypes.IGNORED_REFERENCE:
        setTotal(searchedIgnoredReference?.length || 0)
        break
      default:
        setTotal(searchedMissingReferences?.length || 0)
        break
    }
  }, [
    workspaceFolders,
    selectedAlgorithmRun,
    referenceType,
    selectedWorkspace,
    numberOfFiles,
    search,
    searchedMissingReferences,
    searchedIgnoredReference,
  ])

  const backButtonUrl = useMemo(
    () =>
      singleWorkspace
        ? `${routePaths.SINGLE_WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`
        : `${routePaths.WORKSPACE_DETAILS}/${selectedWorkspace?.id}/overview/smartFunction`,
    [singleWorkspace, selectedWorkspace?.id]
  )

  const handleSearch = useCallback(
    (value: string) => {
      setSearch(value)
    },
    [setSearch]
  )

  return (
    <SmartFunctionHeader
      backButtonUrl={backButtonUrl}
      totalFound={total}
      onSearchAction={handleSearch}
      onSearchChange={(event) => handleSearch(event.target.value)}
    >
      <SelectDocumentReferenceType onChange={handleReferenceChange} />
    </SmartFunctionHeader>
  )
}

export default RefAISmartFunctionDetailsPageHeader
