import { LockOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'

interface ChangePasswordDefinitionStepFooterProps {
  loading: boolean
  onCancel: () => void
  handleOnConfirm: () => void
}

const ChangePasswordDefinitionStepFooter = ({
  loading,
  onCancel,
  handleOnConfirm,
}: ChangePasswordDefinitionStepFooterProps) => {
  return (
    <>
      <Button key="back" onClick={onCancel} type="text">
        {t('userDetailsPage.changePasswordModal.cancelButton')}
      </Button>
      <Button
        icon={<LockOutlined />}
        key="submit"
        type="primary"
        loading={loading}
        onClick={handleOnConfirm}
      >
        {t('userDetailsPage.changePasswordModal.yesButton')}
      </Button>
    </>
  )
}

export default ChangePasswordDefinitionStepFooter
