import { useState } from 'react'
import { DetailsPageLayout } from '../../../components'
import RegulatorySubmissionSmartFunctionDetailsPageHeader from './components/RegulatorySubmissionSmartFunctionDetailsPageHeader'
import RegulatorySubmissionSmartFunctionPanel from './panels/RegulatorySubmissionSmartFunctionPanel'

const RegulatorySubmissionSmartFunctionDetailsPage = () => {
  const [search, setSearch] = useState('')

  return (
    <DetailsPageLayout>
      <RegulatorySubmissionSmartFunctionDetailsPageHeader
        setSearch={setSearch}
        search={search}
      />
      <RegulatorySubmissionSmartFunctionPanel search={search} />
    </DetailsPageLayout>
  )
}

export default RegulatorySubmissionSmartFunctionDetailsPage
