import {
  ExportOutlined,
  PrinterOutlined,
  BulbOutlined,
} from '@ant-design/icons'
import { MenuProps } from 'antd/lib'
import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import {
  permissionActions,
  permissionNames,
} from '../constants/permissionConstant'
import { routePaths } from '../pages/RootPage'
import roleService from '../services/roleService'
import { RootState } from '../store'

interface UsePageContentDropdownProps {
  onExport?: () => void
}

const usePageActionDropdownItems = ({
  onExport,
}: UsePageContentDropdownProps) => {
  const { currentUser } = useSelector((state: RootState) => state.user)
  const { searchResult } = useSelector(
    (state: RootState) => state.searchResultInfo
  )

  const location = useLocation()
  const basePath = `/${location.pathname.split('/')[1]}`
  const navigate = useNavigate()

  const dropdownKeys = useMemo(
    () => ({
      summaries: 'dropdown-content-summaries',
      printContent: 'dropdown-content-print-content',
      exportContent: 'dropdown-content-export-content',
    }),
    []
  )

  const items: MenuProps['items'] = useMemo(() => {
    const summariesItem = {
      key: dropdownKeys.summaries,
      icon: <BulbOutlined />,
      label: t('literatureReviewListPage.summarizeResults'),
    }

    const printContentItem = {
      key: dropdownKeys.printContent,
      icon: <PrinterOutlined />,
      label: t('printContent'),
    }

    const exportContentItem = {
      key: dropdownKeys.exportContent,
      icon: <ExportOutlined />,
      label: t('exportContent'),
    }

    const result: MenuProps['items'] = []

    if (currentUser && basePath === routePaths.SEARCH && searchResult) {
      result.push(summariesItem) // Include summarize item only on the search page
    }

    result.push(printContentItem) // Always include print content item

    if (
      currentUser &&
      roleService.hasPermission(
        currentUser,
        permissionNames.ADMIN,
        permissionActions.ALL
      )
    ) {
      result.push(exportContentItem) // Include export item only for admin users
    }
    return result
  }, [currentUser, basePath, searchResult, dropdownKeys])

  const handleDropdownClick = useCallback(
    (key: string) => {
      switch (key) {
        case dropdownKeys.summaries:
          navigate(`${routePaths.SEARCH_DOCUMENT_SUMMARIES}`)
          break
        case dropdownKeys.printContent:
          window.print()
          break
        case dropdownKeys.exportContent:
          onExport && onExport()
          break
        default:
          break
      }
    },
    [dropdownKeys, navigate, onExport]
  )

  return { items, handleDropdownClick }
}

export default usePageActionDropdownItems
