import { Form, Steps } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ModalBase, ModalContentContainer } from '../../../../../components'
import { biomarkFormRanges } from '../../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../../hooks'
import { smartFunctionService } from '../../../../../services'
import notificationService from '../../../../../services/notificationService'
import {
  AlgorithmRun,
  BiomarkConfigValues,
} from '../../../../../store/reducers/workspaceReducer'
import BiomarkConfigurationModalFooter from './footers/BiomarkConfigurationModalFooter'
import BiomarkConfigurationModalParametersStep from './steps/BiomarkConfigurationModalParametersStep'
import BiomarkConfigurationModalReviewStep from './steps/BiomarkConfigurationModalReviewStep'

const DropDownItem = styled.div`
  width: 200px;
`

interface BiomarkConfigurationModalProps {
  config?: BiomarkConfigValues
}

const BiomarkConfigurationModal = ({
  config,
}: BiomarkConfigurationModalProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)

  const [currentStep, setCurrentStep] = useState(0)
  const { workspaceId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const stepItems = [
    {
      title: t('biomarkConfigurationModal.parameterStep.title'),
    },
    {
      title: t('biomarkConfigurationModal.reviewStep.title'),
    },
  ]

  const resetValues = useCallback(() => {
    form.resetFields()
  }, [form])

  const handleOk = () => {
    setConfirmLoading(true)
  }

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
    setCurrentStep(0)
  }, [setCurrentStep, resetValues])

  const handleConfirm = useCallback(() => {
    setConfirmLoading(false)
    const biomarkConfiguration: BiomarkConfigValues = {
      maxScore: form.getFieldValue('maxScore'),
      trials: form.getFieldValue('trials'),
      testPercent: form.getFieldValue('testPercent') / 100,
      generations: form.getFieldValue('generations'),
      population: form.getFieldValue('population'),
      mutationRate: form.getFieldValue('mutationRate'),
      numMutants: form.getFieldValue('numMutants'),
      numXovers: form.getFieldValue('numXovers'),
      numRefines: form.getFieldValue('numRefines'),
      modelType: form.getFieldValue('modelType'),
      language: 'en',
    }
    smartFunctionService
      .runAlgorithm({
        id: selectedWorkspace?.id || '',
        navigate,
        config: biomarkConfiguration,
      })
      .then((response: AlgorithmRun) => {
        refreshWorkspaceDetails()
        notificationService.notificationSuccess(
          t('smartFunctionDetailsPage.runStarted')
        )
        setOpen(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
      })
  }, [form, navigate, refreshWorkspaceDetails, selectedWorkspace])

  const defaultValues = useMemo(
    () => ({
      maxScore: config?.maxScore || biomarkFormRanges.maxScore.default,
      trials: config?.generations || biomarkFormRanges.trials.default,
      testPercent: config?.testPercent
        ? config.testPercent * 100
        : biomarkFormRanges.testPercent.default,
      generations: config?.generations || biomarkFormRanges.generations.default,
      population: config?.population || biomarkFormRanges.population.default,
      mutationRate:
        config?.mutationRate || biomarkFormRanges.mutationRate.default,
      numMutants: config?.numMutants || biomarkFormRanges.numMutants.default,
      numXovers: config?.numXovers || biomarkFormRanges.numXovers.default,
      numRefines: config?.numRefines || biomarkFormRanges.numRefines.default,
      modelType: config?.modelType || biomarkFormRanges.modelType.default,

      language: 'en',
    }),
    [config]
  )

  const steps: ReactNode[] = useMemo(
    () => [
      <BiomarkConfigurationModalParametersStep form={form} />,
      <BiomarkConfigurationModalReviewStep form={form} />,
    ],
    [form]
  )

  const footers: ReactNode[] = useMemo(
    () => [
      <BiomarkConfigurationModalFooter
        onCancel={handleCancel}
        onNext={() => setCurrentStep(currentStep + 1)}
      />,
      <BiomarkConfigurationModalFooter
        onCancel={handleCancel}
        onPrevious={() => setCurrentStep(currentStep - 1)}
        onSubmit={handleConfirm}
      />,
    ],
    [currentStep, handleCancel, handleConfirm, setCurrentStep]
  )

  return (
    <>
      <DropDownItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {t(
          'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.configureRun'
        )}
      </DropDownItem>
      <ModalBase
        key="main"
        title={t('biomarkConfigurationModal.modalTitle')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        footer={footers[currentStep]}
      >
        <Steps size="small" current={currentStep} items={stepItems} />
        <Form form={form} layout="vertical" initialValues={defaultValues}>
          <ModalContentContainer>{steps[currentStep]}</ModalContentContainer>
        </Form>
      </ModalBase>
    </>
  )
}

export default BiomarkConfigurationModal
