import { Alert } from 'antd'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { styled } from 'styled-components'
import { SectionLoadingState } from '../../components'
import { useClearStore } from '../../hooks'
import { authService } from '../../services'
import { LoginSsoResponse } from '../../services/authService'

const ErrorContainer = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 96px;
`

const SsoManagementPage = () => {
  const clearStore = useClearStore()
  const [loading, setLoading] = useState(true)

  const [fetchDataDone, setFetchDataDone] = useState(false)

  const handleLogin = useCallback(async () => {
    clearStore() // Prevent data from older session to still be there when we log in

    setLoading(true)
    await authService.loginSso().then(
      (response: LoginSsoResponse) => {
        window.location.href = response.url
      },
      (error) => {
        console.error('Login error', error)
        setLoading(false)
      }
    )
  }, [clearStore])

  useEffect(() => {
    if (!fetchDataDone) {
      handleLogin()
      setFetchDataDone(true)
    }
  }, [handleLogin, fetchDataDone])

  return loading ? (
    <SectionLoadingState />
  ) : (
    <ErrorContainer>
      <Alert type="error" message={t('errorMessage.failedSsoLoginMessage')} />
    </ErrorContainer>
  )
}

export default SsoManagementPage
