import { ReloadOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance, Input, Select, Spin } from 'antd'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import { SelectSource } from '../../../../components'
import { selectSourcePrivacyTypes } from '../../../../components/SelectSource'
import { useDataSources } from '../../../../hooks'
import { OfrFormValues } from '../CreateOfrDocumentTemplateModal.tsx'

interface SelectOption {
  label: string
  value: string
}

interface CreateOfrDocumentTemplateModalStepProps {
  form: FormInstance<OfrFormValues>
}

const CreateOfrDocumentTemplateModalStep = ({
  form,
}: CreateOfrDocumentTemplateModalStepProps) => {
  const { getDataSourceFiles } = useDataSources({ preventFetch: true })

  const [loading, setLoading] = useState(false)
  const [state, setState] = useState({
    documents: [] as SelectOption[],
    selectedDocument: undefined as string | undefined,
  })

  const handleSourceChange = (option: string) => {
    form.setFieldsValue({
      dataSource: option,
      documentId: undefined,
    })
    setState({ documents: [], selectedDocument: undefined })
  }

  const handleDocumentChange = (option: string) => {
    form.setFieldsValue({
      documentId: option,
    })
    setState((prevState) => ({ ...prevState, selectedDocument: option }))
  }

  const fetchDocuments = useCallback(() => {
    setState({ documents: [], selectedDocument: undefined })
    form.setFieldsValue({
      documentId: undefined,
    })

    const dataSource = form.getFieldValue('dataSource')
    if (!dataSource) return

    setLoading(true)
    getDataSourceFiles({ dataSourceId: dataSource, format: 'pdf' })
      ?.then((response) => {
        const files = response.map((file: any) => ({
          label: file.path,
          value: file.id,
        }))
        setState({ documents: files, selectedDocument: undefined })
        setLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching documents:', error)
        setLoading(false)
      })
  }, [form, getDataSourceFiles])

  return (
    <Spin spinning={loading}>
      <Form.Item
        name="title"
        label={t('createOfrDocumentTemplateModal.titleDescription')}
        rules={[
          { required: true, message: t('createOfrDocumentTemplateModal.titleRequired') },
        ]}
      >
        <Input placeholder={t('createOfrDocumentTemplateModal.titlePlaceholder')} />
      </Form.Item>
      <Form.Item
        label={t('createOfrDocumentTemplateModal.dataSourceDescription')}
        name="dataSource"
        rules={[
          {
            required: true,
            message: t('createOfrDocumentTemplateModal.dataSourceRequired'),
          },
        ]}
      >
        <SelectSource
          onChange={handleSourceChange}
          privacy={selectSourcePrivacyTypes.INTERNAL}
          includeTypeCode={false}
          includeSearchable={false}
        />
      </Form.Item>
      <Form.Item
        label={t('createOfrDocumentTemplateModal.chooseTemplateDescription')}
        name="documentId"
        rules={[
          { required: true, message: t('createOfrDocumentTemplateModal.documentRequired') },
        ]}
        extra={t('createOfrDocumentTemplateModal.chooseTemplateSubheader')}
      >
        <Select
          value={state.selectedDocument}
          placeholder={t('createOfrDocumentTemplateModal.chooseTemplatePlaceholder')}
          onChange={handleDocumentChange}
          options={state.documents}
        />
      </Form.Item>
      <Button onClick={fetchDocuments} icon={<ReloadOutlined />} block>
        {t('createOfrDocumentTemplateModal.refreshButton')}
      </Button>
    </Spin>
  )
}

export default CreateOfrDocumentTemplateModalStep
