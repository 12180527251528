// i18n.js
import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import translationsEN from './en/en'
import translationsFR from './fr/fr'

const resources = {
  en: {
    translation: translationsEN,
  },
  fr: {
    translation: translationsFR,
  },
}

const savedLanguage = localStorage.getItem('language') || 'en'

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: savedLanguage, // use saved language or default to 'en'
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })

export default i18n
