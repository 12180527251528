import { DeleteOutlined } from '@ant-design/icons'; // Import the necessary Ant Design icon
import { Button, Tooltip } from 'antd'; // Assuming you are using Ant Design components
import { TooltipPlacement } from 'antd/lib/tooltip'
import { t } from 'i18next'
import React from 'react'

interface DeleteIconButtonProps {
  tooltipPlacement?: TooltipPlacement
  tooltipTitle?: string
  icon?: React.ReactNode
  onClick: any
  disabled?: boolean
}

const DeleteIconButton = ({
  tooltipPlacement,
  tooltipTitle,
  icon,
  onClick,
  disabled = false,
}: DeleteIconButtonProps) => {
  return (
    <Tooltip
      placement={tooltipPlacement ? tooltipPlacement : 'left'}
      title={tooltipTitle ?? t('Delete')}
      align={{ offset: [-24, 0] }}
    >
      <Button
        danger
        disabled={disabled}
        icon={icon ? icon : <DeleteOutlined />}
        onClick={onClick}
      />
    </Tooltip>
  )
}

export default DeleteIconButton
