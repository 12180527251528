import { urlList } from '../constants/urlConstant'
import apiService from './apiService'

const baseUrl = urlList.USERS

const getUsers = async () => {
  return apiService
    .fetchItems(`${baseUrl}?pageSize=1000&includeInactive=true`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const exportUserList = async () => {
  return apiService
    .fetchItems(`${baseUrl}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportUserDetailsProps {
  userId: string
}
const exportUserDetails = async ({ userId }: ExportUserDetailsProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${userId}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const userService = {
  getUsers,
  exportUserList,
  exportUserDetails,
}

export default userService
