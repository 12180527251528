import { DeleteOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  BackButton,
  ConfirmDeleteModal,
  DetailsPageLayout,
  HeaderSeparator,
  PageContentDropdown,
  Spacer,
  SubHeader,
} from '../../components'
import { UIFLAGS, urlList } from '../../constants/constants'
import { useLiteratureReviews, useUiFlags } from '../../hooks'
import { apiService } from '../../services'
import literatureReviewService from '../../services/literatureReviewService'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import {
  LiteratureReview,
  setLiteratureReviewLoading,
  setSelectedLiteratureReview,
} from '../../store/reducers/literatureReviewReducer'
import { routePaths } from '../RootPage'
import SelectWorkspacePanelType from '../WorkspaceDetailsPage/components/SelectWorkspacePanelType'
import LiteratureReviewTabs from './LiteratureReviewTabs'

import {
  LiteratureReviewDetailsOverviewInfoPanel,
  LiteratureReviewDetailsOverviewQueriesPanel,
} from './panels'

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
`

interface FormValues {
  title: string
  description?: string
}

export type LiteratureReviewPanelType = 'overview' // The use of camel case is to have camel case in the url
export type LiteratureReviewPanelTypes = {
  OVERVIEW: LiteratureReviewPanelType
}

export const literatureReviewPanelTypes: LiteratureReviewPanelTypes = {
  OVERVIEW: 'overview',
}

export type LiteratureReviewTab = 'info' | 'queries' // The use of camel case is to have camel case in the url
export type LiteratureReviewTypes = {
  INFO: LiteratureReviewTab
  QUERIES: LiteratureReviewTab
}

export const literatureReviewTabs = {
  INFO: 'info',
  QUERIES: 'queries',
}

const LiteratureReviewDetailsPage = () => {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const { literatureReviewId, panelType, tab } = useParams()

  const {
    selectedLiteratureReview,
    refreshLiteratureReviewList,
    exportLiteratureReviewCsv,
  } = useLiteratureReviews({
    preventFetch: true,
  })

  const { hasPermission } = useUiFlags({
    uiFlag: selectedLiteratureReview?.uiFlags || 0,
  })

  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const isCurrentUserOwner = useMemo(
    () => currentUser?.id === selectedLiteratureReview?.createdBy?.id,
    [selectedLiteratureReview, currentUser]
  )
  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const defaultValues: FormValues = useMemo(
    () => ({
      title: selectedLiteratureReview?.title || '',
      description: selectedLiteratureReview?.description || '',
      lastUpdate: selectedLiteratureReview?.lastModifiedDate,
    }),
    [selectedLiteratureReview]
  )
  const [
    selectedLiteratureReviewPanelType,
    setSelectedLiteratureReviewPanelType,
  ] = useState(
    (panelType ||
      literatureReviewPanelTypes.OVERVIEW) as LiteratureReviewPanelType
  )

  const [selectedLiteratureReviewTab, setSelectedLiteratureReviewTab] =
    useState<LiteratureReviewTab>(
      (tab || literatureReviewTabs.INFO) as LiteratureReviewTab
    )

  const panelIndex = useCallback(() => {
    if (
      selectedLiteratureReviewPanelType === literatureReviewPanelTypes.OVERVIEW
    ) {
      switch (selectedLiteratureReviewTab) {
        case literatureReviewTabs.INFO:
          return 0
        case literatureReviewTabs.QUERIES:
          return 1
      }
    }
    return 0
  }, [selectedLiteratureReviewPanelType, selectedLiteratureReviewTab])

  const refreshSelectedLiteratureReview = useCallback(() => {
    dispatch(setLiteratureReviewLoading(true))
    apiService
      .fetchItems(`${urlList.LITERATURE_REVIEWS}/${literatureReviewId}`)
      .then((response: LiteratureReview) => {
        dispatch(setLiteratureReviewLoading(false))
        dispatch(setSelectedLiteratureReview(response))
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        dispatch(setLiteratureReviewLoading(false))
      })
  }, [dispatch, literatureReviewId])

  useEffect(() => {
    refreshSelectedLiteratureReview()
  }, [refreshSelectedLiteratureReview])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  const handleSearchDelete = useCallback(() => {
    refreshSelectedLiteratureReview()
    notificationService.notificationSuccess(
      t('literatureReviewDetailsPage.deleteSearchSuccessful') || ''
    )
  }, [refreshSelectedLiteratureReview])

  const handleDeleteLiteratureReview = () => {
    setConfirmDeleteModalOpen(true)
  }

  const handleConfirmDelete = () => {
    setDeleteLoading(true)
    literatureReviewService
      .deleteLiteratureReview({
        literatureReviewId: selectedLiteratureReview?.id || '',
        navigate,
      })
      .then((response: LiteratureReview) => {
        refreshLiteratureReviewList()
        notificationService.notificationSuccess(
          t('literatureReviewDetailsPage.deleteLiteratureReviewSuccessful') ||
            ''
        )
        navigate(routePaths.LITERATURE_REVIEW_LIST)
        setDeleteLoading(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setDeleteLoading(false)
      })
  }

  const handleSave = () => {
    setSaveLoading(true)
    const values = form.getFieldsValue()
    literatureReviewService
      .updateLiteratureReviewDetails({
        title: values.title,
        description: values.description || '',
        literatureReviewId: selectedLiteratureReview?.id || '',
        navigate,
      })
      .then((response: LiteratureReview) => {
        notificationService.notificationSuccess(
          t('literatureReviewDetailsPage.saveLiteratureReviewSuccessful') || ''
        )
        setSaveLoading(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setSaveLoading(false)
      })
  }

  useEffect(() => {
    setSelectedLiteratureReviewPanelType(
      (panelType ||
        literatureReviewPanelTypes.OVERVIEW) as LiteratureReviewPanelType
    )
    setSelectedLiteratureReviewTab(
      (tab || literatureReviewTabs.INFO) as LiteratureReviewTab
    )
  }, [panelType, tab])

  const handleLiteratureReviewExport = () => {
    exportLiteratureReviewCsv(selectedLiteratureReview?.id || '')
  }

  const panels = useMemo(
    () => [
      <LiteratureReviewDetailsOverviewInfoPanel />,
      <LiteratureReviewDetailsOverviewQueriesPanel
        handleSearchDelete={handleSearchDelete}
      />,
    ],
    [handleSearchDelete]
  )
  return (
    <DetailsPageLayout>
      <SubHeader>
        <BackButton url={routePaths.LITERATURE_REVIEW_LIST} />
        <SelectWorkspacePanelType disabled={true} />
        <HeaderSeparator />
        <LiteratureReviewTabs />
        <Spacer />
        {tab === literatureReviewTabs.INFO &&
          panelType === literatureReviewPanelTypes.OVERVIEW && (
            <>
              <Button
                danger
                icon={<DeleteOutlined />}
                onClick={handleDeleteLiteratureReview}
                disabled={
                  !hasPermission(UIFLAGS.CAN_DELETE) || !isCurrentUserOwner
                }
              >
                {t('Delete')}
              </Button>
              <ConfirmDeleteModal
                open={confirmDeleteModalOpen}
                onConfirm={handleConfirmDelete}
                onCancel={() => setConfirmDeleteModalOpen(false)}
                loading={deleteLoading}
              >
                {t('literatureReviewDetailsPage.confirmDeleteModalContent')}
              </ConfirmDeleteModal>
              <Button
                type="primary"
                style={{ marginLeft: '8px' }}
                onClick={handleSave}
                loading={saveLoading}
                disabled={
                  !hasPermission(UIFLAGS.CAN_UPDATE) || !isCurrentUserOwner
                }
              >
                {t('saveButton')}
              </Button>
            </>
          )}
        <PageContentDropdown onExport={handleLiteratureReviewExport} />
      </SubHeader>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultValues}
        onFinish={handleSave}
        requiredMark={false}
      >
        <ContentContainer>
          {
            panels[panelIndex()] // This is to render the correct panel based on the url
          }
        </ContentContainer>
      </Form>
    </DetailsPageLayout>
  )
}

export default LiteratureReviewDetailsPage
