import { SafetyCertificateOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useEffect, useState } from 'react'
import { CustomLinkTranslation } from '../../../../../components'
import {
  EGNYTE_AUTH_CODE_INFORMATION_URL,
  EGNYTE_CLIENT_ID,
} from '../../../../../constants/constants'

interface CreateDataSourceEgnyteProps {
  form: FormInstance
}
const BASE_FOLDER_PATH = '/Shared'

const CreateDataSourceEgnyteConfig = memo(
  ({ form }: CreateDataSourceEgnyteProps) => {
    const [tenantUid, setTenantUid] = useState(
      form.getFieldValue('config')?.tenantUid || ''
    )

    useEffect(() => {
      const source: string = form.getFieldValue('config')?.sourceFolder || ''
      if (source.includes(BASE_FOLDER_PATH)) {
        const folderPath = source.replace(BASE_FOLDER_PATH, '')
        // reset folder path so it displays without the base path /Shared
        form.setFieldValue('config', {
          ...form.getFieldValue('config'),
          sourceFolder: folderPath,
        })
      }
    }, [form])

    return (
      <>
        <Form.Item
          label={t('createDataSourceModal.egnyteConfig.egnyteDomainField')}
          name={['config', 'tenantUid']}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={t('createDataSourceModal.egnyteConfig.description') || ''}
        >
          <Input
            onChange={(e) => {
              setTenantUid(e.target.value)
            }}
            placeholder={
              t('createDataSourceModal.egnyteConfig.egnyteDomainField') || ''
            }
          />
        </Form.Item>
        <Button
          style={{
            marginBottom: 16,
          }}
          icon={<SafetyCertificateOutlined />}
          type="primary"
          disabled={!tenantUid}
          block
          target="_blank"
          href={`https://${tenantUid}.egnyte.com/puboauth/token?client_id=${EGNYTE_CLIENT_ID}&redirect_uri=https://ca.genaiz.com/oauth/egnyte&scope=Egnyte.filesystem&state=consent&response_type=code`}
        >
          {t('createDataSourceModal.egnyteConfig.authorizeOnEgnyteButtonText')}
        </Button>

        <Form.Item
          label={t('createDataSourceModal.egnyteConfig.enterAuthCodeField')}
          name={['secrets', 'authCode']}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={
            <CustomLinkTranslation
              parentTranslationKey="createDataSourceModal.egnyteConfig.enterAuthCodeDescription"
              childTranslationKey="createDataSourceModal.egnyteConfig.enterAuthCodeLinkText"
              link={EGNYTE_AUTH_CODE_INFORMATION_URL}
            />
          }
        >
          <Input
            placeholder={
              t('createDataSourceModal.egnyteConfig.enterAuthCodeField') || ''
            }
          />
        </Form.Item>

        <Form.Item
          label={t('createDataSourceModal.egnyteConfig.sourceFolderField')}
          extra={t('createDataSourceModal.egnyteConfig.soureFolderHelpText')}
          name={['config', 'sourceFolder']}
        >
          <Input
            addonBefore={BASE_FOLDER_PATH}
            placeholder={
              t('createDataSourceModal.egnyteConfig.sourceFolderPlaceholder') ||
              ''
            }
          />
        </Form.Item>
      </>
    )
  }
)

export default CreateDataSourceEgnyteConfig
