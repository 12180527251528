import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { t } from 'i18next'
import { memo, useMemo, useState } from 'react'
import SelectStore from '../../../../components/SelectStore'
import { workspaceTypes } from '../../../../constants/constants'

interface CreateWorkspaceModalDataStoreStepProps {
  form: FormInstance
  setDataStore: (value: string) => void
}

const CreateWorkspaceModalOutputStep = memo(
  ({ form, setDataStore }: CreateWorkspaceModalDataStoreStepProps) => {
    const handleOnChange = (value: string | undefined) => {
      if (value) {
        setDataStore(value)
        form.setFieldValue('selectedDataStores', [value])
        setSelectedDataStore(value)
      }
    }

    const [selectedDataStore, setSelectedDataStore] = useState(
      form.getFieldValue('selectedDataStore')
    )

    const workspaceType = useMemo(() => {
      return form.getFieldValue('workspaceTypeCode')
    }, [form])

    return (
      <div>
        <Form.Item
          name="selectedDataStore"
          label={t('createWorkspaceModal.storeStep.subHeader' || '')}
          extra={t('createWorkspaceModal.storeStep.infoBox' || '')}
          rules={[
            {
              required: workspaceType === workspaceTypes.DEL,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
        >
          <SelectStore
            onChange={handleOnChange}
            value={selectedDataStore}
            placeholder={t('createWorkspaceModal.storeStep.placeholder') || ''}
          />
        </Form.Item>
      </div>
    )
  }
)

export default CreateWorkspaceModalOutputStep
