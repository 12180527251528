import {
  ControlOutlined,
  DeleteOutlined,
  SaveOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Radio, Space } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import {
  BackButton,
  ConfirmDeleteModal,
  DetailsPageLayout,
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  PageContentDropdown,
  SelectParticipant,
  Spacer,
  SubHeader,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  REGDOCS_TYPECODE,
  SHAREPOINT_TYPECODE,
  privacyTypes,
} from '../../constants/constants'
import { useDataStores } from '../../hooks'
import { dataStoreService, validationService } from '../../services'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import {
  DataStore,
  setDataStoreLoading,
  setSelectedDataStore,
} from '../../store/reducers/dataStoreReducer'
import { User } from '../../store/reducers/userReducer'
import { PrivacyType } from '../../types/generalTypes'
import { routePaths } from '../RootPage'

interface DataStoreFormValues {
  id: string
  title: string
  description: string
  visibility?: PrivacyType
  participants?: string[]
  contactName: string
  contactEmail: string
}

const DataStoreDetailsPage = () => {
  const dispatch = useDispatch()
  const { dataStoreId } = useParams()
  const navigate = useNavigate()

  const [saveLoading, setSaveLoading] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)

  const [form] = Form.useForm()

  const { refreshDataStoreList, exportDataStoreDetailsCsv } = useDataStores({
    preventFetch: true,
  })

  const selectedDataStore = useSelector(
    (state: RootState) => state.dataStore.selected
  )
  const defaultValues: DataStoreFormValues = useMemo(
    () => ({
      id: selectedDataStore?.id || dataStoreId || '',
      title: selectedDataStore?.title || '',
      visibility: selectedDataStore?.visibility || privacyTypes.PRIVATE,
      participants: selectedDataStore?.participants.map(
        (participant: User) => participant.id
      ),
      description: selectedDataStore?.description || '',
      contactName: selectedDataStore?.contactName || '',
      contactEmail: selectedDataStore?.contactEmail || '',
    }),
    [selectedDataStore, dataStoreId]
  )

  const dataStoreTypeCode = useMemo(
    () => selectedDataStore?.typeCode,
    [selectedDataStore]
  )

  const dataStoreTypeName = useMemo(
    () => selectedDataStore?.typeName,
    [selectedDataStore]
  )

  const dataStoreLibrary = useMemo(() => {
    if (selectedDataStore?.typeCode === REGDOCS_TYPECODE) {
      return selectedDataStore?.config?.sourceLibrary
    } else {
      return undefined
    }
  }, [selectedDataStore])

  // DATASTORE does not have egnyte
  const dataStoreFolderPath = useMemo(() => {
    if (selectedDataStore?.typeCode === SHAREPOINT_TYPECODE) {
      return selectedDataStore?.config?.sourceFolder
    } else if (selectedDataStore?.typeCode === REGDOCS_TYPECODE) {
      return selectedDataStore?.config?.sourceUrl
    } else {
      return NO_DATA_PLACEHOLDER
    }
  }, [selectedDataStore])

  const configLabel = useMemo(() => {
    if (dataStoreTypeCode === SHAREPOINT_TYPECODE) {
      return t('dataStoreDetailsPage.sharepointSourceLabel')
    } else if (dataStoreTypeCode === REGDOCS_TYPECODE) {
      return t('dataStoreDetailsPage.regDocsSourceLabel')
    } else {
      return NO_DATA_PLACEHOLDER
    }
  }, [dataStoreTypeCode])

  useEffect(() => {
    dispatch(setDataStoreLoading(true))
    dataStoreService
      .getDataStoreDetails({ dataStoreId: dataStoreId || '' })
      .then((response: DataStore) => {
        dispatch(setDataStoreLoading(false))
        dispatch(setSelectedDataStore(response))
      })
      .catch((error: AxiosError | Error) => {
        dispatch(setDataStoreLoading(false))
        console.error('axios fetch error', error)
      })
  }, [dispatch, dataStoreId])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  const handleSave = (values: DataStoreFormValues) => {
    if (
      values.visibility === privacyTypes.PRIVATE &&
      values.participants?.length === 0
    ) {
      return notificationService.notificationError(
        t('dataStoreDetailsPage.dataStoreParticipantsError') || ''
      )
    }
    setSaveLoading(true)
    dataStoreService
      .updateDataStoreDetails({
        title: values.title,
        description: values.description || '',
        visibility: values.visibility || privacyTypes.PRIVATE,
        participants: values.participants || [],
        dataStoreId: selectedDataStore?.id || '',
        contactEmail: values.contactEmail,
        contactName: values.contactName,
        navigate,
      })
      .then((response) => {
        refreshDataStoreList()
        setSaveLoading(false)
        notificationService.notificationSuccess(
          t('dataStoreDetailsPage.dataStoreSaveSuccess') || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setSaveLoading(false)
      })
  }

  const handleCancel = () => {
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = () => {
    dispatch(setDataStoreLoading(true))
    setConfirmDeleteModalOpen(false)
    setDeleteLoading(true)
    dataStoreService
      .deleteDataStore({
        dataStoreId: selectedDataStore?.id || '',
        navigate,
      })
      .then(() => {
        refreshDataStoreList()
        setDeleteLoading(false)
        notificationService.notificationSuccess(
          t('dataStoreDetailsPage.deleteSuccessful') || ''
        )
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        setDeleteLoading(false)
      })
    dispatch(setDataStoreLoading(false))
    navigate(routePaths.DATA_STORE_LIST)
  }

  const handleExport = () => {
    exportDataStoreDetailsCsv(dataStoreId || '')
  }

  return (
    <DetailsPageLayout>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultValues}
        onFinish={handleSave}
        requiredMark={false}
      >
        <SubHeader>
          <BackButton url={routePaths.DATA_STORE_LIST} />
          <ControlOutlined />
          {t('dataStoreDetailsPage.dataStoreDetailsHeaderTitle')}
          <Spacer />
          <Button
            danger
            icon={<DeleteOutlined />}
            onClick={() => {
              setConfirmDeleteModalOpen(true)
            }}
            loading={deleteLoading}
          >
            {t('dataStoreDetailsPage.deleteDataStoreButton')}
          </Button>
          <ConfirmDeleteModal
            open={confirmDeleteModalOpen}
            onConfirm={() => handleConfirmDelete()}
            onCancel={handleCancel}
            loading={deleteLoading}
            confirmButtonLabel={
              t('dataStoresListPage.confirmDeleteModal.yesButton') || ''
            }
          >
            {t('dataStoresListPage.confirmDeleteModal.content')}
          </ConfirmDeleteModal>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            loading={saveLoading}
          >
            {t('saveButton')}
          </Button>
          <PageContentDropdown onExport={handleExport} />
        </SubHeader>
        <MultiColumnFormContentContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item
              label={t('Title')}
              required
              name="title"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item label={t('Description')} name="description">
              <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
            </Form.Item>
          </MultiColumnFormColumnContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item label={t('Type')}>{dataStoreTypeName}</Form.Item>
            <Form.Item label={configLabel}>{dataStoreFolderPath}</Form.Item>
            {dataStoreLibrary && (
              <Form.Item label={t('Library')}>{dataStoreLibrary}</Form.Item>
            )}
            <Form.Item
              label={t('dataStoreDetailsPage.privacySettingsLabel')}
              name="visibility"
            >
              <Radio.Group disabled>
                <Space direction="vertical">
                  <Radio value={privacyTypes.PRIVATE} disabled>
                    {t('dataStoreDetailsPage.privacySettingsPrivateLabel')}
                  </Radio>
                  <Radio value={privacyTypes.ORGANIZATION} disabled>
                    {t(
                      'dataStoreDetailsPage.privacySettingsOrganizationalLabel'
                    )}
                  </Radio>
                  <Radio value={privacyTypes.FEDERATION} disabled>
                    {t('dataStoreDetailsPage.privacySettingsFederationLabel')}
                  </Radio>
                </Space>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              label={t('dataStoreDetailsPage.dataStoreParticipantsLabel')}
              name="participants"
            >
              <SelectParticipant
                disabled
                initialParticipants={defaultValues?.participants}
              />
            </Form.Item>
            <Form.Item
              label={t('dataStoreDetailsPage.contactNameLabel')}
              required
              name="contactName"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('dataStoreDetailsPage.contactEmailLabel')}
              required
              name="contactEmail"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
                {
                  validator: validationService.validateEmail,
                },
              ]}
            >
              <Input />
            </Form.Item>
          </MultiColumnFormColumnContainer>
        </MultiColumnFormContentContainer>
      </Form>
    </DetailsPageLayout>
  )
}

export default DataStoreDetailsPage
