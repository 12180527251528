import { Button } from 'antd'
import { t } from 'i18next'

interface BiomarkLastConfigurationModalFooterProps {
  closeModal: () => void
}

const BiomarkLastConfigurationModalFooter = ({
  closeModal,
}: BiomarkLastConfigurationModalFooterProps) => {
  return <Button onClick={closeModal}>{t('Close')}</Button>
}

export default BiomarkLastConfigurationModalFooter
