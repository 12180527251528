import { ArrowLeftOutlined, DeleteOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'
import { MouseEvent, ReactNode } from 'react'
import ModalBase from './ModalBase'

export interface ConfirmDeleteModalProps {
  open: boolean
  onConfirm: (event: MouseEvent) => void
  onCancel?: (event: MouseEvent) => void
  loading?: boolean
  children?: ReactNode
  confirmButtonLabel?: string
  cancelButtonLabel?: string
  confirmButtonIcon?: ReactNode
}

const ConfirmDeleteModal = ({
  open,
  onConfirm,
  onCancel,
  loading,
  children,
  confirmButtonLabel,
  cancelButtonLabel,
  confirmButtonIcon,
}: ConfirmDeleteModalProps) => {
  return (
    <ModalBase
      title={t('confirmDeleteModal.title')}
      open={open}
      onOk={onConfirm}
      onCancel={onCancel}
      footer={[
        <Button
          icon={<ArrowLeftOutlined />}
          key="back"
          onClick={onCancel}
          type="text"
        >
          {cancelButtonLabel
            ? cancelButtonLabel
            : t('confirmDeleteModal.noButton')}
        </Button>,
        <Button
          danger
          icon={confirmButtonIcon ? confirmButtonIcon : <DeleteOutlined />}
          key="submit"
          type="primary"
          loading={loading}
          onClick={onConfirm}
        >
          {confirmButtonLabel
            ? confirmButtonLabel
            : t('confirmDeleteModal.yesButton')}
        </Button>,
      ]}
    >
      {children}
    </ModalBase>
  )
}

export default ConfirmDeleteModal
