import { Form, Input } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'

const CreateDataSourceDetailsStep = memo(() => {
  return (
    <>
      <Form.Item
        name="title"
        label={t('createDataSourceModal.detailsStep.title')}
        extra={t(
          'createDataSourceModal.detailsStep.titleSubheader'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <Input
          placeholder={
            t(
              'createDataSourceModal.detailsStep.inputPlaceHolder'
            ) || ''
          }
        />
      </Form.Item>
      <Form.Item
        name="description"
        label={t(
          'createDataSourceModal.detailsStep.description'
        )}
        extra={t(
          'createDataSourceModal.detailsStep.descriptionSubheader'
        )}
      >
        <Input.TextArea autoSize={{ minRows: 3, maxRows: 5 }} />
      </Form.Item>
    </>
  )
})

export default CreateDataSourceDetailsStep
