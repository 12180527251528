import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useMemo } from 'react'
import Moment from 'react-moment'
import { TableBoldColumnContainer } from '../../../components'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import { DataSource } from '../../../store/reducers/dataSourceReducer'

interface DataSourceExtended extends DataSource {
  key: string
}

const WorkspaceSettingsSourcePanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({
    preventFetch: true,
  })

  const formattedData: DataSourceExtended[] =
    useMemo(
      () =>
        selectedWorkspace?.dataSource &&
        typeof selectedWorkspace?.dataSource !== 'string'
          ? [
              {
                ...selectedWorkspace?.dataSource,
                key: selectedWorkspace?.dataSource.id,
              },
            ]
          : [],
      [selectedWorkspace?.dataSource]
    ) || []

  const columns: ColumnsType<DataSource> = [
    {
      title: t('Title'),
      dataIndex: 'title',
      key: 'title',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Creation Date'),
      key: 'createDate',
      dataIndex: 'createdDate',
      render: (textDate) => (
        <>
          {textDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {textDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('Provenance'),
      dataIndex: 'typeName',
      key: 'typeName',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
  ]

  return (
    <>
      {formattedData && (
        <Table
          data-testid="data-sources-list-table"
          rowClassName="page-list-table-row clickable"
          columns={columns}
          dataSource={formattedData}
          pagination={false}
          scroll={{ y: 'calc(100vh - 170px)' }}
        />
      )}
    </>
  )
}

export default WorkspaceSettingsSourcePanel
