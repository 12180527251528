import { ArrowLeftOutlined, MailOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'

interface CreateUserModalReviewStepFooterProps {
  handleConfirm: () => void
  handleBack: () => void
  handleCancel: () => void
}

const CreateUserModalReviewStepFooter = ({
  handleConfirm,
  handleBack,
  handleCancel,
}: CreateUserModalReviewStepFooterProps) => {
  return (
    <>
      <Button key="back" type="text" onClick={handleCancel}>
        {t('Cancel')}
      </Button>
      <Button
        key="back"
        type="text"
        icon={<ArrowLeftOutlined />}
        onClick={handleBack}
      >
        {t('userListPage.createUserModal.goBackButton')}
      </Button>
      <Button
        icon={<MailOutlined />}
        key="submit"
        type="primary"
        onClick={handleConfirm}
      >
        {t('userListPage.createUserModal.sendInviteButton')}
      </Button>
    </>
  )
}

export default CreateUserModalReviewStepFooter
