import axios, { AxiosError, AxiosResponse } from 'axios'
import { urlList } from '../constants/constants'
import apiService from './apiService'
import axiosFactory from './axiosFactory'

const login = async (email: string, password: string) => {
  const params = new URLSearchParams()
  params.append('email', email)
  params.append('password', password)

  return axios
    .post(urlList.LOGIN, params)
    .then((response: AxiosResponse<any, any>) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      apiService.notificationError(error)
      console.error('axios login error', error)
      throw error
    })
}

export interface LoginSsoResponse {
  url: string
}

const loginSso = async () => {
  return axios
    .get(urlList.LOGIN_SSO_SAML, {
      validateStatus: (status) => status >= 200 && status <= 302,
    })
    .then((response: AxiosResponse<LoginSsoResponse, any>) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      apiService.notificationError(error)
      console.error('axios login error', error)
      throw error
    })
}

const logout = async () => {
  return axiosFactory()
    .post(urlList.LOGOUT, {})
    .then((response: AxiosResponse<any, any>) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      console.error('axios logout error', error)
      throw error
    })
}

const forgotPasswordInvite = async (email: string) => {
  return axiosFactory()
    .post(urlList.FORGOT_PASSWORD_INVITE, { email })
    .then((response: AxiosResponse<any, any>) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      apiService.notificationError(error)
      console.error('axios logout error', error)
      throw error
    })
}

const forgotPasswordValidatePin = async (inviteToken: string, pin: string) => {
  return axiosFactory()
    .get(`${urlList.FORGOT_PASSWORD_VALIDATE}=${inviteToken}/verify?pin=${pin}`)
    .then((response: AxiosResponse<any, any>) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      apiService.notificationError(error)
      console.error('axios logout error', error)
      throw error
    })
}

const forgotPasswordUpdatePassword = async (
  inviteToken: string,
  pin: string,
  password: string
) => {
  const params = {
    password: password,
  }

  return axiosFactory()
    .patch(
      `${urlList.FORGOT_PASSWORD_VALIDATE}=${inviteToken}?pin=${pin}`,
      params
    )
    .then((response: AxiosResponse<any, any>) => {
      return response.data
    })
    .catch((error: AxiosError | Error) => {
      apiService.notificationError(error)
      console.error('axios logout error', error)
      throw error
    })
}

const authService = {
  login,
  loginSso,
  logout,
  forgotPasswordInvite,
  forgotPasswordValidatePin,
  forgotPasswordUpdatePassword,
}

export default authService
