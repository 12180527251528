import styled from 'styled-components'

const SimpleSwitchContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  flex-wrap: nowrap;
`

export default SimpleSwitchContainer
