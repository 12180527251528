import { Form, FormInstance, Input } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import { t } from 'i18next'
import styled from 'styled-components'
import { PasswordRules } from '../../../../components'
import { PASSWORD_MIN_LENGTH } from '../../../../constants/constants'

const PasswordRulesContainer = styled.div`
  margin-bottom: 12px;
`

interface FormValues {
  oldPassword: string
  newPassword: string
  confirmPassword: string
}

interface ChangePasswordDefinitionStepProps {
  form: FormInstance
  loading: boolean
}

const ChangePasswordDefinitionStep = ({
  form,
  loading,
}: ChangePasswordDefinitionStepProps) => {
  const defaultValues: FormValues = {
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
  }

  return (
    <Form
      name="normal_login"
      form={form}
      layout="vertical"
      initialValues={defaultValues}
      requiredMark={false}
    >
      <PasswordRulesContainer>
        <PasswordRules />
      </PasswordRulesContainer>
      <Form.Item
        label={t('userDetailsPage.changePasswordModal.oldPasswordLabel')}
        name="oldPassword"
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
          { min: 8, message: t('errorMessage.passwordLength') || '' },
        ]}
      >
        <Input
          type="password"
          placeholder={
            t('userDetailsPage.changePasswordModal.oldPasswordPlaceholder') ||
            ''
          }
          disabled={loading}
        />
      </Form.Item>
      <Form.Item
        label={t('userDetailsPage.changePasswordModal.newPasswordLabel')}
        name="newPassword"
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
          {
            min: PASSWORD_MIN_LENGTH,
            message: t('errorMessage.passwordLength') || '',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('oldPassword') !== value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('errorMessage.passwordMustBeDifferent') || '')
              )
            },
          }),
        ]}
      >
        <PasswordInput placeholder={t('Password') || ''} disabled={loading} />
      </Form.Item>
      <Form.Item
        label={t('Confirm password')}
        name="confirmPassword"
        dependencies={['newPassword']}
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
          {
            min: PASSWORD_MIN_LENGTH,
            message: t('errorMessage.passwordLength') || '',
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue('newPassword') === value) {
                return Promise.resolve()
              }
              return Promise.reject(
                new Error(t('errorMessage.passwordDoNotMatch') || '')
              )
            },
          }),
        ]}
      >
        <Input.Password
          placeholder={t('Confirm password') || ''}
          disabled={loading}
        />
      </Form.Item>
    </Form>
  )
}

export default ChangePasswordDefinitionStep
