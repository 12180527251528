import { UserOutlined } from '@ant-design/icons'
import { Button, Dropdown, MenuProps, theme } from 'antd'
import { Header } from 'antd/lib/layout/layout'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  AfterBreadcrumb,
  Breadcrumb,
  MainHeaderAuditTrails,
  MainHeaderSearch,
  Spacer,
} from '.'
import { urlList } from '../constants/constants'
import { routePaths } from '../pages/RootPage'
import { apiService } from '../services'
import authService from '../services/authService'
import { RootState } from '../store'
import { UserDetails, setCurrentUser } from '../store/reducers/userReducer'

const { useToken } = theme

const DropDownItem = styled.div`
  width: 200px;
`

interface MainHeaderProps {
  mainMenuCollapsed: boolean
}

const MainHeader = memo(({ mainMenuCollapsed }: MainHeaderProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const location = useLocation()
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const { token } = useToken()
  const [loading, setLoading] = useState(false)

  const isSearchAvailable = useMemo(() => {
    return (
      location.pathname === routePaths.SEARCH ||
      location.pathname === routePaths.SEARCH_DOCUMENT_SUMMARIES
    )
  }, [location])

  useEffect(() => {
    apiService
      .fetchItems(urlList.CURRENT_USER)
      .then((response: UserDetails) => {
        dispatch(setCurrentUser(response))
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
        apiService.handleError401(error, navigate)
      })
  }, [dispatch, navigate])

  const handleLogout = useCallback(async () => {
    setLoading(true)
    await authService.logout().then(
      (response: any) => {
        navigate(routePaths.LOGIN)
        setLoading(false)
      },
      (error) => {
        console.error('Logout error', error)
        setLoading(false)
        navigate(routePaths.LOGIN)
      }
    )
  }, [navigate])

  const labelStyle = {
    cursor: 'default',
    margin: '-5px -12px',
    padding: '5px 12px',
    color: token.colorTextSecondary,
  }

  const items: MenuProps['items'] = [
    {
      key: '1',
      label: (
        <DropDownItem style={labelStyle}>
          {currentUser?.displayName}
        </DropDownItem>
      ),
      disabled: true,
    },
    {
      key: '2',
      label: (
        <DropDownItem
          onClick={() =>
            navigate(`${routePaths.USER_DETAILS}/${currentUser?.id}`)
          }
        >
          {t('mainHeader.accountMenu.myProfile')}
        </DropDownItem>
      ),
    },
    {
      key: '3',
      label: (
        <DropDownItem onClick={() => navigate(routePaths.SETTINGS)}>
          {t('mainHeader.accountMenu.settings')}
        </DropDownItem>
      ),
    },
    {
      key: '5',
      label: (
        <DropDownItem onClick={handleLogout}>
          {t('mainHeader.accountMenu.logout')}
        </DropDownItem>
      ),
    },
  ]

  return (
    <>
      <Header
        style={{
          background: token.colorBgContainer,
          display: 'flex',
          alignItems: 'center',
          gap: 8,
          padding: '0 16px',
          borderRadius: '16px 0px 0px 0px',
          borderLeft: '1px solid var(--color-border, rgba(6, 5, 5, 0.15))',
          position: 'fixed',
          zIndex: 100,
          width: mainMenuCollapsed
            ? 'calc(100vw - 80px)'
            : 'calc(100vw - 200px)',
          marginLeft: mainMenuCollapsed ? 80 : 200,
          transitionDelay: '0s, 0s',
          transitionDuration: '0.2s, 0s',
          transitionProperty: 'all, background',
          transitionTimingFunction: 'ease, ease',
        }}
      >
        <Breadcrumb />
        <AfterBreadcrumb />
        {isSearchAvailable && <MainHeaderSearch loading={loading} />}
        {location.pathname === routePaths.AUDIT_TRAIL_LIST && (
          <MainHeaderAuditTrails />
        )}
        <Spacer />
        <Dropdown menu={{ items }} placement="bottomRight" trigger={['click']}>
          <Button
            type="default"
            size="middle"
            aria-label="account of current user"
            aria-controls={'primary-search-account-menu'}
            aria-haspopup="true"
            color="inherit"
            style={{ padding: '4px' }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      </Header>
    </>
  )
})

export default MainHeader
