import { FormInstance } from 'antd/lib'
import { delConfigurationModalTypes } from '../../../../../../constants/constants'
import { DelConfigurationFormValues } from '../DelConfigurationModal'
import DelConfigurationModalDINStep from './DelConfigurationModalDINStep'
import DelConfigurationModalTableAStep from './DelConfigurationModalTableAStep'

export interface DelConfigurationModalInputStepProps {
  form: FormInstance<DelConfigurationFormValues>
  setIsSelected: (isSelected: boolean) => void
}

const DelConfigurationModalInputStep = ({
  form,
  setIsSelected,
}: DelConfigurationModalInputStepProps) => {
  const reviewType = form.getFieldValue('reviewType')

  if (reviewType === delConfigurationModalTypes.DIN) {
    return (
      <DelConfigurationModalDINStep form={form} setIsSelected={setIsSelected} />
    )
  } else if (reviewType === delConfigurationModalTypes.TABLE_A) {
    return (
      <DelConfigurationModalTableAStep
        form={form}
        setIsSelected={setIsSelected}
      />
    )
  } else {
    return (
      <DelConfigurationModalDINStep form={form} setIsSelected={setIsSelected} />
    )
  }
}

export default DelConfigurationModalInputStep
