import { ContainerOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ModalBase,
  ModalContentContainer,
  ModalStepsTitle,
} from '../../../components'
import { ModalSteps, optionalRequiredMark } from '../../../constants/constants'
import useWorkspaces from '../../../hooks/useWorkspaces'
import { dataSourceService, dataStoreService } from '../../../services'
import notificationService from '../../../services/notificationService'
import workspaceService from '../../../services/workspaceService'
import { RootState } from '../../../store'
import { DataSource } from '../../../store/reducers/dataSourceReducer'
import { DataStore } from '../../../store/reducers/dataStoreReducer'
import { WorkspaceDetails } from '../../../store/reducers/workspaceReducer'
import { routePaths } from '../../RootPage'
import CreateWorkspaceModalFooter from './footers/CreateWorkspaceModalFooter'
import CreateWorkspaceModalOutputStep from './steps/CreateWorkspaceModalDataStoreStep'
import CreateWorkspaceModalDefinitionStep from './steps/CreateWorkspaceModalDefinitionStep'
import CreateWorkspaceModalPrivacyStep from './steps/CreateWorkspaceModalPrivacyStep'
import CreateWorkspaceModalResourcesStep from './steps/CreateWorkspaceModalResourcesStep'
import CreateWorkspaceModalReviewStep from './steps/CreateWorkspaceModalReviewStep'
import CreateWorkspaceModalTypeStep from './steps/CreateWorkspaceModalTypeStep'

const CreateWorkspaceModal = () => {
  const [form] = Form.useForm()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [dataSourceId, setDataSourceId] = useState<string>('')
  const [dataSource, setDataSource] = useState<DataSource | undefined>(
    undefined
  )
  const [dataStoreId, setDataStoreId] = useState<string>('')
  const [dataStore, setDataStore] = useState<DataStore | undefined>(undefined)
  const navigate = useNavigate()

  const { refreshWorkspaceList } = useWorkspaces({
    preventFetch: true,
  })
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const defaultValues = useMemo(
    () => ({
      title: '',
      description: '',
      participants: currentUser ? [currentUser.id] : [],
      selectedDataStores: [],
    }),
    [currentUser]
  )

  const stepItems: ModalSteps[] = [
    {
      title: t('createWorkspaceModal.definitionStep.title'),
    },
    {
      title: t('createWorkspaceModal.typeStep.title'),
    },
    {
      title: t('createWorkspaceModal.resourcesStep.title'),
    },
    {
      title: t('createWorkspaceModal.storeStep.title'),
    },
    {
      title: t('createWorkspaceModal.privacyStep.title'),
    },
    {
      title: t('createWorkspaceModal.reviewStep.title'),
    },
  ]

  const resetValues = useCallback(() => {
    form.resetFields()
    setCurrentStep(0)
  }, [form])

  const handleOk = useCallback(() => {
    setConfirmLoading(true)
  }, [])

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
  }, [resetValues])

  const handleNextClick = useCallback(() => {
    form
      .validateFields()
      .then(() => setCurrentStep((currentStep) => currentStep + 1))
      .catch(() => {
        notificationService.notificationError(
          t('createWorkspaceModal.invalidFields')
        )
      })
  }, [form])

  useMemo(() => {
    if (dataSourceId) {
      dataSourceService
        .getDataSourceDetails({ dataSourceId })
        .then((response: DataSource) => {
          setDataSource(response)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [dataSourceId])

  useMemo(() => {
    if (dataStoreId) {
      dataStoreService
        .getDataStoreDetails({ dataStoreId })
        .then((response: DataStore) => {
          setDataStore(response)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [dataStoreId])

  const handlePreviousClick = useCallback(() => {
    setCurrentStep((currentStep) => currentStep - 1)
  }, [])

  const handleSubmit = useCallback(() => {
    const workspace = {
      title: form.getFieldValue('title'),
      description: form.getFieldValue('description'),
      dataSource: form.getFieldValue('selectedDataSource'),
      dataStores: form.getFieldValue('selectedDataStores'),
      workspaceType: form.getFieldValue('type'),
      visibility: form.getFieldValue('visibility'),
      participants: form.getFieldValue('participants'),
      navigate,
    }
    workspaceService
      .createWorkspace(workspace)
      .then((response: WorkspaceDetails) => {
        notificationService.notificationSuccess(
          t('createWorkspaceModal.createWorkspaceSuccess')
        )
        refreshWorkspaceList()
        resetValues()
        setOpen(false)
        navigate(`${routePaths.WORKSPACE_DETAILS}/${response.id}`)
      })
      .catch((error) => {
        console.error('axios fetch error', error)
        notificationService.notificationError(
          t('createWorkspaceModal.errorCreateWorkspace')
        )
      })
    setConfirmLoading(false)
  }, [form, navigate, refreshWorkspaceList, resetValues])

  const steps: ReactNode[] = useMemo(
    () => [
      <CreateWorkspaceModalDefinitionStep form={form} />,
      <CreateWorkspaceModalTypeStep form={form} />,
      <CreateWorkspaceModalResourcesStep
        form={form}
        setDataSource={setDataSourceId}
      />,
      <CreateWorkspaceModalOutputStep
        form={form}
        setDataStore={setDataStoreId}
      />,
      <CreateWorkspaceModalPrivacyStep dataSource={dataSource} form={form} />,
      <CreateWorkspaceModalReviewStep
        form={form}
        selectedDataSource={dataSource}
        selectedDataStore={dataStore}
      />,
    ],
    [form, dataSource, dataStore]
  )

  const footers: ReactNode[] = useMemo(
    () => [
      <CreateWorkspaceModalFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateWorkspaceModalFooter
        onPrevious={handlePreviousClick}
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateWorkspaceModalFooter
        onPrevious={handlePreviousClick}
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateWorkspaceModalFooter
        onPrevious={handlePreviousClick}
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateWorkspaceModalFooter
        onPrevious={handlePreviousClick}
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateWorkspaceModalFooter
        onPrevious={handlePreviousClick}
        onSubmit={() => {
          // this will be changed in future mock
          handleSubmit()
        }}
        onCancel={handleCancel}
      />,
    ],
    [handleCancel, handleNextClick, handlePreviousClick, handleSubmit]
  )

  return (
    <>
      <Button
        icon={<ContainerOutlined />}
        type="primary"
        onClick={() => setOpen(true)}
      >
        {t('createWorkspaceModal.button')}
      </Button>
      <ModalBase
        key="main"
        title={
          <ModalStepsTitle
            title={t('createWorkspaceModal.title')}
            currentStep={currentStep}
            stepItems={stepItems}
          />
        }
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        width={720}
        styles={{
          body: {
            maxHeight: 'calc(100vh - 400px)',
          },
        }}
        footer={footers[currentStep]}
      >
        <ModalContentContainer>
          <Form
            form={form}
            layout="vertical"
            initialValues={defaultValues}
            requiredMark={optionalRequiredMark}
          >
            {steps[currentStep]}
          </Form>
        </ModalContentContainer>
      </ModalBase>
    </>
  )
}

export default CreateWorkspaceModal
