import { DataNode } from 'antd/es/tree'
import { Tree } from 'antd/lib'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { SectionLoadingState } from '../../../../components'
import { useAlgorithmRunDetails } from '../../../../hooks'
import { RegulatorySubmissionRunResult } from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const MissingReferenceLineContainer = styled.div`
  display: flex;
  width: 100%;
  min-height: 24px;
  margin-bottom: 8px;
`

const MissingReferenceContainer = styled.div`
  color: ${(props) => props.theme.token.colorErrorText};
  font-weight: 600;
  min-width: 100px;
`

interface RegulatorySubmissionSmartFunctionPanelProps {
  search: string
}

const RegulatorySubmissionSmartFunctionPanel = ({
  search,
}: RegulatorySubmissionSmartFunctionPanelProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  const formattedData: DataNode[] = useMemo(() => {
    const smartFunctionResult =
      selectedAlgorithmRun?.runResult as RegulatorySubmissionRunResult
    const formattedData = smartFunctionResult?.absent
      .filter((regulatoryItem) =>
        !!search ? regulatoryItem.includes(search || '') : true
      )
      .map((regulatoryItem, index) => {
        const missingReferenceIndex = index

        const formattedMissingReference: DataNode = {
          key: `0-${missingReferenceIndex}`,
          title: (
            <MissingReferenceLineContainer>
              <MissingReferenceContainer>
                {regulatoryItem}
              </MissingReferenceContainer>
            </MissingReferenceLineContainer>
          ),
          className: 'missing-reference-tree-node included-missing-reference',
          selectable: false,
        }

        return formattedMissingReference
      })
    return formattedData || []
  }, [selectedAlgorithmRun?.runResult, search])

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <Tree treeData={formattedData} showLine />
    </SmartFunctionListPanelLayout>
  )
}

export default RegulatorySubmissionSmartFunctionPanel
