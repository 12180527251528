import { Form } from 'antd'
import { FormInstance } from 'antd/lib/form/Form'
import { t } from 'i18next'
import { memo } from 'react'
import { useSelector } from 'react-redux'
import { SelectSource } from '../../../../components'
import { selectSourcePrivacyTypes } from '../../../../components/SelectSource'

interface CreateWorkspaceModalResourcesStepProps {
  form: FormInstance
  setDataSource: (value: string) => void
}

const CreateWorkspaceModalResourcesStep = memo(
  ({ form, setDataSource }: CreateWorkspaceModalResourcesStepProps) => {
    const currentUser = useSelector((state: any) => state.user.currentUser)
    const handleOnChange = (value: string) => {
      setDataSource(value)
      form.setFieldValue('selectedDataSource', value)
      form.setFieldValue('participants', currentUser ? [currentUser.id] : [])
    }

    return (
      <>
        <Form.Item
          name="selectedDataSource"
          label={t('createWorkspaceModal.resourcesStep.subHeader' || '')}
          extra={t('createWorkspaceModal.resourcesStep.infoBox' || '')}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
        >
          <SelectSource
            onChange={handleOnChange}
            value={form.getFieldValue('selectedDataSource')}
            privacy={selectSourcePrivacyTypes.INTERNAL}
            includeTypeCode={false}
            includeSearchable={false}
          />
        </Form.Item>
      </>
    )
  }
)

export default CreateWorkspaceModalResourcesStep
