import { NavigateFunction } from 'react-router-dom'
import { apiService } from '.'
import { privacyTypes } from '../constants/constants'
import { urlList } from '../constants/urlConstant'
import { SourceType } from '../store/reducers/dataSourceReducer'
import { DataStoreConfig } from '../store/reducers/dataStoreReducer'
import { PrivacyType, TranslationDictionary } from '../types/generalTypes'

const baseUrl = urlList.DATA_STORES

interface GetDataStoreDetailsProps {
  dataStoreId: string
}

const getDataStoreDetails = async ({
  dataStoreId,
}: GetDataStoreDetailsProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${dataStoreId}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface UpdateDataStoreDetailsProps {
  title: string
  description: string
  visibility: PrivacyType
  participants: string[]
  contactName: string
  contactEmail: string
  dataStoreId: string
  navigate: NavigateFunction
}

const updateDataStoreDetails = async ({
  title,
  description,
  visibility,
  participants,
  contactName,
  contactEmail,
  dataStoreId,
  navigate,
}: UpdateDataStoreDetailsProps) => {
  const params = {
    title,
    description,
    visibility,
    participants: visibility === privacyTypes.PRIVATE ? participants : [],
    contactEmail,
    contactName,
  }

  return apiService
    .updateItem(`${baseUrl}/${dataStoreId}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios update error', error)
      throw error
    })
}

interface DeleteDataStoreProps {
  dataStoreId: string
  navigate: NavigateFunction
}

interface CreateDataStoreProps {
  title: string | TranslationDictionary
  description: string
  sourceType: SourceType
  visibility: PrivacyType
  participants: string[]
  navigate: NavigateFunction
  config: DataStoreConfig
  contactEmail: string
  contactName: string
}

const deleteDataStore = async ({
  dataStoreId,
  navigate,
}: DeleteDataStoreProps) => {
  return apiService
    .deleteItem(`${baseUrl}/${dataStoreId}`, navigate)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios delete error', error)
      throw error
    })
}

const createDataStore = async ({
  title,
  description,
  visibility,
  sourceType,
  participants,
  config,
  navigate,
  contactName,
  contactEmail,
}: CreateDataStoreProps) => {
  const params = {
    title,
    description,
    visibility,
    config,
    sourceType,
    participants: visibility === privacyTypes.PRIVATE ? participants : [],
    navigate,
    contactEmail,
    contactName,
  }

  return apiService
    .saveItem(`${baseUrl}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

const exportDataStoreList = async () => {
  return apiService
    .fetchItems(`${baseUrl}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportDataStoreDetailsProps {
  dataStoreId: string
}

const exportDataStoreDetails = async ({
  dataStoreId,
}: ExportDataStoreDetailsProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${dataStoreId}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const dataStoreService = {
  createDataStore,
  deleteDataStore,
  exportDataStoreList,
  exportDataStoreDetails,
  getDataStoreDetails,
  updateDataStoreDetails,
}
export default dataStoreService
