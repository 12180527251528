import { Modal, ModalProps } from 'antd'

const ModalBase = (props: ModalProps) => {
  return (
    <Modal {...props} closable={false} maskClosable={false}>
      {props.children}
    </Modal>
  )
}

export default ModalBase
