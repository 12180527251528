import { AxiosError } from 'axios'
import { ReactNode, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { dataSourceService } from '../services'

import notificationService from '../services/notificationService'
import { RootState } from '../store'
import {
  DataSourceType,
  setDataSourceTypeList,
} from '../store/reducers/dataSourceReducer'

interface UseDataSourceTypesProps {
  preventFetch?: boolean
  allowedDataSourceTypes?: string[]
  errorNotificationMessage?: ReactNode
}

const useDataSourceTypes = ({
  preventFetch = false,
  allowedDataSourceTypes,
  errorNotificationMessage,
}: UseDataSourceTypesProps) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const dataSourceTypeList: DataSourceType[] | undefined = useSelector(
    (state: RootState) => state.dataSource.dataSourceTypeList
  )

  const fetchDataSourceTypeList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        dataSourceService
          .getDataSourceType()
          .then((response: DataSourceType[]) => {
            setLoading(false)
            const dataSourceTypeList = response.filter(
              (dataStoreType: DataSourceType) =>
                allowedDataSourceTypes
                  ? allowedDataSourceTypes.includes(dataStoreType.typeCode)
                  : true
            )
            dispatch(setDataSourceTypeList(dataSourceTypeList))
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios fetch error', error)
            notificationService.notificationError(
              errorNotificationMessage || error.message
            )
            setLoading(false)
          })
      }
    },
    [
      dispatch,
      loading,
      preventFetch,
      allowedDataSourceTypes,
      errorNotificationMessage,
    ]
  )

  const refreshDataSourceTypeList = useCallback(async () => {
    fetchDataSourceTypeList(true)
  }, [fetchDataSourceTypeList])

  useEffect(() => {
    fetchDataSourceTypeList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    dataSourceTypeList,
    fetchDataSourceTypeList,
    refreshDataSourceTypeList,
    loading,
    error,
  }
}

export default useDataSourceTypes
