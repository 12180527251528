import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'

export interface Role {
  id: string
  createdBy: string
  createdDate: string
  lastModifiedBy: string
  lastModifiedDate: string
  permissions: string[]
  title: string
  userComments: string
}

export interface RoleState {
  roles: Role[]
}

const initialState: RoleState = {
  roles: [],
}

const roleSlice = createSlice({
  name: 'roles',
  initialState,
  reducers: {
    setRoles: (state, action: PayloadAction<Role[]>) => {
      state.roles = action.payload
    },
    clearRoleState: () => initialState,
  },
})

export const { setRoles, clearRoleState } = roleSlice.actions

export const getRoles = (state: RootState) => state.role.roles

const roleReducer = roleSlice.reducer
export default roleReducer
