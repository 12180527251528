import { LockOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import { t } from 'i18next'
import { useState } from 'react'
import styled from 'styled-components'
import { PasswordRules } from '../../../components'
import { PASSWORD_MIN_LENGTH } from '../../../constants/constants'
import { authService } from '../../../services'
import { ForgotPasswordStepsFooter, FormLayout } from '../ForgotPasswordPage'

const PasswordRulesContainer = styled.div`
  margin-bottom: 12px;
`

interface FormValues {
  newPassword: string
  confirmPassword: string
}

interface ForgotPasswordStep3Props {
  onNext: () => void
  onCancel: () => void
  inviteToken: string
  pin: string
}

const ForgotPasswordStep3Password = ({
  onNext,
  onCancel,
  inviteToken,
  pin,
}: ForgotPasswordStep3Props) => {
  const [loading, setLoading] = useState(false)
  const defaultValues: FormValues = {
    newPassword: '',
    confirmPassword: '',
  }

  const handleSubmit = async (values: FormValues) => {
    setLoading(true)
    await authService
      .forgotPasswordUpdatePassword(inviteToken, pin, values.newPassword)
      .then(
        (response: any) => {
          setLoading(false)
          onNext()
        },
        (error) => {
          console.error('Login error', error)
          setLoading(false)
        }
      )
  }

  return (
    <Form
      name="normal_login"
      layout="vertical"
      initialValues={defaultValues}
      onFinish={handleSubmit}
      requiredMark={false}
    >
      <FormLayout>
        <div>
          <PasswordRulesContainer>
            <PasswordRules />
          </PasswordRulesContainer>
          <Form.Item
            label={t('forgotPasswordPage.step3Password.newPasswordLabel')}
            name="newPassword"
            rules={[
              { required: true, message: t('errorMessage.isRequired') || '' },
              {
                min: PASSWORD_MIN_LENGTH,
                message: t('errorMessage.passwordLength') || '',
              },
            ]}
          >
            <PasswordInput
              placeholder={t('Password') || ''}
              disabled={loading}
            />
          </Form.Item>
          <Form.Item
            label={t('forgotPasswordPage.step3Password.confirmPasswordLabel')}
            name="confirmPassword"
            dependencies={['newPassword']}
            rules={[
              { required: true, message: t('errorMessage.isRequired') || '' },
              {
                min: PASSWORD_MIN_LENGTH,
                message: t('errorMessage.passwordLength') || '',
              },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('newPassword') === value) {
                    return Promise.resolve()
                  }
                  return Promise.reject(
                    new Error(t('errorMessage.passwordDoNotMatch') || '')
                  )
                },
              }),
            ]}
          >
            <Input.Password
              placeholder={t('Confirm password') || ''}
              disabled={loading}
            />
          </Form.Item>
        </div>

        <ForgotPasswordStepsFooter>
          <Button onClick={onCancel} type="text">
            {t('Cancel')}
          </Button>
          <Button type="primary" htmlType="submit" icon={<LockOutlined />}>
            {t('forgotPasswordPage.step3Password.okButton')}
          </Button>
        </ForgotPasswordStepsFooter>
      </FormLayout>
    </Form>
  )
}

export default ForgotPasswordStep3Password
