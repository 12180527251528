import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { RootState } from '../store'
import { setLanguage } from '../store/reducers/languageReducer'
import { Language } from '../types/generalTypes'

const useLanguage = () => {
  const { i18n } = useTranslation()
  const dispatch = useDispatch()

  const selectedLanguage = useSelector(
    (state: RootState) => state.language.selectedLanguage
  ) as Language

  const toggleLanguage = (newLanguage: Language) => {
    i18n.changeLanguage(newLanguage)
    localStorage.setItem('language', newLanguage)
    dispatch(setLanguage(newLanguage))
  }

  useEffect(() => {
    const storedLanguage = localStorage.getItem('language')
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage)
      dispatch(setLanguage(storedLanguage as Language))
    }
  }, [dispatch, i18n, selectedLanguage])

  return { selectedLanguage, toggleLanguage }
}

export default useLanguage
