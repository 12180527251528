import { Select } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { SelectOption } from '../../types/generalTypes'
import { UserListFilterType, userListFilters } from './UserListPage'

interface UserListFiltersProps {
  onChange?: (value: UserListFilterType) => void
}

const UserListFilters = ({ onChange }: UserListFiltersProps) => {
  const { filter } = useParams()
  const [selectedValue, setSelectedValue] = useState<UserListFilterType>(
    (filter || userListFilters.ALL_USERS) as UserListFilterType
  )

  const options: SelectOption[] = [
    {
      label: t('userListPage.filters.all') || '',
      value: userListFilters.ALL_USERS,
    },
    {
      label: t('userListPage.filters.active') || '',
      value: userListFilters.ACTIVE_USERS,
    },
    {
      label: t('userListPage.filters.inactive') || '',
      value: userListFilters.INACTIVE_USERS,
    },
    {
      label: t('userListPage.filters.admin') || '',
      value: userListFilters.ADMIN_ROLE,
    },
    {
      label: t('userListPage.filters.user') || '',
      value: userListFilters.USER_ROLE,
    },
  ]

  const handleChange = (value: UserListFilterType) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      style={{
        width: 192,
      }}
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t('Overview')}
    />
  )
}

export default UserListFilters
