import { EyeOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'

interface CreateUserModalDefinitionStepFooterProps {
  loading: boolean
  handleCancel: () => void
  handleNext: () => void
  isNextDisabled: boolean
}

const CreateUserModalDefinitionStepFooter = ({
  loading,
  handleCancel,
  handleNext,
  isNextDisabled
}: CreateUserModalDefinitionStepFooterProps) => {
  return (
    <>
      <Button key="back" onClick={handleCancel} type="text">
        {t('Cancel')}
      </Button>
      <Button
        icon={<EyeOutlined />}
        key="submit"
        type="primary"
        loading={loading}
        onClick={handleNext}
        disabled={isNextDisabled}
      >
        {t('userListPage.createUserModal.reviewInviteButton')}
      </Button>
    </>
  )
}

export default CreateUserModalDefinitionStepFooter
