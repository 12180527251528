import { InboxOutlined } from '@ant-design/icons'
import { Input } from 'antd'
import Table, { ColumnsType } from 'antd/lib/table'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  AllowClearIcon,
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  SubHeader,
  TableBoldColumnContainer,
} from '../../components'
import {
  NO_DATA_PLACEHOLDER,
  permissionActions,
  permissionNames,
  urlList,
} from '../../constants/constants'
import { permissionValues } from '../../constants/permissionConstant'
import { useRoles } from '../../hooks'
import useUsers, { UserResponse } from '../../hooks/useUsers'
import { apiService } from '../../services'
import { RootState } from '../../store'
import { User, setUsers } from '../../store/reducers/userReducer'
import { routePaths } from '../RootPage'
import UserListFilters from './UserListFilters'
import CreateUserModal from './modals/CreateUserModal'

const TitleContainer = styled.div`
  display: flex;
  gap: 8px;
  padding-left: 6px;
  padding-right: 6px;
`

const { Search } = Input

interface ExtendedUser extends User {
  key: string
  roleName?: string
}

export type UserListFilterType =
  | 'all'
  | 'active'
  | 'inactive'
  | 'admins'
  | 'users'

export type UserListFilterTypes = {
  ALL_USERS: UserListFilterType
  ACTIVE_USERS: UserListFilterType
  INACTIVE_USERS: UserListFilterType
  ADMIN_ROLE: UserListFilterType
  USER_ROLE: UserListFilterType
}

export const userListFilters: UserListFilterTypes = {
  ALL_USERS: 'all',
  ACTIVE_USERS: 'active',
  INACTIVE_USERS: 'inactive',
  ADMIN_ROLE: 'admins',
  USER_ROLE: 'users',
}

const UserListPage = () => {
  const { filter } = useParams()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { roleList } = useRoles({})
  const { exportUserListCsv } = useUsers({ preventFetch: true })
  const userList: User[] | undefined = useSelector(
    (state: RootState) => state.user.users
  )
  const [loading, setLoading] = useState(false)
  const [localSearchValue, setLocalSearchValue] = useState('')
  const [selectedFilter, setSelectedFilter] = useState<UserListFilterType>(
    (filter || userListFilters.ALL_USERS) as UserListFilterType
  )

  const handleFilterChange = (value: UserListFilterType) => {
    setSelectedFilter(value)
    handleSearch('')
    navigate(`${routePaths.USER_LIST}/${value}`)
  }

  const formattedData: ExtendedUser[] = useMemo(() => {
    if (!userList) return []

    let filteredUsers: User[] = []
    switch (selectedFilter) {
      case userListFilters.ALL_USERS:
        filteredUsers = userList || []
        break
      case userListFilters.ACTIVE_USERS:
        filteredUsers = userList.filter((user) => user.isActive)
        break
      case userListFilters.INACTIVE_USERS:
        filteredUsers = userList.filter((user) => !user.isActive)
        break
      case userListFilters.ADMIN_ROLE:
        const adminRole = roleList.find((role) =>
          role.permissions.includes(permissionNames.ALL)
        )
        filteredUsers = userList.filter((user) => user.role === adminRole?.id)
        break
      case userListFilters.USER_ROLE:
        const userPermission = `${permissionNames.USERS}:${permissionActions.UPDATE}:${permissionValues.OWN}`
        filteredUsers = userList.filter((user) => {
          const userRole = roleList.find((role) =>
            role.permissions.includes(userPermission)
          )
          return userRole && user.role === userRole.id
        })
        break
      default:
        filteredUsers = userList || []
    }

    return filteredUsers.map((user: User) => ({
      ...user,
      key: user.id,
      roleName: roleList.find((role) => role.id === user.role)?.title,
    }))
  }, [userList, selectedFilter, roleList])

  const updateUserList = useCallback(
    (searchQuery?: string) => {
      const url = !!searchQuery
        ? `${urlList.USERS}?searchQuery=${searchQuery}&pageSize=1000&includeInactive=true`
        : `${urlList.USERS}?pageSize=1000&includeInactive=true`
      setLoading(true)
      apiService
        .fetchItems(url)
        .then((response: UserResponse) => {
          setLoading(false)
          dispatch(setUsers(response.data))
        })
        .catch((error: AxiosError | Error) => {
          setLoading(false)
          console.error('axios fetch error', error)
        })
    },
    [dispatch]
  )

  useEffect(() => {
    // Update selected filter when the filter prop changes
    setSelectedFilter(
      (filter || userListFilters.ALL_USERS) as UserListFilterType
    )
  }, [filter])

  useEffect(() => {
    // Fetch and update user list based on the selected filter
    updateUserList(localSearchValue)
  }, [selectedFilter, localSearchValue, updateUserList])

  const handleSearch = (searchText: string) => {
    setLocalSearchValue(searchText)
    updateUserList(searchText)
  }

  const handleClick = (id: string) => {
    navigate(`${routePaths.USER_DETAILS}/${id}`)
  }

  const columns: ColumnsType<User> = [
    {
      title: t('Name'),
      dataIndex: 'displayName',
      key: 'displayName',
      width: '40%',
      sorter: (a: User, b: User) =>
        a.displayName && b.displayName
          ? a.displayName.localeCompare(b.displayName)
          : -1,
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },

    {
      title: t('Email'),
      key: 'email',
      dataIndex: 'email',
      width: '30%',
      sorter: (a: User, b: User) =>
        a.email && b.email ? a.email.localeCompare(b.email) : -1,
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Role'),
      key: 'role',
      dataIndex: 'roleName',
      sorter: (a: User, b: User) =>
        a.role && b.role ? a.role.localeCompare(b.role) : -1,
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t('Status'),
      key: 'isActive',
      dataIndex: 'isActive',
      sorter: (a: User, b: User) => (a.isActive && b.isActive ? 1 : -1),
      render: (text) => <>{text ? t('Active') : t('Inactive')}</>,
    },
  ]

  const handleUserListExport = () => {
    exportUserListCsv()
  }

  return (
    <ListPageLayout>
      <ListPageHeader
        title={t('userListPage.title', {
          count: userList?.length,
        })}
        actions={
          <>
            <CreateUserModal />
            <PageContentDropdown
              onExport={handleUserListExport}
              disabled={formattedData.length === 0}
            />
          </>
        }
      />
      <SubHeader>
        <UserListFilters onChange={handleFilterChange} />
        <TitleContainer>
          <InboxOutlined />
          {formattedData?.length} {t('Found')}
        </TitleContainer>
        <Search
          placeholder={t('userListPage.searchPlaceholder') || ''}
          allowClear={{
            clearIcon: <AllowClearIcon />,
          }}
          onSearch={handleSearch}
          onChange={(event) => handleSearch(event.target.value)}
          style={{ flex: 1 }}
          value={localSearchValue}
        />
      </SubHeader>
      <Table
        loading={loading}
        rowClassName="page-list-table-row clickable"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        scroll={{ y: 'calc(100vh - 202px)' }}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleClick(record.id)
            },
          }
        }}
      />
    </ListPageLayout>
  )
}

export default UserListPage
