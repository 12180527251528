import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import {
  AlgorithmRun,
  QualityReviewRunResult
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface QualityReviewSmartFunctionStatistics {
  totalRuns?: number
  totalDocuments?: number
  totalDeviations?: number
}

const QualityReviewSmartFunctionStatisticsPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] =
    useState<QualityReviewSmartFunctionStatistics>()

  const mostRecentCompleteAlgorithmRun = useMemo(() => {
    if (!selectedWorkspace?.algorithmRuns) {
      return undefined
    }
    return selectedWorkspace?.algorithmRuns
      .filter(
        (obj) => obj.runStatus === 'COMPLETE' && obj.completedDate !== null
      )
      .reduce((prev, current) => {
        if (prev.completedDate && current.completedDate) {
          return Date.parse(prev.completedDate) >
            Date.parse(current.completedDate)
            ? prev
            : current
        } else if (prev.completedDate) {
          return prev
        } else {
          return current
        }
      }, selectedWorkspace.algorithmRuns[0])
  }, [selectedWorkspace])

  useEffect(() => {
    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res: AlgorithmRun) => {
          const smartFunctionResult = res.runResult as QualityReviewRunResult
          setStatistics({
            totalRuns: selectedWorkspace?.algorithmRuns?.length,
            totalDocuments: Object.keys(smartFunctionResult).length,
            totalDeviations: Object.values(smartFunctionResult).reduce(
              (acc, curr) =>
                acc +
                curr.reduce(
                  (count, document) =>
                    document.qualityReviewDeviation !== null &&
                    document.qualityReviewDeviation !== undefined
                      ? count + 1
                      : count,
                  0
                ),
              0
            ),
          })
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [mostRecentCompleteAlgorithmRun, selectedWorkspace])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.qualityReviewStatistics.smartFunctionRuns'
        )}
        value={statistics?.totalRuns ?? NO_DATA_PLACEHOLDER}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.qualityReviewStatistics.totalDocuments'
        )}
        value={statistics?.totalDocuments ?? NO_DATA_PLACEHOLDER}
      />
       <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.qualityReviewStatistics.totalDeviations'
        )}
        value={statistics?.totalDeviations ?? NO_DATA_PLACEHOLDER}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default QualityReviewSmartFunctionStatisticsPanel
