import { Steps } from 'antd'
import styled from 'styled-components'
import { ModalSteps } from '../../constants/constants'
import ModalContentContainer from './ModalContentContainer'

interface ModalStepsTitleProps {
  title: string
  currentStep: number
  stepItems: ModalSteps[]
}

const ModalTitleContainer = styled.span`
  padding-bottom: 10px;
`

const ModalStepsTitle = ({
  title,
  currentStep,
  stepItems,
}: ModalStepsTitleProps) => {
  return (
    <ModalContentContainer>
      <ModalTitleContainer
        style={{
          paddingBottom: '10px',
        }}
      >
        {title}
      </ModalTitleContainer>
      <Steps
        size="small"
        style={{
          fontWeight: 'normal',
        }}
        current={currentStep}
        items={stepItems}
      />
    </ModalContentContainer>
  )
}

export default ModalStepsTitle
