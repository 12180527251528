import { Form, Steps } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ModalBase, ModalContentContainer } from '../../../../../components'
import { delConfigurationModalTypes } from '../../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../../hooks'
import { smartFunctionService } from '../../../../../services'
import notificationService from '../../../../../services/notificationService'
import {
  AlgorithmRun,
  DelConfigValues,
} from '../../../../../store/reducers/workspaceReducer'
import DelConfigurationModalFooter from './footer/DelConfigurationModalFooter'
import DelConfigurationModalInputStep from './steps/DelConfigurationModalInputStep'
import DelConfigurationModalReviewStep from './steps/DelConfigurationModalReviewStep'
import DelConfigurationModalTypeStep from './steps/DelConfigurationModalTypeStep'

const DropDownItem = styled.div`
  width: 200px;
`

interface DelConfigurationModalProps {
  // change this when backend is updated
  config?: DelConfigValues
}

export interface DelConfigurationFormValues {
  fileId: string
  fileName: string
  reviewType: string
  dinFileId?: string
  dinFileName?: string
  dinClientFileId?: string
  dinClientFileName?: string
  tableAHcFileId?: string
  tableAHcFileName?: string
  tableAComprehensiveFileId?: string
  tableAComprehensiveFileName?: string
}

const DelConfigurationModal = ({ config }: DelConfigurationModalProps) => {
  const [form] = Form.useForm<DelConfigurationFormValues>()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)
  const [isSelected, setIsSelected] = useState<boolean>(false)
  const { workspaceId } = useParams()
  const navigate = useNavigate()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId: workspaceId,
    preventFetch: true,
  })

  const resetValues = useCallback(() => {
    form.resetFields()
  }, [form])

  const handleOk = () => {
    setConfirmLoading(true)
  }

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
    setCurrentStep(0)
  }, [setCurrentStep, resetValues])

  const getParams = useCallback(
    (reviewType: string): DelConfigValues | undefined => {
      switch (reviewType) {
        case delConfigurationModalTypes.DIN:
          return {
            reviewType,
            dinFileId: form.getFieldValue('dinFileId'),
            dinClientFileId: form.getFieldValue('dinClientFileId'),
          }
        case delConfigurationModalTypes.TABLE_A:
          return {
            reviewType,
            tableAHcFileId: form.getFieldValue('tableAHcFileId'),
            tableAComprehensiveFileId: form.getFieldValue(
              'tableAComprehensiveFileId'
            ),
          }
        default:
          return undefined
      }
    },
    [form]
  )

  const handleConfirm = useCallback(() => {
    setConfirmLoading(false)
    const reviewType = form.getFieldValue('reviewType')
    const params = getParams(reviewType)
    // check params
    if (!params) {
      notificationService.notificationError(
        t('smartFunctionDetailsPage.runError')
      )
      return
    }

    smartFunctionService
      .runAlgorithm({
        id: selectedWorkspace?.id || '',
        navigate,
        config: params,
      })
      .then((response: AlgorithmRun) => {
        refreshWorkspaceDetails()
        notificationService.notificationSuccess(
          t('smartFunctionDetailsPage.runStarted')
        )
        setOpen(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
      })
  }, [form, navigate, refreshWorkspaceDetails, selectedWorkspace, getParams])

  const defaultValues = useMemo(
    () => ({
      fileId: undefined,
      fileName: undefined,
      dinFileId: undefined,
      dinFileName: undefined,
      dinClientFileId: undefined,
      dinClientFileName: undefined,
      tableAHcFileId: undefined,
      tableAHcFileName: undefined,
      tableAComprehensiveFileId: undefined,
      reviewType: delConfigurationModalTypes.DIN, // default value
    }),
    []
  )

  const stepItems = [
    {
      title: t('delConfigurationModal.steps.typeStep.title'),
    },
    {
      title: t('delConfigurationModal.steps.input.title'),
    },
    {
      title: t('delConfigurationModal.steps.review.title'),
    },
  ]

  const steps: ReactNode[] = useMemo(
    () => [
      <DelConfigurationModalTypeStep form={form} />,
      <DelConfigurationModalInputStep
        form={form}
        setIsSelected={setIsSelected}
      />,
      <DelConfigurationModalReviewStep form={form} />,
    ],
    [form]
  )
  const resetFormFiles = useCallback(() => {
    const fields = [
      'dinClientFileId',
      'dinClientFileName',
      'dinFileId',
      'dinFileName',
      'tableAHcFileId',
      'tableAHcFileName',
      'tableAComprehensiveFileId',
      'tableAComprehensiveFileName',
    ]
    const resetFields = fields.reduce(
      (acc, field) => ({ ...acc, [field]: undefined }),
      {}
    )
    form.setFieldsValue(resetFields)
  }, [form])

  const onNextClick = useCallback(() => {
    if (currentStep === 0) {
      resetFormFiles()
      setIsSelected(false)
    }
    setCurrentStep(currentStep + 1)
  }, [currentStep, resetFormFiles])

  const footers: ReactNode[] = useMemo(
    () => [
      <DelConfigurationModalFooter
        onCancel={handleCancel}
        onNext={onNextClick}
      />,
      <DelConfigurationModalFooter
        onCancel={handleCancel}
        onPrevious={() => setCurrentStep(currentStep - 1)}
        onNext={onNextClick}
        nextDisabled={!isSelected}
      />,
      <DelConfigurationModalFooter
        onCancel={handleCancel}
        onPrevious={() => setCurrentStep(currentStep - 1)}
        onSubmit={handleConfirm}
      />,
    ],
    [currentStep, handleCancel, handleConfirm, isSelected, onNextClick]
  )

  return (
    <>
      <DropDownItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {t(
          'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.configureRun'
        )}
      </DropDownItem>
      <ModalBase
        destroyOnClose
        key="main"
        title={t('delConfigurationModal.modalTitle')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        footer={footers[currentStep]}
      >
        <Steps size="small" current={currentStep} items={stepItems} />
        <Form form={form} layout="vertical" initialValues={defaultValues}>
          <ModalContentContainer>{steps[currentStep]}</ModalContentContainer>
        </Form>
      </ModalBase>
    </>
  )
}

export default DelConfigurationModal
