import { AimOutlined, DeleteOutlined, SaveOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate, useParams } from 'react-router'
import { styled } from 'styled-components'
import { BackButton, ConfirmDeleteModal, Spacer } from '../../../components'
import { useOfrDocumentTemplate } from '../../../hooks'
import { routePaths } from '../../RootPage'

export const HeaderContainer = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  padding: 16px;
  gap: 16px;
`

export const TitleContainer = styled.div`
  display: flex;
  margin-top: auto;
  margin-bottom: auto;
  gap: 8px;
  color: ${(props) => props.theme.token.colorText};
`

interface OfrDocumentTemplateDetailsPageHeaderProps {
  onSave: () => void
}

const OfrDocumentTemplateDetailsPageHeader = ({
  onSave,
}: OfrDocumentTemplateDetailsPageHeaderProps) => {
  const navigate = useNavigate()
  const { formId } = useParams()
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false)
  const [deleteLoading, setDeleteLoading] = useState(false)

  const { deleteOfrDocumentTemplate } = useOfrDocumentTemplate({})

  const handleCancel = () => {
    setConfirmDeleteModalOpen(false)
  }

  const handleConfirmDelete = () => {
    setDeleteLoading(true)
    setConfirmDeleteModalOpen(false)
    setDeleteLoading(true)
    if (formId) {
      deleteOfrDocumentTemplate({
        documentId: formId,
        navigate: null,
      })
    }
    setConfirmDeleteModalOpen(false)

    setDeleteLoading(false)
    navigate(routePaths.OFR_DOCUMENT_TEMPLATE_LIST)
  }

  return (
    <HeaderContainer>
      <BackButton
        url={routePaths.OFR_DOCUMENT_TEMPLATE_LIST}
        tooltipMessage={t('ofrDocumentTemplateDetailsPage.backButtonTooltip')}
      />
      <TitleContainer>
        <AimOutlined />
        <div>{t('Configuration')}</div>
      </TitleContainer>
      <Spacer />
      <Button
        danger
        icon={<DeleteOutlined />}
        onClick={() => {
          setConfirmDeleteModalOpen(true)
        }}
        loading={deleteLoading}
      >
        {t('ofrDocumentTemplateDetailsPage.deleteDocumentTemplateButton')}
      </Button>
      <ConfirmDeleteModal
        open={confirmDeleteModalOpen}
        onConfirm={() => handleConfirmDelete()}
        onCancel={handleCancel}
        loading={deleteLoading}
        confirmButtonLabel={
          t('ofrDocumentTemplateListPage.confirmDeleteModal.yesButton') || ''
        }
      >
        {t('ofrDocumentTemplateListPage.confirmDeleteModal.content')}
      </ConfirmDeleteModal>
      <Button
        type="primary"
        icon={<SaveOutlined />}
        htmlType="submit"
        loading={false}
        onClick={onSave}
      >
        {t('saveButton')}
      </Button>
    </HeaderContainer>
  )
}

export default OfrDocumentTemplateDetailsPageHeader
