import { FileTextOutlined } from '@ant-design/icons'
import { Table, Tag } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useEffect, useMemo } from 'react'
import Moment from 'react-moment'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import {
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../../components'
import {
  algorithmRunStatuses,
  BACK_END_PULL_INTERVAL,
  dateFormat,
  NO_DATA_PLACEHOLDER,
  workspaceTypes,
} from '../../../constants/constants'
import useWorkspaceDetails from '../../../hooks/useWorkspaceDetails'
import useWorkspaces from '../../../hooks/useWorkspaces'
import {
  AlgorithmRun,
  clearSelectedAlgorithmRun,
  QualityReviewConfigValues,
} from '../../../store/reducers/workspaceReducer'
import { routePaths } from '../../RootPage'
import SmartFunctionActionDropdown from '../../SmartFunctions/components/SmartFunctionActionDropdown'

const ListPanelLayout = styled.div`
  height: calc(100vh - 124px);
  border-radius: 16px 16px 0px 0px;
`

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 124px);
`

interface ExtendedAlgorithmRun extends AlgorithmRun {
  key: string
  smartFunctionName?: string
  algorithmRunIndex?: number
  statusText?: string
  isStatusOngoing?: boolean
}

interface WorkspaceSmartFunctionPanelProps {
  singleWorkspace?: boolean
}

const WorkspaceSmartFunctionPanel = ({
  singleWorkspace,
}: WorkspaceSmartFunctionPanelProps) => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { workspaceId } = useParams()
  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const { loading } = useWorkspaces({ workspaceId, preventFetch: true })
  const isStatusOngoing = useMemo(
    () =>
      !!selectedWorkspace?.algorithmRuns?.find(
        (run) =>
          run.runStatus === algorithmRunStatuses.ONGOING_INGESTION ||
          run.runStatus === algorithmRunStatuses.ONGOING_AI
      ),
    [selectedWorkspace?.algorithmRuns]
  )
  const formattedData: ExtendedAlgorithmRun[] = useMemo(() => {
    const defaultRun: ExtendedAlgorithmRun = {
      id: '0',
      key: '0',
      workspace: selectedWorkspace?.title || '',
      dataSource:
        typeof selectedWorkspace?.dataSource === 'string'
          ? selectedWorkspace?.dataSource
          : selectedWorkspace?.dataSource?.id,
      runStatus: algorithmRunStatuses.READY,
      statusText:
        t(`workspaceDetailsPage.status.${algorithmRunStatuses.READY}`) ||
        NO_DATA_PLACEHOLDER,
      smartFunctionName: selectedWorkspace?.algorithmName || '',
    }
    const runs = selectedWorkspace?.algorithmRuns
    return !!runs && runs.length > 0
      ? runs.map((run: AlgorithmRun, index: number) => ({
          ...run,
          key: run.id,
          smartFunctionName: selectedWorkspace.algorithmName,
          algorithmRunIndex: index + 1,
          statusText: run?.runStatus
            ? t(`workspaceDetailsPage.status.${run?.runStatus}`) ||
              NO_DATA_PLACEHOLDER
            : NO_DATA_PLACEHOLDER,
          isStatusOngoing,
        }))
      : [defaultRun]
  }, [selectedWorkspace, isStatusOngoing])

  useEffect(() => {
    const interval = setInterval(() => {
      refreshWorkspaceDetails()
    }, BACK_END_PULL_INTERVAL)

    return () => clearInterval(interval) // Clear the interval to prevent memory leaks.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns: ColumnsType<ExtendedAlgorithmRun> = [
    {
      title: t('Name'),
      dataIndex: 'smartFunctionName',
      key: 'smartFunctionName',
      width: '40%',
      render: (text, record) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },
    {
      title: t('Date'),
      dataIndex: 'actionDate',
      key: 'actionDate',
      width: '15%',
      render: (actionDate) => (
        <>
          {actionDate ? (
            <Moment local format={dateFormat.PRIMARY}>
              {actionDate}
            </Moment>
          ) : (
            NO_DATA_PLACEHOLDER
          )}
        </>
      ),
    },
    {
      title: t('workspaceDetailsPage.workspaceSmartFunctionPanel.runNumber'),
      key: 'runCount',
      render: (text, record) => (
        <>
          {t('smartFunctionDetailsPage.runCount', {
            count: record.algorithmRunIndex || 0,
          })}
        </>
      ),
    },
    {
      title: t('Status'),
      dataIndex: 'statusText',
      key: 'statusText',
      render: (text: string) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: '',
      key: 'action',
      align: 'right',
      render: (text, record) => (
        <>
          <SmartFunctionActionDropdown key={record.id} algorithmRun={record} />
        </>
      ),
    },
  ]

  if (selectedWorkspace?.typeCode === workspaceTypes.OFR_QR) {
    columns.splice(1, 0, {
      title: t('workspaceDetailsPage.workspaceSmartFunctionPanel.template'),
      dataIndex: 'config',
      key: 'config',
      width: '15%',
      render: (config: QualityReviewConfigValues) => {
        const fileName = config?.documentTemplateName
        return (
          <>
            {fileName ? (
              <Tag
                icon={<FileTextOutlined />}
                color="default"
                style={{
                  textOverflow: 'ellipsis',
                }}
              >
                {fileName}
              </Tag>
            ) : (
              NO_DATA_PLACEHOLDER
            )}
          </>
        )
      },
    })
  }

  const handleRecordClick = (record: ExtendedAlgorithmRun) => {
    if (record.id !== '0') {
      dispatch(clearSelectedAlgorithmRun())
      let baseRoute = ''
      switch (selectedWorkspace?.typeCode) {
        case workspaceTypes.BIOMARK:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_BIOMARK
          break
        case workspaceTypes.REFAI:
          baseRoute = singleWorkspace
            ? routePaths.SINGLE_WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
            : routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REFAI
          break
        case workspaceTypes.REGSUB:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_REGSUB
          break
        case workspaceTypes.DEL:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_DEL
          break
        case workspaceTypes.OFR_QR:
          baseRoute = routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR
          break
      }

      navigate(`${baseRoute}/${selectedWorkspace?.id}/${record.id}`)
    }
  }

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <ListPanelLayout>
      <Table
        rowClassName={(record) =>
          record.id !== '0' &&
          record.runStatus === algorithmRunStatuses.COMPLETE
            ? 'page-list-table-row clickable'
            : 'page-list-table-row'
        }
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRecordClick(record)
            },
          }
        }}
        scroll={{ y: 'calc(100vh - 155px)' }}
      />
    </ListPanelLayout>
  )
}

export default WorkspaceSmartFunctionPanel
