import { Form, FormInstance, Input } from 'antd'
import { t } from 'i18next'
import { memo, useMemo } from 'react'
import styled from 'styled-components'

const HeaderTextContainer = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-top: 16px;
  margin-bottom: 16px;
  line-height: '12px';
`

interface CreateWorkspaceModalDefinitionStepProps {
  form: FormInstance
}

const CreateWorkspaceModalDefinitionStep = memo(
  ({ form }: CreateWorkspaceModalDefinitionStepProps) => {
    const title = useMemo(() => form.getFieldValue('title') || '', [form])
    const description = useMemo(
      () => form.getFieldValue('description') || '',
      [form]
    )

    return (
      <>
        <HeaderTextContainer>
          {t(
            'createWorkspaceModal.definitionStep.subHeader' ||
              ''
          )}
        </HeaderTextContainer>
        <Form.Item
          name="title"
          label={t(
            'createWorkspaceModal.definitionStep.titleFormHeader' ||
              ''
          )}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
        >
          <Input
            value={title}
            placeholder={
              t(
                'createWorkspaceModal.definitionStep.titleFormPlaceholder'
              ) || ''
            }
          />
        </Form.Item>
        <Form.Item
          name="description"
          label={t(
            'createWorkspaceModal.definitionStep.descriptionFormHeader'
          )}
        >
          <Input.TextArea
            value={description}
            placeholder={
              t(
                'createWorkspaceModal.definitionStep.descriptionFormPlaceholder'
              ) || ''
            }
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </>
    )
  }
)

export default CreateWorkspaceModalDefinitionStep
