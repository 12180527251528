import { Select } from 'antd'
import { t } from 'i18next'
import { memo, useMemo, useState, useEffect } from 'react'
import { useRoles } from '../hooks'
import { Role } from '../store/reducers/roleReducer'
import { SelectOption } from '../types/generalTypes'

interface SelectRoleProps {
  value?: string
  onChange?: (value: string) => void
  style?: Object
  disabled?: boolean
  preventFetch?: boolean
}

const SelectRole = memo(
  ({
    onChange,
    value,
    style,
    disabled,
    preventFetch = false,
  }: SelectRoleProps) => {
    const { roleList, loading } = useRoles({ preventFetch })
    const options: SelectOption[] = useMemo(
      () =>
        roleList
          ?.filter((role: Role) => !!role.id)
          .map((role: Role) => ({
            label: role.title || '',
            value: role.id || '',
          })),
      [roleList]
    )
    const [selectedValue, setSelectedValue] = useState(value || undefined)

    useEffect(() => {
      setSelectedValue(value)
    }, [value])

    const handleChange = (value: string) => {
      setSelectedValue(value)
      if (onChange) {
        onChange(value)
      }
    }

    return (
      <Select
        style={style}
        onChange={handleChange}
        value={selectedValue}
        options={options || undefined}
        loading={loading}
        placeholder={t('selectRole.placeholder')}
        disabled={disabled}
      />
    )
  }
)

export default SelectRole
