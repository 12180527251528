import { Select } from 'antd'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useOfrDocumentTemplate } from '../hooks'
import { OfrDocumentTemplate } from '../store/reducers/ofrDocumentTemplateReducer'

export interface OfrDocumentSelectOption {
  key: string
  value: string
  label: string
  disabled?: boolean
  documentId: string
}

interface SelectOfrDocumentProps {
  onChange?: (selectedOptions: OfrDocumentSelectOption | undefined) => void
  disabled?: boolean
  dataSourceId?: string
  initalDocument?: OfrDocumentSelectOption
}

const SelectOfrDocumentTemplate = memo(
  ({
    onChange,
    disabled,
    dataSourceId,
    initalDocument,
  }: SelectOfrDocumentProps) => {
    const { ofrDocumentTemplateList, loading } = useOfrDocumentTemplate({
      preventFetch: false,
    })

    const [selectedDocuments, setSelectedDocuments] = useState<
      OfrDocumentSelectOption | undefined | null
    >(undefined)

    const mapDocumentsToOptions = (
      docs: OfrDocumentTemplate[]
    ): OfrDocumentSelectOption[] => {
      return docs.map((doc) => ({
        key: doc.id,
        value: doc.id,
        label: doc.title,
        documentId: doc.documentId,
      }))
    }

    const options: OfrDocumentSelectOption[] = useMemo(() => {
      if (!ofrDocumentTemplateList) return []
      const filteredDocuments = dataSourceId
        ? ofrDocumentTemplateList.filter(
            (doc) => doc.dataSource === dataSourceId
          )
        : ofrDocumentTemplateList

      return mapDocumentsToOptions(filteredDocuments)
    }, [ofrDocumentTemplateList, dataSourceId])

    useEffect(() => {
      if (initalDocument && options.length > 0) {
        // find document based on documentId
        setSelectedDocuments(initalDocument)
      }
    }, [initalDocument, options])

    const handleChange = useCallback(
      (option: OfrDocumentSelectOption) => {
        const selectedOption = options.filter((opt) =>
          option.value.includes(opt.value)
        )
        setSelectedDocuments(option)
        if (onChange && selectedOption.length > 0) {
          const option = selectedOption[0]
          onChange({
            key: option.key,
            value: option?.value,
            label: option?.label,
            documentId: option?.documentId,
          })
        }
      },
      [onChange, options]
    )

    return (
      <Select
        size="large"
        style={{ width: '100%' }}
        options={options}
        disabled={disabled}
        value={selectedDocuments}
        onChange={handleChange}
        loading={loading}
        labelInValue
      />
    )
  }
)

export default SelectOfrDocumentTemplate
