import { Button } from 'antd'
import { t } from 'i18next'
import React from 'react'

interface WorkspaceSettingsFooterProps {
  handleCancel: () => void
  handleSubmit: () => void
  icon: React.ReactNode
  confirmButtonText: string
}

const WorkspaceSettingsFooter = ({
  handleCancel,
  handleSubmit,
  icon,
  confirmButtonText,
}: WorkspaceSettingsFooterProps) => {
  return (
    <>
      <Button key="back" onClick={handleCancel} type="text">
        {t('Cancel')}
      </Button>
      <Button icon={icon} key="submit" type="primary" onClick={handleSubmit}>
        {confirmButtonText}
      </Button>
    </>
  )
}

export default WorkspaceSettingsFooter
