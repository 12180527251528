import { FileTextOutlined, InboxOutlined } from '@ant-design/icons'
import { Input, Tag, theme } from 'antd'
import { t } from 'i18next'
import { ReactNode, useMemo } from 'react'
import Moment from 'react-moment'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { AllowClearIcon } from '../../../components'
import BackButton from '../../../components/BackButton'
import BoldText from '../../../components/display/BoldText'
import {
  NO_DATA_PLACEHOLDER,
  algorithmRunStatuses,
  dateFormat,
  workspaceTypes,
} from '../../../constants/constants'
import { useAlgorithmRunDetails, useWorkspaceDetails } from '../../../hooks'
import {
  AlgorithmRunConfigValues,
  QualityReviewConfigValues,
} from '../../../store/reducers/workspaceReducer'
import SmartFunctionActionDropdown from './SmartFunctionActionDropdown'

const { Search } = Input

export const SmartFunctionSubHeader = styled.div`
  display: flex;
  align-items: flex-start;
  width: 100%;
  margin-bottom: 12px;
  gap: 16px;
`

export const SmartFunctionMainSubHeader = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`

export const SmartFunctionSubHeaderLine = styled.div`
  display: flex;
  flex: 1;
  padding: 8px;
  gap: 16px;
  color: ${(props) => props.theme.token.colorText};
`

export const SmartFunctionSubHeaderCell = styled.div`
  flex: 50%;
  display: flex;
  align-items: center;
`

export const SmartFunctionSubHeaderCellContainer = styled.div`
  flex: 50%;
  display: flex;
`

export const SmartFunctionSubHeaderCellSection = styled.div`
  flex: auto;
  color: ${(props) => props.theme.token.colorText};
`

const LabelBlueContainer = styled.div`
  display: flex;
  padding: 1px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 4px;
  border: 1px solid var(--blue-3, #91caff);
  background: var(--blue-1, #e6f4ff);
  width: fit-content;
  color: black;
`

const TotalCellContainer = styled.div`
  display: flex;
  align-content: center;
  flex-wrap: wrap;
  gap: 8px;
`

const SmartFunctionHeaderFirstRow = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
`

const { useToken } = theme

interface SmartFunctionHeaderLayoutProps {
  backButtonUrl: string
  totalFound: number
  onSearchAction?: (value: string) => void
  onSearchChange?: (event: any) => void
  search?: ReactNode
  searchPlaceholder?: string
  children?: ReactNode
}

const SmartFunctionHeader = ({
  backButtonUrl,
  children,
  onSearchAction,
  onSearchChange,
  search,
  searchPlaceholder,
  totalFound,
}: SmartFunctionHeaderLayoutProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const { token } = useToken()
  const { selectedAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
    preventFetch: true,
  })
  const { selectedWorkspace } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const algorithmRunIndex = useMemo(() => {
    return selectedAlgorithmRun
      ? (selectedWorkspace?.algorithmRuns
          ?.map((run) => run.id)
          .indexOf(selectedAlgorithmRun.id) || 0) + 1
      : 0
  }, [selectedAlgorithmRun, selectedWorkspace])

  // Type guard function to check if config is of type QualityReviewConfigValues
  const isQualityReviewConfig = (
    config?: AlgorithmRunConfigValues
  ): config is QualityReviewConfigValues => {
    if (!config) return false
    return 'documentTemplateName' in config
  }

  return (
    <SmartFunctionSubHeader>
      <BackButton
        url={backButtonUrl}
        tooltipMessage={t('smartFunctionDetailsPage.backButtonTooltip')}
      />

      <SmartFunctionMainSubHeader>
        <SmartFunctionHeaderFirstRow>
          <SmartFunctionSubHeaderLine>
            <SmartFunctionSubHeaderCell>
              <BoldText>
                {selectedWorkspace?.algorithmName || NO_DATA_PLACEHOLDER}
              </BoldText>
            </SmartFunctionSubHeaderCell>
            <SmartFunctionSubHeaderCellContainer>
              <SmartFunctionSubHeaderCellSection>
                {selectedAlgorithmRun?.actionDate ? (
                  <Moment
                    local
                    date={selectedAlgorithmRun?.actionDate}
                    format={dateFormat.PRIMARY}
                  />
                ) : (
                  NO_DATA_PLACEHOLDER
                )}
              </SmartFunctionSubHeaderCellSection>
              <SmartFunctionSubHeaderCellSection>
                {t('smartFunctionDetailsPage.runCount', {
                  count: algorithmRunIndex || 0,
                })}
              </SmartFunctionSubHeaderCellSection>
              <SmartFunctionSubHeaderCellSection>
                {selectedAlgorithmRun?.runStatus
                  ? t(
                      `workspaceDetailsPage.status.${selectedAlgorithmRun?.runStatus}`
                    )
                  : NO_DATA_PLACEHOLDER}
              </SmartFunctionSubHeaderCellSection>
            </SmartFunctionSubHeaderCellContainer>
          </SmartFunctionSubHeaderLine>
          <SmartFunctionActionDropdown
            key={selectedAlgorithmRun?.id}
            algorithmRun={selectedAlgorithmRun}
          />
        </SmartFunctionHeaderFirstRow>
        <SmartFunctionSubHeaderLine>
          <SmartFunctionSubHeaderCell>
            <LabelBlueContainer>
              {selectedWorkspace?.dataSource.title || NO_DATA_PLACEHOLDER}
            </LabelBlueContainer>
            {selectedWorkspace?.typeCode === workspaceTypes.OFR_QR && (
              <Tag
                icon={<FileTextOutlined />}
                color="default"
                style={{
                  marginLeft: '8px',
                }}
              >
                {isQualityReviewConfig(selectedAlgorithmRun?.config)
                  ? selectedAlgorithmRun.config.documentTemplateName ||
                    NO_DATA_PLACEHOLDER
                  : NO_DATA_PLACEHOLDER}
              </Tag>
            )}
          </SmartFunctionSubHeaderCell>
          <SmartFunctionSubHeaderCell>
            {selectedAlgorithmRun?.actionBy?.displayName || NO_DATA_PLACEHOLDER}
          </SmartFunctionSubHeaderCell>
        </SmartFunctionSubHeaderLine>
        {selectedAlgorithmRun?.runStatus === algorithmRunStatuses.COMPLETE &&
          selectedWorkspace?.typeCode !== workspaceTypes.DEL && (
            <SmartFunctionSubHeaderLine>
              {children}
              <TotalCellContainer>
                <InboxOutlined />
                <div>
                  {totalFound} {t('Found')}
                </div>
              </TotalCellContainer>
              <Search
                placeholder={
                  searchPlaceholder || t('smartFunctionDetailsPage.defaultSearchPlaceholder') || ''
                }
                allowClear={{
                  clearIcon: <AllowClearIcon />,
                }}
                onSearch={onSearchAction}
                onChange={onSearchChange}
                style={{ flex: 1, backgroundColor: token.colorBgBase }}
              />
            </SmartFunctionSubHeaderLine>
          )}
      </SmartFunctionMainSubHeader>
    </SmartFunctionSubHeader>
  )
}

export default SmartFunctionHeader
