import { ConfigProvider } from 'antd'
import ReactDOM from 'react-dom/client'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import App from './App'
import { useTheme } from './hooks'
import './i18n/i18n'
import './index.css'
import './notifications.css'
import reportWebVitals from './reportWebVitals'
import store from './store'
import './table.css'
import './tree.css'

const ThemeConfigProvider = ({ children }: { children: React.ReactNode }) => {
  const { themeConfiguration } = useTheme() // cant call this in Root Component

  return (
    <ConfigProvider theme={themeConfiguration}>
      <ThemeProvider theme={themeConfiguration}>{children}</ThemeProvider>
    </ConfigProvider>
  )
}

const RootComponent = () => {
  return (
    <BrowserRouter>
      <Provider store={store}>
        <ThemeConfigProvider>
          <App />
        </ThemeConfigProvider>
      </Provider>
    </BrowserRouter>
  )
}

const rootElement = document.getElementById('root') as HTMLElement

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement)
  root.render(<RootComponent />)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
