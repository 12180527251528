import { Form, Radio, RadioChangeEvent, Space } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { SelectParticipant } from '../../../../components'
import { privacyTypes } from '../../../../constants/constants'
import { RootState } from '../../../../store'

interface CreateDataStorePrivacyStepProps {
  form: FormInstance
}

const CreateDataStorePrivacyStep = memo(
  ({ form }: CreateDataStorePrivacyStepProps) => {
    const [isSelectDisabled, setIsSelectDisabled] = useState(
      form.getFieldValue('visibility') !== privacyTypes.PRIVATE
    )

    const currentUser = useSelector(
      (state: RootState) => state.user.currentUser
    )
    const [initialParticipants, setInitialParticipants] = useState<string[]>(
      form.getFieldValue('visibility') === privacyTypes.PRIVATE
        ? form.getFieldValue('participants')
        : []
    )

    const handleRadioChange = useCallback(
      (e: RadioChangeEvent) => {
        const isPrivate = e.target.value === privacyTypes.PRIVATE
        const participants = isPrivate && currentUser ? [currentUser.id] : []
        setIsSelectDisabled(!isPrivate)
        setInitialParticipants(participants)
        form.setFieldsValue({ participants })
      },
      [currentUser, form]
    )

    useEffect(() => {
      form.validateFields(['participants'])
    }, [isSelectDisabled, form])

    return (
      <div>
        <Form.Item
          label={t('createDataStoreModal.privacyStep.description')}
          name="visibility"
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          initialValue={
            form.getFieldValue('visibility') || privacyTypes.PRIVATE
          }
        >
          <Radio.Group buttonStyle="solid" onChange={handleRadioChange}>
            <Space direction="vertical">
              <Radio value={privacyTypes.PRIVATE}>
                {t('createDataStoreModal.privacyStep.privateOption')}
              </Radio>
              <Radio value={privacyTypes.ORGANIZATION}>
                {t('createDataStoreModal.privacyStep.organizationalOption')}
              </Radio>
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={t('createDataStoreModal.privacyStep.participantSelectHeader')}
          name="participants"
          rules={[
            {
              validator: (_, value) => {
                if (!isSelectDisabled && (!value || value.length === 0)) {
                  return Promise.reject(t('errorMessage.isRequired') || '')
                }
                return Promise.resolve()
              },
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={
            t('createDataStoreModal.privacyStep.extraInputPlaceholder') || ''
          }
        >
          <SelectParticipant
            disabled={isSelectDisabled}
            initialParticipants={initialParticipants}
          />
        </Form.Item>
      </div>
    )
  }
)
export default CreateDataStorePrivacyStep
