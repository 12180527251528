import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { SectionLoadingState } from '../../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import { BiomarkersRunResult } from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

export interface BiomarkTableRow {
  key: number
  index?: number
  name: string
  absoluteImportance: number
  relativeImportance: number
  cumulativeImportance?: number
}

export interface BiomarkSmartFunctionPanelProps {
  search: string
}

const BiomarkSmartFunctionPanel = ({
  search,
}: BiomarkSmartFunctionPanelProps) => {
  const { workspaceId, algorithmRunId } = useParams()

  const { selectedAlgorithmRun, loading } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
    preventFetch: true,
  })

  const runResult = useMemo(
    () => selectedAlgorithmRun?.runResult as BiomarkersRunResult,
    [selectedAlgorithmRun?.runResult]
  )

  const getCumulativeImportance = useCallback(
    (index: number) =>
      runResult?.featureRanking
        .slice(0, index + 1)
        .map((featureRanking) => featureRanking.importancePercentage)
        .reduce((total, relativeImportance) => total + relativeImportance),

    [runResult?.featureRanking]
  )

  const formattedData: BiomarkTableRow[] =
    useMemo(
      () =>
        runResult?.featureRanking &&
        runResult?.featureRanking
          .filter((featureRanking) =>
            !!search ? featureRanking.columnName.includes(search || '') : true
          )
          .map(
            (featureRanking, index) =>
              ({
                id: featureRanking.index,
                key: featureRanking.index,
                index: featureRanking.index,
                name: featureRanking.columnName,
                absoluteImportance: featureRanking.importance,
                relativeImportance: featureRanking.importancePercentage,
                cumulativeImportance: getCumulativeImportance(index),
              } as BiomarkTableRow)
          ),
      [runResult, getCumulativeImportance, search]
    ) || []

  const formatNumber = useCallback(
    (number: number | string) =>
      number !== undefined
        ? number.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })
        : NO_DATA_PLACEHOLDER,
    []
  )

  const columns: ColumnsType<BiomarkTableRow> = [
    {
      title: t('smartFunctionDetailsPage.biomarkTableTitles.index'),
      dataIndex: 'index',
      key: 'index',
      width: '15%',
      render: (text, record) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t(
        'smartFunctionDetailsPage.biomarkTableTitles.absoluteImportance'
      ),
      dataIndex: 'absoluteImportance',
      key: 'absoluteImportance',
      width: '15%',
      render: (val, record) => <>{formatNumber(val)}</>,
    },
    {
      title: t(
        'smartFunctionDetailsPage.biomarkTableTitles.relativeImportance'
      ),
      dataIndex: 'relativeImportance',
      key: 'relativeImportance',
      width: '15%',
      render: (val, record) => <>{formatNumber(val)}</>,
    },
    {
      title: t(
        'smartFunctionDetailsPage.biomarkTableTitles.cumulativeImportance'
      ),
      dataIndex: 'cumulativeImportance',
      key: 'cumulativeImportance',
      width: '15%',
      render: (val, record) => <>{formatNumber(val)}</>,
    },
    {
      title: t('smartFunctionDetailsPage.biomarkTableTitles.name'),
      dataIndex: 'name',
      key: 'name',
      width: '40%',
      render: (text, record) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <Table
        rowClassName="page-list-table-row"
        columns={columns}
        dataSource={formattedData}
        pagination={false}
      />
    </SmartFunctionListPanelLayout>
  )
}

export default BiomarkSmartFunctionPanel
