import React from 'react'
import { styled } from 'styled-components'

const TestPageContainer = styled.div`
  display: flex;
  padding: 16px;
  flex-direction: column;
`

const TestPageHeader = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  align-content: center;
  justify-content: center;
  font-weight: 600;
  font-size: 24px;
  position: fixed;
  margin-top: -32px;
`

const TestPageContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  padding-top: 16px;
  justify-content: center;
`

const TestIFramePage = () => {
  return (
    <TestPageContainer>
      <TestPageHeader>Test page for iframe</TestPageHeader>
      <TestPageContent>
        <iframe
          src="http://dev.genaiz.com/loginSso"
          title="Genaiz"
          height="600"
          width="700"
        ></iframe>
      </TestPageContent>
    </TestPageContainer>
  )
}

export default TestIFramePage
