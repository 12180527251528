import { Divider } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useMemo } from 'react'
import {
  ModalContentContainer,
  ModalTextDescription,
  ModalTextLabel,
  SelectParticipant,
} from '../../../../components'
import { privacyTypes } from '../../../../constants/constants'

interface CreateDataSourceReviewStepProps {
  form: FormInstance
}

const CreateDataSourceReviewStep = memo(
  ({ form }: CreateDataSourceReviewStepProps) => {
    const visibility = useMemo(
      () => form.getFieldValue('visibility') || '',
      [form]
    )

    const participants = useMemo(
      () => form.getFieldValue('participants') || [],
      [form]
    )

    const title = useMemo(() => form.getFieldValue('title') || '', [form])
    const description = useMemo(
      () => form.getFieldValue('description') || '',
      [form]
    )

    const contactEmail = useMemo(
      () => form.getFieldValue('contactEmail') || '',
      [form]
    )
    const contactName = useMemo(
      () => form.getFieldValue('contactName') || '',
      [form]
    )

    const sourceName = useMemo(
      () => form.getFieldValue('sourceName') || '',
      [form]
    )

    return (
      <ModalContentContainer>
        <Divider orientation="left" plain>
          {t('createDataSourceModal.reviewStep.intendedDefinition')}
        </Divider>
        <ModalTextLabel>
          {t('createDataSourceModal.reviewStep.dataSourceName')}
        </ModalTextLabel>
        <ModalTextDescription>{title}</ModalTextDescription>
        <ModalTextLabel>
          {t('createDataSourceModal.reviewStep.description')}
        </ModalTextLabel>
        <ModalTextDescription>{description}</ModalTextDescription>
        <Divider orientation="left" plain>
          {t('createDataSourceModal.reviewStep.specifedType')}
        </Divider>
        <ModalTextLabel>
          {t('createDataSourceModal.reviewStep.dataSourceType')}
        </ModalTextLabel>
        <ModalTextDescription>{sourceName}</ModalTextDescription>
        <Divider orientation="left" plain>
          {t('createDataSourceModal.reviewStep.selectedPrivacy')}
        </Divider>
        <ModalTextLabel>
          {t('createDataSourceModal.reviewStep.privacySettings')}
        </ModalTextLabel>
        <ModalTextDescription>{t(visibility) || ''}</ModalTextDescription>
        {visibility === privacyTypes.PRIVATE && (
          <>
            <ModalTextLabel>
              {t('createDataSourceModal.reviewStep.participants')}
            </ModalTextLabel>
            <SelectParticipant disabled initialParticipants={participants} />
          </>
        )}

        <Divider orientation="left" plain>
          {t('createDataSourceModal.reviewStep.contactInformation')}
        </Divider>
        <ModalTextLabel>
          {t('createDataSourceModal.reviewStep.contactName')}
        </ModalTextLabel>
        <ModalTextDescription>{contactName}</ModalTextDescription>
        <ModalTextLabel>
          {t('createDataSourceModal.reviewStep.contactEmail')}
        </ModalTextLabel>
        <ModalTextDescription>{contactEmail}</ModalTextDescription>
      </ModalContentContainer>
    )
  }
)

export default CreateDataSourceReviewStep
