import styled from 'styled-components'

const Row = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  height: 46px;
  padding: 8px;
`

const StyledText = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: rgba(0, 0, 0, 0.25);
`

interface SmartFucntionProgressPanelProps {
  text: string
}

const SmartFunctionProgressPanel = ({
  text,
}: SmartFucntionProgressPanelProps) => {
  return (
    <Row>
      <StyledText>{text}</StyledText>
    </Row>
  )
}

export default SmartFunctionProgressPanel
