import { ReloadOutlined } from '@ant-design/icons'
import { Button, Form, Select, Spin, Typography } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import useWorkspaceFolders from '../../../../../../hooks/useWorkspaceFolders'
import { SelectOption } from '../../../../../../types/generalTypes'
import { DelConfigurationModalInputStepProps } from './DelConfigurationModalInputStep'

const { Text } = Typography

const DelConfigurationModalDINStep = ({
  form,
  setIsSelected,
}: DelConfigurationModalInputStepProps) => {
  const { workspaceId } = useParams()

  const {
    workspaceFiles,
    isWorkspaceFilesLoading,
    refreshWorkspaceFolders,
    parsePath,
  } = useWorkspaceFolders({
    workspaceId,
    preventFetch: true,
  })

  const options = useMemo(() => {
    if (!workspaceFiles || !workspaceFiles.length) {
      return []
    }
    return workspaceFiles.map((file) => ({
      label: parsePath(file.path),
      value: file.id,
    }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workspaceFiles])

  const refreshDocumentList = () => {
    setIsSelected(false)
    form.setFieldsValue({
      dinFileId: undefined,
      dinFileName: undefined,
      dinClientFileId: undefined,
      dinClientFileName: undefined,
    })

    refreshWorkspaceFolders()
  }

  const handleChange = (option: SelectOption) => {
    form.setFieldsValue({
      dinFileId: option.value,
      dinFileName: option.label,
    })
    checkSelection()
  }

  const handleDinClientChange = (option: SelectOption) => {
    form.setFieldsValue({
      dinClientFileId: option.value,
      dinClientFileName: option.label,
    })
    checkSelection()
  }

  const checkSelection = () => {
    const dinFileId = form.getFieldValue('dinFileId')
    const dinClientFileId = form.getFieldValue('dinClientFileId')

    if (dinFileId && dinClientFileId) {
      setIsSelected(true)
    } else {
      setIsSelected(false)
    }
  }

  const filterOption = (input: string, option?: SelectOption) =>
    (option?.label ?? '').toLowerCase().includes(input.toLowerCase())

  return (
    <>
      <Spin spinning={isWorkspaceFilesLoading}>
        <Form.Item
          name="dinFileId"
          label={t('delConfigurationModal.steps.dinReview.dinFieldTitle')}
          rules={[
            {
              required: true,
              message: t('delConfigurationModal.documentsRequired'),
            },
          ]}
          extra={t('delConfigurationModal.steps.dinReview.dinFieldLabel')}
        >
          <Select
            labelInValue
            allowClear
            style={{ width: '100%' }}
            placeholder={t(
              'delConfigurationModal.steps.dinReview.dinFieldPlaceholder'
            )}
            onChange={handleChange}
            options={options}
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
          />
        </Form.Item>
        <Form.Item
          label={t('delConfigurationModal.steps.dinReview.dinClientTitle')}
          name="dinClientFileId"
          rules={[
            {
              required: true,
              message: t('delConfigurationModal.documentsRequired'),
            },
          ]}
          extra={t('delConfigurationModal.steps.dinReview.dinClientFeildLabel')}
        >
          <Select
            labelInValue
            allowClear
            style={{ width: '100%' }}
            placeholder={t('delConfigurationModal.steps.input.extra')}
            onChange={handleDinClientChange}
            options={options}
            showSearch
            optionFilterProp="children"
            filterOption={filterOption}
          />
        </Form.Item>
      </Spin>
      <Text
        style={{
          paddingBottom: '10px',
        }}
      >
        {t('delConfigurationModal.steps.input.documentRefreshTitle')}
      </Text>
      <Button
        onClick={refreshDocumentList}
        icon={<ReloadOutlined />}
        block
        disabled={isWorkspaceFilesLoading}
      >
        {t('delConfigurationModal.steps.input.refreshDocuments')}
      </Button>
    </>
  )
}

export default DelConfigurationModalDINStep
