import { Col, Row } from 'antd'
import { t } from 'i18next'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { SectionLoadingState } from '../../../../components'
import { useAlgorithmRunDetails } from '../../../../hooks'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'
import { algorithmRunStatuses } from '../../../../constants/constants'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const DelSmartFunctionPanelRow = styled(Row)`
  height: 46px;
  padding: 8px;
  background: #fff;
  align-items: center;
`

const DelSmartFunctionPanelText = styled.div`
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: rgba(0, 0, 0, 0.25);
`

const DelSmartFunctionPanel = () => {
  const { workspaceId, algorithmRunId } = useParams()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      {selectedAlgorithmRun?.runStatus === algorithmRunStatuses.COMPLETE && (
        <DelSmartFunctionPanelRow>
          <Col span={24}>
            <DelSmartFunctionPanelText>
              {t('smartFunctionDetailsPage.del.delDetailsDescription')}
            </DelSmartFunctionPanelText>
          </Col>
        </DelSmartFunctionPanelRow>
      )}
    </SmartFunctionListPanelLayout>
  )
}

export default DelSmartFunctionPanel
