import { ReadOutlined } from '@ant-design/icons'
import { Button, Form, Input, InputRef, Radio } from 'antd'
import { t } from 'i18next'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  FilterBadge,
  ModalBase,
  SelectLiteratureReview,
} from '../../../components'
import InfoSection from '../../../components/display/InfoSection'
import { useLiteratureReviews } from '../../../hooks'
import {
  LiteratureReviewSavingType,
  literatureReviewSavingTypes,
} from '../../../services/literatureReviewService'
import notificationService from '../../../services/notificationService'
import { RootState } from '../../../store'
import { LiteratureReview } from '../../../store/reducers/literatureReviewReducer'
import { SearchMethod } from '../../../types/generalTypes'
import {
  literatureReviewPanelTypes,
  literatureReviewTabs,
} from '../../LiteratureReviewDetailsPage/LiteratureReviewDetailsPage'
import { routePaths } from '../../RootPage'

const TabContainer = styled.div`
  margin: 8px 0 16px 0;
`

const BadgesContainer = styled.div`
  display: flex;
  gap: 8px;
`
interface NewTabProps {
  inputContent: string
  setInputContent: (content: string) => void
}

const NewTab = ({ inputContent, setInputContent }: NewTabProps) => {
  const inputRef = useRef<InputRef>(null)

  useEffect(() => {
    inputRef.current?.focus()
  }, [])

  return (
    <Form layout="vertical" requiredMark={false}>
      <Form.Item
        label={t('searchPage.createLiteratureReviewModal.newReviewLabel')}
        required
        rules={[
          { required: true, message: t('errorMessage.isRequired') || '' },
        ]}
        extra={t('searchPage.createLiteratureReviewModal.newReviewExtra')}
        name="title"
      >
        <Input
          placeholder={
            t('searchPage.createLiteratureReviewModal.inputPlaceholder') || ''
          }
          ref={inputRef}
          value={inputContent}
          onChange={(event) => setInputContent(event.target.value)}
        />
      </Form.Item>
    </Form>
  )
}

interface ExistingTabProps {
  setLiteratureReview: (literatureReview: LiteratureReview | undefined) => void
}

const ExistingTab = ({ setLiteratureReview }: ExistingTabProps) => (
  <Form layout="vertical">
    <Form.Item
      label={t('searchPage.createLiteratureReviewModal.existingReviewLabel')}
      extra={t('searchPage.createLiteratureReviewModal.existingReviewExtra')}
    >
      <SelectLiteratureReview
        style={{ width: '200px' }}
        onChange={setLiteratureReview}
      />
    </Form.Item>
  </Form>
)

export interface CreateLiteratureReviewModalProps {
  query?: string
  searchMethod?: SearchMethod
  sourceId?: string
  period?: string
  total?: number
  disabled?: boolean
}

const CreateLiteratureReviewModal = ({
  query,
  sourceId,
  period,
  total,
  searchMethod,
  disabled,
}: CreateLiteratureReviewModalProps) => {
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [savingType, setSavingType] = useState<LiteratureReviewSavingType>(
    literatureReviewSavingTypes.NEW
  )
  const [reviewTitle, setReviewTitle] = useState('')
  const [literatureReview, setLiteratureReview] = useState<
    LiteratureReview | undefined
  >(undefined)
  const source = useSelector((state: RootState) =>
    state.dataSource.dataSourceList.find((source) => source.id === sourceId)
  )

  const { createLiteratureReview, addToExistingLiteratureReview } =
    useLiteratureReviews({
      preventFetch: true,
    })

  const handleOk = async () => {
    setConfirmLoading(true)

    const handleSuccess = (response: LiteratureReview, tab: string) => {
      setConfirmLoading(false)
      setOpen(false)
      navigate(
        `${routePaths.LITERATURE_REVIEW_DETAILS}/${response.id}/${literatureReviewPanelTypes.OVERVIEW}/${tab}`
      )
      notificationService.notificationSuccess(
        t(
          savingType === literatureReviewSavingTypes.NEW
            ? 'searchPage.createLiteratureReviewModal.sucessCreateLiteratureReview'
            : 'searchPage.createLiteratureReviewModal.successAddSearchQueryToLiteratureReview'
        )
      )
    }

    const handleError = (error: any) => {
      console.error('axios fetch error', error)
      setOpen(false)
      setConfirmLoading(false)
    }

    try {
      if (savingType === literatureReviewSavingTypes.NEW) {
        const response = await createLiteratureReview({
          query: query || '',
          searchMethod,
          sourceId: sourceId || '',
          period: period || '',
          reviewTitle,
          navigate,
        })
        handleSuccess(response, literatureReviewTabs.INFO)
      } else if (savingType === literatureReviewSavingTypes.EXISTING) {
        const response = await addToExistingLiteratureReview({
          literatureReviewId: literatureReview?.id || '',
          query: query || '',
          searchMethod,
          sourceId: sourceId || '',
          period: period || '',
          navigate,
        })
        handleSuccess(response, literatureReviewTabs.QUERIES)
      }
    } catch (error) {
      handleError(error)
    }
  }

  const handleCancel = () => {
    setOpen(false)
  }

  const handleLiteratureReview = (value?: LiteratureReview) => {
    setLiteratureReview(value)
  }

  const tabs = {
    NEW: <NewTab inputContent={reviewTitle} setInputContent={setReviewTitle} />,
    EXISTING: <ExistingTab setLiteratureReview={handleLiteratureReview} />,
  }

  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        disabled={disabled}
        icon={<ReadOutlined />}
      >
        {t('searchPage.createLiteratureReviewModal.openModalButton')}
      </Button>
      <ModalBase
        destroyOnClose
        title={t('searchPage.createLiteratureReviewModal.title')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel} type="text">
            {t('Cancel')}
          </Button>,
          <Button
            key="submit"
            type="primary"
            icon={<ReadOutlined />}
            onClick={handleOk}
            loading={confirmLoading}
          >
            {savingType === literatureReviewSavingTypes.NEW
              ? t('searchPage.createLiteratureReviewModal.submitButtonNew')
              : t(
                  'searchPage.createLiteratureReviewModal.submitButtonExisting'
                )}
          </Button>,
        ]}
      >
        <TabContainer>
          <Radio.Group
            defaultValue={literatureReviewSavingTypes.NEW}
            size="large"
            buttonStyle="solid"
          >
            <Radio.Button
              value={literatureReviewSavingTypes.NEW}
              onClick={() => setSavingType(literatureReviewSavingTypes.NEW)}
            >
              {t('New')}
            </Radio.Button>
            <Radio.Button
              value={literatureReviewSavingTypes.EXISTING}
              onClick={() =>
                setSavingType(literatureReviewSavingTypes.EXISTING)
              }
            >
              {t('Existing')}
            </Radio.Button>
          </Radio.Group>
        </TabContainer>
        {tabs[savingType]}
        <InfoSection
          label={t('searchPage.createLiteratureReviewModal.includedQueryLabel')}
        >
          <div>{query}</div>
          <BadgesContainer>
            {source?.title && (
              <FilterBadge color="default">
                {source.typeCode}: {source?.title}
              </FilterBadge>
            )}
            {period && <FilterBadge color="success">{period}</FilterBadge>}
          </BadgesContainer>
          <div>{t('searchPage.totalResult', { count: total })}</div>
        </InfoSection>
      </ModalBase>
    </>
  )
}

export default CreateLiteratureReviewModal
