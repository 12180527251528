import { Button } from 'antd'
import { AxiosError, isAxiosError } from 'axios'
import { t } from 'i18next'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { httpErrorStatus, urlList } from '../../constants/constants'
import { apiService } from '../../services'
import { RootState } from '../../store'
import { UserDetails, setCurrentUser } from '../../store/reducers/userReducer'
import { routePaths } from '../RootPage'

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  font-weight: 600;
  margin: auto;
`

const ErrorMessageContainer = styled.div``

const PageNotFound = () => {
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const isCurrentUserInStore = useSelector(
    (state: RootState) => !!state.user.currentUser
  )
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    apiService
      .fetchItems(urlList.CURRENT_USER)
      .then((response: UserDetails) => {
        dispatch(setCurrentUser(response))
        setLoading(false)
      })
      .catch((error: AxiosError | Error) => {
        setLoading(false)
        console.error('axios fetch error', error)
        if (
          isAxiosError(error) &&
          error.response?.status === httpErrorStatus.ERROR_401_UNAUTHORIZED
        ) {
          navigate(routePaths.LOGIN)
        }
      })
  }, [dispatch, navigate])

  const handleClick = () => {
    if (isCurrentUserInStore === true) {
      navigate(routePaths.SEARCH)
    } else {
      navigate(routePaths.LOGIN)
    }
  }

  return (
    <PageContainer>
      <ErrorMessageContainer>
        {t('errorMessage.pageNotFound')}
      </ErrorMessageContainer>
      <Button type="primary" onClick={handleClick} loading={loading}>
        {t('Home')}
      </Button>
    </PageContainer>
  )
}

export default PageNotFound
