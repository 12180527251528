import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useCallback, useMemo } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import {
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../../../components'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import {
  DeviatingOfrBlock,
  QualityReviewRunResult,
} from '../../../../store/reducers/workspaceReducer'
import { routePaths } from '../../../RootPage'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

interface QualityReviewRow extends DeviatingOfrBlock {
  filename: string
  key: string
  numberOfDeviations: number
}

interface QualityReviewSmartFunctionPanelProps {
  search: string
  deviationsOnly?: boolean
}

const QualityReviewSmartFunctionPanel = ({
  search,
  deviationsOnly,
}: QualityReviewSmartFunctionPanelProps) => {
  const { workspaceId, algorithmRunId } = useParams()
  const navigate = useNavigate()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  const formattedData: any[] = useMemo(() => {
    const runResult = selectedAlgorithmRun?.runResult as QualityReviewRunResult

    if (!runResult) return []

    const filteredData = Object.keys(runResult).filter((fileName) =>
      search ? fileName?.includes(search) : true
    )

    // Map over filteredData to get the desired format
    const mappedData = filteredData.map((fileName) => {
      const results = runResult[fileName]
      let deviationCount = results.reduce(
        (count, document) =>
          document.qualityReviewDeviation !== null &&
          document.qualityReviewDeviation !== undefined
            ? count + 1
            : count,
        0
      )
      
      if (deviationsOnly && deviationCount === 0) return null

      return {
        key: fileName,
        filename: fileName,
        numberOfDeviations: deviationCount,
        ...results,
      }
    })

    return mappedData.filter((item) => item !== null)
  }, [deviationsOnly, search, selectedAlgorithmRun?.runResult])

  const columns: ColumnsType<QualityReviewRow> = [
    {
      title: t(
        'smartFunctionDetailsPage.qualityReviewTableTitles.documentName'
      ),
      dataIndex: 'filename',
      key: 'filename',
      width: '60%',
      render: (text) => (
        <TableBoldColumnContainer>
          {text || NO_DATA_PLACEHOLDER}
        </TableBoldColumnContainer>
      ),
    },

    {
      title: t('smartFunctionDetailsPage.qualityReviewTableTitles.deviations'),
      key: 'numberOfDeviations',
      dataIndex: 'numberOfDeviations',
      width: '40%',

      render: (value) => <>{value || 0}</>,
    },
  ]

  const handleRecordClick = useCallback(
    (record: QualityReviewRow) => {
      if (record?.numberOfDeviations > 0) {
        navigate(
          `${routePaths.WORKSPACE_SMART_FUNCTION_DETAILS_OFR_QR_DOCUMENT_DETAILS}/${workspaceId}/${algorithmRunId}/${record.filename}`
        )
      }
    },
    [workspaceId, algorithmRunId, navigate]
  )

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <Table
        loading={loading}
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        scroll={{ y: 'calc(100vh - 202px)' }}
        rowClassName={(record) =>
          record?.numberOfDeviations > 0
            ? 'page-list-table-row pink-background clickable'
            : 'page-list-table-row'
        }
        onRow={(record, rowIndex) => {
          return {
            onClick: (event) => {
              handleRecordClick(record)
            },
          }
        }}
      />
    </SmartFunctionListPanelLayout>
  )
}

export default QualityReviewSmartFunctionPanel
