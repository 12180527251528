import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { Role } from './roleReducer'

interface UserBase {
  automator?: boolean
  createdBy?: string | null
  displayName: string
  email: string
  id: string
  isActive: boolean
  language: string
  lastModifiedBy?: string
  oid?: string
}

export interface User extends UserBase {
  role?: string
}

export interface UserDetails extends UserBase {
  role?: Role
}

export interface UserState {
  users?: User[]
  selected?: UserDetails
  currentUser?: UserDetails
  listCsv?: string
  detailsCsv?: string
}

const initialState: UserState = {
  users: [],
  selected: undefined,
  currentUser: undefined,
  listCsv: undefined,
  detailsCsv: undefined,
}

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setUsers: (state, action: PayloadAction<User[]>) => {
      state.users = action.payload
    },
    setSelectedUser: (state, action: PayloadAction<UserDetails>) => {
      state.selected = action.payload
    },
    setCurrentUser: (state, action: PayloadAction<UserDetails>) => {
      state.currentUser = action.payload
    },
    setUserListCsv: (state, action: PayloadAction<string>) => {
      state.listCsv = action.payload
    },
    setUserDetailsCsv: (state, action: PayloadAction<string>) => {
      state.detailsCsv = action.payload
    },
    clearUserState: () => initialState,
  },
})

export const {
  clearUserState,
  setCurrentUser,
  setSelectedUser,
  setUserDetailsCsv,
  setUserListCsv,
  setUsers
} = userSlice.actions

export const getUsers = (state: RootState) => state.user.users

const userReducer = userSlice.reducer
export default userReducer
