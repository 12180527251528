import { Form, Input } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import { validationService } from '../../../../services'

const CreateDataStoreContactStep = memo(() => {
  return (
    <>
      <Form.Item
        name="contactName"
        label={t(
          'createDataStoreModal.contactStep.contactName'
        )}
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
        ]}
      >
        <Input
          placeholder={
            t(
              'createDataStoreModal.contactStep.contactNamePlaceHolder'
            ) || ''
          }
        />
      </Form.Item>
      <Form.Item
        label={t(
          'createDataStoreModal.contactStep.contactEmail'
        )}
        name="contactEmail"
        rules={[
          {
            required: true,
            message: t('errorMessage.isRequired') || '',
          },
          {
            validator: validationService.validateEmail,
          },
        ]}
        extra={t(
          'createDataStoreModal.contactStep.contactEmailDescription'
        )}
      >
        <Input
          placeholder={
            t(
              'createDataStoreModal.contactStep.contactEmailPlaceHolder'
            ) || ''
          }
        />
      </Form.Item>
    </>
  )
})

export default CreateDataStoreContactStep
