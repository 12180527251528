import { DataSource } from '../../store/reducers/dataSourceReducer'

const dataSourceMock: DataSource[] = [
  {
    id: '1',
    contactName: 'John Doe',
    contactEmail: 'john.doe@example.com',
    createdBy: 'AdminUser',
    createdDate: '2023-08-01',
    config: {
      tenantUid: 'abc123',
      sourceFolder: '/folder1',
      sourceFolderId: 'folder123',
    },
    description: 'Sample data source 1',
    isInternal: true,
    searchable: true,
    lastModifiedBy: 'AdminUser',
    lastModifiedDate: '2023-08-15',
    organizationName: 'OrgA',
    participants: [
      {
        id: 'user1',
        createdBy: 'AdminUser',
        displayName: 'User 1',
        email: 'user1@example.com',
        isActive: true,
        language: 'en',
        lastModifiedBy: 'AdminUser',
        role: '',
      },
    ],
    title: 'Data Source 1',
    typeName: 'TypeA',
    typeCode: 'A',
    visibility: 'PRIVATE',
  },
  {
    id: '2',
    contactName: 'Jane Smith',
    contactEmail: 'jane.smith@example.com',
    createdBy: 'AdminUser',
    createdDate: '2023-08-02',
    config: {
      tenantUid: 'def456',
      sourceFolder: '/folder2',
      sourceFolderId: 'folder456',
    },
    description: 'Sample data source 2',
    isInternal: false,
    searchable: true,
    lastModifiedBy: 'AdminUser',
    lastModifiedDate: '2023-08-16',
    organizationName: 'OrgB',
    participants: [],
    title: 'Data Source 2',
    typeName: 'TypeB',
    typeCode: 'B',
    visibility: 'ORGANIZATION',
  },
  {
    id: '3',
    contactName: 'Alice Johnson',
    contactEmail: 'alice.johnson@example.com',
    createdBy: 'AdminUser',
    createdDate: '2023-08-03',
    config: {
      tenantUid: 'ghi789',
      sourceFolder: '/folder3',
      sourceFolderId: 'folder789',
    },
    description: 'Sample data source 3',
    isInternal: true,
    searchable: true,
    lastModifiedBy: 'AdminUser',
    lastModifiedDate: '2023-08-17',
    organizationName: 'OrgC',
    participants: [
      {
        id: 'user1',
        createdBy: 'AdminUser',
        displayName: 'User 1',
        email: 'user1@example.com',
        isActive: true,
        language: 'en',
        lastModifiedBy: 'AdminUser',
        role: '',
      },
    ],
    title: 'Data Source 3',
    typeName: 'TypeA',
    typeCode: 'A',
    visibility: 'PRIVATE',
  },
  {
    id: '4',
    contactName: 'Bob Williams',
    contactEmail: 'bob.williams@example.com',
    createdBy: 'AdminUser',
    createdDate: '2023-08-04',
    config: {
      tenantUid: 'jkl012',
      sourceFolder: '/folder4',
      sourceFolderId: 'folder012',
    },
    description: 'Sample data source 4',
    isInternal: false,
    searchable: true,
    lastModifiedBy: 'AdminUser',
    lastModifiedDate: '2023-08-18',
    organizationName: 'OrgD',
    participants: [],
    title: 'Data Source 4',
    typeName: 'TypeB',
    typeCode: 'B',
    visibility: 'ORGANIZATION',
  },
]

export default dataSourceMock
