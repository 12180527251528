import { AxiosError } from 'axios'
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  DataSource,
  setDefaultDataSource,
} from '../store/reducers/dataSourceReducer'
import { RootState } from '../store'
import { apiService } from '../services'
import { urlList } from '../constants/urlConstant'

const useDefaultDataSource = () => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const defaultDataSource: DataSource | undefined = useSelector(
    (state: RootState) => state.dataSource.default
  )

  useEffect(() => {
    if (!defaultDataSource) {
      setLoading(true)
      apiService
        .fetchItems(urlList.DATA_SOURCES_DEFAULT)
        .then((response: DataSource) => {
          setLoading(false)
          dispatch(setDefaultDataSource(response))
        })
        .catch((error) => {
          console.error('axios fetch error', error)
          setError(error)
          setLoading(false)
        })
    }
  }, [dispatch, defaultDataSource])

  return {
    defaultDataSource,
    loading,
    error,
  }
}

export default useDefaultDataSource
