import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { PrivacyType } from '../../types/generalTypes'
import { SourceType } from './dataSourceReducer'
import { User } from './userReducer'

export interface DataStoreConfig {
  tenantUid?: string
  sourceFolder?: string
  sourceFolderId?: string
  sourceUrl?: string
  sourceLibrary?: string
  sourceLibraryId?: string
}

export interface DataStore {
  id: string
  createdBy: string
  createdDate: string
  description: string
  lastModifiedBy: string
  lastModifiedDate: string
  participants: User[]
  title: string
  visibility: PrivacyType
  sourceType?: SourceType
  typeName: string
  typeCode: string
  config: DataStoreConfig
  contactName: string
  contactEmail: string
}

export interface DataStoreState {
  dataStoreList: DataStore[]
  default?: DataStore
  selected?: DataStore
  loading: boolean
  listCsv?: string
  detailsCsv?: string
}

const initialState: DataStoreState = {
  dataStoreList: [],
  loading: false,
  listCsv: undefined,
  detailsCsv: undefined,
}

const dataStoreSlice = createSlice({
  name: 'dataStores',
  initialState,
  reducers: {
    setDataStoreList: (state, action: PayloadAction<DataStore[]>) => {
      state.dataStoreList = action.payload
    },
    setDataStoreLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setDefaultDataStore: (state, action: PayloadAction<DataStore>) => {
      state.default = action.payload
    },
    setSelectedDataStore: (state, action: PayloadAction<DataStore>) => {
      state.selected = action.payload
    },
    setDataStoreListCsv(state, action: PayloadAction<string>) {
      state.listCsv = action.payload
    },
    setDataStoreDetailsCsv(state, action: PayloadAction<string>) {
      state.detailsCsv = action.payload
    },
    clearDataStoreState: () => initialState,
  },
})

export const {
  clearDataStoreState,
  setDataStoreDetailsCsv,
  setDataStoreList,
  setDataStoreListCsv,
  setDataStoreLoading,
  setDefaultDataStore,
  setSelectedDataStore
} = dataStoreSlice.actions

export const getDataStores = (state: RootState) => state.dataStore.dataStoreList

const dataStoreReducer = dataStoreSlice.reducer
export default dataStoreReducer
