import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import {
  AlgorithmRun,
  RegulatorySubmissionRunResult,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface RegulatorySubmissionSmartFunctionStatistics {
  missingExpectedFiles?: number
}

const RegulatorySubmissionSmartFunctionStatisticsPanel = () => {
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })
  const [statistics, setStatistics] =
    useState<RegulatorySubmissionSmartFunctionStatistics>()

  const mostRecentCompleteAlgorithmRun = useMemo(() => {
    if (!selectedWorkspace?.algorithmRuns) {
      return undefined
    }
    return selectedWorkspace?.algorithmRuns
      .filter(
        (obj) => obj.runStatus === 'COMPLETE' && obj.completedDate !== null
      )
      .reduce((prev, current) => {
        if (prev.completedDate && current.completedDate) {
          return Date.parse(prev.completedDate) >
            Date.parse(current.completedDate)
            ? prev
            : current
        } else if (prev.completedDate) {
          return prev
        } else {
          return current
        }
      }, selectedWorkspace.algorithmRuns[0])
  }, [selectedWorkspace])

  useEffect(() => {
    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res: AlgorithmRun) => {
          const smartFunctionResult =
            res.runResult as RegulatorySubmissionRunResult
          setStatistics({
            missingExpectedFiles: smartFunctionResult.absent.length,
          })
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [mostRecentCompleteAlgorithmRun, selectedWorkspace])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.regulatorySubmissionStatistics.smartFunctionRuns'
        )}
        value={selectedWorkspace?.algorithmRuns?.length ?? NO_DATA_PLACEHOLDER}
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.regulatorySubmissionStatistics.missingExpectedFiles'
        )}
        value={statistics?.missingExpectedFiles ?? NO_DATA_PLACEHOLDER}
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default RegulatorySubmissionSmartFunctionStatisticsPanel
