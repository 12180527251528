import { urlList } from '../constants/constants'
import apiService from './apiService'

const baseUrl = urlList.SHAREPOINT_SITES

export interface SharePointSite {
  id: string
  name: string
}

export interface SharePointLibraryResponse {
  id: string
  name: string
}

export interface getSharePointSitesResponse {
  name: string
  webUrl: string
}

const getSharePointSites = async () => {
  return apiService
    .fetchItems(`${baseUrl}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface getSharePointSitesWithTenantUidProps {
  tenantUid: string
}

const getSharePointSitesWithTenantUid = async ({
  tenantUid,
}: getSharePointSitesWithTenantUidProps) => {
  return apiService
    .fetchItems(`${baseUrl}/tenantId=${tenantUid}`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

export interface getSharePointLibrariesProps {
  siteUrl?: string
}

const getSharePointLibraries = async ({
  siteUrl,
}: getSharePointLibrariesProps) => {
  const url =
    siteUrl && siteUrl !== ''
      ? `${baseUrl}/libraries?siteUrl=${siteUrl}`
      : `${baseUrl}/libraries`
  return apiService
    .fetchItems(url)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const sharepointService = {
  getSharePointSites,
  getSharePointLibraries,
  getSharePointSitesWithTenantUid,
}

export default sharepointService
