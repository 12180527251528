import { CloseCircleOutlined } from '@ant-design/icons'
import { Tag, Typography } from 'antd'
import { DefaultOptionType } from 'antd/es/select'
import { FormInstance } from 'antd/lib/form'
import { t } from 'i18next'
import { useMemo } from 'react'
import styled from 'styled-components'
import { RegulatorySubmissionConfigurationModalFormValues } from '../RegulatorySubmissionConfigurationModal'

const { Text } = Typography

interface RegulatorySubmissionConfigurationModalReviewStepProps {
  form: FormInstance<RegulatorySubmissionConfigurationModalFormValues>
}

const TagsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: flex-start;
`

const RegulatorySubmissionConfigurationModalReviewStep = ({
  form,
}: RegulatorySubmissionConfigurationModalReviewStepProps) => {
  const documents = useMemo(() => form.getFieldValue('documents'), [form])

  const onDelete = (value: string | number | null | undefined) => {
    const currentDocuments = form.getFieldValue('documents')
    const filteredDocuments = currentDocuments.filter(
      (doc: DefaultOptionType) => doc.value !== value
    )
    const tableOfContentIds = filteredDocuments.map(
      (doc: DefaultOptionType) => doc.value
    )

    form.setFieldsValue({
      documents: filteredDocuments,
      tableOfContentIds: tableOfContentIds,
    })
  }

  const documentTags = documents
    ? documents.map((doc: DefaultOptionType, index: number) => (
        <Tag
          key={index}
          closeIcon={documents.length > 1 ? <CloseCircleOutlined /> : undefined}
          onClose={() => onDelete(doc.value)}
          style={{
            marginBottom: '5px',
            maxWidth: '400px',
          }}
        >
          <Text ellipsis>{doc.label}</Text>
        </Tag>
      ))
    : null

  return (
    <div>
      <Text>{t('regulatorySubmissionConfigurationModal.reviewHeader')}</Text>
      <TagsContainer
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: '10px',
          alignItems: 'flex-start', // Align flex items to the start (top) of the column
        }}
      >
        {documentTags}
      </TagsContainer>
    </div>
  )
}

export default RegulatorySubmissionConfigurationModalReviewStep
