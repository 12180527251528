import { DatabaseOutlined } from '@ant-design/icons'
import { Button, Form, FormInstance } from 'antd'
import { t } from 'i18next'
import { memo, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import {
  ModalBase,
  ModalContentContainer,
  ModalStepsTitle,
} from '../../../components'
import {
  EGNYTE_TYPECODE,
  REGDOCS_TYPECODE,
  SHAREPOINT_TYPECODE,
  optionalRequiredMark,
  privacyTypes,
} from '../../../constants/constants'
import { useDataSourceTypes, useDataSources } from '../../../hooks'
import {
  dataSourceService,
  egnyteService,
  validationService,
} from '../../../services'
import notificationService from '../../../services/notificationService'
import { RootState } from '../../../store'
import {
  DataSourceConfigType,
  DataSourceType,
} from '../../../store/reducers/dataSourceReducer'
import CreateDataSourceFooter from './footers/CreateDataSourceFooter'
import CreateDataSourceContactStep from './steps/CreateDataSourceContactStep'
import CreateDataSourceDetailsStep from './steps/CreateDataSourceDetailsStep'
import CreateDataSourcePrivacyStep from './steps/CreateDataSourcePrivacyStep'
import CreateDataSourceReviewStep from './steps/CreateDataSourceReviewStep'
import CreateDataSourceTypeStep from './steps/CreateDataSourceTypeStep'
import {
  CreateDataSourceEgnyteConfig,
  CreateDataSourceRegdocsConfig,
  CreateDataSourceSharePointConfig,
} from './steps/setup'

const BASE_FOLDER_PATH = '/Shared'

const CreateDataSourceModal = memo(() => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const currentUser = useSelector((state: RootState) => state.user.currentUser)

  const { refreshDataSourceList } = useDataSources({
    preventFetch: true,
  })
  const { refreshDataSourceTypeList, dataSourceTypeList } = useDataSourceTypes({
    preventFetch: true,
  })

  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const stepItems = useMemo(
    () => [
      {
        title: t('createDataSourceModal.stepItems.definition'),
      },
      { title: t('createDataSourceModal.stepItems.type') },
      { title: t('createDataSourceModal.stepItems.setup') },
      {
        title: t('createDataSourceModal.stepItems.privacy'),
      },
      {
        title: t('createDataSourceModal.stepItems.contact'),
      },
      {
        title: t('createDataSourceModal.stepItems.review'),
      },
    ],
    []
  )

  const resetValues = useCallback(() => {
    form.resetFields()
    setCurrentStep(0)
  }, [form])

  const handleOk = useCallback(() => {
    setConfirmLoading(true)
  }, [])

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
  }, [resetValues])

  const handleNextClick = useCallback(() => {
    form
      .validateFields()
      .then(() => setCurrentStep(currentStep + 1))
      .catch(() => {
        notificationService.notificationError(
          t('createDataSourceModal.invalidFields')
        )
      })
  }, [currentStep, form])

  const getDataSourceType = useCallback(
    async (selectedSource: string) => {
      if (!dataSourceTypeList) {
        await refreshDataSourceTypeList()
      }
      const sourceType = dataSourceTypeList?.find(
        (source: DataSourceType) => source.typeCode === selectedSource
      )
      if (sourceType) {
        form.setFieldsValue({ sourceType: sourceType.id })
      }
    },
    [form, refreshDataSourceTypeList, dataSourceTypeList]
  )

  const handlePreviousClick = useCallback(() => {
    setCurrentStep(currentStep - 1)
  }, [currentStep])

  const handleTypeStepNextClick = useCallback(async () => {
    const selectedSource = form.getFieldValue('sourceCode')
    await getDataSourceType(selectedSource)
    form.setFieldsValue({
      config: {
        sourceFolder: null,
        sourceFolderId: null,
        tenantUid: null,
      },
    })
    handleNextClick()
  }, [form, getDataSourceType, handleNextClick])

  const handleEgnyteNext = useCallback(() => {
    const config = form.getFieldValue('config')
    const secrets = form.getFieldValue('secrets')
    const source = form.getFieldValue('config')?.sourceFolder || ''
    const folderpath = BASE_FOLDER_PATH + source
    if (!validationService.validateEgnyteConfig(config, secrets)) {
      notificationService.notificationError(
        t('createDataSourceModal.egnyteConfig.invalidEgnyteConfig')
      )
      return
    }
    egnyteService
      .validateEgnyteConfiguration({
        code: secrets.authCode,
        domainName: config.tenantUid,
        folderName: folderpath,
      })
      .then((response) => {
        if (response) {
          form.setFieldValue('config', {
            ...config,
            sourceFolder: folderpath,
          })
          handleNextClick()
        }
      })
      .catch((error) => {
        notificationService.notificationError(
          t('createDataSourceModal.egnyteConfig.invalidEgnyteConfig')
        )
        console.error('error: ', error)
      })
  }, [form, handleNextClick])

  const handleSubmit = useCallback(async () => {
    try {
      const code = form.getFieldValue('sourceCode')
      const config: DataSourceConfigType = form.getFieldValue('config')
      let newConfig: DataSourceConfigType = {}
      if (code === EGNYTE_TYPECODE || code === SHAREPOINT_TYPECODE) {
        newConfig.sourceFolder = config.sourceFolder
        newConfig.sourceFolderId = config.sourceFolderId
        newConfig.tenantUid = config.tenantUid
      } else if (code === REGDOCS_TYPECODE) {
        if (config.sourceUrl && config.sourceLibrary !== '') {
          newConfig.sourceUrl = config.sourceUrl
        }
        newConfig.sourceLibrary = config.sourceLibrary
        newConfig.sourceLibraryId = config.sourceLibraryId
      }

      const dataSource = {
        title: form.getFieldValue('title'),
        description: form.getFieldValue('description'),
        visibility: form.getFieldValue('visibility'),
        config: newConfig,
        participants: form.getFieldValue('participants'),
        sourceType: form.getFieldValue('sourceType'),
        secrets: form.getFieldValue('secrets'),
        contactName: form.getFieldValue('contactName'),
        contactEmail: form.getFieldValue('contactEmail'),
        navigate,
      }
      await dataSourceService.createDataSource(dataSource)
      notificationService.notificationSuccess(
        t('createDataSourceModal.successCreateDataSource')
      )
      refreshDataSourceList()
      resetValues()
      setOpen(false)
    } catch (error) {
      console.error('axios create data source error', error)
      notificationService.notificationError(
        t('createDataSourceModal.errorCreateDataSource')
      )
    }
    setConfirmLoading(false)
  }, [form, navigate, refreshDataSourceList, resetValues])

  const defaultValues = useMemo(
    () => ({
      title: '',
      sourceCode: EGNYTE_TYPECODE,
      visibility: privacyTypes.PRIVATE,
      participants: currentUser ? [currentUser.id] : [],
    }),
    [currentUser]
  )

  const CreateDataSourceConfigWrapper = memo(
    ({ form }: { form: FormInstance }) => {
      switch (form.getFieldValue('sourceCode')) {
        case EGNYTE_TYPECODE:
          return <CreateDataSourceEgnyteConfig form={form} />
        case SHAREPOINT_TYPECODE:
          return <CreateDataSourceSharePointConfig form={form} />
        case REGDOCS_TYPECODE:
          return <CreateDataSourceRegdocsConfig form={form} />
        default:
          return <CreateDataSourceSharePointConfig form={form} />
      }
    }
  )

  // Create a separate component for the footer wrapper
  const CreateDataSourceFooterWrapper = memo(
    ({ form }: { form: FormInstance }) => {
      return form.getFieldValue('sourceCode') === EGNYTE_TYPECODE ? (
        <CreateDataSourceFooter
          onNext={handleEgnyteNext}
          onCancel={handleCancel}
          onPrevious={handlePreviousClick}
        />
      ) : (
        <CreateDataSourceFooter
          onNext={handleNextClick}
          onCancel={handleCancel}
          onPrevious={handlePreviousClick}
        />
      )
    }
  )

  const steps = useMemo(
    () => [
      <CreateDataSourceDetailsStep />,
      <CreateDataSourceTypeStep form={form} />,
      <CreateDataSourceConfigWrapper form={form} />,
      <CreateDataSourcePrivacyStep form={form} />,
      <CreateDataSourceContactStep />,
      <CreateDataSourceReviewStep form={form} />,
    ],
    [CreateDataSourceConfigWrapper, form]
  )

  const footers = useMemo(
    () => [
      <CreateDataSourceFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
      />,
      <CreateDataSourceFooter
        onNext={handleTypeStepNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataSourceFooterWrapper form={form} />,
      <CreateDataSourceFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataSourceFooter
        onNext={handleNextClick}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
      <CreateDataSourceFooter
        onSubmit={handleSubmit}
        onCancel={handleCancel}
        onPrevious={handlePreviousClick}
      />,
    ],
    [
      form,
      handleTypeStepNextClick,
      handleCancel,
      handleNextClick,
      handlePreviousClick,
      handleSubmit,
      CreateDataSourceFooterWrapper,
    ]
  )

  return (
    <>
      <Button
        icon={<DatabaseOutlined />}
        onClick={() => setOpen(true)}
        type="primary"
      >
        {t('dataSourcesListPage.addDataSourceButton')}
      </Button>
      <ModalBase
        key="main"
        title={
          <ModalStepsTitle
            title={t('createDataSourceModal.title')}
            currentStep={currentStep}
            stepItems={stepItems}
          />
        }
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        width={720}
        footer={footers[currentStep]}
        styles={{
          body: {
            maxHeight: 'calc(100vh - 400px)',
          },
        }}
      >
        <ModalContentContainer>
          <Form
            form={form}
            layout="vertical"
            initialValues={defaultValues}
            requiredMark={optionalRequiredMark}
          >
            {steps[currentStep]}
          </Form>
        </ModalContentContainer>
      </ModalBase>
    </>
  )
})

export default CreateDataSourceModal
