import { AxiosError } from 'axios'
import { useCallback, useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urlList } from '../constants/constants'
import { apiService } from '../services'
import { RootState } from '../store'
import { setAuditTrailsComponents } from '../store/reducers/auditTrailReducer'

interface UseAuditTrailsComponents {
  preventFetch?: boolean
}

const useAuditTrailsComponents = ({
  preventFetch = false,
}: UseAuditTrailsComponents) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()

  const auditTrailsComponents: string[] | undefined = useSelector(
    (state: RootState) => state.auditTrail.auditTrailsComponents
  )

  const fetchAuditTrailComponentList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        apiService
          .fetchItems(`${urlList.Audit_TRAILS}/requestComponents`)
          .then((response: string[]) => {
            setLoading(false)
            dispatch(setAuditTrailsComponents(response))
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios fetch error', error)
            setLoading(false)
          })
      }
    },
    [dispatch, loading, preventFetch]
  )

  const refreshAuditTrailList = useCallback(async () => {
    fetchAuditTrailComponentList(true)
  }, [fetchAuditTrailComponentList])

  useEffect(() => {
    refreshAuditTrailList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    auditTrailsComponents,
    error,
    fetchAuditTrailComponentList,
    loading,
  }
}

export default useAuditTrailsComponents
