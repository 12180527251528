import React from 'react'
import { VscCircleSmall } from 'react-icons/vsc'
import { ReactNode } from 'react'
import styled from 'styled-components'

const MainContainer = styled.div`
  display: flex;
`

const DotContainer = styled.div`
  padding: 3px 4px;
`

interface ListItemDotProps {
  children: ReactNode
}

const ListItemDot = ({ children }: ListItemDotProps) => {
  return (
    <MainContainer>
      <DotContainer>{<VscCircleSmall />}</DotContainer>
      <div>{children}</div>
    </MainContainer>
  )
}

export default ListItemDot
