import { FormOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import styled from 'styled-components'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px;
`

interface CreateOfrFormModalFooterProps {
  onCancel: () => void
  onSubmit?: () => void
}

const CreateOfrFormModalFooter = memo(
  ({ onCancel, onSubmit }: CreateOfrFormModalFooterProps) => {
    return (
      <Footer>
        <Button key="cancel" onClick={onCancel} type="text">
          {t('Cancel')}
        </Button>
        <Button
          icon={<FormOutlined />}
          key="submit"
          type="primary"
          onClick={onSubmit}
        >
          {t('createOfrDocumentTemplateModal.annotateButton')}
        </Button>
      </Footer>
    )
  }
)

export default CreateOfrFormModalFooter
