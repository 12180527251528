import { Table } from 'antd'
import { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useParams } from 'react-router-dom'
import { styled } from 'styled-components'
import { SectionLoadingState } from '../../../../components'
import {
  NO_DATA_PLACEHOLDER,
  OfrBlockType,
  ofrBlockTypeNames,
  qualityReviewDeviationNames,
  QualityReviewDeviationType
} from '../../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../../hooks'
import {
  DeviatingOfrBlock,
  QualityReviewRunResult,
} from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

const QualityReviewSmartFunctionDocumentDetailsPagePanel = () => {
  const qualityReviewDeviationMapping = qualityReviewDeviationNames
  const qualityReviewOfrBlockTypeMapping = ofrBlockTypeNames
  const { workspaceId, algorithmRunId, filename } = useParams()
  const { loading, selectedAlgorithmRun } = useAlgorithmRunDetails({
    workspaceId,
    algorithmRunId,
    preventFetch: true,
  })

  const smartFunctionResult = useMemo(
    () => selectedAlgorithmRun?.runResult as QualityReviewRunResult,
    [selectedAlgorithmRun?.runResult]
  )

  const formattedData: DeviatingOfrBlock[] | undefined = useMemo(() => {
    if (!smartFunctionResult) return undefined
    const arrayKey = Object.keys(smartFunctionResult).find(
      (key) => key === filename
    )
    if (!arrayKey) return undefined
    return smartFunctionResult[arrayKey] as DeviatingOfrBlock[]
  }, [filename, smartFunctionResult])

  const columns: ColumnsType<DeviatingOfrBlock> = [
    {
      title: t(
        'smartFunctionDetailsPage.qualityReviewDocumentAnnotationsTableTitles.annotationName'
      ),
      dataIndex: 'ofrBlockName',
      key: 'ofrBlockName',
      width: '40%',
      render: (text) => <>{text || NO_DATA_PLACEHOLDER}</>,
    },
    {
      title: t(
        'smartFunctionDetailsPage.qualityReviewDocumentAnnotationsTableTitles.deviationType'
      ),
      key: 'qualityReviewDeviation',
      dataIndex: 'qualityReviewDeviation',
      width: '15%',
      render: (qualityReviewDeviation: string) => {
        const isValidDeviation =
          qualityReviewDeviation in qualityReviewDeviationMapping
        return (
          <>
            {isValidDeviation
              ? qualityReviewDeviationMapping[
                  qualityReviewDeviation as QualityReviewDeviationType
                ]
              : NO_DATA_PLACEHOLDER}
          </>
        )
      },
    },
    {
      title: t(
        'smartFunctionDetailsPage.qualityReviewDocumentAnnotationsTableTitles.annotationType'
      ),
      key: 'ofrBlockType',
      dataIndex: 'ofrBlockType',
      width: '15%',
      render: (value) => {
        const isValidType = value in qualityReviewOfrBlockTypeMapping
        return (
          <>
            {isValidType
              ? qualityReviewOfrBlockTypeMapping[value as OfrBlockType]
              : NO_DATA_PLACEHOLDER}
          </>
        )
      },
    },
    {
      title: t(
        'smartFunctionDetailsPage.qualityReviewDocumentAnnotationsTableTitles.pageNumber'
      ),
      key: 'pageNum',
      dataIndex: 'pageNum',
      width: '30%',
      render: (value) => <>{value || 0}</>,
    },
  ]

  return loading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <Table
        loading={loading}
        columns={columns}
        dataSource={formattedData}
        pagination={false}
        scroll={{ y: 'calc(100vh - 202px)' }}
        rowClassName={(record) => 'page-list-table-row'}
      />
    </SmartFunctionListPanelLayout>
  )
}

export default QualityReviewSmartFunctionDocumentDetailsPagePanel
