import { SearchOutlined } from '@ant-design/icons'
import { Button, theme } from 'antd'
import { Input } from 'antd/lib'
import { t } from 'i18next'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useSearchDocuments, useTheme } from '../hooks'
import notificationService from '../services/notificationService'
import { RootState } from '../store'
import { setFillSearchFormData } from '../store/reducers/searchResultInfoReducer'
import { themeColorKeys } from '../theme'
import SelectSource, { selectSourcePrivacyTypes } from './SelectSource'
import SelectTimePeriod, {
  SelectTimePeriodOnChangeParams,
  getStartAndEndDate,
  getTextForTimePeriod,
  timePeriodValues,
} from './SelectTimePeriod'
import AllowClearIcon from './display/AllowClearIcon'

const { Search } = Input

interface MainHeaderProps {
  loading?: boolean
}

const { useToken } = theme

const MainHeaderSearch = ({ loading }: MainHeaderProps) => {
  const dispatch = useDispatch()
  const { areCustomDateValid, performSearch, searchLoading } =
    useSearchDocuments()

  const { token } = useToken()
  const searchTextValue: string | undefined = useSelector(
    (state: RootState) =>
      state.searchResultInfo.fillSearchFormData?.searchTextValue
  )
  const { selectedTheme } = useTheme()
  const [sourceId, setSourceId] = useState<string>('')
  const [period, setPeriod] = useState(
    getTextForTimePeriod(timePeriodValues.ALL) || ''
  )
  const [searchStartDate, setSearchStartDate] = useState('')
  const [searchEndDate, setSearchEndDate] = useState('')

  const [searchText, setSearchText] = useState('')

  useEffect(() => {
    if (searchTextValue) {
      setSearchText(searchTextValue)
      dispatch(setFillSearchFormData({ searchTextValue: undefined }))
    }
  }, [searchTextValue, dispatch])

  const handleTimePeriodChange = useCallback(
    (values: SelectTimePeriodOnChangeParams) => {
      setPeriod(getTextForTimePeriod(values.timePeriodValue) || '')
      const { startDate, endDate } = getStartAndEndDate(
        values.timePeriodValue,
        values.startDate || '',
        values.endDate || ''
      )
      setSearchStartDate(startDate)
      setSearchEndDate(endDate)

      if (!areCustomDateValid(startDate, endDate)) {
        notificationService.notificationError(
          t('errorMessage.selectCustomPeriodDateError')
        )
      }
    },
    [areCustomDateValid]
  )

  const handleSearch = useCallback(
    async (searchText: any) => {
      if (searchText === '' || !sourceId) {
        return
      }
      performSearch({
        searchText,
        sourceId,
        startDate: searchStartDate,
        endDate: searchEndDate,
        period,
      })
    },
    [performSearch, period, searchEndDate, searchStartDate, sourceId]
  )

  const searchButtonStyle =
    selectedTheme === themeColorKeys.dark
      ? {
          backgroundColor: token.colorBgContainer,
          color: token.colorText,
          border: `1px solid ${token.colorBorder}`,
        }
      : {}

  return (
    <>
      <SelectSource
        onChange={(value) => setSourceId(value)}
        privacy={selectSourcePrivacyTypes.PUBLIC}
        includeSearchable={true}
      />
      <SelectTimePeriod onChange={handleTimePeriodChange} />
      <Search
        placeholder={t('mainHeader.searchPlaceholder') || ''}
        allowClear={{
          clearIcon: <AllowClearIcon />,
        }}
        value={searchText}
        onChange={(e) => setSearchText(e.target.value)}
        onSearch={handleSearch}
        disabled={searchLoading || loading}
        loading={searchLoading || loading}
        enterButton={
          <Button style={searchButtonStyle} icon={<SearchOutlined />} />
        }
        style={{
          flex: 1,
        }}
        styles={{
          affixWrapper: {
            backgroundColor: token.colorBgContainer,
            border: `1px solid ${token.colorBorder}`,
          },
          prefix: {
            backgroundColor: token.colorBgContainer,
            color: token.colorText,
          },
          input: {
            backgroundColor: token.colorBgContainer,
            color: token.colorText,
          },
          suffix: {
            color: token.colorText,
            backgroundColor: `${token.colorBgLayout} !important`,
          },
        }}
      />
    </>
  )
}

export default MainHeaderSearch
