import { UserAddOutlined } from '@ant-design/icons'
import { Button, Form, Steps } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ModalBase } from '../../../components'
import { urlList } from '../../../constants/constants'
import { apiService } from '../../../services'
import notificationService from '../../../services/notificationService'
import { RootState } from '../../../store'
import { Role } from '../../../store/reducers/roleReducer'
import { Language } from '../../../types/generalTypes'
import CreateUserModalDefinitionStepFooter from './footer/CreateUserModalDefinitionStepFooter'
import CreateUserModalReviewStepFooter from './footer/CreateUserModalReviewStepFooter'
import CreateUserModalDefinitionStep from './steps/CreateUserModalDefinitionStep'
import CreateUserModalReviewStep from './steps/CreateUserModalReviewStep'

export interface CreateUserFormValues {
  emails: string
  userRole: string
  language: string
}

interface UserInvitationParams {
  email: string
  language: string
  role: string
}

interface InvitationList {
  invitedUsers: UserInvitationParams[]
}

const CreateUserModal = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const roles = useSelector((state: RootState) => state.role?.roles)
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const [emails, setEmails] = useState('')
  const [selectedRole, setSelectedRole] = useState<Role | undefined>()
  const [selectedLanguage, setSelectedLanguage] = useState<Language>('en')

  const [currentStep, setCurrentStep] = useState(0)

  const stepItems = [
    {
      title: t('userListPage.createUserModal.definitionStepTitle'),
    },
    {
      title: t('userListPage.createUserModal.reviewStepTitle'),
    },
  ]

  const resetValues = useCallback(() => {
    setEmails('')
    setSelectedLanguage('en')
    setSelectedRole(undefined)
    form.resetFields()
  }, [form])

  const handleRoleChange = useCallback(
    (value: string) => {
      const role = roles.find((role: Role) => role.id === value)
      setSelectedRole(role)
    },
    [roles]
  )

  const handleOk = () => {
    setConfirmLoading(true)
  }

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
    setCurrentStep(0)
  }, [setCurrentStep, resetValues])

  const handleConfirm = useCallback(() => {
    const params: InvitationList = {
      invitedUsers: emails.split(',').map((email: string) => ({
        email: email.trim(),
        language: selectedLanguage,
        role: selectedRole?.id || '',
      })),
    }
    apiService
      .saveItem(urlList.INVITE_USER, navigate, params)
      .then((response) => {
        notificationService.notificationSuccess(
          t('userListPage.createUserModal.successSentMessage')
        )
        resetValues()
        setConfirmLoading(false)
        setOpen(false)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios invite user error', error)
        setConfirmLoading(false)
      })
  }, [emails, navigate, selectedLanguage, selectedRole, resetValues])

  const steps: ReactNode[] = useMemo(
    () => [
      <CreateUserModalDefinitionStep
        form={form}
        setSelectedRole={setSelectedRole}
        setSelectedLanguage={setSelectedLanguage}
        setEmails={setEmails}
        handleRoleChange={handleRoleChange}
      />,
      <CreateUserModalReviewStep
        form={form}
        selectedRole={selectedRole}
        selectedLanguage={selectedLanguage}
        emails={emails}
      />,
    ],
    [
      form,
      setSelectedRole,
      setSelectedLanguage,
      setEmails,
      selectedRole,
      selectedLanguage,
      emails,
      handleRoleChange,
    ]
  )

  const footers: ReactNode[] = useMemo(
    () => [
      <CreateUserModalDefinitionStepFooter
        loading={confirmLoading}
        handleCancel={handleCancel}
        isNextDisabled={!emails || !selectedRole}
        handleNext={() => setCurrentStep(1)}
      />,
      <CreateUserModalReviewStepFooter
        handleCancel={handleCancel}
        handleBack={() => setCurrentStep(0)}
        handleConfirm={handleConfirm}
      />,
    ],
    [confirmLoading, handleCancel, handleConfirm, emails, selectedRole]
  )

  return (
    <>
      <Button
        icon={<UserAddOutlined />}
        onClick={() => setOpen(true)}
        type="primary"
      >
        {t('userListPage.createUserModal.button')}
      </Button>
      <ModalBase
        key="main"
        title={t('userListPage.createUserModal.title')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        forceRender
        footer={footers[currentStep]}
      >
        <Steps size="small" current={currentStep} items={stepItems} />
        {steps[currentStep]}
      </ModalBase>
    </>
  )
}

export default CreateUserModal
