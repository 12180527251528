import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { Language } from '../../types/generalTypes'

export interface LanguageState {
  selectedLanguage: Language
}

const initialState: LanguageState = {
  selectedLanguage: 'en',
}

const languageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<Language>) => {
      state.selectedLanguage = action.payload
    },
    clearLangagueState: () => initialState,
  },
})

export const { setLanguage, clearLangagueState } = languageSlice.actions

export const getTheme = (state: RootState) => state.language.selectedLanguage

const languageReducer = languageSlice.reducer
export default languageReducer
