import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ofrDocumentTemplateService } from '../services'
import { RootState } from '../store'
import {
  OFrImage,
  setSelectedOfrDocumentTemplateImages,
} from '../store/reducers/ofrDocumentTemplateReducer'

interface UseOfrDocumentTemplateImagesProps {
  documentId?: string
  preventFetch?: boolean
}

const useOfrDocumentTemplateImages = ({
  documentId,
  preventFetch = false,
}: UseOfrDocumentTemplateImagesProps) => {
  const dispatch = useDispatch()
  const selectedOfrDocumentTemplateImages = useSelector(
    (state: RootState) =>
      state.ofrDocumentTemplate.selectedDocumentTemplateImages
  )
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const [isFetchDone, setIsFetchDone] = useState(false)

  // convert buffer to base64
  const bufferToBase64 = (buffer: number[]): string => {
    const binary = new Uint8Array(buffer).reduce(
      (acc, byte) => acc + String.fromCharCode(byte),
      ''
    )
    return `data:image/jpeg;base64,${btoa(binary)}`
  }

  const fetchOfrDocumentTemplateImages = useCallback(
    async (refreshForceCall = false) => {
      if (!documentId) {
        return
      }
      if (!loading && ((!preventFetch && !isFetchDone) || refreshForceCall)) {
        setLoading(true)
        return ofrDocumentTemplateService
          .getOfrDocumentTemplateImages({ documentId })
          .then((response: OFrImage[]) => {
            const images: OFrImage[] = response.map((imageBuffer) => {
              const base64Image = bufferToBase64(imageBuffer.stream.data)
              return { ...imageBuffer, image: base64Image }
            })
            dispatch(setSelectedOfrDocumentTemplateImages(images))
            setLoading(false)
            setIsFetchDone(true)
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios get error', error)
            setLoading(false)
          })
      }
    },
    [dispatch, loading, preventFetch, isFetchDone, documentId]
  )

  const refreshOfrDocumentTemplateImages = useCallback(async () => {
    fetchOfrDocumentTemplateImages(true)
  }, [fetchOfrDocumentTemplateImages])

  useEffect(() => {
    fetchOfrDocumentTemplateImages(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    selectedOfrDocumentTemplateImages,
    refreshOfrDocumentTemplateImages,
    loading,
    error,
  }
}

export default useOfrDocumentTemplateImages
