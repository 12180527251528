import { AxiosError } from 'axios'
import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urlList } from '../constants/constants'
import { apiService } from '../services'
import workspaceService from '../services/workspaceService'
import { RootState } from '../store'
import {
  Workspace,
  setWorkspaceList,
  setWorkspaceListCsv,
} from '../store/reducers/workspaceReducer'
import { HttpResponse } from '../types/generalTypes'
import useDownloadFile from './useDownloadFile'

export interface WorkspaceResponse extends HttpResponse {
  data: Workspace[]
}

interface UseWorkspaceListProps {
  workspaceId?: string
  algorithmRunId?: string
  preventFetch?: boolean
}

const useWorkspaces = ({ preventFetch = false }: UseWorkspaceListProps) => {
  const dispatch = useDispatch()
  const workspaceList: Workspace[] | undefined = useSelector(
    (state: RootState) => state.workspace.workspaceList
  )
  const downloadCsv = useDownloadFile()
  const [error, setError] = useState<AxiosError | Error>()
  const [loading, setLoading] = useState(false)

  const fetchWorkspaceList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        setLoading(true)
        apiService
          .fetchItems(urlList.WORKSPACES)
          .then((response: WorkspaceResponse) => {
            setLoading(false)
            dispatch(setWorkspaceList(response.data))
          })
          .catch((error: AxiosError | Error) => {
            setError(error)
            console.error('axios fetch error', error)
            setLoading(false)
          })
      }
    },
    [dispatch, preventFetch, loading]
  )

  const refreshWorkspaceList = useCallback(async () => {
    fetchWorkspaceList(true)
  }, [fetchWorkspaceList])

  useEffect(() => {
    fetchWorkspaceList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportWorkspaceListCsv = useCallback(() => {
    if (workspaceList) {
      workspaceService
        .exportWorkspaceList()
        .then((response) => {
          const fileName = 'workspace_list.csv'
          downloadCsv(response, fileName)
          dispatch(setWorkspaceListCsv(response))
        })
        .catch((error: any) => {
          console.error('Error fetching workspace list CSV data', error)
        })
    }
  }, [workspaceList, dispatch, downloadCsv])

  return {
    error,
    exportWorkspaceListCsv,
    fetchWorkspaceList,
    loading,
    refreshWorkspaceList,
    workspaceList,
  }
}

export default useWorkspaces
