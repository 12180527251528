import { Button, DatePicker, Divider, Select } from 'antd'
import { t } from 'i18next'
import moment from 'moment'
import { useMemo, useState, memo } from 'react'
import styled from 'styled-components'
import { SelectGroupOptions } from '../types/generalTypes'

const FieldContainer = styled.div`
  margin-bottom: 4px;
`

const CustomContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 4px 4px;
  gap: 8px;
`

const CustomLabelContainer = styled.div<{ selected: boolean }>`
  padding: 4px 8px;
  margin: 0 -4px;
  font-weight: ${(props) => (props.selected ? '600' : '400')};
  background-color: ${(props) => (props.selected ? '#f8f0ff' : 'inherit')};
`

export type TimePeriodDictionary = {
  ALL: TimePeriodValue
  _24H: TimePeriodValue
  WEEK: TimePeriodValue
  _30D: TimePeriodValue
  _3M: TimePeriodValue
  _6M: TimePeriodValue
  YEAR: TimePeriodValue
  CUSTOM: TimePeriodValue
}

export const timePeriodValues: TimePeriodDictionary = {
  ALL: 'ALL',
  _24H: '24H',
  WEEK: 'WEEK',
  _30D: '30D',
  _3M: '3M',
  _6M: '6M',
  YEAR: 'YEAR',
  CUSTOM: 'CUSTOM',
}

export type TimePeriodValue =
  | 'ALL'
  | '24H'
  | 'WEEK'
  | '30D'
  | '3M'
  | '6M'
  | 'YEAR'
  | 'CUSTOM'

export const getTextForTimePeriod = (timePeriodValue: TimePeriodValue) => {
  switch (timePeriodValue) {
    case timePeriodValues.ALL:
      return t('selectTimePeriodMenu.all')
    case timePeriodValues._24H:
      return t('selectTimePeriodMenu.24h')
    case timePeriodValues.WEEK:
      return t('selectTimePeriodMenu.week')
    case timePeriodValues._30D:
      return t('selectTimePeriodMenu.30days')
    case timePeriodValues._3M:
      return t('selectTimePeriodMenu.3months')
    case timePeriodValues._6M:
      return t('selectTimePeriodMenu.6months')
    case timePeriodValues.YEAR:
      return t('selectTimePeriodMenu.year')
    case timePeriodValues.CUSTOM:
      return t('selectTimePeriodMenu.year')
  }
}

interface TimePeriodStartEndDate {
  startDate: string
  endDate: string
}

export const getStartAndEndDate = (
  timePeriodValue: TimePeriodValue,
  startDateParam: string,
  endDateParam: string
): TimePeriodStartEndDate => {
  const today = () => moment().add(1, 'day').startOf('day')
  const format = moment.defaultFormatUtc
  switch (timePeriodValue) {
    case timePeriodValues.ALL:
      return {
        startDate: '',
        endDate: '',
      }
    case timePeriodValues._24H:
      return {
        startDate: moment().subtract(24, 'hours').format(format),
        endDate: today().format(format),
      }
    case timePeriodValues.WEEK:
      return {
        startDate: today().subtract(7, 'days').format(format),
        endDate: today().format(format),
      }
    case timePeriodValues._30D:
      return {
        startDate: today().subtract(30, 'days').format(format),
        endDate: today().format(format),
      }
    case timePeriodValues._3M:
      return {
        startDate: today().subtract(3, 'months').format(format),
        endDate: today().format(format),
      }
    case timePeriodValues._6M:
      return {
        startDate: today().subtract(6, 'months').format(format),
        endDate: today().format(format),
      }
    case timePeriodValues.YEAR:
      return {
        startDate: today().subtract(1, 'years').format(format),
        endDate: today().format(format),
      }
    case timePeriodValues.CUSTOM:
      return {
        startDate: moment(startDateParam).format(format),
        endDate: moment(endDateParam).format(format),
      }
  }

  return { startDate: '', endDate: '' }
}

export interface SelectTimePeriodOnChangeParams {
  timePeriodValue: TimePeriodValue
  startDate?: string
  endDate?: string
}

interface SelectTimePeriodMenuProps {
  onChange: (value: SelectTimePeriodOnChangeParams) => void
  defaultValue?: TimePeriodValue
}

const SelectTimePeriod = memo(
  ({ onChange, defaultValue }: SelectTimePeriodMenuProps) => {
    const [customStartDate, setCustomStartDate] = useState('')
    const [customEndDate, setCustomEndDate] = useState('')
    const [value, setValue] = useState({
      timePeriodValue: defaultValue || timePeriodValues.ALL,
    })
    const [open, setOpen] = useState(false)
    const [datePickerOpen, setDatePickerOpen] = useState(false)

    const handleChange = (value: TimePeriodValue) => {
      setOpen(false)
      const returnValue = { timePeriodValue: value }
      setValue(returnValue)
      onChange(returnValue)
    }

    const handleCustomClick = () => {
      setOpen(false)
      const returnValue = {
        timePeriodValue: timePeriodValues.CUSTOM,
        startDate: customStartDate,
        endDate: customEndDate,
      }
      setValue(returnValue)
      onChange(returnValue)
    }

    const mainOptions: SelectGroupOptions[] = useMemo(
      () => [
        {
          label: t('selectTimePeriodMenu.mainLabel'),
          options: [
            {
              label: t('selectTimePeriodMenu.all'),
              value: timePeriodValues.ALL,
            },
            {
              label: t('selectTimePeriodMenu.24h'),
              value: timePeriodValues._24H,
            },
            {
              label: t('selectTimePeriodMenu.week'),
              value: timePeriodValues.WEEK,
            },
            {
              label: t('selectTimePeriodMenu.30days'),
              value: timePeriodValues._30D,
            },
            {
              label: t('selectTimePeriodMenu.3months'),
              value: timePeriodValues._3M,
            },
            {
              label: t('selectTimePeriodMenu.6months'),
              value: timePeriodValues._6M,
            },
            {
              label: t('selectTimePeriodMenu.year'),
              value: timePeriodValues.YEAR,
            },
            {
              label: t('selectTimePeriodMenu.custom'),
              value: timePeriodValues.CUSTOM,
            },
          ],
        },
      ],
      []
    )

    return (
      <Select
        onChange={handleChange}
        defaultValue={timePeriodValues.ALL}
        value={value.timePeriodValue}
        options={mainOptions}
        placeholder={t('selectSource.placeholder')}
        style={{ minWidth: 150 }}
        open={open}
        onClick={() => setOpen(!open)}
        onBlur={() => {
          if (!datePickerOpen) {
            setOpen(false)
          }
        }}
        dropdownRender={(menu) => (
          <>
            {menu}
            <Divider />
            <CustomContainer onClick={(event) => event.stopPropagation()}>
              <CustomLabelContainer
                selected={value?.timePeriodValue === timePeriodValues.CUSTOM}
              >
                {t('selectTimePeriodMenu.custom')}
              </CustomLabelContainer>
              <FieldContainer>
                <DatePicker
                  onMouseDown={() => setDatePickerOpen(true)}
                  onChange={(value: any) => {
                    setCustomStartDate(value?.$d || '')
                    setDatePickerOpen(false)
                  }}
                  placeholder={t('selectTimePeriodMenu.startDate') || ''}
                />
              </FieldContainer>
              <FieldContainer>
                <DatePicker
                  onMouseDown={() => setDatePickerOpen(true)}
                  onChange={(value: any) => {
                    setCustomEndDate(value?.$d || '')
                    setDatePickerOpen(false)
                  }}
                  placeholder={t('selectTimePeriodMenu.endDate') || ''}
                />
              </FieldContainer>
              <Button onMouseDown={handleCustomClick}>
                {t('selectTimePeriodMenu.selectCustomButton')}
              </Button>
            </CustomContainer>
          </>
        )}
      ></Select>
    )
  }
)

export default SelectTimePeriod
