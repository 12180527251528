import { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { DetailsPageLayout } from '../../../components'
import {
  BACK_END_PULL_INTERVAL,
  algorithmRunStatuses,
} from '../../../constants/constants'
import { useAlgorithmRunDetails } from '../../../hooks'
import DelSmartFunctionDetailsPageHeader from './components/DelSmartFunctionDetailsPageHeader'
import DelSmartFunctionPanel from './panels/DelSmartFunctionPanel'

const DelSmartFunctionDetailsPage = () => {
  const { workspaceId, algorithmRunId } = useParams()
  const { selectedAlgorithmRun, refreshAlgorithmRun } = useAlgorithmRunDetails({
    algorithmRunId,
    workspaceId,
    preventFetch: true,
  })

  useEffect(() => {
    if (
      selectedAlgorithmRun?.runStatus !== algorithmRunStatuses.COMPLETE &&
      selectedAlgorithmRun?.runStatus !== algorithmRunStatuses.FAILED &&
      selectedAlgorithmRun?.runStatus !== algorithmRunStatuses.CANCELLED
    ) {
      const interval = setInterval(() => {
        refreshAlgorithmRun();
      }, BACK_END_PULL_INTERVAL);
  
      return () => clearInterval(interval);
    }
  }, [selectedAlgorithmRun, refreshAlgorithmRun]);
  
  return (
    <DetailsPageLayout>
      <DelSmartFunctionDetailsPageHeader />
      <DelSmartFunctionPanel />
    </DetailsPageLayout>
  )
}

export default DelSmartFunctionDetailsPage
