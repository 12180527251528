import { Select } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useAuditTrailsComponents } from '../hooks'
import { SelectOption } from '../types/generalTypes'

interface SelectAuditTrailsComponentProps {
  value?: string
  onChange?: (value: string | undefined) => void
}

const SelectAuditTrailsComponent = ({
  value,
  onChange,
}: SelectAuditTrailsComponentProps) => {
  const { auditTrailsComponents, loading } = useAuditTrailsComponents({})

  const [selectedValue, setSelectedValue] = useState<string | undefined>('all')

  useEffect(() => {
    if (value) {
      setSelectedValue(value)
    }
  }, [value])

  const options: SelectOption[] = useMemo(() => {
    const optionAll = {
      label: t('selectAuditTrailsComponent.allLogs'),
      value: 'all',
    }
    const options =
      auditTrailsComponents?.map((component) => ({
        label: component,
        value: component,
      })) || []
    return [optionAll, ...options]
  }, [auditTrailsComponents])

  return (
    <Select
      options={options}
      loading={loading}
      onChange={onChange}
      value={selectedValue}
      style={{ minWidth: 200 }}
    />
  )
}

export default SelectAuditTrailsComponent
