import styled from 'styled-components'

export const SmartFunctionListPanelLayout = styled.div`
  background-color: var(--color-bg-layout, #f5f5f5);
  height: calc(100vh - 224px);
  margin-left: -16px;
  margin-right: -16px;
  padding: 16px;
  border-radius: 16px 16px 0px 0px;
  overflow-y: auto;
  @media print {
    height: auto;
  }
`
