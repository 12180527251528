import { Divider } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useMemo } from 'react'
import {
  ModalContentContainer,
  SelectParticipant,
} from '../../../../components'
import { REGDOCS_TYPECODE, privacyTypes } from '../../../../constants/constants'
import ModalFormReviewSection from '../component/ModalFormReviewSection'

interface CreateDataStoreReviewStepProps {
  form: FormInstance
}

const CreateDataStoreReviewStep = memo(
  ({ form }: CreateDataStoreReviewStepProps) => {
    const visibility = useMemo(
      () => form.getFieldValue('visibility') || '',
      [form]
    )

    const participants = useMemo(
      () => form.getFieldValue('participants') || [],
      [form]
    )

    const title = useMemo(() => form.getFieldValue('title') || '', [form])

    const contactEmail = useMemo(
      () => form.getFieldValue('contactEmail') || '',
      [form]
    )
    const contactName = useMemo(
      () => form.getFieldValue('contactName') || '',
      [form]
    )

    const sourceName = useMemo(
      () => form.getFieldValue('sourceName') || '',
      [form]
    )

    const libraryName = useMemo(() => {
      const sourceCode = form.getFieldValue('sourceCode') || ''
      return sourceCode === REGDOCS_TYPECODE
        ? form.getFieldValue(['config', 'sourceLibrary']) || ''
        : undefined
    }, [form])

    const description = useMemo(() => {
      const descriptionValue = form.getFieldValue('description') || ''
      return descriptionValue !== '' && descriptionValue !== undefined
        ? descriptionValue
        : undefined
    }, [form])

    return (
      <ModalContentContainer>
        <Divider orientation="left" plain>
          {t('createDataStoreModal.reviewStep.intendedDefinition')}
        </Divider>
        <ModalFormReviewSection
          label={t('createDataStoreModal.reviewStep.dataStoreName')}
          value={title}
        />
        {description && (
          <ModalFormReviewSection
            label={t('createDataStoreModal.reviewStep.description')}
            value={description}
          />
        )}
        <Divider orientation="left" plain>
          {t('createDataStoreModal.reviewStep.specifedType')}
        </Divider>

        <ModalFormReviewSection
          label={t('createDataStoreModal.reviewStep.dataStoreType')}
          value={sourceName}
        />

        {libraryName && (
          <ModalFormReviewSection
            label={t('createDataStoreModal.reviewStep.libraryName')}
            value={libraryName}
          />
        )}
        <Divider orientation="left" plain>
          {t('createDataStoreModal.reviewStep.selectedPrivacy')}
        </Divider>
        <ModalFormReviewSection
          label={t('createDataStoreModal.reviewStep.selectedPrivacy')}
          value={t(visibility) || ''}
        />
        {visibility === privacyTypes.PRIVATE && (
          <ModalFormReviewSection
            label="Participants"
            value={
              <SelectParticipant disabled initialParticipants={participants} />
            }
          />
        )}

        <Divider orientation="left" plain>
          {t('createDataStoreModal.reviewStep.contactInformation')}
        </Divider>
        <ModalFormReviewSection
          label={t('createDataStoreModal.reviewStep.contactName')}
          value={contactName}
        />
        <ModalFormReviewSection
          label={t('createDataStoreModal.reviewStep.contactEmail')}
          value={contactEmail}
        />
      </ModalContentContainer>
    )
  }
)

export default CreateDataStoreReviewStep
