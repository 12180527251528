import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { NO_DATA_PLACEHOLDER } from '../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../hooks'
import { smartFunctionService } from '../../../../services'
import {
  AlgorithmRun,
  BiomarkersRunResult,
} from '../../../../store/reducers/workspaceReducer'
import WorkspaceStatistic from './WorkspaceStatistic'
import WorkspaceStatisticsPanelContainer from './WorkspaceStatisticsPanelContainer'

interface BioMarkerSmartFunctionStatistics {
  retainedFeatures?: number
  score?: number
}

const BioMarkerSmartFunctionStatisticsPanel = () => {
  const dispatch = useDispatch()
  const { selectedWorkspace } = useWorkspaceDetails({ preventFetch: true })

  const [statistics, setStatistics] =
    useState<BioMarkerSmartFunctionStatistics>()

  const mostRecentCompleteAlgorithmRun = useMemo(() => {
    if (!selectedWorkspace?.algorithmRuns) {
      return undefined
    }
    return selectedWorkspace?.algorithmRuns
      .filter(
        (obj) => obj.runStatus === 'COMPLETE' && obj.completedDate !== null
      )
      .reduce((prev, current) => {
        if (prev.completedDate && current.completedDate) {
          return Date.parse(prev.completedDate) >
            Date.parse(current.completedDate)
            ? prev
            : current
        } else if (prev.completedDate) {
          return prev
        } else {
          return current
        }
      }, selectedWorkspace.algorithmRuns[0])
  }, [selectedWorkspace])

  const calculateStatistics = useCallback((res: AlgorithmRun) => {
    if (!res.runResult) {
      return { retainedFeatures: undefined, score: undefined }
    }

    const biomarkerResult = res.runResult as BiomarkersRunResult
    const bestMask = biomarkerResult.bestMask || [0]

    const retainedFeatures =
      bestMask.reduce((a, b) => a + b, 0) / bestMask.length
    const score = biomarkerResult?.f1Score || 0

    return { retainedFeatures, score }
  }, [])

  useEffect(() => {
    if (mostRecentCompleteAlgorithmRun && selectedWorkspace?.id) {
      smartFunctionService
        .getWorkspaceAlgorithmRun({
          workspaceId: selectedWorkspace?.id,
          algorithmRunId: mostRecentCompleteAlgorithmRun.id,
        })
        .then((res) => {
          const values = calculateStatistics(res)
          setStatistics(values)
        })
        .catch((error) => {
          console.error('axios fetch error', error)
        })
    }
  }, [
    mostRecentCompleteAlgorithmRun,
    selectedWorkspace,
    calculateStatistics,
    dispatch,
  ])

  return (
    <WorkspaceStatisticsPanelContainer>
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.biomarkerStatistics.retainedFeatures'
        )}
        value={
          statistics?.retainedFeatures
            ? statistics.retainedFeatures.toFixed(2)
            : NO_DATA_PLACEHOLDER
        }
      />
      <WorkspaceStatistic
        title={t(
          'workspaceDetailsPage.workspaceOverviewInfoPanel.biomarkerStatistics.score'
        )}
        value={
          statistics?.score ? statistics.score.toFixed(2) : NO_DATA_PLACEHOLDER
        }
      />
    </WorkspaceStatisticsPanelContainer>
  )
}

export default BioMarkerSmartFunctionStatisticsPanel
