import { InputNumber, Slider } from 'antd'
import styled from 'styled-components'

interface RangeSliderProps {
  min: number
  max: number
  onChange: (newValue: number | null) => void
  value: number
  isDecimal?: boolean
}

const SliderContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`

const StyledSlider = styled(Slider)`
  flex-grow: 1;
`

export const RangeSlider = ({
  min = 1,
  max = 20,
  onChange,
  value,
  isDecimal = false,
}: RangeSliderProps) => {
  const step = isDecimal ? 0.01 : 1
  return (
    <SliderContainer>
      <StyledSlider
        min={min}
        max={max}
        onChange={onChange}
        value={value}
        step={step} // Set step to 0.1 for decimal values
      />
      <InputNumber
        min={min}
        max={max}
        step={step} // Set step to 0.1 for decimal values
        style={{ margin: '0 16px' }}
        value={value}
        onChange={onChange}
      />
    </SliderContainer>
  )
}
