import { Select } from 'antd'
import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { ReferenceType, referenceTypes } from '../RefAISmartFunctionDetailsPage'
import { SelectOption } from '../../../../types/generalTypes'
import { t } from 'i18next'

interface SelectReferenceTypeProps {
  onChange?: (value: ReferenceType | undefined) => void
}

const SelectDocumentReferenceType = ({
  onChange,
}: SelectReferenceTypeProps) => {
  const { referenceType } = useParams()
  const [selectedValue, setSelectedValue] = useState<ReferenceType>(
    (referenceType || referenceTypes.MISSING_REFERENCE) as ReferenceType
  )

  const options: SelectOption[] = [
    {
      label:
        t(
          'smartFunctionDetailsPage.refAISelectReferenceType.missingReferences'
        ) || '',
      value: referenceTypes.MISSING_REFERENCE,
    },
    {
      label:
        t(
          'smartFunctionDetailsPage.refAISelectReferenceType.ignoredReferences'
        ) || '',
      value: referenceTypes.IGNORED_REFERENCE,
    },
    {
      label:
        t('smartFunctionDetailsPage.refAISelectReferenceType.documents') || '',
      value: referenceTypes.DOCUMENT,
    },
  ]

  const handleChange = (value: ReferenceType) => {
    setSelectedValue(value)
    if (onChange) {
      onChange(value)
    }
  }

  return (
    <Select
      onChange={handleChange}
      value={selectedValue}
      options={options}
      placeholder={t(
        'smartFunctionDetailsPage.refAISelectReferenceType.missingReferences'
      )}
    />
  )
}

export default SelectDocumentReferenceType
