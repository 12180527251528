import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import {
  EGNYTE_TYPECODE,
  REGDOCS_TYPECODE,
  SHAREPOINT_TYPECODE,
} from '../../../../constants/constants'
import { useDataSourceTypes } from '../../../../hooks'
import { DataSourceType } from '../../../../store/reducers/dataSourceReducer'

interface CreateDataSourceTypeProps {
  form: FormInstance
}

const ALLOWED_DATASOURCE_TYPES = [
  SHAREPOINT_TYPECODE,
  EGNYTE_TYPECODE,
  REGDOCS_TYPECODE,
]

const CreateDataSourceTypeStep = memo(({ form }: CreateDataSourceTypeProps) => {
  const { dataSourceTypeList, loading } = useDataSourceTypes({
    allowedDataSourceTypes: ALLOWED_DATASOURCE_TYPES,
    errorNotificationMessage: t(
      'createDataSourceModal.typesStep.errorRetrievingTypes'
    ),
  })

  return (
    <Form.Item
      label={t('createDataSourceModal.typesStep.title')}
      name="sourceType"
      rules={[
        {
          required: true,
          message: t('errorMessage.isRequired') || '',
        },
      ]}
    >
      <Radio.Group buttonStyle="solid">
        <Space direction="vertical">
          {!loading &&
            dataSourceTypeList?.map((dataSourceType: DataSourceType) => (
              <Radio
                key={dataSourceType.id}
                value={dataSourceType.id}
                onChange={() => {
                  form.setFieldsValue({
                    sourceType: dataSourceType.id,
                    sourceName: dataSourceType.typeName,
                    sourceCode: dataSourceType.typeCode,
                  })
                }}
              >
                {dataSourceType.typeName}
              </Radio>
            ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
})

export default CreateDataSourceTypeStep
