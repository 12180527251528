import { Button } from 'antd'
import { t } from 'i18next'

interface ChangePasswordReviewStepFooterProps {
  onCancel: () => void
}

const ChangePasswordReviewStepFooter = ({
  onCancel,
}: ChangePasswordReviewStepFooterProps) => {
  return (
    <>
      <Button key="back" onClick={onCancel} type="text">
        {t('userDetailsPage.changePasswordModal.closeButton')}
      </Button>
    </>
  )
}

export default ChangePasswordReviewStepFooter
