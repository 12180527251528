import { NavigateFunction } from 'react-router-dom'
import { urlList } from '../constants/constants'
import {
  MissingReference,
  ReferBy,
  WorkspaceConfig,
  WorkspaceDetails,
} from '../store/reducers/workspaceReducer'
import { PrivacyType } from '../types/generalTypes'
import apiService from './apiService'

const baseUrl = urlList.WORKSPACES

interface CreateWorkSpaceProps {
  title: string
  description: string
  workspaceType: number
  dataSource: number
  dataStores: number[]
  visibility: string
  participants: string[]
  config?: WorkspaceConfig
  navigate?: NavigateFunction
}

const createWorkspace = async ({
  title,
  description,
  workspaceType,
  dataSource,
  dataStores,
  visibility,
  participants,
  config,
  navigate,
}: CreateWorkSpaceProps) => {
  const params = {
    title,
    description,
    workspaceType,
    dataSource,
    dataStores,
    visibility,
    participants,
    config,
  }
  return apiService
    .saveItem(`${baseUrl}`, navigate, params)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

const getWorkspaceType = async () => {
  return apiService
    .fetchItems(urlList.WORKSPACE_TYPES)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface GetWorkspaceDetailsProps {
  workspaceId: string
}

const getWorkspaceDetails = async ({
  workspaceId,
}: GetWorkspaceDetailsProps) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}`)
    .then((response: WorkspaceDetails) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface WorkspaceParams {
  title?: string
  description?: string
  workspaceType?: number
  visibility?: PrivacyType
  config?: WorkspaceConfig
  dataSource?: number
  dataStores?: number[]
  participants?: string[]
}

interface UpdateWorkspaceDetailsProps {
  title?: string
  description?: string
  workspaceType?: number
  dataSource?: number
  dataStores?: number[]
  visibility?: PrivacyType
  participants?: string[]
  config?: WorkspaceConfig
  navigate?: NavigateFunction
  workspaceId: string
}

const updateWorkspaceDetails = async ({
  title,
  description,
  workspaceType,
  dataSource,
  dataStores,
  visibility,
  participants,
  config,
  navigate,
  workspaceId,
}: UpdateWorkspaceDetailsProps) => {
  const params: WorkspaceParams = {}
  if (title) {
    params.title = title
  }
  if (description || description === '') {
    params.description = description
  }
  if (workspaceType) {
    params.workspaceType = workspaceType
  }
  if (dataSource) {
    params.dataSource = dataSource
  }
  if (Array.isArray(dataStores)) {
    params.dataStores = dataStores
  }
  if (visibility) {
    params.visibility = visibility
  }
  if (participants) {
    params.participants = participants
  }
  if (config) {
    params.config = config
  }

  return apiService
    .updateItem(`${baseUrl}/${workspaceId}`, navigate, params)
    .then((response: WorkspaceDetails) => {
      return response
    })
    .catch((error) => {
      console.error('axios create error', error)
      throw error
    })
}

interface IncludeExcludeDocument {
  workspaceConfig?: WorkspaceConfig
  missingReference: string
  workspaceId: string
}

const includeExcludeMissingReference = async ({
  workspaceConfig,
  missingReference,
  workspaceId,
}: IncludeExcludeDocument) => {
  const excludedReference: string[] = [
    ...(workspaceConfig?.excludedMissingReference || []),
  ]

  if (excludedReference?.includes(missingReference)) {
    excludedReference.splice(excludedReference.indexOf(missingReference), 1)
  } else {
    excludedReference.push(missingReference)
  }

  return updateWorkspaceDetails({
    workspaceId,
    config: { excludedMissingReference: excludedReference },
  })
    .then((response: WorkspaceDetails) => {
      return response
    })
    .catch((error) => {
      throw error
    })
}

const exportWorkspaceList = async () => {
  return apiService
    .fetchItems(`${baseUrl}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

interface ExportWorkspaceDetailsProp {
  workspaceId: string
}

const exportWorkspaceDetails = async ({
  workspaceId,
}: ExportWorkspaceDetailsProp) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const exportWorkspaceDataSource = async ({
  workspaceId,
}: ExportWorkspaceDetailsProp) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/dataSource/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const exportWorkspaceDataStores = async ({
  workspaceId,
}: ExportWorkspaceDetailsProp) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/dataStore/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const exportWorkspaceParticipants = async ({
  workspaceId,
}: ExportWorkspaceDetailsProp) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/participants/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

const exportWorkspaceAlgorithmRunsList = async ({
  workspaceId,
}: ExportWorkspaceDetailsProp) => {
  return apiService
    .fetchItems(`${baseUrl}/${workspaceId}/algorithmRuns/csv`)
    .then((response) => {
      return response
    })
    .catch((error) => {
      console.error('axios get error', error)
      throw error
    })
}

// We need to keep this in the service here, because it is likely that it will change with future version of RefAI
// One central place to manage that will be easier
const getRefAIMissingReferenceKey = (missingReference: MissingReference) =>
  missingReference.missing || missingReference.missingFile

const getReferByIdKey = (referBy: ReferBy) => referBy.id || referBy.referById

const workspaceService = {
  createWorkspace,
  exportWorkspaceAlgorithmRunsList,
  exportWorkspaceDataSource,
  exportWorkspaceDataStores,
  exportWorkspaceDetails,
  exportWorkspaceList,
  exportWorkspaceParticipants,
  getRefAIMissingReferenceKey,
  getReferByIdKey,
  getWorkspaceDetails,
  getWorkspaceType,
  includeExcludeMissingReference,
  updateWorkspaceDetails,
}

export default workspaceService
