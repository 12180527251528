import { FileWordOutlined, FolderOpenOutlined } from '@ant-design/icons'
import Tree, { DataNode } from 'antd/es/tree'
import { Key } from 'antd/lib/table/interface'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import styled from 'styled-components'
import { SectionLoadingState } from '../../../../components'
import useWorkspaceFolders from '../../../../hooks/useWorkspaceFolders'
import { WorkspaceFolder } from '../../../../store/reducers/workspaceReducer'
import { SmartFunctionListPanelLayout } from '../../components/SmartFunctionListPanelLayout'

const { DirectoryTree } = Tree

const SectionLoadingStateContainer = styled.div`
  display: flex;
  height: calc(100vh - 224px);
`

interface RefAISmartFunctionFoldersPanelProps {
  search: string
}

const RefAISmartFunctionFoldersPanel = ({
  search,
}: RefAISmartFunctionFoldersPanelProps) => {
  const { workspaceId } = useParams()
  const { isWorkspaceFilesLoading, workspaceFolders } = useWorkspaceFolders({
    workspaceId,
    preventFetch: true,
  })
  const [extendedKeys, setExtendedKeys] = useState<Key[]>([])

  const handleOnExpand = (expandedKeys: Key[]) => {
    setExtendedKeys(expandedKeys)
  }

  const getName = useCallback((path: string) => path.split('/').pop(), [])

  const formatDocuments = useCallback(
    (folder: WorkspaceFolder) =>
      folder.files
        .filter((document) =>
          !!search ? document.path.includes(search || '') : true
        )
        .map((document) => ({
          key: document.id,
          title: getName(document.path),
          icon: <FileWordOutlined />,
          selectable: false,
        })),
    [search, getName]
  )

  const isNodeHavingChildren = useCallback(
    (formattedFolder: DataNode) =>
      !!formattedFolder.children &&
      Array.isArray(formattedFolder?.children) &&
      formattedFolder?.children.length > 0,
    []
  )

  const formattedNode = useCallback(
    (folder: WorkspaceFolder) => {
      const documents: DataNode[] = formatDocuments(folder)
      const folders = [
        ...folder.folders
          .map((folder) => formattedNode(folder))
          .filter((formattedFolder) =>
            !!search ? isNodeHavingChildren(formattedFolder) : true
          ),
      ]
      const formattedFolder: DataNode = {
        key: folder.path,
        title: getName(folder.path),
        children: [...folders, ...documents],
        icon: <FolderOpenOutlined />,
        selectable: false,
      }

      return formattedFolder
    },
    [search, formatDocuments, isNodeHavingChildren, getName]
  )

  const formattedData: DataNode[] = useMemo(() => {
    const formattedData = workspaceFolders?.map((folder) => {
      const documents: DataNode[] = formatDocuments(folder)
      const folders = [
        ...folder.folders
          .map((folder) => formattedNode(folder))
          .filter((formattedFolder) =>
            !!search ? isNodeHavingChildren(formattedFolder) : true
          ),
      ]

      const formattedFolder: DataNode = {
        key: folder.path,
        title: folder.path,
        children: [...folders, ...documents],
        selectable: false,
      }

      return formattedFolder
    })
    return formattedData || []
  }, [
    workspaceFolders,
    formattedNode,
    search,
    formatDocuments,
    isNodeHavingChildren,
  ])

  const flatNodeDataTree: (dataNodeArray?: DataNode[]) => DataNode[] =
    useCallback(
      (dataNodeArray?: DataNode[]) =>
        dataNodeArray
          ? dataNodeArray.reduce(
              (final: DataNode[], dataNode: DataNode) => [
                ...final,
                dataNode,
                ...flatNodeDataTree(dataNode.children as DataNode[]),
              ],
              []
            )
          : [],
      []
    )

  useEffect(() => {
    if (formattedData) {
      const flatData = flatNodeDataTree(formattedData)
      const keysToExtend = flatData.map((data) => data.key.toString())
      setExtendedKeys(keysToExtend)
    }
  }, [formattedData, flatNodeDataTree])

  return isWorkspaceFilesLoading ? (
    <SectionLoadingStateContainer>
      <SectionLoadingState />
    </SectionLoadingStateContainer>
  ) : (
    <SmartFunctionListPanelLayout>
      <DirectoryTree
        treeData={formattedData}
        expandedKeys={extendedKeys}
        onExpand={handleOnExpand}
      />
    </SmartFunctionListPanelLayout>
  )
}

export default RefAISmartFunctionFoldersPanel
