import { ReloadOutlined } from '@ant-design/icons'
import { Button, Form, Select } from 'antd'
import { t } from 'i18next'
import { FormInstance } from 'rc-field-form'
import { memo, useCallback, useEffect, useState } from 'react'
import { sharepointService } from '../../../../../services'
import notificationService from '../../../../../services/notificationService'
import { getSharePointSitesResponse } from '../../../../../services/sharepointService'
import { SelectOption } from '../../../../../types/generalTypes'

interface CreateDataStoreRegdocConfigProps {
  form: FormInstance
}

const CreateDataStoreRegdocConfig = memo(
  ({ form }: CreateDataStoreRegdocConfigProps) => {
    const [selectedSiteUrl, setSelectedSiteUrl] = useState(
      form.getFieldValue('config')?.sourceUrl || ''
    )

    const [siteUrls, setSiteUrls] = useState([
      {
        value: '',
        label: t('createDataStoreModal.regdocsConfig.rootSiteLabel') || '',
      },
    ])
    const [sharePointLibraries, setSharePointLibraries] = useState([])

    const handleSiteChange = (option: SelectOption) => {
      form.setFieldsValue({
        config: {
          sourceUrl: option.value,
          sourceLibrary: null,
          sourceLibraryId: null,
        },
      })
      setSelectedSiteUrl(option.value)
    }

    const handleLibraryChange = (option: SelectOption) => {
      form.setFieldsValue({
        config: {
          sourceLibrary: option.label,
          sourceLibraryId: option.value,
        },
      })
    }

    const getSharePointSites = useCallback(() => {
      // TODO complete when backend is done
      sharepointService
        .getSharePointSites()
        .then((response) => {
          setSiteUrls([
            {
              value: '',
              label:
                t('createDataStoreModal.regdocsConfig.rootSiteLabel') || '',
            },
            ...response.map((site: getSharePointSitesResponse) => ({
              value: site.webUrl,
              label: site.name,
            })),
          ])
        })
        .catch((error) => {
          notificationService.notificationError(
            t('createDataStoreModal.regdocsConfig.errorRetrievingSites') ||
              error
          )
          console.error(error)
          setSharePointLibraries([])
        })
    }, [])

    useEffect(() => {
      getSharePointSites()
    }, [getSharePointSites])

    const getSharePointLibraries = useCallback(() => {
      sharepointService
        .getSharePointLibraries({
          siteUrl: selectedSiteUrl,
        })
        .then((response) => {
          setSharePointLibraries(
            response.map((library: any) => ({
              value: library.id,
              label: library.name,
            }))
          )
        })
        .catch((error) => {
          notificationService.notificationError(
            t('createDataStoreModal.regdocsConfig.errorRetrievingLibraries') ||
              error
          )
          console.error(error)
          setSharePointLibraries([])
        })
    }, [selectedSiteUrl])

    useEffect(() => {
      if (selectedSiteUrl !== undefined) {
        setSharePointLibraries([])
        getSharePointLibraries()
      }
    }, [getSharePointLibraries, selectedSiteUrl])

    return (
      <>
        <Form.Item
          label={t('createDataStoreModal.regdocsConfig.siteURLTitle')}
          name={['config', 'sourceUrl']}
          initialValue={selectedSiteUrl}
          style={{
            marginBottom: 12,
          }}
        >
          <Select
            showSearch
            placeholder={t('createDataStoreModal.regdocsConfig.siteURLTitle')}
            onChange={(_value: string, options: any) =>
              handleSiteChange(options)
            }
            disabled={siteUrls.length === 0}
            options={siteUrls}
            optionFilterProp="label"
          />
        </Form.Item>
        <Form.Item
          label={t('createDataStoreModal.regdocsConfig.sharePointLibraryTitle')}
          name={['config', 'sourceLibrary']}
          rules={[
            {
              required: true,
              message: t('errorMessage.isRequired') || '',
            },
          ]}
          extra={t(
            'createDataStoreModal.regdocsConfig.sharePointLibraryDescription'
          )}
        >
          <Select
            showSearch
            placeholder={t(
              'createDataStoreModal.regdocsConfig.sharePointSiteSelectorField'
            )}
            onChange={(_value: string, options: any) =>
              handleLibraryChange(options)
            }
            disabled={sharePointLibraries.length === 0}
            options={sharePointLibraries}
            optionFilterProp="label"
          />
        </Form.Item>
        <Button
          onClick={getSharePointLibraries}
          icon={<ReloadOutlined />}
          block
        >
          {t('createDataStoreModal.regdocsConfig.refreshButtonText')}
        </Button>
      </>
    )
  }
)

export default CreateDataStoreRegdocConfig
