import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { HttpResponse } from '../../types/generalTypes'
import { OfrBlockType } from '../../constants/constants'

export interface OfrCell {
  isGhost?: boolean
  x1?: number
  y1?: number
  x2?: number
  y2?: number
}

export interface OcrText {
  level: number
  pageNum: number
  block: number
  paragraphNum: number
  lineNum: number
  wordNum: number
  top: number
  left: number
  width: number
  height: number
  confidence: number
  text: string
}

export interface WritingBlocks {
  id: number
  x: number
  y: number
  width: number
  height: number
}

export interface HtrText {
  text: string
  writingBlocks: WritingBlocks[]
  x1: number
  y1: number
  x2: number
  y2: number
}

export interface OfrBlockInfo {
  id: string
  ofrBlockName?: string
  ofrBlockType?: OfrBlockType
  cell?: OfrCell
  deviations?: number
  ocrTexts?: OcrText[]
  htrTexts?: HtrText[]
  isCheckbox?: boolean
  containsWriting?: boolean
  hasStrikeThrough?: boolean
  hasStrikeThroughText?: boolean
}

export interface OfrDocumentTemplatePage {
  id: string
  DPI: number
  writingHeightCM: number
  writingWidthCM: number
  imageWidth: number
  imageHeight: number
  blocks: OfrBlockInfo[]
}

export enum ofrStatusEnum {
  READY = 'READY',
  PROCESSING = 'PROCESSING',
  INGESTION = 'INGESTION',
  FAILED = 'FAILED',
}

type OFRStatusType = keyof typeof ofrStatusEnum

export interface OfrDocumentTemplate {
  id: string
  title: string
  formPages?: OfrDocumentTemplatePage[]
  description?: string | null
  dataSource: string
  documentId: string
  status: OFRStatusType
  createdBy?: string
  lastModifiedBy?: string
  createdDate?: string
  lastModifiedDate?: string
  archived?: boolean
}

export interface OfrDocumentTemplateResponse extends HttpResponse {
  data: OfrDocumentTemplate[]
}

export interface OfrState {
  ofrDocumentTemplateList?: OfrDocumentTemplate[]
  selectedDocumentTemplate?: OfrDocumentTemplate
  selectedDocumentTemplateImages?: OFrImage[]
  ofrDocumentTemplateListCsv?: string
  loading: boolean
}

const initialState: OfrState = {
  loading: false,
  selectedDocumentTemplate: undefined,
}

export interface OfrFormImageBuffer {
  type: string
  data: number[]
}

export interface OFrImage {
  id: string
  stream: OfrFormImageBuffer
  image: string
}

const ofrDocumentTemplateSlice = createSlice({
  name: 'ofr',
  initialState,
  reducers: {
    setOfrDocumentTemplateList: (
      state,
      action: PayloadAction<OfrDocumentTemplate[]>
    ) => {
      state.ofrDocumentTemplateList = action.payload
    },

    setOfrLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setSelectedOfrDocumentTemplate: (
      state,
      action: PayloadAction<OfrDocumentTemplate>
    ) => {
      state.selectedDocumentTemplate = action.payload
    },
    setSelectedOfrDocumentTemplateImages: (
      state,
      action: PayloadAction<OFrImage[]>
    ) => {
      state.selectedDocumentTemplateImages = action.payload
    },
    setOfrDocumentTemplateListCsv: (state, action: PayloadAction<string>) => {
      state.ofrDocumentTemplateListCsv = action.payload
    },
    clearOfrState: () => initialState,
  },
})

export const {
  setOfrDocumentTemplateList,
  setOfrDocumentTemplateListCsv,
  setOfrLoading,
  setSelectedOfrDocumentTemplate,
  setSelectedOfrDocumentTemplateImages,
  clearOfrState,
} = ofrDocumentTemplateSlice.actions

export const selectedDocumentTemplate = (state: RootState) =>
  state.ofrDocumentTemplate.selectedDocumentTemplate

const ofrDocumentTemplateReducer = ofrDocumentTemplateSlice.reducer
export default ofrDocumentTemplateReducer
