import { Spin } from 'antd'
import styled from 'styled-components'

const SectionContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0;
`

const LoadingStateContainer = styled.div`
  font-weight: 600;
  margin: auto;
`

const SectionLoadingState = () => {
  return (
    <SectionContainer data-testid="section-loading">
      <LoadingStateContainer>
        <Spin size="large" />
      </LoadingStateContainer>
    </SectionContainer>
  )
}

export default SectionLoadingState
