import { t } from 'i18next'
import ListItemDot from './ListItemDot'
import styled from 'styled-components'
import Link from 'antd/lib/typography/Link'
import {
  BREACH_DATABASE_LINK,
  COMMON_PASSWORD_LIST_LINK,
} from '../../constants/constants'

const TitleContainer = styled.div`
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 12px;
`

const PasswordRules = () => {
  return (
    <div>
      <TitleContainer>{t('passwordRules.title')}</TitleContainer>
      <div>
        <ListItemDot>{t('passwordRules.passwordLength')}</ListItemDot>
        <ListItemDot>{t('passwordRules.passwordReuse')}</ListItemDot>
        <ListItemDot>{t('passwordRules.name')}</ListItemDot>
        <ListItemDot>{t('passwordRules.birthday')}</ListItemDot>
        <ListItemDot>{t('passwordRules.dictionary')}</ListItemDot>
        <ListItemDot>
          {t('passwordRules.commonPassword')}{' '}
          <Link href={COMMON_PASSWORD_LIST_LINK} target="_blank">
            {t('passwordRules.commonPasswordLink')}
          </Link>
          .
        </ListItemDot>
        <ListItemDot>
          {t('passwordRules.breachDatabase')}{' '}
          <Link href={BREACH_DATABASE_LINK} target="_blank">
            {t('passwordRules.breachDatabaseLink')}
          </Link>{' '}
          {t('passwordRules.breachDatabase2')}.
        </ListItemDot>
      </div>
    </div>
  )
}

export default PasswordRules
