import styled from 'styled-components'

const DetailsPageLayout = styled.div`
  background: ${({ theme }) => theme.token.colorBgLayout};
  padding: 16px;
  min-height: calc(100vh - 64px);
  border-radius: 16px 16px 0px 0px;
`

export default DetailsPageLayout
