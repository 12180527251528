import { FormInstance } from 'antd'
import { t } from 'i18next'
import { useMemo } from 'react'
import { ModalContentContainer } from '../../../../../../components'
import { BiomarkConfigValues } from '../../../../../../store/reducers/workspaceReducer'
import ModalFormReviewSection from '../../../../../DataStoreListPage/modals/component/ModalFormReviewSection'

interface BiomarkConfigurationModalReviewStepProps {
  form: FormInstance<BiomarkConfigValues>
}

const BiomarkConfigurationModalReviewStep = ({
  form,
}: BiomarkConfigurationModalReviewStepProps) => {
  const maxScore: number = useMemo(() => form.getFieldValue('maxScore'), [form])
  const trials: number = useMemo(() => form.getFieldValue('trials'), [form])
  const testPercent: number = useMemo(
    () => form.getFieldValue('testPercent'),
    [form]
  )
  const generations: number = useMemo(
    () => form.getFieldValue('generations'),
    [form]
  )
  const population: number = useMemo(
    () => form.getFieldValue('population'),
    [form]
  )
  const mutationRate: number = useMemo(
    () => form.getFieldValue('mutationRate'),
    [form]
  )
  const numMutants: number = useMemo(
    () => form.getFieldValue('numMutants'),
    [form]
  )
  const numXovers: number = useMemo(
    () => form.getFieldValue('numXovers'),
    [form]
  )
  const numRefines: number = useMemo(
    () => form.getFieldValue('numRefines'),
    [form]
  )
  const modelType: string = useMemo(
    () => form.getFieldValue('modelType'),
    [form]
  )

  return (
    <ModalContentContainer>
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.maxScore')}
        value={maxScore}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.trials')}
        value={trials}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.testPercent')}
        value={testPercent}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.generations')}
        value={generations}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.population')}
        value={population}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.mutationRate')}
        value={mutationRate}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.numMutants')}
        value={numMutants}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.numXovers')}
        value={numXovers}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.numRefines')}
        value={numRefines}
      />
      <ModalFormReviewSection
        label={t('biomarkConfigurationModal.reviewStep.modelType')}
        value={t(
          `biomarkConfigurationModal.parameterStep.modelType.${modelType}`
        )}
      />
    </ModalContentContainer>
  )
}

export default BiomarkConfigurationModalReviewStep
