import { AxiosError } from 'axios'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { urlList } from '../constants/constants'
import { apiService } from '../services'
import { RootState } from '../store'
import { Role, setRoles } from '../store/reducers/roleReducer'

interface UseRolesProps {
  preventFetch?: boolean
}

const useRoles = ({ preventFetch = false }: UseRolesProps) => {
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState<AxiosError | Error>()
  const roleList: Role[] | undefined = useSelector(
    (state: RootState) => state.role.roles
  )

  useEffect(() => {
    if (!preventFetch && (!roleList || roleList.length === 0)) {
      setLoading(true)
      apiService
        .fetchItems(urlList.ROLES)
        .then((response: Role[]) => {
          setLoading(false)
          dispatch(setRoles(response))
        })
        .catch((error: AxiosError | Error) => {
          setError(error)
          console.error('axios fetch error', error)
          setLoading(false)
        })
    }
  }, [dispatch, roleList, preventFetch])

  return {
    roleList,
    loading,
    error,
  }
}

export default useRoles
