import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  permissionActions,
  permissionNames,
  privacyTypes,
  urlList,
} from '../constants/constants'
import { apiService, dataStoreService } from '../services'
import roleService from '../services/roleService'
import { RootState } from '../store'
import {
  DataStore,
  setDataStoreDetailsCsv,
  setDataStoreList,
  setDataStoreListCsv,
  setDataStoreLoading,
} from '../store/reducers/dataStoreReducer'
import useDownloadFile from './useDownloadFile'

interface UseDataStoresProps {
  preventFetch?: boolean
}

const useDataStores = ({ preventFetch = false }: UseDataStoresProps) => {
  const dispatch = useDispatch()
  const loading = useSelector((state: RootState) => state.dataStore.loading)
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const downloadCsv = useDownloadFile()
  const selectedDataStore: DataStore | undefined = useSelector(
    (state: RootState) => state.dataStore.selected
  )
  const rawDataStoreList: DataStore[] = useSelector(
    (state: RootState) => state.dataStore.dataStoreList
  )
  const dataStoreList: DataStore[] = useMemo(
    () =>
      rawDataStoreList
        .filter((dataStore: DataStore) =>
          currentUser &&
          roleService.hasPermission(
            currentUser,
            permissionNames.DATA_STORES,
            permissionActions.ALL
          )
            ? true
            : dataStore.visibility !== privacyTypes.PRIVATE
        )
        .map((dataStore: DataStore) => {
          return {
            ...dataStore,
            key: dataStore.id === undefined ? dataStore.id : dataStore.id,
          }
        }),
    [currentUser, rawDataStoreList]
  )

  const fetchDataStoreList = useCallback(
    (refreshForceCall = false) => {
      if (!loading && (!preventFetch || refreshForceCall)) {
        dispatch(setDataStoreLoading(true))
        apiService
          .fetchItems(urlList.DATA_STORES)
          .then((response: any) => {
            dispatch(setDataStoreLoading(false))
            dispatch(setDataStoreList(response.data))
          })
          .catch((error) => {
            console.error('axios fetch error', error)
            dispatch(setDataStoreLoading(false))
          })
      }
    },
    [dispatch, loading, preventFetch]
  )

  const refreshDataStoreList = useCallback(async () => {
    fetchDataStoreList(true)
  }, [fetchDataStoreList])

  useEffect(() => {
    fetchDataStoreList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const exportDataStoreList = useCallback(() => {
    if (dataStoreList) {
      dataStoreService
        .exportDataStoreList()
        .then((response) => {
          const fileName = 'dataStores.csv'
          downloadCsv(response, fileName)
          dispatch(setDataStoreListCsv(response))
        })
        .catch((error: any) => {
          console.error('Error fetching data source list CSV data', error)
        })
    }
  }, [dataStoreList, dispatch, downloadCsv])

  const exportDataStoreDetailsCsv = useCallback(
    (dataStoreId: string) => {
      if (!selectedDataStore || dataStoreId === undefined || dataStoreId === '')
        return

      dataStoreService
        .exportDataStoreDetails({dataStoreId})
        .then((response) => {
          const fileName = `${selectedDataStore?.title}_${selectedDataStore?.createdDate}.csv`
          downloadCsv(response, fileName)
          dispatch(setDataStoreDetailsCsv(response))
        })
        .catch((error: any) => {
          console.error('Error fetching data source details CSV data', error)
        })
    },
    [downloadCsv, selectedDataStore, dispatch]
  )

  return {
    dataStoreList,
    loading,
    refreshDataStoreList,
    selectedDataStore,
    exportDataStoreDetailsCsv,
    exportDataStoreList,
  }
}

export default useDataStores
