import { InboxOutlined } from '@ant-design/icons'
import { Spin } from 'antd'
import { t } from 'i18next'
import { useEffect, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import {
  ListPageLayout,
  PageContentDropdown,
  SearchTable,
  Spacer,
  SubHeader,
} from '../../components'
import { SearchTableRow } from '../../components/SearchTable'
import { NO_DATA_PLACEHOLDER, searchMethods } from '../../constants/constants'
import {
  useDataSources,
  useDownloadFile,
  useSearchDocuments,
} from '../../hooks'
import i18n from '../../i18n/i18n'
import { RootState } from '../../store'
import { SearchQueryParams } from '../../store/reducers/searchResultInfoReducer'
import { BackEndSortOrder } from '../../types/generalTypes'
import { routePaths } from '../RootPage'
import CreateLiteratureReviewModal from './modals/CreateLiteratureReviewModal'

const FullPageContainer = styled.div`
  display: flex;
  margin-top: 100px;
  width: 100%;
  flex: 1 0;
`

const InitialMessageContainer = styled.div`
  font-weight: 600;
  margin: auto;
`

const searchMethod = searchMethods.KEYWORD_SEARCH_METHOD
const MAX_RESULTS = 10000 // NLM only handles 10k results
const SearchPage = () => {
  const navigate = useNavigate()
  const { performSearch, searchLoading } = useSearchDocuments()
  const [firstSearchDone, setFirstSearchDone] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)

  const { searchResult, searchResultInfo } = useSelector((state: RootState) => {
    const { searchResult } = state.searchResultInfo
    const searchResultInfo = searchResult?.data || []
    return { searchResult, searchResultInfo }
  })

  const { query, sourceId, period, startDate, endDate }: SearchQueryParams =
    useSelector((state: RootState) => state.searchResultInfo.searchQueryParams)

  const { dataSourceList } = useDataSources({})
  const dataSource = useMemo(
    () => dataSourceList.find((source) => source.id === sourceId),
    [dataSourceList, sourceId]
  )

  const downloadCsv = useDownloadFile()

  const formattedData: SearchTableRow[] = useMemo(
    () =>
      (searchResultInfo || []).map((document, index: number) => {
        const language = i18n.language
        const title =
          typeof document?.title === 'string'
            ? document.title
            : document?.title?.[language] ?? NO_DATA_PLACEHOLDER
        const documentLink =
          typeof document?.documentLink === 'string'
            ? document.documentLink
            : document?.documentLink?.[language] ?? ''

        return {
          ...document,
          key: index.toString(),
          id: document._id ?? `missing-id-${index}`,
          title,
          publicationName: document?.publicationName ?? NO_DATA_PLACEHOLDER,
          authors: document?.authors ?? [],
          publicationDate:
            document?.publicationDate ??
            document?.createdDate ??
            NO_DATA_PLACEHOLDER,
          documentLink,
          language,
          csv: document?.csv ?? '',
        }
      }),
    [searchResultInfo]
  )

  useEffect(() => {
    if (searchResultInfo.length > 0) {
      setFirstSearchDone(true)
    }
  }, [searchResultInfo])

  useEffect(() => {
    setCurrentPage(searchResult.page || 0)
  }, [searchResult.page])

  const handleOnRowClick = (recordId: string) => {
    navigate(`${routePaths.SEARCH_DOCUMENT_DETAILS}/${recordId}`)
  }

  const handleSearchQueryExport = () => {
    if (searchResult.csv) {
      downloadCsv(searchResult.csv, 'search_results.csv')
    }
  }

  const refreshData = (
    page: number,
    searchField?: string,
    searchOrder?: BackEndSortOrder
  ) => {
    setCurrentPage(page)
    performSearch({
      searchText: query,
      sourceId: sourceId || '',
      period: period || '',
      startDate: startDate || '',
      endDate: endDate || '',
      sortField: searchField,
      sortOrder: searchOrder,
      page,
    })
  }

  const totalResult = useMemo(() => {
    return parseInt(searchResult?.totalResults ?? '0') < MAX_RESULTS
      ? parseInt(searchResult?.totalResults ?? '0')
      : MAX_RESULTS
  }, [searchResult])

  return (
    <ListPageLayout>
      <SubHeader>
        <>
          <InboxOutlined />{' '}
          {t('searchPage.totalResult', { count: formattedData.length })}
        </>
        <Spacer />
        <CreateLiteratureReviewModal
          disabled={searchLoading || formattedData.length === 0}
          query={query || ''}
          searchMethod={searchMethod}
          sourceId={sourceId || ''}
          period={period || ''}
          total={formattedData.length}
        />
        <PageContentDropdown
          onExport={handleSearchQueryExport}
          disabled={
            searchLoading || formattedData.length === 0 || !searchResult?.csv
          }
        />
      </SubHeader>
      {firstSearchDone ? (
        <Spin spinning={searchLoading}>
          <SearchTable
            formattedData={formattedData}
            onClick={(recordId) => handleOnRowClick(recordId)}
            maxHeight="calc(100vh - 230px)"
            usePagination
            refreshData={refreshData}
            totalResult={totalResult}
            currentPage={currentPage}
            dataSourceVisibility={dataSource?.visibility}
          />
        </Spin>
      ) : (
        <FullPageContainer>
          <InitialMessageContainer>
            {t('searchPage.initialMessage')}
          </InitialMessageContainer>
        </FullPageContainer>
      )}
    </ListPageLayout>
  )
}

export default SearchPage
