import { TablePaginationConfig } from 'antd'
import { SorterResult } from 'antd/es/table/interface'
import Table, { ColumnsType } from 'antd/lib/table'
import { t } from 'i18next'
import moment from 'moment'
import { useEffect, useMemo, useState } from 'react'
import Moment from 'react-moment'
import { useLocation } from 'react-router-dom'
import styled from 'styled-components'
import {
  ListPageHeader,
  ListPageLayout,
  PageContentDropdown,
  SectionLoadingState,
  TableBoldColumnContainer,
} from '../../components'
import { TimePeriodValue } from '../../components/SelectTimePeriod'
import { NO_DATA_PLACEHOLDER, dateFormat } from '../../constants/constants'
import { useAuditTrails } from '../../hooks'
import { AuditTrail } from '../../store/reducers/auditTrailReducer'
import { FilterValue, TableCurrentDataSource } from 'antd/lib/table/interface'

const FullPageContainer = styled.div`
  display: flex;
  width: 100%;
  flex: 1 0;
`

const InitialMessageContainer = styled.div`
  font-weight: 600;
  margin: auto;
`

interface AuditTrailExtended extends AuditTrail {
  key: string
}

const AuditTrailListPage = () => {
  const { search } = useLocation()
  const query = new URLSearchParams(search)
  const [currentPage, setCurrentPage] = useState(1)
  const [currentSort, setCurrentSort] = useState<
    SorterResult<AuditTrail> | SorterResult<AuditTrail>[] | undefined
  >()

  const {
    auditTrails,
    auditTrailsTotal,
    loading,
    refreshAuditTrailList,
    exportAuditTrailCsv,
  } = useAuditTrails({
    timePeriod: (query.get('timePeriod') || '') as TimePeriodValue,
    startDateParam: query.get('startDate') || '',
    endDateParam: query.get('endDate') || '',
    component: query.get('component') || '',
    preventFetch: true,
  })

  useEffect(() => {
    refreshAuditTrailList()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search])

  const formattedData: AuditTrailExtended[] =
    useMemo(
      () =>
        auditTrails?.map((auditTrail: AuditTrail) => ({
          ...auditTrail,
          key: auditTrail.id,
        })),
      [auditTrails]
    ) || []

  const columns: ColumnsType<AuditTrail> = useMemo(
    () => [
      {
        title: t('ID'),
        dataIndex: 'id',
        key: 'id',
        width: '10%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.id && b.id ? a.id.localeCompare(b.id) : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field === 'id'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (text) => (
          <TableBoldColumnContainer>
            {text || NO_DATA_PLACEHOLDER}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('Component'),
        dataIndex: 'requestComponent',
        key: 'component',
        width: '15%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.requestComponent && b.requestComponent
            ? a.requestComponent.localeCompare(b.requestComponent)
            : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field === 'requestComponent'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (text) => (
          <TableBoldColumnContainer>
            {text || NO_DATA_PLACEHOLDER}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('Method'),
        dataIndex: 'requestMethod',
        key: 'method',
        width: '10%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.requestMethod && b.requestMethod
            ? a.requestMethod.localeCompare(b.requestMethod)
            : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field === 'requestMethod'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (text) => (
          <TableBoldColumnContainer>
            {text || NO_DATA_PLACEHOLDER}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('Route'),
        dataIndex: 'requestRoute',
        key: 'route',
        width: '25%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.requestRoute && b.requestRoute
            ? a.requestRoute.localeCompare(b.requestRoute)
            : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field === 'requestRoute'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (text) => (
          <TableBoldColumnContainer>
            {text || NO_DATA_PLACEHOLDER}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('Code'),
        dataIndex: 'responseCode',
        key: 'code',
        width: '10%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.responseCode && b.responseCode
            ? a.responseCode - b.responseCode
            : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field === 'requestCode'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (text) => (
          <TableBoldColumnContainer>
            {text || NO_DATA_PLACEHOLDER}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('User'),
        dataIndex: 'actionByDisplayName',
        key: 'user',
        width: '15%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.actionByDisplayName && b.actionByDisplayName
            ? a.actionByDisplayName.localeCompare(b.actionByDisplayName)
            : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field ===
            'actionByDisplayName'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (text) => (
          <TableBoldColumnContainer>
            {text || NO_DATA_PLACEHOLDER}
          </TableBoldColumnContainer>
        ),
      },
      {
        title: t('Date'),
        key: 'date',
        dataIndex: 'actionDate',
        width: '15%',
        sorter: (a: AuditTrail, b: AuditTrail) =>
          a.actionDate && b.actionDate
            ? moment(a.actionDate).valueOf() - moment(b.actionDate).valueOf()
            : -1,
        sortOrder:
          currentSort &&
          (currentSort as SorterResult<AuditTrail>).field === 'actionDate'
            ? (currentSort as SorterResult<AuditTrail>).order
            : undefined,
        render: (textDate) => (
          <>
            {textDate ? (
              <Moment local format={dateFormat.PRIMARY}>
                {textDate}
              </Moment>
            ) : (
              NO_DATA_PLACEHOLDER
            )}
          </>
        ),
      },
    ],
    [currentSort]
  )

  const handleExportAuditTrailList = () => {
    exportAuditTrailCsv()
  }

  const handleOnChange = (
    pagination: TablePaginationConfig,
    filters: Record<string, FilterValue | null>,
    sorter: SorterResult<AuditTrail> | SorterResult<AuditTrail>[],
    extra: TableCurrentDataSource<AuditTrail>
  ) => {
    refreshAuditTrailList(
      pagination.current,
      sorter as SorterResult<AuditTrail>
    )
    setCurrentPage(pagination.current || 0)
    setCurrentSort(sorter)
  }

  return loading ? (
    <SectionLoadingState />
  ) : (
    <>
      <ListPageLayout data-testid="data-sources-list">
        <ListPageHeader
          title={t('auditTrailListPage.title', {
            count: auditTrailsTotal,
          })}
          actions={
            <PageContentDropdown
              onExport={handleExportAuditTrailList}
              disabled={formattedData.length === 0}
            />
          }
        />
        {auditTrails.length === 0 ? (
          <FullPageContainer>
            <InitialMessageContainer>
              {t('auditTrailListPage.initialMessage')}
            </InitialMessageContainer>
          </FullPageContainer>
        ) : (
          <Table
            onChange={handleOnChange}
            data-testid="data-sources-list-table"
            rowClassName="page-list-table-row clickable"
            columns={columns}
            dataSource={formattedData}
            pagination={{
              current: currentPage,
              total: auditTrailsTotal,
              pageSize: 100,
              showSizeChanger: false,
              position: ['bottomLeft'],
            }}
            scroll={{ y: 'calc(100vh - 222px)' }}
          />
        )}
      </ListPageLayout>
    </>
  )
}

export default AuditTrailListPage
