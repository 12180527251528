import { Divider, FormInstance } from 'antd'
import { t } from 'i18next'
import { memo, useMemo } from 'react'
import {
  ModalContentContainer,
  ModalTextDescription,
  ModalTextLabel,
  SelectParticipant,
} from '../../../../components'
import { privacyTypes } from '../../../../constants/constants'
import { DataSource } from '../../../../store/reducers/dataSourceReducer'
import { DataStore } from '../../../../store/reducers/dataStoreReducer'

interface CreateWorkspaceModalReviewStepProps {
  form: FormInstance
  selectedDataSource?: DataSource
  selectedDataStore?: DataStore
}

const CreateWorkspaceModalReviewStep = memo(
  ({
    form,
    selectedDataSource,
    selectedDataStore,
  }: CreateWorkspaceModalReviewStepProps) => {
    const workspaceType = useMemo(
      () => form.getFieldValue('workspaceTypeName') || '',
      [form]
    )

    const visibility = useMemo(
      () => form.getFieldValue('visibility') || '',
      [form]
    )

    const participants = useMemo(
      () => form.getFieldValue('participants') || [],
      [form]
    )

    const title = useMemo(() => form.getFieldValue('title') || '', [form])
    const description = useMemo(
      () => form.getFieldValue('description') || '',
      [form]
    )

    return (
      <ModalContentContainer>
        <Divider orientation="left" plain>
          {t('createWorkspaceModal.reviewStep.intendedDefinition')}
        </Divider>
        <ModalTextLabel>
          {t('createWorkspaceModal.reviewStep.name')}
        </ModalTextLabel>
        <ModalTextDescription>{title}</ModalTextDescription>
        <ModalTextLabel>
          {t('createWorkspaceModal.reviewStep.description')}
        </ModalTextLabel>
        <ModalTextDescription>{description}</ModalTextDescription>
        <Divider orientation="left" plain>
          {t('createWorkspaceModal.reviewStep.specifedType')}
        </Divider>
        <ModalTextLabel>
          {t('createWorkspaceModal.reviewStep.type')}
        </ModalTextLabel>
        <ModalTextDescription>{workspaceType}</ModalTextDescription>
        <Divider orientation="left" plain>
          {t('createWorkspaceModal.reviewStep.selectedPrivacy')}
        </Divider>
        <ModalTextLabel>
          {t('createWorkspaceModal.reviewStep.privacySettings')}
        </ModalTextLabel>
        <ModalTextDescription>{t(visibility) || ''}</ModalTextDescription>
        {selectedDataSource && (
          <>
            <Divider orientation="left" plain>
              {t('createWorkspaceModal.reviewStep.selectedResource')}
            </Divider>
            <ModalTextLabel>
              {t('createWorkspaceModal.reviewStep.dataSource')}
            </ModalTextLabel>
            <ModalTextDescription>
              {selectedDataSource.title || ''}
            </ModalTextDescription>
          </>
        )}
        {selectedDataStore && (
          <>
            <Divider orientation="left" plain>
              {t('createWorkspaceModal.reviewStep.selectedStore')}
            </Divider>
            <ModalTextLabel>
              {t('createWorkspaceModal.reviewStep.dataStore')}
            </ModalTextLabel>
            <ModalTextDescription>
              {selectedDataStore.title || ''}
            </ModalTextDescription>
          </>
        )}

        {visibility === privacyTypes.PRIVATE && (
          <>
            <ModalTextLabel>
              {t('createWorkspaceModal.reviewStep.participants')}
            </ModalTextLabel>
            <SelectParticipant disabled initialParticipants={participants} />
          </>
        )}
      </ModalContentContainer>
    )
  }
)

export default CreateWorkspaceModalReviewStep
