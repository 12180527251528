import { t } from 'i18next'
import styled from 'styled-components'

const TextContainer = styled.div`
  font-weight: 600;
`

const ChangePasswordReviewStep = () => {
  return (
    <TextContainer>
      {t('userDetailsPage.changePasswordModal.reviewDescription')}
    </TextContainer>
  )
}

export default ChangePasswordReviewStep
