import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { PrivacyType } from '../../types/generalTypes'
import { User } from './userReducer'

export interface DataSource {
  id: string
  clusterId?: null | string
  externalId?: null | string
  oid?: null | string
  contactName: string
  contactEmail: string
  createdBy: string
  createdDate: string
  config?: DataSourceConfigType
  defaultSource?: boolean
  description: string
  isInternal?: boolean
  lastModifiedBy: string
  lastModifiedDate: string
  organizationName: string
  participants: User[]
  searchable: boolean
  sourceType?: SourceType
  title: string
  typeName: string
  typeCode: string
  visibility: PrivacyType
}

export interface DataSourceType {
  createdBy: string
  createdDate: string
  id: string
  isInternal: boolean
  lastModifiedBy: string
  lastModifiedDate: string
  typeCode: string
  typeName: string
}

export type SourceType = '1' | '2' | '3'

export interface DataSourceConfigType {
  tenantUid?: string
  sourceFolder?: string
  sourceFolderId?: string
  sourceUrl?: string
  sourceLibrary?: string
  sourceLibraryId?: string
}

export interface EgnyteSecrets {
  authCode: string
}

export interface DataSourceState {
  dataSourceList: DataSource[]
  dataSourceTypeList?: DataSourceType[]
  default?: DataSource
  selected?: DataSource
  loading: boolean
  listCsv?: string
  detailsCsv?: string
}

const initialState: DataSourceState = {
  dataSourceList: [],
  dataSourceTypeList: undefined,
  loading: false,
  listCsv: undefined,
  detailsCsv: undefined,
}

const dataSourceSlice = createSlice({
  name: 'dataSources',
  initialState,
  reducers: {
    setDataSourceList: (state, action: PayloadAction<DataSource[]>) => {
      state.dataSourceList = action.payload
    },
    setDataSourceTypeList: (state, action: PayloadAction<DataSourceType[]>) => {
      state.dataSourceTypeList = action.payload
    },
    setDataSourceLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    setDefaultDataSource: (state, action: PayloadAction<DataSource>) => {
      state.default = action.payload
    },
    setSelectedDataSource: (state, action: PayloadAction<DataSource>) => {
      state.selected = action.payload
    },
    setDataSourceListCsv(state, action: PayloadAction<string>) {
      state.listCsv = action.payload
    },
    setDataSourceDetailsCsv(state, action: PayloadAction<string>) {
      state.detailsCsv = action.payload
    },
    clearDataSourceState: () => initialState,
  },
})

export const {
  clearDataSourceState,
  setDataSourceDetailsCsv,
  setDataSourceList,
  setDataSourceListCsv,
  setDataSourceTypeList,
  setDataSourceLoading,
  setDefaultDataSource,
  setSelectedDataSource,
} = dataSourceSlice.actions

export const getDataSources = (state: RootState) =>
  state.dataSource.dataSourceList

const dataSourceReducer = dataSourceSlice.reducer
export default dataSourceReducer
