import { Form, Steps } from 'antd'
import { SelectProps } from 'antd/lib'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ModalBase, ModalContentContainer } from '../../../../../components'
import { useWorkspaceDetails } from '../../../../../hooks'
import useWorkspaceFolders from '../../../../../hooks/useWorkspaceFolders'
import { smartFunctionService } from '../../../../../services'
import notificationService from '../../../../../services/notificationService'
import {
  AlgorithmRun,
  RegulatorySubmissionConfigValues,
} from '../../../../../store/reducers/workspaceReducer'
import RegulatorySubmissionConfigurationModalFooter from './footer/RegulatorySubmissionConfigurationModalFooter'
import RegulatorySubmissionConfigurationModalReviewStep from './steps/RegulatorySubmissionConfigurationModalReviewStep'
import RegulatorySubmissionConfigurationScopeStep from './steps/RegulatorySubmissionConfigurationModalScopeStep'

const DropDownItem = styled.div`
  width: 200px;
`

interface RegulatorySubmissionConfigurationModalProps {
  config?: RegulatorySubmissionConfigValues
}

export interface RegulatorySubmissionConfigurationModalFormValues {
  documents: SelectProps['options']
  tableOfContentIds: string[]
}

const RegulatorySubmissionConfigurationModal = ({
  config,
}: RegulatorySubmissionConfigurationModalProps) => {
  const [form] =
    Form.useForm<RegulatorySubmissionConfigurationModalFormValues>()
  const navigate = useNavigate()
  const [open, setOpen] = useState(false)
  const [confirmLoading, setConfirmLoading] = useState(false)
  const { workspaceId } = useParams()
  const [currentStep, setCurrentStep] = useState(0)
  const [isNextDisabled, setIsNextDisabled] = useState(true)
  const [options, setOptions] = useState<SelectProps['options']>([])

  const { selectedWorkspace, refreshWorkspaceDetails } = useWorkspaceDetails({
    workspaceId,
    preventFetch: true,
  })

  const {
    workspaceFiles,
    refreshWorkspaceFolders,
    isWorkspaceFilesLoading,
    clearFolders,
  } = useWorkspaceFolders({
    workspaceId: workspaceId,
    preventFetch: true,
  })

  const parsePath = (filePath: string): string => {
    const parts = filePath.split('/')
    const filename = parts.pop()!
    const parentFolder = parts.pop() // Get the parent folder prefix
    return parentFolder ? `${parentFolder}/${filename}` : filename
  }
  useEffect(() => {
    if (open) {
      setOptions([])
      if (workspaceFiles && workspaceFiles.length > 0) {
        setOptions(
          workspaceFiles.map((file) => ({
            label: parsePath(file.path),
            value: file.id,
          }))
        )
      }
    }
  }, [open, workspaceFiles])

  const stepItems = [
    {
      title: t('regulatorySubmissionConfigurationModal.steps.scope'),
    },
    {
      title: t('regulatorySubmissionConfigurationModal.steps.review'),
    },
  ]

  const resetValues = useCallback(() => {
    form.resetFields()
    setOptions([])
    clearFolders()
  }, [clearFolders, form])

  const handleOk = () => {
    setConfirmLoading(true)
  }

  const handleCancel = useCallback(() => {
    resetValues()
    setOpen(false)
    setCurrentStep(0)
  }, [setCurrentStep, resetValues])

  const handleConfirm = useCallback(() => {
    setConfirmLoading(false)
    const regulatorySubmissionConfig: RegulatorySubmissionConfigValues = {
      tableOfContentIds: form.getFieldValue('tableOfContentIds'),
      documentNames: form
        .getFieldValue('documents')
        .map((document: { label: string }) => document.label),
    }

    smartFunctionService
      .runAlgorithm({
        id: selectedWorkspace?.id || '',
        navigate,
        config: regulatorySubmissionConfig,
      })
      .then((response: AlgorithmRun) => {
        refreshWorkspaceDetails()
        notificationService.notificationSuccess(
          t('smartFunctionDetailsPage.runStarted')
        )
        resetValues()
        setOpen(false)
        setCurrentStep(0)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios fetch error', error)
      })
  }, [form, navigate, refreshWorkspaceDetails, resetValues, selectedWorkspace])

  const defaultValues = useMemo(
    () => ({
      documents: [],
      tableOfContentIds: [],
    }),
    []
  )

  // Check if documents field is empty on initial render and when defaultValues change
  useEffect(() => {
    const initialDocuments = defaultValues.documents
    setIsNextDisabled(!initialDocuments || initialDocuments.length === 0)
  }, [defaultValues])

  const handleFormValuesChange = useCallback(
    (_changedValues: any, allValues: any) => {
      // Check if documents field is empty
      const isDocumentsEmpty =
        !allValues.documents || allValues.documents.length === 0
      setIsNextDisabled(isDocumentsEmpty)
    },
    []
  )

  const steps: ReactNode[] = useMemo(
    () => [
      <RegulatorySubmissionConfigurationScopeStep
        form={form}
        options={options}
        loading={isWorkspaceFilesLoading}
        refreshWorkspaceFolders={refreshWorkspaceFolders}
      />,
      <RegulatorySubmissionConfigurationModalReviewStep form={form} />,
    ],
    [form, options, isWorkspaceFilesLoading, refreshWorkspaceFolders]
  )

  const footers: ReactNode[] = useMemo(
    () => [
      <RegulatorySubmissionConfigurationModalFooter
        onCancel={handleCancel}
        onNext={() => setCurrentStep(currentStep + 1)}
        nextDisabled={isNextDisabled}
      />,
      <RegulatorySubmissionConfigurationModalFooter
        onCancel={handleCancel}
        onPrevious={() => setCurrentStep(currentStep - 1)}
        onSubmit={handleConfirm}
      />,
    ],
    [currentStep, handleCancel, handleConfirm, isNextDisabled]
  )

  return (
    <>
      <DropDownItem
        onClick={() => {
          setOpen(true)
        }}
      >
        {t(
          'workspaceDetailsPage.workspaceSmartFunctionPanel.actionMenu.configureRun'
        )}
      </DropDownItem>
      <ModalBase
        destroyOnClose
        key="main"
        title={t('regulatorySubmissionConfigurationModal.title')}
        open={open}
        onOk={handleOk}
        confirmLoading={confirmLoading}
        onCancel={handleCancel}
        footer={footers[currentStep]}
      >
        <Steps size="small" current={currentStep} items={stepItems} />
        <Form
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          onValuesChange={handleFormValuesChange}
        >
          <ModalContentContainer>{steps[currentStep]}</ModalContentContainer>
        </Form>
      </ModalBase>
    </>
  )
}

export default RegulatorySubmissionConfigurationModal
