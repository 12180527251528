// src/components/PageContentDropdown.tsx

import { MoreOutlined } from '@ant-design/icons'
import { Button, Dropdown } from 'antd'
import { MouseEvent } from 'react'
import { usePageActionDropdownItems } from '../hooks'


interface PageContentDropdownProps {
  onExport?: () => void
  disabled?: boolean
}

const PageContentDropdown = ({
  onExport,
  disabled,
}: PageContentDropdownProps) => {
  
  const { items, handleDropdownClick } = usePageActionDropdownItems({
    onExport,
  })

  const handleOpenMenu = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation()
  }

  return (
    <Dropdown
      menu={{
        items,
        onClick: ({ key, domEvent }) => {
          domEvent.stopPropagation()
          handleDropdownClick(key)
        },
      }}
      placement="bottomRight"
      trigger={['click']}
      disabled={disabled}
    >
      <Button
        icon={<MoreOutlined />}
        onClick={(e) => {
          handleOpenMenu(e)
        }}
      />
    </Dropdown>
  )
}

export default PageContentDropdown
