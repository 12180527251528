import { Alert, AlertProps, Card, Space, Steps } from 'antd'
import { t } from 'i18next'
import { ReactNode, useState } from 'react'
import { useNavigate } from 'react-router'
import styled from 'styled-components'
import { routePaths } from '../RootPage'
import ForgotPasswordStep1Email from './Steps/ForgotPasswordStep1Email'
import ForgotPasswordStep2Code from './Steps/ForgotPasswordStep2Code'
import ForgotPasswordStep3Password from './Steps/ForgotPasswordStep3Password'
import ForgotPasswordStep4Review from './Steps/ForgotPasswordStep4Review'

const ForgotPasswordLayout = styled.div`
  display: flex;
  padding: 32px 0; // 96px - 64px (there is a 64px margin for all content)
  justify-content: center;
`

export const FormLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height 200px;
`

export const ForgotPasswordStepsFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 24px 0;
  gap: 8px;
`

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top 24px;
`
const StyledAlert: React.FunctionComponent<AlertProps> = styled(Alert)`
  position: absolute;
  left: 0;
  top: 16px;
  right: 0;
  margin: auto;
  width: fit-content;
  max-width: 800px;
  z-index: 999999;
`

const ForgotPasswordPage = () => {
  const [currentStep, setCurrentStep] = useState(0)
  const navigate = useNavigate()
  const [email, setEmail] = useState('')
  const [inviteToken, setInviteToken] = useState('')
  const [pin, setPin] = useState('')
  const [emailSentAlertVisible, setEmailSentAlertVisible] = useState(false)
  const [codeErrorMessage, setCodeErrorMessage] = useState(false)

  const handleStep1NextClick = (
    enteredEmail: string,
    receivedInviteToken: string
  ) => {
    setEmail(enteredEmail)
    showAlert(receivedInviteToken)
    setCurrentStep(currentStep + 1)
  }

  const showAlert = (receivedInviteToken: string) => {
    setInviteToken(receivedInviteToken)
    setEmailSentAlertVisible(true)
    setCodeErrorMessage(false)
  }

  const handleErrorCode = () => {
    setCodeErrorMessage(true)
    setEmailSentAlertVisible(false)
  }

  const handleStep2NextClick = (receivedPin: string) => {
    setPin(receivedPin)
    setCurrentStep(currentStep + 1)
    setEmailSentAlertVisible(false)
  }

  const handleNextClick = () => {
    setCurrentStep(currentStep + 1)
  }

  const handleCancel = () => {
    navigate(routePaths.LOGIN)
  }

  const steps: ReactNode[] = [
    <ForgotPasswordStep1Email
      onCancel={handleCancel}
      onNext={handleStep1NextClick}
    />,
    <ForgotPasswordStep2Code
      onCancel={handleCancel}
      onNext={handleStep2NextClick}
      email={email}
      onResend={(inviteToken: string) => showAlert(inviteToken)}
      inviteToken={inviteToken}
      onCodeError={handleErrorCode}
    />,
    <ForgotPasswordStep3Password
      onCancel={handleCancel}
      onNext={handleNextClick}
      pin={pin}
      inviteToken={inviteToken}
    />,
    <ForgotPasswordStep4Review onButtonClick={handleCancel} />,
  ]

  const stepItems = [
    {
      title: t('forgotPasswordPage.step1EmailTitle'),
    },
    {
      title: t('forgotPasswordPage.step2CodeTitle'),
    },
    {
      title: t('forgotPasswordPage.step3PasswordTitle'),
    },
    {
      title: t('forgotPasswordPage.step4ReviewTitle'),
    },
  ]

  return (
    <>
      {inviteToken && emailSentAlertVisible && (
        <Space
          direction="horizontal"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          <StyledAlert
            showIcon
            message={t('forgotPasswordPage.step2Code.emailSent')}
            type="success"
            closable
            afterClose={() => setEmailSentAlertVisible(false)}
          />
        </Space>
      )}
      {codeErrorMessage && (
        <Space
          direction="horizontal"
          style={{ width: '100%', justifyContent: 'center' }}
        >
          <StyledAlert
            showIcon
            message={t('forgotPasswordPage.step2Code.errorCode')}
            type="error"
            closable
            afterClose={() => setCodeErrorMessage(false)}
          />
        </Space>
      )}
      <ForgotPasswordLayout>
        <Card
          title={t('forgotPasswordPage.title')}
          bordered={false}
          bodyStyle={{
            paddingBottom: 0,
            width: '550px',
            backgroundColor: 'white',
          }}
        >
          <Steps size="small" current={currentStep} items={stepItems} />
          <ContentContainer>{steps[currentStep]}</ContentContainer>
        </Card>
      </ForgotPasswordLayout>
    </>
  )
}

export default ForgotPasswordPage
