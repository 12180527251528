import { Form, Steps } from 'antd'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { ReactNode, useCallback, useMemo, useState } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { ModalBase } from '../../../components'
import { urlList } from '../../../constants/constants'
import { apiService } from '../../../services'
import notificationService from '../../../services/notificationService'
import { RootState } from '../../../store'
import ChangePasswordDefinitionStepFooter from './footers/ChangePasswordDefinitionStepFooter'
import ChangePasswordReviewStepFooter from './footers/ChangePasswordReviewStepFooter'
import ChangePasswordDefinitionStep from './steps/ChangePasswordDefinitionStep'
import ChangePasswordReviewStep from './steps/ChangePasswordReviewStep'

interface ChangePasswordPayload {
  oldPassword: string
  newPassword: string
}

interface ChangePasswordModalProps {
  open: boolean
  setOpen: (open: boolean) => void
}

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 16px 16px;
`

const ChangePasswordModal = ({ open, setOpen }: ChangePasswordModalProps) => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const [currentStep, setCurrentStep] = useState(0)

  const onCancel = useCallback(() => {
    form.resetFields()
    setCurrentStep(0)
    setOpen(false)
  }, [form, setOpen])

  const stepItems = [
    {
      title: t('userDetailsPage.changePasswordModal.definitionStepTitle'),
    },
    {
      title: t('userDetailsPage.changePasswordModal.reviewStepTitle'),
    },
  ]

  const handleOnConfirm = useCallback(() => {
    const params: ChangePasswordPayload = {
      oldPassword: form.getFieldValue('oldPassword'),
      newPassword: form.getFieldValue('newPassword'),
    }
    setLoading(true)
    apiService
      .patchItem(
        `${urlList.USERS}${currentUser?.id}/password`,
        navigate,
        params
      )
      .then((response) => {
        notificationService.notificationSuccess(
          t('userDetailsPage.changePasswordModal.passwordChangeSuccess') || ''
        )
        setLoading(false)
        setCurrentStep(1)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios invite user error', error)
        setLoading(false)
      })
  }, [form, navigate, currentUser])

  const steps: ReactNode[] = useMemo(
    () => [
      <ChangePasswordDefinitionStep form={form} loading={loading} />,
      <ChangePasswordReviewStep />,
    ],
    [form, loading]
  )

  const footers: ReactNode[] = useMemo(
    () => [
      <ChangePasswordDefinitionStepFooter
        loading={loading}
        onCancel={onCancel}
        handleOnConfirm={handleOnConfirm}
      />,
      <ChangePasswordReviewStepFooter onCancel={onCancel} />,
    ],
    [handleOnConfirm, loading, onCancel]
  )

  return (
    <ModalBase
      key={'changePasswordModal'}
      title={t('userDetailsPage.changePasswordModal.title')}
      open={open}
      onOk={handleOnConfirm}
      onCancel={onCancel}
      footer={[footers[currentStep]]}
    >
      <Steps size="small" current={currentStep} items={stepItems} />
      <ContentContainer>{steps[currentStep]}</ContentContainer>
    </ModalBase>
  )
}

export default ChangePasswordModal
