import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { DataType, TranslationDictionary } from '../../types/generalTypes'

export interface SearchQueryParams {
  query?: string
  sourceId?: string
  period?: string
  startDate?: string
  endDate?: string
}

export interface SearchResultItem {
  _id: string
  title: string | TranslationDictionary
  summary?: string
  summaryStatus?: string
  tags?: string
  authors: string[]
  documentLink: string | TranslationDictionary
  url: Object
  createdDate: string
  dataType: DataType
  dataSource: string
  publicationName: string
  publicationDate: string
  csv: string
}

export interface SearchResult {
  csv?: string
  data?: SearchResultItem[]
  id?: string
  page?: number
  pageSize?: number
  totalPages?: number
  totalResults?: string
}

export interface FillSearchFormData {
  searchTextValue?: string
}

export interface SearchResultState {
  searchQueryParams: SearchQueryParams
  searchResult: SearchResult
  lastSearch?: string
  selectedSearchResultItem?: SearchResultItem
  debug?: string
  loading?: boolean
  fillSearchFormData?: FillSearchFormData
}

const initialState: SearchResultState = {
  searchQueryParams: {
    query: '',
    sourceId: '',
    period: '',
    startDate: undefined,
    endDate: undefined,
  },
  searchResult: {
    data: [],
    id: '',
    page: 0,
    pageSize: 0,
    totalPages: 0,
    totalResults: '',
  },
  lastSearch: '',
  selectedSearchResultItem: undefined,
  debug: '',
  loading: false,
  fillSearchFormData: undefined,
}

const authSlice = createSlice({
  name: 'searchResultInfo',
  initialState,
  reducers: {
    setSearchQueryParams: (state, action: PayloadAction<SearchQueryParams>) => {
      state.searchQueryParams = action.payload
    },
    setSearchLoading(state, action: PayloadAction<boolean>) {
      state.loading = action.payload
    },
    setSearchResult: (state, action: PayloadAction<SearchResult>) => {
      state.searchResult = action.payload
    },
    setSelectedSearchResultItem: (
      state,
      action: PayloadAction<SearchResultItem>
    ) => {
      state.selectedSearchResultItem = action.payload
    },
    setFillSearchFormData: (
      state,
      action: PayloadAction<FillSearchFormData>
    ) => {
      state.fillSearchFormData = action.payload
    },
    clearSearchResultState: () => initialState,
  },
})

export const {
  setSearchQueryParams,
  setSearchLoading,
  setSearchResult,
  setSelectedSearchResultItem,
  setFillSearchFormData,
  clearSearchResultState,
} = authSlice.actions

export const getSearchResultInfo = (state: RootState) => state.searchResultInfo
export const getSearchResult = (state: RootState) =>
  state.searchResultInfo.searchResult

const searchResultInfoReducer = authSlice.reducer
export default searchResultInfoReducer
