import { AppstoreOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form } from 'antd'
import { t } from 'i18next'
import { useCallback, useState } from 'react'
import {
  DetailsPageLayout,
  MultiColumnFormContentContainer,
  SelectLanguage,
  SimpleSwitch,
  Spacer,
  SubHeader,
} from '../../components'
import { useLanguage, useTheme } from '../../hooks'
import { themeColorKeys } from '../../theme'
import { Language } from '../../types/generalTypes'

const SettingsPage = () => {
  const [saveLoading, setSaveLoading] = useState(false)

  // const { currentUser } = useSelector((state: RootState) => state.user)

  const { selectedTheme, toggleTheme } = useTheme()

  const { selectedLanguage, toggleLanguage } = useLanguage()

  const handleSave = () => {
    // TODO: Implement save
    setSaveLoading(true)
  }

  const handleLanguageChange = useCallback(
    (value: Language | undefined) => {
      if (value) {
        toggleLanguage(value)
      }
    },
    [toggleLanguage]
  )

  return (
    <DetailsPageLayout>
      <SubHeader>
        <AppstoreOutlined />
        {t('settingsPage.general')}
        <Spacer />
        <Button
          type="primary"
          icon={<SaveOutlined />}
          htmlType="submit"
          loading={saveLoading}
          onClick={handleSave}
        >
          {t('saveButton')}
        </Button>
      </SubHeader>
      <MultiColumnFormContentContainer>
        <Form layout="vertical" onFinish={handleSave} requiredMark={false}>
          <SelectLanguage
            value={selectedLanguage as Language}
            onChange={handleLanguageChange}
          />
          <Form.Item
            label={t('settingsPage.currentProductVersion')}
            name="description"
          >
            v2.01
          </Form.Item>
          <Form.Item label={t('settingsPage.nightMode')} name="nightMode">
            <SimpleSwitch
              checked={selectedTheme === themeColorKeys.dark}
              onClick={toggleTheme}
            />
          </Form.Item>
        </Form>
      </MultiColumnFormContentContainer>
    </DetailsPageLayout>
  )
}

export default SettingsPage
