import { CheckOutlined } from '@ant-design/icons'
import { Button, Card, Form, Input, Layout } from 'antd'
import { PasswordInput } from 'antd-password-input-strength'
import { AxiosError } from 'axios'
import { t } from 'i18next'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { PasswordRules, SelectLanguage } from '../../components'
import { PASSWORD_MIN_LENGTH, urlList } from '../../constants/constants'
import { apiService } from '../../services'
import notificationService from '../../services/notificationService'
import { routePaths } from '../RootPage'

const PasswordRulesContainer = styled.div`
  margin-bottom: 12px;
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`

const StyledTitle = styled.div`
  font-weight: 600;
  font-size: 16px;
  margin-bottom: 20px;
`

const ContentContainer = styled.div`
  max-height: calc(100vh - 331px);
  overflow-y: auto;
`

const StyledDescription = styled.div`
  font-weight: 600;
  font-size: 12px;
  margin-bottom: 20px;
`

export interface InvitePayload {
  displayName: string
  language: string
  password: string
}
interface FormValueProps {
  name: string
  language: string
  password: string
  confirmPassword: string
}

const InviteCompleteProfilePage = () => {
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const [form] = Form.useForm()

  const handleSubmit = (values: FormValueProps) => {
    const params: InvitePayload = {
      displayName: values.name,
      language: values.language,
      password: values.password,
    }
    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get('token')

    setLoading(true)
    apiService
      .patchItem(
        `${urlList.REGISTER_USER}/inviteToken=${token}`,
        navigate,
        params
      )
      .then((response) => {
        notificationService.notificationSuccess(
          t('userListPage.createUserModal.userInvited') || ''
        )
        setLoading(false)
        navigate(routePaths.LOGIN)
      })
      .catch((error: AxiosError | Error) => {
        console.error('axios invite user error', error)
        setLoading(false)
      })
  }

  const defaultValues: FormValueProps = {
    name: '',
    language: '',
    password: '',
    confirmPassword: '',
  }

  return (
    <Layout
      style={{
        display: 'flex',
        alignItems: 'center',
        height: 'calc(100vh - 20px)',
        paddingTop: 20, // 96-12-64 px
        paddingBottom: 92, // 96-4 px
      }}
    >
      <Card
        bordered={false}
        bodyStyle={{
          width: '520px',
          backgroundColor: 'white',
          height: 'calc(100vh - 192px)',
        }}
      >
        <Form
          name="normal_login"
          onFinish={handleSubmit}
          form={form}
          layout="vertical"
          initialValues={defaultValues}
          requiredMark={false}
        >
          <StyledTitle>{t('inviteCompleteProfile.title')}</StyledTitle>
          <ContentContainer>
            <StyledDescription>
              {t('inviteCompleteProfile.introduction')}
            </StyledDescription>
            <Form.Item
              label={t('inviteCompleteProfile.fullName')}
              name="name"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <Input placeholder={t('Name') || ''} disabled={loading} />
            </Form.Item>
            <Form.Item
              label={t('Language')}
              name="language"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <SelectLanguage loading={loading} />
            </Form.Item>
            <PasswordRulesContainer>
              <PasswordRules />
            </PasswordRulesContainer>
            <Form.Item
              label={'Password'}
              name="password"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
                {
                  min: PASSWORD_MIN_LENGTH,
                  message: t('errorMessage.passwordLength') || '',
                },
              ]}
            >
              <PasswordInput
                placeholder={t('Password') || ''}
                disabled={loading}
              />
            </Form.Item>
            <Form.Item
              label={t('Confirm password')}
              name="confirmPassword"
              dependencies={['password']}
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
                {
                  min: PASSWORD_MIN_LENGTH,
                  message: t('errorMessage.passwordLength') || '',
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue('password') === value) {
                      return Promise.resolve()
                    }
                    return Promise.reject(
                      new Error(t('errorMessage.passwordDoNotMatch') || '')
                    )
                  },
                }),
              ]}
            >
              <Input.Password
                placeholder={t('Confirm password') || ''}
                disabled={loading}
              />
            </Form.Item>
          </ContentContainer>
          <Form.Item>
            <ButtonContainer>
              <Button icon={<CheckOutlined />} type="primary" htmlType="submit">
                {t('inviteCompleteProfile.submitButton')}
              </Button>
            </ButtonContainer>
          </Form.Item>
        </Form>
      </Card>
    </Layout>
  )
}

export default InviteCompleteProfilePage
