import axios from 'axios'

const axiosFactory = () => {
  const axiosInstance = axios.create({
    withCredentials: true,
  })
  return axiosInstance
}

export default axiosFactory
