import { Form, FormInstance, Radio, Space } from 'antd'
import { t } from 'i18next'
import { memo } from 'react'
import {
  REGDOCS_TYPECODE,
  SHAREPOINT_TYPECODE,
} from '../../../../constants/constants'
import { useDataSourceTypes } from '../../../../hooks'
import { DataSourceType } from '../../../../store/reducers/dataSourceReducer'

interface CreateDataStoreTypeProps {
  form: FormInstance
}
const ALLOWED_DATASTORE_TYPES = [SHAREPOINT_TYPECODE, REGDOCS_TYPECODE]

const CreateDataStoreTypeStep = memo(({ form }: CreateDataStoreTypeProps) => {
  const { dataSourceTypeList, loading } = useDataSourceTypes({
    allowedDataSourceTypes: ALLOWED_DATASTORE_TYPES,
    errorNotificationMessage: t(
      'createDataStoreModal.typesStep.errorRetrievingTypes'
    ),
  })

  return (
    <Form.Item
      label={t('createDataStoreModal.typesStep.typeLabel')}
      name={'sourceType'}
      rules={[
        {
          required: true,
          message: t('errorMessage.isRequired') || '',
        },
      ]}
    >
      <Radio.Group buttonStyle="solid">
        <Space direction="vertical">
          {!loading &&
            dataSourceTypeList?.map((dataStoreType: DataSourceType) => (
              <Radio
                key={dataStoreType.id}
                value={dataStoreType.id}
                onChange={() => {
                  form.setFieldsValue({
                    sourceType: dataStoreType.id,
                    sourceName: dataStoreType.typeName,
                    sourceCode: dataStoreType.typeCode,
                  })
                }}
              >
                {dataStoreType.typeName}
              </Radio>
            ))}
        </Space>
      </Radio.Group>
    </Form.Item>
  )
})

export default CreateDataStoreTypeStep
