import { ProfileOutlined, SaveOutlined } from '@ant-design/icons'
import { Button, Form, Input } from 'antd'
import { t } from 'i18next'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { useNavigate } from 'react-router-dom'
import {
  BackButton,
  ConfirmSaveModal,
  DetailsPageLayout,
  MultiColumnFormColumnContainer,
  MultiColumnFormContentContainer,
  PageContentDropdown,
  SelectRole,
  SimpleSwitch,
  SimpleSwitchContainer,
  Spacer,
  SubHeader,
} from '../../components'
import { urlList } from '../../constants/constants'
import { useUsers } from '../../hooks'
import { apiService } from '../../services'
import notificationService from '../../services/notificationService'
import { RootState } from '../../store'
import { setSelectedUser } from '../../store/reducers/userReducer'
import { routePaths } from '../RootPage'
import ChangePasswordModal from './modals/ChangePasswordModal'

interface UserFormValues {
  id?: string
  name?: string
  email?: string
  role?: string
  isActive?: boolean
}

const UserDetailsPage = () => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [form] = Form.useForm()
  const { userId } = useParams()
  const { exportUserDetailsCsv } = useUsers({
    preventFetch: true,
  })
  const selectedUser = useSelector((state: RootState) => state.user.selected)
  const currentUser = useSelector((state: RootState) => state.user.currentUser)
  const [openConfirmModal, setOpenConfirmModal] = useState(false)
  const [openChangePasswordModal, setOpenChangePasswordModal] = useState(false)
  const [saveIsActiveLoading, setSaveIsActiveLoading] = useState(false)
  const [saveNameLoading, setSaveNameLoading] = useState(false)
  const [saveEmailLoading, setSaveEmailLoading] = useState(false)
  const [saveRoleLoading, setSaveRoleLoading] = useState(false)
  const defaultValues: UserFormValues | undefined = useMemo(
    () => ({
      id: selectedUser?.id,
      name: selectedUser?.displayName,
      email: selectedUser?.email,
      role: selectedUser?.role?.id,
      isActive: selectedUser?.isActive,
    }),
    [selectedUser]
  )
  const [userFormValues, setUserFormValues] = useState<
    UserFormValues | undefined
  >(undefined)

  const isSelectedUserTheCurrentOne = useMemo(() => {
    return selectedUser?.id === currentUser?.id
  }, [selectedUser, currentUser])

  const refreshUser = useCallback(async () => {
    apiService
      .fetchItems(`${urlList.USERS}${userId}`)
      .then((response) => {
        dispatch(setSelectedUser(response))
      })
      .catch((error) => {
        console.error('axios save search error', error)
      })
  }, [dispatch, userId])

  useEffect(() => {
    refreshUser()
  }, [refreshUser])

  useEffect(() => {
    form.setFieldsValue(defaultValues)
  }, [form, defaultValues])

  const handleCancel = () => {
    form.resetFields()
  }

  const handleSubmit = (values: any) => {
    setOpenConfirmModal(true)
    setUserFormValues(values)
  }

  const patchUser = useCallback(
    async (
      url: string,
      param: any,
      setSaveLoading: (value: boolean) => void,
      successMessage?: string,
      errorMessage?: string
    ) => {
      setSaveLoading(true)
      apiService
        .patchItem(url, navigate, param)
        .then(async (response) => {
          notificationService.notificationSuccess(successMessage || '')
          await refreshUser()
          setSaveLoading(false)
        })
        .catch((error) => {
          console.error('axios save search error', error)
          notificationService.notificationError(
            t(errorMessage ? errorMessage : 'userDetailsPage.saveUserError') ||
              ''
          )
          setSaveLoading(false)
        })
    },
    [refreshUser, navigate]
  )

  const handleConfirm = () => {
    if (userFormValues?.isActive !== defaultValues?.isActive) {
      patchUser(
        `${urlList.USERS}${userId}/isActive`,
        {
          isActive: userFormValues?.isActive ? 'true' : 'false',
        },
        setSaveIsActiveLoading,
        t('userDetailsPage.userSavedSuccess') || ''
      )
    }

    if (userFormValues?.name !== defaultValues?.name) {
      patchUser(
        `${urlList.USERS}${userId}`,
        {
          displayName: userFormValues?.name,
        },
        setSaveNameLoading,
        t('userDetailsPage.userSavedSuccess') || ''
      )
    }

    if (userFormValues?.email !== defaultValues?.email) {
      patchUser(
        `${urlList.USERS}${userId}/email`,
        {
          email: userFormValues?.email,
        },
        setSaveEmailLoading,
        t('userDetailsPage.userSavedSuccess') || '',
        t('userDetailsPage.userEmailError') || ''
      )
    }

    if (userFormValues?.role !== defaultValues?.role) {
      patchUser(
        `${urlList.USERS}${userId}/role`,
        {
          role: userFormValues?.role,
        },
        setSaveRoleLoading,
        t('userDetailsPage.userSavedSuccess') || ''
      )
    }

    setOpenConfirmModal(false)
  }

  const handleCancelConfirm = () => {
    setOpenConfirmModal(false)
  }

  const isSaveLoading = () => {
    return (
      saveIsActiveLoading ||
      saveNameLoading ||
      saveEmailLoading ||
      saveRoleLoading
    )
  }

  const handleExportUserDetailsCsv = () => {
    exportUserDetailsCsv(userId || '')
  }

  return (
    <DetailsPageLayout>
      <Form
        form={form}
        layout="vertical"
        initialValues={defaultValues}
        onFinish={handleSubmit}
        requiredMark={false}
      >
        <SubHeader>
          <BackButton url={routePaths.USER_LIST} />
          <div>
            <ProfileOutlined /> {t('userDetailsPage.userDetailsTitle')}
          </div>
          <Spacer />
          <SimpleSwitchContainer>
            <div>
              {form.getFieldValue('isActive') ? t('Active') : t('Inactive')}
            </div>
            <Form.Item name="isActive" valuePropName="checked" noStyle>
              <SimpleSwitch
                onClick={(value) => {
                  form.setFieldValue('isActive', value)
                }}
                checked={defaultValues?.isActive}
              />
            </Form.Item>
          </SimpleSwitchContainer>
          <Button onClick={handleCancel} loading={isSaveLoading()} type="text">
            {t('Cancel')}
          </Button>
          <Button
            type="primary"
            icon={<SaveOutlined />}
            htmlType="submit"
            loading={isSaveLoading()}
          >
            {t('saveButton')}
          </Button>
          <PageContentDropdown onExport={handleExportUserDetailsCsv} />
        </SubHeader>
        <MultiColumnFormContentContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item
              label={t('Name')}
              required
              name="name"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <Input />
            </Form.Item>
            <Form.Item
              label={t('Email')}
              required
              name="email"
              rules={[
                { required: true, message: t('errorMessage.isRequired') || '' },
              ]}
            >
              <Input />
            </Form.Item>
          </MultiColumnFormColumnContainer>
          <MultiColumnFormColumnContainer>
            <Form.Item label={t('Role')} required name="role">
              <SelectRole
                disabled={isSelectedUserTheCurrentOne}
                value={selectedUser?.role?.id}
              />
            </Form.Item>
            {isSelectedUserTheCurrentOne && (
              <Form.Item label={t('userDetailsPage.changePasswordLabel')}>
                <Button onClick={() => setOpenChangePasswordModal(true)}>
                  {t('userDetailsPage.changePassword')}
                </Button>
              </Form.Item>
            )}
          </MultiColumnFormColumnContainer>
        </MultiColumnFormContentContainer>
      </Form>
      <ChangePasswordModal
        open={openChangePasswordModal}
        setOpen={setOpenChangePasswordModal}
      />
      <ConfirmSaveModal
        open={openConfirmModal}
        onConfirm={handleConfirm}
        onCancel={handleCancelConfirm}
      >
        {t('userDetailsPage.confirmModalMessage')}
      </ConfirmSaveModal>
    </DetailsPageLayout>
  )
}

export default UserDetailsPage
