import { EMAIL_FORMAT_REGEX } from '../constants/constants'

const validateEmail = (_: any, value: string) => {
  if (value && !EMAIL_FORMAT_REGEX.test(value)) {
    return Promise.reject('Please enter a valid email address')
  }
  return Promise.resolve()
}

const validateEgnyteConfig = (
  config: {
    tenantUid: string
  },
  secrets: {
    authCode: string
  }
) => {
  return config?.tenantUid && secrets?.authCode
}

const validationService = {
  validateEmail,
  validateEgnyteConfig,
}

export default validationService
