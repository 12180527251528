import { Input, theme } from 'antd'
import { t } from 'i18next'
import { ChangeEvent, MouseEvent, useCallback, useMemo, useState } from 'react'
import { CSSProperties, styled } from 'styled-components'
import { RestoreBlockIcon } from '.'
import { OfrBlockType, ofrBlockTypes } from '../constants/constants'
import DeleteIconButton from './DeleteIconButton'
import SimpleSwitchContainer from './display/SimpleSwitchContainer'
import SelectOfrBlockType from './SelectOfrBlockType'
import SimpleSwitch from './SimpleSwitch'

const OfrBlockContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.8);
  border-radius: 4px;
`

const DeleteButtonContainer = styled.div`
  position: absolute;
  top: 8px;
  left: -40px;
  z-index: 20;
`

const AnnotateSwitchContainer = styled.div`
  position: absolute;
  top: 9px;
  left: 8px;
  background: ${(props) => props.theme.colorBgContainer};
`

const AnnotationInfoContainer = styled.div<{ $visible: boolean }>`
  position: absolute;
  top: 36px;
  flex-direction: column;
  margin-left: 8px;
  min-width: 150px;
  gap: 8px;
  display: ${(props) => (props.$visible ? 'flex' : 'none')};
`

const { useToken } = theme

export interface AnnotationInfo {
  isAnnotated: boolean
  blockType: OfrBlockType | undefined
  name: string
}

interface OfrBlockProps {
  x1: number
  y1: number
  x2: number
  y2: number
  onAnnotationChange?: (value: AnnotationInfo) => void
  onToBeDeletedChange?: (value: boolean) => void
  onSelectedChange?: (value: boolean) => void
  onMouseUp?: () => void
  selected: boolean
  initialBlockName?: string
  initialBlockType?: OfrBlockType
}

const OfrBlock = ({
  x1,
  y1,
  x2,
  y2,
  onAnnotationChange,
  onToBeDeletedChange,
  onSelectedChange,
  onMouseUp,
  selected,
  initialBlockName,
  initialBlockType,
}: OfrBlockProps) => {
  const { token } = useToken()
  const [isAnnotated, setIsAnnotated] = useState(
    !!initialBlockName || !!initialBlockType
  )
  const [toBeDeleted, setToBeDeleted] = useState(false)
  const [blockType, setBlockType] = useState<OfrBlockType | undefined>(
    initialBlockType || ofrBlockTypes.NONE
  )
  const [blockName, setBlockName] = useState(initialBlockName || '')

  const border = useMemo(() => {
    if (toBeDeleted) {
      return selected
        ? `2px solid ${token.colorError}`
        : `2px dashed ${token.colorError}` // red
    } else if (isAnnotated) {
      return selected
        ? `2px solid ${token.colorPrimary}`
        : `2px dashed ${token.colorPrimary}` // purple
    } else {
      return selected ? `2px solid ${token.colorPrimary}` : `2px solid #000`
    }
  }, [toBeDeleted, isAnnotated, selected, token])

  const style: CSSProperties = useMemo(
    () => ({
      height: Math.abs(y2 - y1),
      width: Math.abs(x2 - x1),
      border,
      left: Math.min(x1, x2),
      top: Math.min(y1, y2),
    }),
    [x1, y1, x2, y2, border]
  )

  const toggleIsSelected = useCallback(() => {
    if (onSelectedChange) {
      onSelectedChange(!selected)
    }
  }, [selected, onSelectedChange])

  const toggleIsAnnotated = useCallback(
    (value: boolean) => {
      if (!selected && onSelectedChange) {
        onSelectedChange(!selected)
      }
      setIsAnnotated(value)
      const params: AnnotationInfo = {
        isAnnotated: value,
        blockType: blockType,
        name: blockName,
      }
      if (onAnnotationChange) {
        onAnnotationChange(params)
      }
    },
    [selected, onSelectedChange, blockName, blockType, onAnnotationChange]
  )

  const handleDeleteButtonOnClick = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation()
      if (!selected) {
        if (onSelectedChange) {
          onSelectedChange(true)
        }
      }
      setToBeDeleted(() => !toBeDeleted)
      if (onToBeDeletedChange) {
        onToBeDeletedChange(toBeDeleted)
      }
    },
    [onToBeDeletedChange, toBeDeleted, selected, onSelectedChange]
  )

  const handleBlockTypeChange = useCallback(
    (value?: OfrBlockType) => {
      setBlockType(value)
      const params: AnnotationInfo = {
        isAnnotated,
        blockType: value,
        name: blockName,
      }
      if (onAnnotationChange) {
        onAnnotationChange(params)
      }
    },
    [blockName, isAnnotated, onAnnotationChange]
  )

  const handleBlockNameChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setBlockName(e.target.value)
      const params: AnnotationInfo = {
        isAnnotated,
        blockType: blockType,
        name: e.target.value,
      }
      if (onAnnotationChange) {
        onAnnotationChange(params)
      }
    },
    [blockType, isAnnotated, onAnnotationChange]
  )

  const handleDivClick = useCallback(
    (e: MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      if (!selected && onSelectedChange) {
        onSelectedChange(true)
      }
    },
    [selected, onSelectedChange]
  )

  return (
    <OfrBlockContainer
      onClick={toggleIsSelected}
      style={style}
      onMouseUp={onMouseUp}
    >
      {selected && (
        <>
          <DeleteButtonContainer>
            {toBeDeleted ? (
              <RestoreBlockIcon
                tooltipTitle={t('ofrBlock.restoreBlock')}
                onClick={handleDeleteButtonOnClick}
              />
            ) : (
              <DeleteIconButton
                tooltipTitle={t('ofrBlock.deleteButtonTooltip')}
                onClick={handleDeleteButtonOnClick}
              />
            )}
          </DeleteButtonContainer>
          <AnnotateSwitchContainer>
            <SimpleSwitchContainer>
              <SimpleSwitch checked={isAnnotated} onClick={toggleIsAnnotated} />
              <div>{t('ofrBlock.annotate')}</div>
            </SimpleSwitchContainer>
          </AnnotateSwitchContainer>
          <AnnotationInfoContainer $visible={isAnnotated}>
            <SelectOfrBlockType
              defaultValue={blockType}
              onChange={handleBlockTypeChange}
              onClick={handleDivClick}
            />
            <div onClick={handleDivClick}>
              <Input
                placeholder={t('ofrBlock.enterName')}
                onChange={handleBlockNameChange}
                value={blockName}
              />
            </div>
          </AnnotationInfoContainer>
        </>
      )}
    </OfrBlockContainer>
  )
}

export default OfrBlock
