import { Select } from 'antd'
import { memo, useMemo, useState } from 'react'
import { useDataStores } from '../hooks'
import { DataStore } from '../store/reducers/dataStoreReducer'

interface SelectOption {
  label: string
  value: string
}

interface SelectStoreProps {
  onChange?: (value: string | undefined) => void
  value?: string
  style?: Object
  placeholder?: string
}

const SelectStore = memo(
  ({ value, onChange, style, placeholder }: SelectStoreProps) => {
    const { dataStoreList } = useDataStores({})

    const options: SelectOption[] | undefined = useMemo(
      () =>
        dataStoreList.map((dataStore: DataStore) => ({
          label: dataStore.title || '',
          value: dataStore.id || '',
        })),
      [dataStoreList]
    )

    const [selectedDataStore, setSelectedDataStore] = useState(
      value ? value : undefined
    )

    const handleChange = (value: string) => {
      setSelectedDataStore(value)
      if (onChange) {
        onChange(value)
      }
    }

    return (
      <Select
        style={style}
        onChange={handleChange}
        value={selectedDataStore}
        options={options}
        placeholder={placeholder ? placeholder : ''}
      />
    )
  }
)

export default SelectStore
