import { permissionActions } from '../constants/permissionConstant'
import { UserDetails } from '../store/reducers/userReducer'
import {
  PermissionAction,
  PermissionName,
  PermissionType,
} from '../types/generalTypes'

const hasPermission = (
  user: UserDetails,
  permissionName: PermissionName,
  permissionAction?: PermissionAction,
  permissionType?: PermissionType
) => {
  if (!user || !user.role || !user.role.permissions) {
    return false
  }

  const permissionFound = user.role.permissions.find((permission) => {
    if (permission === permissionActions.ALL) {
      return true
    }

    const permissionSplitted = permission.split(':')
    if (permissionAction && permissionType) {
      return (
        permissionSplitted[0].includes(permissionName) &&
        permissionSplitted[1].includes(permissionAction) &&
        permissionSplitted[2].includes(permissionType)
      )
    } else if (permissionAction) {
      return (
        permissionSplitted[0].includes(permissionName) &&
        permissionSplitted[1].includes(permissionAction)
      )
    } else {
      return permissionSplitted[0].includes(permissionName)
    }
  })
  return !!permissionFound
}

const roleService = {
  hasPermission,
}

export default roleService
