import { Select } from 'antd'
import { LabeledValue } from 'antd/lib/select'
import { t } from 'i18next'
import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { privacyTypes } from '../constants/constants'
import { useDataSources, useDefaultDataSource } from '../hooks'
import dataSourceService, {
  DataSourceTypeItem,
} from '../services/dataSourcesService'
import { DataSource } from '../store/reducers/dataSourceReducer'
import { SelectGroupOptions } from '../types/generalTypes'

type SelectSourcePrivacyType = 'INTERNAL' | 'PUBLIC' | 'ALL'

export const selectSourcePrivacyTypes: {
  [key in SelectSourcePrivacyType]: SelectSourcePrivacyType
} = {
  INTERNAL: 'INTERNAL',
  PUBLIC: 'PUBLIC',
  ALL: 'ALL',
}

const StyledSelect = styled(Select)`
  min-width: 200px;
  .ant-select-selector {
    background-color: ${(props) => props.theme.colorBgContainer};
    color: ${(props) => props.theme.colorText};
    border: ${(props) => props.theme.colorBorder};
  }
`

interface SelectSourceProps {
  onChange: (value: string) => void
  value?: string
  privacy?: SelectSourcePrivacyType
  includeTypeCode?: boolean
  includeSearchable?: boolean
}

const buildSelectLabel = (
  source: DataSource,
  includeTypeCode: boolean = false
) => {
  const typeCode =
    includeTypeCode && source.typeCode ? `${source.typeCode}: ` : ''
  return `${typeCode}${source.title}`
}

const SelectSource = memo(
  ({
    onChange,
    value,
    privacy = selectSourcePrivacyTypes.ALL,
    includeSearchable = true,
    includeTypeCode = true,
  }: SelectSourceProps) => {
    const {
      dataSourceList,
      internalDataSourceList,
      publicDataSourceList,
      searchableDataSourceList,
      loading,
    } = useDataSources({})

    const [dirty, setDirty] = useState(false)

    const { defaultDataSource, loading: defaultDataSourceLoading } =
      useDefaultDataSource()

    const publicOptionGroups =
      dataSourceService.getTypeList(publicDataSourceList)

    const internalOptionGroups = dataSourceService.getTypeList(
      internalDataSourceList
    )
    const searchableOptionsGroups = dataSourceService.getTypeList(
      searchableDataSourceList,
      true // searchable type codes
    )

    const isPrivateSource = (source: DataSource) => {
      // private or organization privacy types are only private sources
      return (
        source.visibility === privacyTypes.PRIVATE ||
        source.visibility === privacyTypes.ORGANIZATION
      )
    }

    const isMatchingOrganization = (
      source: DataSource,
      organizationName: string
    ) => {
      return source.organizationName === organizationName
    }

    const buildOptions = (
      dataSource: DataSource,
      includeTypeCode: boolean | undefined
    ) => ({
      label: buildSelectLabel(dataSource, includeTypeCode),
      value: dataSource.id,
    })

    const internalOptions: SelectGroupOptions[] = useMemo(() => {
      return internalOptionGroups.map((type: DataSourceTypeItem) => {
        const filteredSources = internalDataSourceList.filter((source) => {
          const privateSource = isPrivateSource(source)
          const matchingOrg = isMatchingOrganization(source, type.name)
          if (privacy === selectSourcePrivacyTypes.INTERNAL) {
            return privateSource && matchingOrg
          }
          return matchingOrg
        })

        return {
          label: type.name,
          options: filteredSources.map((source) =>
            buildOptions(source, includeTypeCode)
          ),
        }
      })
    }, [internalDataSourceList, internalOptionGroups, privacy, includeTypeCode])

    const publicOptions: SelectGroupOptions[] = useMemo(() => {
      return publicOptionGroups.map((type: DataSourceTypeItem) => ({
        label: type.name,
        options: publicDataSourceList
          .filter((source) => source.typeCode === type.id)
          .map((source) => ({
            label: buildSelectLabel(source, includeTypeCode),
            value: source.id,
          })),
      }))
    }, [publicDataSourceList, publicOptionGroups, includeTypeCode])

    const searchableOptions: SelectGroupOptions[] = useMemo(() => {
      return searchableOptionsGroups.map((type: DataSourceTypeItem) => ({
        label: type.name,
        options: searchableDataSourceList
          .filter((source) => source.typeCode === type.id)
          .map((source) => ({
            label: buildSelectLabel(source, includeTypeCode),
            value: source.id,
          })),
      }))
    }, [searchableDataSourceList, searchableOptionsGroups, includeTypeCode])

    const options = useMemo(() => {
      if (includeSearchable) {
        return searchableOptions
      } else {
        switch (privacy) {
          case selectSourcePrivacyTypes.PUBLIC:
            return publicOptions
          case selectSourcePrivacyTypes.INTERNAL:
            return internalOptions
          case selectSourcePrivacyTypes.ALL:
          default:
            return [...publicOptions, ...internalOptions]
        }
      }
    }, [
      includeSearchable,
      searchableOptions,
      privacy,
      publicOptions,
      internalOptions,
    ])

    const defaultValue: LabeledValue = useMemo(() => {
      if (value) {
        return {
          label:
            dataSourceList.find((source) => source.id === value)?.title || '',
          value: dataSourceList.find((source) => source.id === value)?.id || '',
        }
      } else if (privacy === selectSourcePrivacyTypes.INTERNAL) {
        const firstOption = options?.[0]?.options?.[0] || {}
        return {
          label: firstOption.label ?? '',
          value: firstOption.value ?? '',
        }
      } else {
        return {
          label: `${defaultDataSource?.typeCode}: ${defaultDataSource?.title}`,
          value: defaultDataSource?.id || '',
        }
      }
    }, [defaultDataSource, dataSourceList, value, options, privacy])

    const [selectedValue, setSelectedValue] = useState<LabeledValue | string>(
      defaultValue
    )

    const updateSelectedValue = useCallback(
      (id: string) => {
        const selectedDataSource = dataSourceList.find(
          (source) => source.id === id
        )

        if (selectedDataSource) {
          const label =
            buildSelectLabel(selectedDataSource, includeTypeCode) || ''
          setSelectedValue(label)
        } else {
          setSelectedValue('')
        }
      },
      [dataSourceList, setSelectedValue, includeTypeCode]
    )

    const handleChange = useCallback(
      (value: string | LabeledValue) => {
        if (value) {
          const id = typeof value === 'string' ? value : (value.value as string)
          setDirty(true)
          updateSelectedValue(id)
          if (onChange) {
            onChange(id)
          }
        }
      },
      [onChange, updateSelectedValue]
    )

    useEffect(() => {
      if (
        !dirty &&
        !defaultDataSourceLoading &&
        defaultValue.value &&
        !loading &&
        dataSourceList.length > 0
      ) {
        handleChange(defaultValue) // update default value after everything is loaded
      }
    }, [
      handleChange,
      defaultValue,
      dirty,
      defaultDataSourceLoading,
      dataSourceList,
      loading,
    ])

    return (
      <StyledSelect
        onChange={(value) => {
          handleChange(value)
        }}
        value={selectedValue}
        options={options}
        placeholder={t('selectSource.placeholder')}
        loading={loading || defaultDataSourceLoading}
      />
    )
  }
)

export default SelectSource
