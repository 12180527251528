import { UndoOutlined } from '@ant-design/icons' // Import the necessary Ant Design icon
import { Button, Tooltip } from 'antd' // Assuming you are using Ant Design components
import { TooltipPlacement } from 'antd/lib/tooltip'
import React from 'react'

interface RestoreIconButtonProps {
  tooltipPlacement?: TooltipPlacement
  tooltipTitle: string
  icon?: React.ReactNode
  onClick: any
}

const RestoreIconButton = ({
  tooltipPlacement,
  tooltipTitle,
  icon,
  onClick,
}: RestoreIconButtonProps) => {
  return (
    <Tooltip
      placement={tooltipPlacement ? tooltipPlacement : 'left'}
      title={tooltipTitle}
      align={{ offset: [-24, 0] }}
    >
      <Button icon={icon ? icon : <UndoOutlined />} onClick={onClick} />
    </Tooltip>
  )
}

export default RestoreIconButton
