import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  PlaySquareOutlined,
} from '@ant-design/icons'
import { Button } from 'antd'
import { t } from 'i18next'
import { memo, useMemo } from 'react'
import styled from 'styled-components'
import { algorithmRunStatuses } from '../../../../../../constants/constants'
import { useWorkspaceDetails } from '../../../../../../hooks'

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 24px;
  gap: 8px;
`

interface DelConfigurationModalFooterProps {
  onCancel: () => void
  onNext?: () => void
  nextDisabled?: boolean
  onPrevious?: () => void
  onSubmit?: () => void
}

const DelConfigurationModalFooter = memo(
  ({
    onCancel,
    onNext,
    nextDisabled = false,
    onPrevious,
    onSubmit,
  }: DelConfigurationModalFooterProps) => {
    const { selectedWorkspace } = useWorkspaceDetails({
      preventFetch: true,
    })

    const isStatusOngoing = useMemo(() => {
      return !!selectedWorkspace?.algorithmRuns?.find(
        (run) =>
          run.runStatus === algorithmRunStatuses.ONGOING_INGESTION ||
          run.runStatus === algorithmRunStatuses.ONGOING_AI
      )
    }, [selectedWorkspace?.algorithmRuns])

    return (
      <Footer>
        <Button onClick={onCancel} type="text">
          {t('Cancel')}
        </Button>
        {onPrevious && (
          <Button type="text" icon={<ArrowLeftOutlined />} onClick={onPrevious}>
            {t('Previous')}
          </Button>
        )}
        {onNext && (
          <Button
            icon={<ArrowRightOutlined />}
            disabled={nextDisabled}
            type="primary"
            onClick={onNext}
          >
            {t('Next')}
          </Button>
        )}
        {onSubmit && (
          <Button
            icon={<PlaySquareOutlined />}
            type="primary"
            onClick={onSubmit}
            disabled={isStatusOngoing}
          >
            {t('regulatorySubmissionConfigurationModal.runAlgorithm')}
          </Button>
        )}
      </Footer>
    )
  }
)

export default DelConfigurationModalFooter
